import { Col, Row, Typography } from "antd"

const PreviewData = (props: any) => {
    return <>
    <Row style={{display: "flex", flexWrap: "wrap"}}>
        <Col className="requirement-card-info">
            <Typography className="description-label">Vehicle Type</Typography>
            <Typography style={{fontSize: 14}}>{props.data?.vehicleType}</Typography>
        </Col>
        <Col className="requirement-card-info">
            <Typography className="description-label">Vehicle Category</Typography>
            <Typography>{props.data?.vehicle && JSON.parse(props.data?.vehicle).name}</Typography>
        </Col>
        <Col className="requirement-card-info">
            <Typography className="description-label">Make</Typography>
            <Typography style={{fontSize: 14}}>{JSON.parse(props.data?.make).name}</Typography>
        </Col>
        


        <Col className="requirement-card-info">
            <Typography className="description-label">Model</Typography>
            <Typography style={{fontSize: 14}}>{JSON.parse(props.data?.model).name}</Typography>
        </Col>
        <Col className="requirement-card-info">
            <Typography className="description-label">Variant</Typography>
            <Typography style={{fontSize: 14}}>{JSON.parse(props.data?.variant).name}</Typography>
        </Col>
        <Col className="requirement-card-info">
            <Typography className="description-label">Buying City</Typography>
            <Typography style={{fontSize: 14}}>{props.data?.cityName}</Typography>
        </Col>
        <Col className="requirement-card-info">
            <Typography className="description-label">GEVL State</Typography>
            <Typography style={{fontSize: 14}}>{props.data?.stateName}</Typography>
        </Col>
        <Col className="requirement-card-info">
            <Typography className="description-label">Registered To</Typography>
            <Typography style={{fontSize: 14}}>{props.data?.registeredTo}</Typography>
        </Col>
        <Col className="requirement-card-info">
            <Typography className="description-label">Registration Type</Typography>
            <Typography style={{fontSize: 14}}>{props.data?.registrationType}</Typography>
        </Col>
        <Col className="requirement-card-info">
            <Typography className="description-label">Payment Type</Typography>
            <Typography style={{fontSize: 14}}>{props.data?.paymentType}</Typography>
        </Col>
        <Col className="requirement-card-info">
            <Typography className="description-label">Insurance Type</Typography>
            <Typography style={{fontSize: 14}}>{props.data?.insuranceType}</Typography>
        </Col>
        <Col className="requirement-card-info">
            <Typography className="description-label">Road Tax Type</Typography>
            <Typography style={{fontSize: 14}}>{props.data?.roadTaxType}</Typography>
        </Col>
        <Col className="requirement-card-info">
            <Typography className="description-label">No of Vehicle</Typography>
            <Typography style={{fontSize: 14}}>{props.data?.vehicleCount}</Typography>
        </Col>
        <Col className="requirement-card-info">
            <Typography className="description-label">Tenure in Month</Typography>
            <Typography style={{fontSize: 14}}>{JSON.parse(props.data?.tenure).name}</Typography>
        </Col>
        <Col className="requirement-card-info">
            <Typography className="description-label">Mileage per month(KM)</Typography>
            <Typography style={{fontSize: 14}}>{props.data?.mileage}</Typography>
        </Col>
        <Col className="requirement-card-info">
            <Typography className="description-label">Lease Type</Typography>
            <Typography style={{fontSize: 14}}>{props.data?.leaseType}</Typography>
        </Col>
    </Row>
    </>
}

export default PreviewData;