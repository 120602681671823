import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col, Select, DatePicker, InputNumber, Radio, Checkbox } from "antd";
import TagsInput from "../../../../components/TagInput";
import { getRequest } from "../../../../utils/handler/apiHandler";
const { Option } = Select;

function Step3Form(props: any) {
    const form = props.form;
    const [master, setMaster] = useState<any>([]);
    const paymentType: any = [
        {
            id: 1,
            paymentType: "Arrear"
        },
        {
            id: 2,
            paymentType: "Advance"
        }
    ];
    const billingType: any = [
        {
            id: 1,
            type: "Monthly"
        },
        {
            id: 1,
            type: "Quarterly"
        },
        {
            id: 1,
            type: "Half Yearly"
        },
        {
            id: 1,
            type: "Yearly"
        }
    ]

    useEffect(() => {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/all-masters`).then(res => {
            setMaster(res.data);
        })
    }, [])
    const optionsToRender = (options: any[], identifier: any) => {
        return options?.map((value: any) => (
            <Option key={value.id} value={value?.[identifier]}>
            {value?.[identifier]}
            </Option>
        ));
    };
    return ( 
        <React.Fragment>
            <Row className="">
                <Col className="form-column">
                    <Form.Item
                        label="Payment Type"
                        name="paymentType"
                        rules={[{ required: true, message: 'Please enter payment type!' }]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Payment Type"
                        >
                            {optionsToRender(paymentType, "paymentType")}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Billing Type"
                        name="billingType"
                        rules={[{ required: true, message: 'Please enter billing type!' }]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Billing Type"
                        >
                            {optionsToRender(billingType, "type")}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="LR Payable"
                        name="leaseRentalPayable"
                        rules={[{ required: true, message: 'Please enter lease rental payable!' }]}
                    >
                        <Input placeholder="LR Payable" />
                    </Form.Item>
                    <Form.Item
                        label="Delay Payment Charges(%)"
                        name="delayedPaymentChargePercentage"
                        // rules={[{ required: true, message: 'Please enter delayed payment charge percentage!' }]}
                    >
                        <Input placeholder="Delay Payment Charges" />
                    </Form.Item>
                </Col>
                <Col className="form-column">
                    <Form.Item
                        label="Payment Mode"
                        name="paymentMode"
                        rules={[{ required: true, message: 'Please enter payment mode!' }]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Payment Mode"
                        >
                            {optionsToRender(master?.payment_mode, "paymentMode")}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Lock in Period"
                        name="lockInPeriod"
                        rules={[{ required: true, message: 'Please enter lock in period!' }]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Lock in Period"
                        >
                            {optionsToRender(master?.lock_in_period, "lockInPeriod")}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Foreclosure Penalty"
                        name="foreclosurePenaltyPercentage"
                        // rules={[{ required: true, message: 'Please enter foreclosure penalty percentage!' }]}
                    >
                        <Input placeholder="Foreclosure Penalty" />
                    </Form.Item>
                    <Form.Item
                        label="Other Changes/Clauses"
                        name="otherClause"
                        //rules={[{ required: true, message: 'Please enter legal business name!' }]}
                    >
                        <Input placeholder="Other Changes/Clauses" />
                    </Form.Item>
                </Col>
            </Row>
        </React.Fragment>
     );
}

export default Step3Form;