export const getStageStyles = (stage: number) => {
    let color = '';
    let backgroundColor = '';

    // Define a map to store stage styling information
    const stageStylesMap: Map<number, { color: string, backgroundColor: string }> = new Map([
        [11, { color: '#14BE43', backgroundColor: '#2CE35F1A' }],
        [10, { color: '#D00E0E', backgroundColor: 'rgba(208, 14, 14, 0.10)' }],
        [8, { color: '#872CE3', backgroundColor: 'rgba(135, 44, 227, 0.10)' }],
        [7, { color: '#8EB100', backgroundColor: '#B8DF1B4D' }],
        [6, { color: '#2ACAE0', backgroundColor: '#2ACAE01A' }],
        [5, { color: '#E32CA5', backgroundColor: '#E32CA51A' }],
        [4, { color: '#0EAF92', backgroundColor: 'rgba(14, 175, 146, 0.10)' }],
        [3, { color: '#336699', backgroundColor: '#C5D9F1' }],
        [2, { color: '#E39A2C', backgroundColor: 'rgba(227, 154, 44, 0.10)' }],
        [9, { color: '#FF5733', backgroundColor: '#FFE6E0' }],
        [1, { color: '#D22CA5', backgroundColor: 'rgba(227, 154, 44, 0.20)' }],
    ]);

    // Check if stage exists in the map, otherwise set default styling
    const stageStyle = stageStylesMap.get(stage) || { color: '#2ACAE0', backgroundColor: '#2ACAE01A' };
    color = stageStyle.color;
    backgroundColor = stageStyle.backgroundColor;

    return { color, backgroundColor };
};
