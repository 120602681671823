import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface Auth {
  isAdmin: boolean;
  isAuth: boolean;
  isFinance: false,
  accessToken: string;
  refreshToken: string;
  idToken: string;
}

const initialState: Auth = {
  isAdmin: false,
  isAuth: false,
  isFinance: false,
  
  accessToken: "",
  refreshToken: "",
  idToken: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<Auth>) => {
      state.isAdmin = action.payload.isAdmin;
      state.isFinance = action.payload.isFinance;
      
      state.isAuth = action.payload.isAuth;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.idToken = action.payload.idToken;
    }
  },
});
export const { setAuth } = authSlice.actions;
export const authSelector = (state: RootState) => state.auth;
export default authSlice.reducer;