
const getDynamicAction = (menus: any, pageUrl: any, subMenuUrl: any) => {
    /* Get action item start */
    let actionItem = null;
    menus.map((menu: any) => {
        let submenu: any = null;
        if(menu.menu === subMenuUrl) {
            submenu = menu.submenu;
            submenu.map((action: any) => {
                if(action.url === `/${pageUrl}`) {
                    actionItem =  JSON.parse(action.actions).actions;
                }
            })
        }
    });
    return actionItem;
    /* Get action item end */
}

export default getDynamicAction;