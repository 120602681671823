export const getSampleQuoteStageStyles = (stage: number) => {
    let color = '';
    let backgroundColor = '';

    // Define a map to store stage styling information
    const stageStylesMap: Map<number, { color: string, backgroundColor: string }> = new Map([
        [1, { color: '#872CE3', backgroundColor: 'rgba(135, 44, 227, 0.10)' }],
        [2, { color: '#8EB100', backgroundColor: '#B8DF1B4D' }],
        [3, { color: '#0099AD', backgroundColor: 'rgba(0, 153, 173, 0.10)' }],
        [4, { color: '#14BE43', backgroundColor: '#2CE35F1A' }],
        [5, { color: '#5601C1', backgroundColor: 'rgba(86, 1, 193, 0.10)' }],
        [6, { color: '#FF4D4F', backgroundColor: 'rgba(255, 77, 79, 0.10)' }],
        [7, { color: '#E39A2C', backgroundColor: 'rgba(227, 154, 44, 0.10)' }],
        [8, { color: '#E32CDC', backgroundColor: 'rgba(227, 44, 220, 0.10)' }],
    ]);

    // Check if stage exists in the map, otherwise set default styling
    const stageStyle = stageStylesMap.get(stage) || { color: '#2ACAE0', backgroundColor: '#2ACAE01A' };
    color = stageStyle.color;
    backgroundColor = stageStyle.backgroundColor;

    return { color, backgroundColor };
};
