import React, { useState } from 'react';
import Modal from 'antd/lib/modal/Modal'; // Assuming you are using Ant Design modal
import Button from 'antd/lib/button/button'; // Assuming you are using Ant Design button

interface ConfirmPopupProps {
  visible: boolean;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmPopup: React.FC<ConfirmPopupProps> = ({
  visible,
  message,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal
      title="Confirm Submission"
      visible={visible}
      onCancel={onClose}
      centered={true}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="confirm" type="primary" onClick={onConfirm}>
          Confirm
        </Button>,
      ]}
    >
      {message}
    </Modal>
  );
};

export default ConfirmPopup;
