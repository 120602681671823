import axios from "axios";
import { store } from "../../app/store";
import jwtDecode from "jwt-decode";
import { keycloakConfig } from '../../utils/keycloak.config';
import { Alert, message } from "antd";
import axiosRetry from 'axios-retry';
axiosRetry(axios, { retries: 1 });
const { clientId, baseURL } = keycloakConfig;

export const signout = async () => {
    const refreshToken = store.getState().auth.refreshToken;
    try {
      await axios.post(`${baseURL}/realms/GensolEvLease/protocol/openid-connect/logout`, {
        client_id: clientId,
        refresh_token: refreshToken,
      },{
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }).then(res => {
        if(res.status === 204) {
            localStorage.removeItem("persist:root");
            window.location.replace("/")
        }
      });
    } catch (err) {
      console.log("Error: ", err);
    }
  }

const checkTokenValidity = () => {
    const accessToken = store.getState().auth.accessToken;
    const token: any = jwtDecode(accessToken);
    const timeStamp1 = token?.exp *1000;
    const timeStamp2 = Date.now();
    
    if(timeStamp1 < timeStamp2) {
        signout()
      }
}

export const getRequestForDownload = async (endpoint: string, contentType?: string) => {
    checkTokenValidity();
    const accessToken = store.getState().auth.accessToken;
    try {
        const response = await axios.get(endpoint,{
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                // "Content-Type": contentType?contentType:"application/json",
            },
            responseType: 'arraybuffer'
        });
        return {
            status: response.status,
            data: response.data,
            msg: "Fetched successfully"
        }
    } catch (err: any) {
        if(err?.response?.status === 401)
            signout();
        else if(err?.response?.data)
            message.error(err?.response?.data.message);
        else 
            message.error(err?.message);

        return {
            status: err?.response?.status,
            err: err?.response?.status,
            msg: err?.response?.statusText
        }
    }
}

export const getRequest = async (endpoint: string) => {
    checkTokenValidity();
    const accessToken = store.getState().auth.accessToken;
    try {
        const response = await axios.get(endpoint,{
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            }
        });
        return {
            status: response.status,
            data: response.data,
            msg: "Fetched successfully"
        }
    } catch (err: any) {
        if(err?.response?.status === 401)
            signout();
        else if(err?.response?.data)
            message.error(err?.response?.data.message);
        else 
            message.error(err?.message);

        return {
            status: err?.response?.status,
            err: err?.response?.status,
            msg: err?.response?.statusText
        }
    }
}

export const postRequest = async (endpoint: string, payload: any, contentType: string | undefined = "application/json") => {
    checkTokenValidity();
    const accessToken = store.getState().auth.accessToken;
    try {
        const response = await axios.post(
            endpoint,
            payload,
            {
                headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": contentType
                }
            });
        if(response.status === 201)
            message.success('Created successfully');
        return {
            status: response?.status,
            data: response.data,
            msg: "Created successfully"
        }
    } catch (err: any) {
        if(err.response.status === 401)
            signout();
        else if(err.response?.data)
            message.error(err.response?.data.message);
        else 
            message.error(err.message);

        return {
            status: err.response?.status,
            err: err.response.status,
            msg: err.response.statusText
        }
    }
}

export const putRequest = async (endpoint: string, payload: any) => {
    checkTokenValidity();
    const accessToken = store.getState().auth.accessToken;
    try {
        const response = await axios.put(
            endpoint,
            payload,
            {
                headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json"
                }
            });
        message.success('Updated successfully');
        return {
            status: response.status,
            data: response.data,
            msg: "Updated successfully"
        }
    } catch (err: any) {
        if(err.response.status === 401)
            signout();
        else if(err.response?.data)
            message.error(err.response?.data.message);
        else 
            message.error(err.message);

        return {
            status: err.response?.status,
            err: err.response.status,
            msg: err.response.statusText
        }
    }
}



export const handleFileDownload = async (endpoint: string, fileName: string, fileExtension: string) => {
    checkTokenValidity();
    const accessToken = store.getState().auth.accessToken;
    try {
        const response = await axios.get(
            endpoint,
            {
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                },
                responseType: 'arraybuffer'
        });

        const url =  window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.${fileExtension}`);
        document.body.appendChild(link);
        link.addEventListener('click', () => {
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
            }, 100);
        });
        link.click();
        // document.body.parentNode?.removeChild(link);
        message.success('Downloaded successfully.');
        return response.status;
    } catch (err: any) {
        if(err?.response?.status === 401)
            signout();
        else if(err?.response?.data)
            message.error(err?.response?.data.message);
        else 
            message.error(err?.message);

        message.error('Error while downloading!');
        return {
            status: err?.response?.status,
            err: err?.response?.status,
            msg: err?.response?.statusText
        }
    }
}