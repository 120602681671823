import { 
    SALES_ROLE, 
    SALES_EDITABLE_DOCUMENT_STATE, 
    CREDIT_ROLE, 
    CREDIT_EDITABLE_DOCUMENT_STATE 
  } from "../../../constants";
  
  export const getDisableModifyButtonFlag = (userRole: string, docStatusName: string): boolean => {
    if(!docStatusName && (SALES_ROLE.has(userRole) || userRole === 'GEVL-ADMIN')) return false;
    if (userRole === 'GEVL-ADMIN' || docStatusName === 'Review Completed') {
      return false;
    }
  
    if (SALES_ROLE.has(userRole)) {
      return !SALES_EDITABLE_DOCUMENT_STATE.has(docStatusName);
    }
  
    if (CREDIT_ROLE.has(userRole)) {
      return !CREDIT_EDITABLE_DOCUMENT_STATE.has(docStatusName);
    }
  
    // If userRole or docStatusName doesn't match any expected values, assume modification is not disabled
    return false;
  };
  