import { Button, Form, Input, Modal, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { ReactComponent as CrossIcon } from '../../../src/pages/Dashboard/Prospect/utils/SVGs/cross_icon.svg';
import { ReactComponent as TickIcon } from '../../../src/pages/Dashboard/Prospect/utils/SVGs/tick_icon_stroke.svg';
import { getStageStyles } from '../../pages/Dashboard/Prospect/utils/stageUtils';
import { getRequest, putRequest } from '../handler/apiHandler';
import { useNavigate, useLocation } from "react-router-dom";
import "../utils.css";
const { Option } = Select;

const OTHER = "Other";

interface ChangeStateModalProps {
  visible: boolean;
  currentState : string;
  currentStateId: number;
  currentStateOrder: number;
  prospectId: string|undefined;
  onSave: () =>void;
  onEditorClose: () => void;
}

const ChangeStateModal: React.FC<ChangeStateModalProps> = ({  
  visible,
  currentState,
  currentStateId,
  currentStateOrder,
  prospectId,
  onSave,
  onEditorClose,
}) => {
  const [changeToStages, setChangeToStages] = useState<any>();
  const [changeToStage, setChangeToStage] = useState<number|null>(null);
  const [lostReasons, setLostReasons] = useState<any>();
  const [otherSelection, setOtherSelection] = useState<boolean>(false);
  
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const { color, backgroundColor } = getStageStyles(currentStateId)
  useEffect(() => {
    const fetchChangeToStages = async () => {
      try {
        const res = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/stages?order=${currentStateOrder}`);
        if (res.status === 404 || res.status === 400) {
          navigate("/dashboard/prospect");
          return;
        }
        setChangeToStages(res.data);
      } catch (error) {
        console.error('Error fetching change to stages:', error);
      }
    };
  
    fetchChangeToStages();
  }, [currentStateOrder]);
  useEffect(()=>{
    const fetchLostReasons = async () => {
      try {
        const res = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/lost-reasons`);
        if (res.status === 404 || res.status === 400) {
          navigate("/dashboard/prospect");
          return;
        }
        setLostReasons(res.data);
      } catch (error) {
        console.error('Error fetching lost-reasons:', error);
      }
    };
  
    fetchLostReasons();
  },[])
  
  const handleChangeToSelect = (id:number)=>{
    setChangeToStage(id);
  }

  const handleOtherLostReason = (value : any) => {
      if(value.children == OTHER) {
        setOtherSelection(true);
      }else{
        setOtherSelection(false);
      }    
  }

  const getProspectLostReasonObj = (value:any) => {
    if (value) {
      const foundReason = lostReasons.find((reason:any) => reason.id === value);
      if (foundReason) {
        return JSON.stringify({
          id: foundReason.id,
          name: foundReason.name
        });
      }
    }
    return undefined;
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const response = await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/prospect/${prospectId}`, {
        stage: {
          "id": values.changeTo,
          "name": changeToStages.find((stage: any) => stage.id == values.changeTo)?.name
        },
        stageRemark: values.remarks,
        prospectLostReason: getProspectLostReasonObj(values.lostReason),
      });
  
      if (response.status === 404 || response.status === 400) {
        navigate("/dashboard/prospect");
        return;
      }
  
      onSave();
    } catch (errorInfo) {
      console.log('Validation failed:', errorInfo);
    }
  };
  const handleModalClose = () => {
    form.resetFields();
    setChangeToStage(null);
    setOtherSelection(false);
    onEditorClose();
  }
  
  return (
    <Modal
      title={<span style={{fontSize: 16, fontWeight: 700, color: "#404040"}}>Change State</span>}
      visible={visible}
      width={'375px'}
      onCancel={handleModalClose}
      className="change-state-modal-override"
      centered={true}
      footer={[
        <Button icon={<CrossIcon />} style={{color:'#FF4D4F',backgroundColor:'rgba(255, 77, 79, 0.10)',borderColor:'#FF4D4F', fontWeight: '500'}} key="cancel" onClick={handleModalClose}>
          Cancel
        </Button>,
        <Button icon={<TickIcon color='white'/>} style={{color:'#FFF',backgroundColor:'1777FF', fontWeight: '500'}} key="confirm" type="primary" onClick={handleSave}>
          Change State
        </Button>,  
      ]}
    >
      <div>
        <p className="description-label" style={{marginBottom:'15px', marginTop:'13px'}} >Current Stage: 
          <span style={{ marginLeft:'10px', padding: '0 8px', color: color, backgroundColor: backgroundColor, borderRadius: '4px'}}>{currentState}</span></p>
        <Form
                form={form}
                layout="vertical"
                requiredMark={false}
          >          
          <Row style={{ display: 'flex', alignItems: 'flex-start', alignSelf: 'stretch' }}>                
            <Form.Item style={{width:'100%', marginBottom: '8px'}}
                label={ <p className="description-label">Change To</p> }
                name="changeTo"
                rules={[{ required: true, message: 'Please enter Change To!' }]}
            >
                <Select onSelect={handleChangeToSelect} style={{ width: '100%' }} placeholder="Select stage you want to change to">
                  {changeToStages?.map((value: any) => {
                    const { color, backgroundColor } = getStageStyles(value.id)
                    return <Option key={value.id} value={value.id} title={<span style={{color:color,backgroundColor:backgroundColor}}>{value.name}</span>}>
                      <span style={{color:color,backgroundColor:backgroundColor}}>{value.name}</span>
                    </Option>
                  })}
                </Select>
            </Form.Item>            
              {changeToStage==10 && <Form.Item style={{width:'100%', marginBottom: '8px'}}
                label={ <p className="description-label">Lost Reason</p> }
                name = "lostReason"
                rules={[{ required: true, message: 'Please enter Lost Reason!' }]}
            >
                
                <Select onSelect={(key, value) => handleOtherLostReason(value)} style={{ width: '100%' }} placeholder="Select reason for lost state">
                  {lostReasons.map((value: any) => (
                    <Option key={value.id} value={value.id}>
                      {value.name}
                    </Option>
                  ))}
                </Select>
            </Form.Item>}
            <Form.Item style={{width:'100%', marginBottom: '8px'}}
                label={ <p className="description-label">Remarks {!otherSelection && <span style={{color:'#B2B2B2'}}>(Optional)</span>}</p> }
                name = "remarks"
                rules={[{  required: otherSelection ? true : false , message: 'Please enter Remarks!' }]}
            >
                <Input placeholder='Enter Remaks'></Input>
            </Form.Item>
          </Row>
        </Form>
      </div>
  </Modal>

  );
};

export default ChangeStateModal;