import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Form, Typography } from 'antd';
import { ReactComponent as DownMark } from '../../../../utils/SVGs/DownMark.svg';
import { ReactComponent as RightMark } from '../../../../utils/SVGs/RightMark.svg';
import { formatIndianRupee, formatRatioNumber } from '../formUtils';

interface DebtToEquityRatioProps {
    form: any; 
    viewModeActive: boolean;
    updateTotalCnt: number;
}

const DebtToEquityRatioComponent: React.FC<DebtToEquityRatioProps> = ({ form, updateTotalCnt, viewModeActive }) => {
    const [collapseDebtToEquityRatioFields, setCollapseDebtToEquityRatioFields] = useState<boolean>(false);
    const INR_SYMBOL: string = '\u20B9';
    
    return (
        <>
            <Row
                gutter={16}
                style={{ marginTop: '10px', paddingTop: '10px', borderBottom: '1px #E8E8E8 solid', paddingBottom: '20px' }}
            >
                <Col span={12}>
                    <span onClick={() => setCollapseDebtToEquityRatioFields((oldState) => !oldState)}>
                        {collapseDebtToEquityRatioFields ? <RightMark /> : <DownMark />}
                    </span>
                    <span style={{ fontSize: '14px', fontWeight: '600', marginLeft:'10px' }}>Debt to Equity Ratio</span>
                </Col>
                <Col span={12} key={1}>
                    <Typography style={{ fontSize: 14, fontWeight: '600', paddingLeft: '10px' }}>
                        { formatRatioNumber(form.getFieldValue('debtToEquityRatio')) }
                    </Typography>
                </Col>
                
            </Row>
            {
                !collapseDebtToEquityRatioFields && (
                    <>
                        <Row gutter={16} style={{ paddingTop: '20px', paddingBottom:'20px', borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                            <Col span={12}><p style={{ marginLeft: '20px' }}>Total Liabilities</p></Col>
                            <Col span={12} key={1}>
                                <Typography style={{ paddingLeft: '10px' }}>
                                    <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue('totalLiabilities'))}`}</span>
                                </Typography>
                            </Col>
                        </Row>

                        <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: '20px', borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                            <Col span={12}><p style={{ marginLeft: '20px' }}>Total Shareholder's Equity</p></Col>
                            <Col span={12} key={1}>
                                <Typography style={{ paddingLeft: '10px' }}>
                                    <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue('totalShareholderEquity'))}`}</span>
                                </Typography>
                            </Col>
                        </Row>
                    </>
                )
            }
        </>
    );
};

export default DebtToEquityRatioComponent;
