import React, { useEffect, useState } from 'react';
import { Button, Form, message, Spin, Steps, theme } from 'antd';
import Step1Form from './StepForm/Step1From';
import Step2Form from './StepForm/Step2From';
import Step3Form from './StepForm/Step3From';
import { getRequest, postRequest, putRequest } from '../../../utils/handler/apiHandler';
import { Context } from '../../../utils/helpers/Context';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router';
import { LoadingOutlined } from '@ant-design/icons';

const EditClientRequest = () => {
  // const state = useLocation().state;
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [loader, setLoader] = useState(false);
  const navigation = useNavigate();
  const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;
  const [contextState, setContextState] = useState<any>({
      master: [],
      clientId: "",
      business: [],
      businessSegement: []
  });

  const steps = [
    {
      title: 'First',
      content: <Step1Form form={form} />,
    },
    {
      title: 'Second',
      content: <Step2Form form={form} />,
    },
    {
      title: 'Third',
      content: <Step3Form form={form} />,
    },
  ];

  const next = async () => {
    try {
      await form.validateFields(['entityName', 'mlaNumbers', 'entityGstNumber', 'entityPanNumber','entityCinNumber', 'state', 'registrationAddress', 'clientOnBoardingDate', 'legalBusinessName', 'agreementType', 'parentGstNumber', 'parentCinNumber', 'businessSegment', 'registeredTo', 'downPaymentApplicable', 'insuranceType', 'serviceInclusions','primaryUseCase', 'roadTaxType', 'insuranceZeroDep', 'insuranceManaged']);
      setCurrent((prev)=>prev + 1);
  } catch {}
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  const page = useLocation().pathname.split("/").pop();
  useEffect(() => {
    getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/client-on-boarding?clientOnBoardingId=${page}`).then(res => {
      console.log(res.data[0]);
      form.setFieldsValue({
        creditRequestApprovalId: res.data[0].creditRequestApprovalId,
        clientId: res.data[0].clientId,
        entityName: res.data[0].entityName,
        legalBusinessName: res.data[0].legalBusinessName,
        mlaNumbers: res.data[0].mlaNumbers !== null ? res.data[0].mlaNumbers.split(",") : [],
        agreementType: res.data[0].agreementType,
        state: res.data[0].state,
        registrationAddress: res.data[0].registrationAddress,
        registrationDocUrl: res.data[0].registrationAddressDocUrl,
        invoiceAddress: res.data[0].invoiceAddresses !== "" ? res.data[0].invoiceAddresses.split(" |$#$| ") : [],
        communicationAddresses: res.data[0].communicationAddresses !== "" ? res.data[0].communicationAddresses.split(" |$#$| ") : [],
        businessSegment: res.data[0].businessSegment,
        entityGstNumber: res.data[0].entityGstNumber,
        entityCinNumber: res.data[0].entityCinNumber,
        entityPanNumber: res.data[0].entityPanNumber,
        parentGstNumber: res.data[0].parentGstNumber,
        parentCinNumber: res.data[0].parentCinNumber,
        parentPanNumber: res.data[0].parentPanNumber,
        insuranceManaged: res.data[0].insuranceManaged,
        insuranceZeroDep: res.data[0].insuranceZeroDep || false,
        roadTaxType: res.data[0].roadTaxType,
        clientOnBoardingDate: dayjs(res.data[0].clientOnBoardingDate),
        contactNumbers: res.data[0].contactNumbers !== null ? JSON.parse(res.data[0].contactNumbers) : [],
        emailIds: res.data[0].emailIds ? JSON.parse(res.data[0].emailIds) : [],
        registeredTo: res.data[0].registeredTo,
        primaryUseCase: res.data[0].primaryUseCase,
        otherUseCase: res.data[0].otherUseCase,
        downPaymentApplicable: res.data[0].downPaymentApplicable,
        insuranceType: res.data[0].insuranceType || "AYF",
        securityDepositApplicable: res.data[0].securityDepositApplicable,
        authorizeSignatories: res.data[0].authorizeSignatories ? JSON.parse(res.data[0].authorizeSignatories) : [],
        accountManagers: res.data[0].accountManagers ? JSON.parse(res.data[0].accountManagers) : [],
        bdManagers: res.data[0].bdManagers ? JSON.parse(res.data[0].bdManagers) : [],
        serviceInclusions: res.data[0].serviceInclusions ? JSON.parse(res.data[0].serviceInclusions) : [],
        paymentType: res.data[0].paymentType,
        paymentMode: res.data[0].paymentMode,
        billingType: res.data[0].billingType,
        lockInPeriod: res.data[0].lockInPeriod,
        leaseRentalPayable: res.data[0].leaseRentalPayable,
        foreclosurePenaltyPercentage: res.data[0].foreclosurePenaltyPercentage,
        delayedPaymentChargePercentage: res.data[0].delayedPaymentChargePercentage,
        otherClause: res.data[0].otherClause,
        isChild: res.data[0].isChild
      })
    });
  }, []);

  const contentStyle: React.CSSProperties = {
    marginTop: 16,
  };

  const onFinish = async (values: any) => {
    const formData = form.getFieldsValue(true);
    const payload = {
      creditRequestApprovalId: formData.creditRequestApprovalId,
      clientId: formData.clientId,
      entityName: formData.entityName,
      legalBusinessName: formData.legalBusinessName,
      mlaNumbers: formData.mlaNumbers.join(","),
      agreementType: formData.agreementType,
      state: formData.state,
      registrationAddress: formData.registrationAddress,
      registrationDocUrl: formData.registrationDocUrl,
      invoiceAddresses: formData.invoiceAddress !== null ? formData.invoiceAddress.join(" |$#$| ") : [],
      communicationAddresses: formData.communicationAddresses !== null ? formData.communicationAddresses.join(" |$#$| ") : [],
      isOtherSegment: formData.businessSegment === "Other",
      businessSegment: formData.businessSegment === "Other" ? formData.other : formData.businessSegment,
      entityGstNumber: formData.entityGstNumber,
      entityCinNumber: formData.entityCinNumber,
      entityPanNumber: formData.entityPanNumber,
      parentGstNumber: formData.parentGstNumber,
      parentCinNumber: formData.parentCinNumber,
      parentPanNumber: formData.parentPanNumber,
      clientOnBoardingDate: dayjs(formData.clientOnBoardingDate).format("YYYY-MM-DD"),
      contactNumbers: JSON.stringify(formData.contactNumbers),
      emailIds: JSON.stringify(formData.emailIds),
      registeredTo: formData.registeredTo,
      primaryUseCase: formData.primaryUseCase,
      otherUseCase: formData.otherUseCase !== undefined ? formData.otherUseCase: null,
      downPaymentApplicable: formData.downPaymentApplicable,
      securityDepositApplicable: formData.securityDepositApplicable,
      authorizeSignatories: JSON.stringify(formData.authorizeSignatories),
      accountManagers: JSON.stringify(formData.accountManagers),
      bdManagers: JSON.stringify(formData.bdManagers),
      roadTaxType: formData.roadTaxType,
      insuranceManaged: formData.insuranceManaged,
      insuranceType: formData.insuranceManaged ? formData.insuranceType : null,
      insuranceZeroDep: formData.insuranceManaged ? formData.insuranceZeroDep : null,
      serviceInclusions: JSON.stringify(formData.serviceInclusions),
      paymentType: formData.paymentType,
      paymentMode: formData.paymentMode,
      billingType: formData.billingType,
      lockInPeriod: formData.lockInPeriod,
      leaseRentalPayable: formData.leaseRentalPayable,
      foreclosurePenaltyPercentage: formData.foreclosurePenaltyPercentage,
      delayedPaymentChargePercentage: formData.delayedPaymentChargePercentage,
      otherClause: formData.otherClause
    }

    setLoader(true);
    if(formData.primaryUseCase == "Other") {
      postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/client-use-case`, {
        data: formData.otherUseCase
      }).then(res => {
        message.destroy();
        putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/client-on-boarding/${page}`, payload).then(res => {
          setLoader(false);
        })
        navigation("/dashboard/client");
      })
    } else {
      payload.otherUseCase = null;
      putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/client-on-boarding/${page}`, payload).then(res => {
        setLoader(false);
        navigation("/dashboard/client");
      });
    }
  }

  return (
    <Context.Provider value={{ contextState, setContextState }}>
      <Steps current={current} items={items} />
      <div style={contentStyle}>
        <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        onKeyDown={(e)=> e.key == "Enter" ? e.preventDefault(): ''}
        >
            {steps[current].content}

        <Form.Item>
        <div style={{ marginTop: 24 }}>
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" htmlType="submit">
            Done
            {loader ? <Spin indicator={antIcon} /> : ""}
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </div>
        </Form.Item>

        </Form>
        </div>
    </Context.Provider>
  );
};

export default EditClientRequest;