import React, { useEffect, useState } from "react";
import { Form, Input, Button, Divider, Row, Col, message, Table, List, Typography, Tooltip } from "antd";
import { authSelector } from "../../../features/auth/authSlice";
import { useAppSelector } from "../../../app/hooks";
import axios from "axios";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

interface Roles {
    srNo: number,
    roleName: string
}

function CreateRole() {
    const tooltip_message = "Enter role name with GEVL- prefix to successfully reflect in system, e.g. GEVL-SALES."
    const [form] = Form.useForm();
    const auth = useAppSelector(authSelector);
    const [messageApi, contextHolder] = message.useMessage();
    const [role, setRole] = useState([]);

    const onFinish = async (values: any) => {
        let payload = {
            name: values.role
        }
        
        if(values.role.split("-")[0].toUpperCase() !== "GEVL") {
            messageApi.warning("Role name must start with GEVL prefix!");
            return;
        }

        try {
            await axios.post(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/roles`, payload, {
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`,
                    "Content-Type": "application/json"
                }
            }).then(res => {
                if(res.status == 201)
                    messageApi.success("Role added!");
                       window.location.reload();

            })
        } catch (err: any) {
            console.log("Error: ", err);
            messageApi.error(`${err?.response?.data?.errors[0]}`);

        }
    };
    const columns: ColumnsType<Roles> = [
        {
            title: 'Sr. No.',
            dataIndex: 'srNo',
            key: 'srNo',
            width: '15%',
            // ...getColumnSearchProps('name'),
        },
        {
            title: 'Role',
            dataIndex: 'roleName',
            key: 'roleName',
            width: '30%',
            // ...getColumnSearchProps('emailId'),
        }
    ];
    useEffect(() => {
        const fetchRole = async () => {
            try {
                await axios.get(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/roles`, {
                    headers: {
                        "Authorization": `Bearer ${auth.accessToken}`,
                        "Content-Type": "application/json"
                    }
                }).then(res => {
                    let input: any = [];
                    res.data.map((e: any, i: number) => {
                        input.push({
                            srNo: i+1,
                            roleName: e.name
                        });
                    })
                    setRole(input);
                })
            } catch (err) {
                console.log("Error: ", err);
            }
        }
        fetchRole()
    }, []);
    return (
        <React.Fragment>
            {contextHolder}
            <div className="page-header">
            <Form 
                form={form}
                onFinish={onFinish}
                layout="inline"
                style={{marginBottom: 10}}
            >
                <Form.Item
                    name="role"
                    rules={[{ required: true, message: 'Please enter the role!' }]}
                >
                    <Input placeholder="ex. GEVL-ROLE_NAME" />
                </Form.Item>
                <Form.Item>
                <Button type="primary" htmlType="submit" icon={<PlusOutlined />}>
                    Add Role
                </Button>
                </Form.Item>
            </Form>
            </div>

            <Table 
                columns={columns} 
                dataSource={role}
                pagination={{
                    pageSize: 8,
                }}
                scroll={{ x: 800 }}
            />
        </React.Fragment>     
    )
            
};

export default CreateRole;