import React, { useEffect, useState, useContext } from "react";
import { Form, Input, Row, Col, InputNumber, Radio, Space, Select, Upload, Button, message, Divider, DatePicker, Checkbox, Modal, Tooltip } from "antd";
import { FilePdfOutlined, LinkOutlined, LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from 'antd';
import { getRequest, postRequest } from "../../../../utils/handler/apiHandler";
import { authSelector } from "../../../../features/auth/authSlice";
import { useAppSelector } from "../../../../app/hooks";
import { Context } from '../../../../utils/helpers/Context';
import axios from "axios";
import { isArray } from "lodash";
// import { TagsInput } from "react-tag-input-component";
import TagsInput from "../../../../components/TagInput";
const { Option } = Select;

function Step1Form(props: any) {
    const form = props.form;
    const auth = useAppSelector(authSelector);
    const [file, setFile] = useState<any>(null);
    const [parentEnable, setParentEnable] = useState(form.getFieldsValue(true).isChild);
    const [businessName, setBusinessName] = useState<any>([]);
    const [master, setMaster] = useState<any>([]);
    const [businessSegment, setBusinessSegment] = useState<any>([]);
    const [emailId, setEmailId] = useState<any>([]);
    const [contact, setContact] = useState<any>([]);
    const [communicationAddressTags, setCommunicationAddressTags] = useState<any>(form.getFieldsValue(true).communicationAddresses);
    const [invoiceAddressTags, setInvoiceAddressTags] = useState<any>(form.getFieldsValue(true).invoiceAddress);
    const [contactNumber, setContactNumber] = useState<any>([]);
    const [registrationAddressDoc, setRegistrationAddressDoc] = useState<any>(null);
    const [registrationAddress, setRegistrationAddress] = useState<any>(null);
    const [communicationAddress, setCommunicationAddress] = useState<any>([]);
    const [invoiceAddress, setInvoiceAddress] = useState<any>([]);
    const [businessEntity, setBusinessEntity] = useState<any>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [fileLoader, setFileLoader] = useState(false);
    const [modalDocUrl, setModalDocUrl] = useState<any>("");
    const tooltip = form.getFieldsValue(true).registrationDocUrl || "";
    const [otherSegment, setOtherSegment] = useState<boolean>(form.getFieldsValue(true).businessSegment === "Other");

    const [docURI, setDocURI] = useState<any>([]);
    useEffect(() => {
        if(registrationAddressDoc !== null && businessEntity !== null || props.state && registrationAddressDoc !== null ) {
            setFileLoader(true)
            var bodyFormData = new FormData();
            bodyFormData.append('file', registrationAddressDoc);
            bodyFormData.append('clientId', businessEntity?.clientId || props.state.clientId);
            bodyFormData.append('clientName', businessEntity?.entityName || props.state.entityName);
            bodyFormData.append('documentType', "Registration Address Document");
            axios({
                method: "post",
                url: `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/file/upload`,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data", "Authorization": `Bearer ${auth.accessToken}` },
              }).then(response => {
                setDocURI([...docURI, response?.data?.DocumentUrl])
                  message.success(response?.data?.msg)
                  setFileLoader(false);
                }).catch((err: any) => {
                    setRegistrationAddressDoc(null);
                    message.error(err?.response?.data?.message);
                    setFileLoader(false);
                });
            }
    }, [registrationAddressDoc]);
    
    useEffect(() => {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/entity-on-boarding`).then(res => {
            let data: any = [];
            res?.data?.map((entity: any, i: any) => {
                data.push({ ...entity, id: i });
            })
            setBusinessName(data);
        });
        
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/all-masters`).then(res => {
            setMaster(res?.data);
        });
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/segments`).then(res => {
            setBusinessSegment(res?.data);
        })
    }, []);

    const handleBusinessName = (value: any) => {
        form.setFieldsValue({
            isChild: businessName[value]?.isChild
        })
        if(!businessName[value]?.isChild)
        form.setFieldsValue({
            parentGstNumber: null,
            parentCinNumber: null,
            parentPanNumber: null,
        })
        setParentEnable(businessName[value]?.isChild);
        setBusinessEntity(businessName[value])

        form.setFieldsValue({
            creditRequestApprovalId: businessName[value].creditRequestApprovalId,
            clientId: businessName[value].clientId,
            legalBusinessName: businessName[value].legalBusinessName,
            mlaNumbers: businessName[value].mlaNumbers,
            entityName: businessName[value].entityName,
            businessSegment: businessName[value].businessSegment,
            authorizeSignatories: JSON.parse(businessName[value].authorizedSignatories)
        });
    }


    const optionsRenderer = (options: any[], identifier: any) => {
        if(isArray(options))
        return options?.map((value: any) => (
            <Option key={value.id} value={value?.id}>
            {value?.[identifier]}
            </Option>
        ));
    };

    const handleAddress = (value: any, checked: any) => {        
        if(value == "invoiceAddress") {
            const registrationAddress: any = form.getFieldsValue(true).registrationAddress
            if(checked.target.checked) {
                selectInvoiceAddress([registrationAddress]);
                setInvoiceAddress([registrationAddress]);
                form.setFieldsValue({
                    [value]: [registrationAddress]
                });
            }
            else {
                setInvoiceAddress([]);
                form.setFieldsValue({
                    [value]: []
                });
            }
        }
        if(value == "communicationAddresses") {
            if(checked.target.checked) {
                setCommunicationAddress([registrationAddress]);
                form.setFieldsValue({
                    [value]: [form.getFieldsValue(true).registrationAddress]
                });
            }
            else {
                setCommunicationAddress([]);
                form.setFieldsValue({
                    [value]: []
                });
            }
        }
    }

    const selectedEmail = (tags: any) => {
        setEmailId(tags)
        form.setFieldsValue({
            emailIds: tags
        })
    }
    const selectedContact = (tags: any) => {
        setContact(tags)
        form.setFieldsValue({
            contactNumbers: tags
        })
    }
    const selectCommunicationAddress = (tags: any)=> {
        setCommunicationAddressTags(tags)
        form.setFieldsValue({
            communicationAddresses: tags
        })
    }
    const selectInvoiceAddress = (tags: any)=> {
        setInvoiceAddressTags(tags)
        form.setFieldsValue({
            invoiceAddress: tags
        })
    }


    const optionsToRender = (options: any[], identifier: any) => {
        if(isArray(options))
        return options?.map((value: any) => (
            <Option key={value.id} value={value?.[identifier]}>
            {value?.[identifier]}
            </Option>
        ));
    };

    const handleDocViewer = (url: any) => {
        if(url == undefined) return;
        setModalDocUrl(url);
        setModalOpen(true);
    }

    return ( 
        <React.Fragment>
            <Row className="">
                <Col className="form-column">
                <Form.Item
                        label="Entity Name"
                        name="entityName"
                        rules={[{ required: true, message: 'Please form entity name!' }]}
                    >
                        <Select
                            showSearch={true}
                            style={{ width: '100%' }}
                            placeholder="Entity Name"
                            onChange={value => handleBusinessName(value)}
                        >
                            {optionsRenderer(businessName, "entityName")}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Entity GST Number"
                        name="entityGstNumber"
                        rules={[{ required: true, message: 'Please enter entity GST number!' }]}
                    >
                        <Input placeholder="Entity GST Number" />
                    </Form.Item>
                    <Form.Item
                        label="Entity PAN Number"
                        name="entityPanNumber"
                        rules={[{ required: true, message: 'Please enter entity PAN number!' }]}
                    >
                        <Input placeholder="Entity PAN Number" />
                    </Form.Item>
                    <Form.Item
                        label="Entity CIN Number"
                        name="entityCinNumber"
                        rules={[{ required: true, message: 'Please enter entity CIN number!' }]}
                    >
                        <Input placeholder="Entity CIN Number" />
                    </Form.Item>
                    <Form.Item
                        label="Client On-boarding Date"
                        name="clientOnBoardingDate"
                        rules={[{ required: true, message: 'Please enter client on boarding date!' }]}
                    >
                        <DatePicker
                            format="YYYY-MM-DD"
                            style={{ width: "100%"}}
                        />
                    </Form.Item>
                    <Form.Item
                        label="State"
                        name="state"
                        rules={[{ required: true, message: 'Please enter state name!' }]}
                    >
                        <Select
                            showSearch={true}
                            style={{ width: '100%' }}
                            placeholder="State"
                        >
                            {optionsToRender(master?.states, "stateName")}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Contact Number(s)"
                        name="contactNumbers"
                        // rules={[{ required: true, message: 'Please enter contact Number!' }]}
                    >
                        <TagsInput selectedTags={selectedContact} maxlength={10} placeholder="Contact Number(s)" tags={form.getFieldsValue(true).contactNumbers == null ? [] : form.getFieldsValue(true).contactNumbers}/>
                    </Form.Item>

                    <div style={{display: "flex", alignItems: "center", width: "100%"}}>
                    <Form.Item
                        label="Registration Address"
                        name="registrationAddress"
                        style={{width: "100%"}}
                        rules={[{ required: true, message: 'Please enter registration address!' }]}
                    >
                        <Input value={form.getFieldsValue().registrationAddress} onChange={(e) => setRegistrationAddress(e.target.value)} placeholder="Registration Address" />
                    </Form.Item>
                    <Upload
                        maxCount={1}
                        showUploadList={false}
                        beforeUpload={(file) => {
                            form.setFieldsValue({ registrationDoc: file })
                            setRegistrationAddressDoc(file)
                            return false
                        }}
                    >
                        <Button style={{marginTop: 8, marginLeft: 8, height: 40}} icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                    {
                        fileLoader 
                        ? <LoadingOutlined 
                            style={{
                                color: "blue", 
                                fontSize: 24, 
                                marginLeft: 5, 
                                marginTop: 2,
                            }} /> 
                        : form.getFieldsValue(true).registrationDocUrl 
                        ? 
                        <Tooltip title={tooltip.split(".com/")[1]}>
                            <LinkOutlined 
                                style={{
                                        color: "blue", 
                                        fontSize: 24, 
                                        marginLeft: 5, 
                                        marginTop: 2
                                    }} 
                                onClick={() => handleDocViewer(form.getFieldsValue(true).registrationDocUrl)} 
                            /> 
                        </Tooltip>
                    : null
                    }
                    </div>

                    <Form.Item
                        label="Invoice Address"
                        name="invoiceAddress"
                        //rules={[{ required: true, message: 'Please enter legal business name!' }]}
                    >
                        <TagsInput selectedTags={selectInvoiceAddress} checkbox={{placeholder: "Same as registration", data: [form.getFieldsValue(true).registrationAddress]}} placeholder="Invoice Address(s)" tags={form.getFieldsValue(true).invoiceAddress}/>
                    </Form.Item>
                </Col>
                <Col className="form-column">
                    <Form.Item
                        label="Legal Business Name"
                        name="legalBusinessName"
                        rules={[{ required: true, message: 'Please enter legal business name!' }]}
                    >
                        <Input disabled={true} placeholder="Legal Business Name" />
                    </Form.Item>
                    <Form.Item
                        label="Parent GST Number"
                        name="parentGstNumber"
                        //rules={[{ required: true, message: 'Please enter legal business name!' }]}
                    >
                        <Input disabled={!parentEnable} placeholder="Parent GST Number" />
                    </Form.Item>
                    <Form.Item
                        label="Parent PAN Number"
                        name="parentPanNumber"
                        //rules={[{ required: true, message: 'Please enter legal business name!' }]}
                    >
                        <Input disabled={!parentEnable} placeholder="Parent CIN Number" />
                    </Form.Item>
                    <Form.Item
                        label="Parent CIN Number"
                        name="parentCinNumber"
                        //rules={[{ required: true, message: 'Please enter legal business name!' }]}
                    >
                        <Input disabled={!parentEnable} placeholder="Parent CIN Number" />
                    </Form.Item>
                    <Form.Item
                        label="Agreement Type"
                        name="agreementType"
                        rules={[{ required: true, message: 'Please enter agreement type!' }]}
                    >
                        <Select
                            showSearch={true}
                            style={{ width: '100%' }}
                            placeholder="Agreement Type"
                        >
                            {optionsToRender(master?.agreement_type, "agreementType")}
                        </Select>
                    </Form.Item>
                    <div style={{display: "flex", alignItems: "flex-end"}}>
                    <Form.Item
                        style={{width: "100%"}}
                        label="Business Segment"
                        name="businessSegment"
                        rules={[{ required: true, message: 'Please enter business segment!' }]}
                    >
                        <Select
                            showSearch={true}
                            style={{ width: '100%' }}
                            placeholder="Business Segement"
                            onChange={(e) => setOtherSegment(e === "Other")}
                        >
                        {optionsToRender(businessSegment, "name")}
                        </Select>
                    </Form.Item>
                    {
                        otherSegment
                        ? <Form.Item
                            className="secondary-field"
                            name='other'
                            rules={[{ required: true, message: 'Please enter Business Segment' }]}
                        >
                            <Input placeholder="Other business segment" />
                        </Form.Item>
                        : null
                    }
                    </div>
                    <Form.Item
                        label="Email ID"
                        name="emailIds"
                        //rules={[{ required: true, message: 'Please enter legal business name!' }]}
                    >
                        <TagsInput selectedTags={selectedEmail} placeholder="Email ID(s)" tags={form.getFieldsValue(true).emailIds == null ? [] : form.getFieldsValue(true).emailIds}/>
                    </Form.Item>


                    <Form.Item
                        label="Communication Address"
                        name="communicationAddresses"
                        //rules={[{ required: true, message: 'Please enter legal business name!' }]}
                    >
                        <TagsInput selectedTags={selectCommunicationAddress} checkbox={{placeholder: "Same as registration", data: [form.getFieldsValue(true).registrationAddress]}} placeholder="Invoice Address(s)" tags={form.getFieldsValue(true).communicationAddresses}/>
                    </Form.Item>
                </Col>
            </Row>
            <Modal
                centered
                open={modalOpen}
                width={1500}
                style={{}}
                onCancel={() => setModalOpen(false)}
                footer={[
                    <Button type="primary" key="cancel" onClick={() => setModalOpen(false)}>
                    Close
                    </Button>,
                ]}
            >
            <iframe
                title="docx"
                src={modalDocUrl}
                style={{ width: "100%", height: "60vh", border: "none" }}
            />
            </Modal>
        </React.Fragment>
     );
}

export default Step1Form;