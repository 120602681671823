import { Modal } from 'antd';
import axios from 'axios';

type FieldType = {
  [key: string]: string | number | boolean;
};

async function submitTheFormData(
  currentStep: number,
  stepsLength: number,
  quoteId: number,
  formData: FieldType,
  formType: string,
  auth: { accessToken: string },
  messageApi: any,
  navigate: any,
  setDisableSubmitButton: React.Dispatch<React.SetStateAction<boolean>>,
) {

  if (currentStep !== stepsLength - 1) {
    return;
  }
  
  try {
    const url =
      formType === 'UPDATEQUOTE'
        ? `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/${quoteId}`
        : `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote`;

    const response = formType === 'UPDATEQUOTE'
      ? await axios.put(url, formData, { headers: { Authorization: `Bearer ${auth.accessToken}` } })
      : await axios.post(url, formData, { headers: { Authorization: `Bearer ${auth.accessToken}` } });

   messageApi.success(formType === 'UPDATEQUOTE' ? 'Quote Updated!' : 'Quote Created!');
    setTimeout(()=>{
      navigate('/dashboard/quote');
    },500);
    
  } catch (error:any) {
    console.log(`error while submit =%o`,error.message)
    setDisableSubmitButton(false);
    const errorMessage =   `Error ${formType === 'UPDATEQUOTE' ? 'updating' : 'creating'} the Quote: ${error.message}`;
    
    Modal.error({
      title: 'Error',
      content: errorMessage,
  });
  }
}

export default submitTheFormData;
