import { useEffect, useState } from "react";
import { Button, Card, Divider, Modal, Row, Tag, Typography } from "antd";
import { getFormattedDate } from "../../../../utils/helpers/utils";
import { convertUtcToIst } from "../../../../utils/helpers/convertUTCtoIST";
import { ReactComponent as DownloadIcon } from '../utils/SVGs/download_icon.svg';
import InfiniteScroll from "react-infinite-scroll-component";
import { getRequest } from "../../../../utils/handler/apiHandler";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAppSelector } from "../../../../app/hooks";
import { authSelector } from "../../../../features/auth/authSlice";
import _ from "lodash";

interface RequirementVersionHistoryModalProps {
    data: any;
    showVersionHistoryModal: boolean;
    setShowVersionHistoryModal: (showVersionHistoryModal: boolean) => void;
    prospectName: any
}
const RequirementVersionHistoryModal: React.FC<RequirementVersionHistoryModalProps>= ({ data, showVersionHistoryModal, setShowVersionHistoryModal, prospectName }) => {
    const navigate = useNavigate();
    const auth = useAppSelector(authSelector);
    const [loader, setLoader] = useState(false);
    const [infiniteLoader, setInfiniteLoader] = useState(false);
    const [requirementModalData, setRequirementModalData] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(0); // Initialize currentPage to 1
    const [totalPages, setTotalPages] = useState(1); // Initialize totalPages to 1
    const REQUIREMENTSPAGESIZE = 10;

    const fetchMoreData = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    useEffect(() => {
        try {
            setLoader(true);
            const requirementId = data.requirementId
            getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/requirement_logs?requirementId=${requirementId}&page=${currentPage}&size=${REQUIREMENTSPAGESIZE}`).then(res => {
                const { data, pageCount: newTotalPages } = res?.data; // Assuming totalPages is returned from API
                setRequirementModalData((prevData: any) => [...prevData, ...data]);
                setTotalPages(newTotalPages);   
                setInfiniteLoader(false);
                setLoader(false);
            });
        } catch (err) { console.log("Error: ", err) }
      }, [currentPage]);

      const downloadQuotePreviewExcel = async (quoteId: any) => {
        try {
          const res = await axios.post(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/report/excel/${quoteId}`,
          {},
          {
              headers: {
                  "Authorization": `Bearer ${auth.accessToken}`
              },
              responseType: 'arraybuffer'
          }
          );
          const url =  window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${prospectName}_${quoteId}.xlsx`);
          document.body.appendChild(link);
          link.addEventListener('click', () => {
              setTimeout(() => {
                  window.URL.revokeObjectURL(url);
              }, 100);
          });
          link.click();
          document.body.parentNode?.removeChild(link);
      } catch (error) {
          console.error('Error fetching dbData:', error);
      }
      }

    return (
        <Modal
            title={<Typography className="fixed-header">Requirement {data?.requirementNumber} Version History</Typography>}
            centered
            open={showVersionHistoryModal}
            onOk={() => setShowVersionHistoryModal(false)}
            onCancel={() => setShowVersionHistoryModal(false)}
            footer={null}
            width={"80%"}
            className="requirement-modal"
        >
        <div style={{marginTop: 25, padding: 20}}>
        
        <InfiniteScroll
            dataLength={requirementModalData.length}
            next={fetchMoreData}
            hasMore={currentPage < totalPages-1 && !infiniteLoader}
            loader={<Row justify="space-between" className="shimmer-ui" style={{ height: 40, borderRadius: 12, marginBottom: 8 }} />}
            height="auto"
            style={{ overflowY: 'scroll', scrollbarWidth: 'none', marginTop: 20, maxHeight: 400 }}
        >
            {requirementModalData.map((e: any) => {
                let changeDetails = e.changeDetails && JSON.parse(e.changeDetails);
                return <Card className="requirement-card-info-container" style={{marginTop: 12, border: "none"}}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <Typography style={{fontWeight: 500, fontSize: 14, marginBottom: 10, color: "#585858"}}>{getFormattedDate(convertUtcToIst(e.createdAt))}</Typography>
                        {e.quoteId && <Button
                            style={{ padding: 5, border: 'none', backgroundColor: 'transparent', boxShadow: 'none', fontSize: '12px', fontWeight: 500, color: '#2C66E3' }}
                            onClick={_=> downloadQuotePreviewExcel(e.quoteId)}
                        >
                            <span style={{ color: '#2C66E3', marginRight:'0.4rem' }}>
                                <DownloadIcon />
                            </span>
                            Download Sample Quotes
                        </Button>}
                    </div>
                    <Divider style={{margin: 0}}/>
                    <div style={{display: "flex", flexWrap: "wrap", gap: "0 50px"}}>
                        <div className="requirement-card-info">
                        <Typography className="description-label">Vehicle Type{changeDetails && changeDetails.vehicleType ? <span className="change-tag">Changed</span> : null}</Typography>
                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14}}>
                            <span style={{display: loader?"none":"", color: changeDetails && changeDetails.vehicleType ? "#FFA800": "#000000"}}>{
                                loader
                                ? ""
                                : e.vehicleType
                            }</span>
                        </Typography>
                        </div>
                        <div className="requirement-card-info">
                        <Typography className="description-label">Vehicle Category{changeDetails && changeDetails.vehicle ? <span className="change-tag">Changed</span> : null}</Typography>
                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14}}>
                            <span style={{display: loader?"none":"", color: changeDetails && changeDetails.vehicle ? "#FFA800": "#000000"}}>{
                                loader
                                ? ""
                                : e?.vehicle && JSON.parse(e?.vehicle).name
                            }</span>
                        </Typography>
                        </div>
                        <div className="requirement-card-info">
                        <Typography className="description-label">Make{changeDetails && changeDetails.make ? <span className="change-tag">Changed</span> : null}</Typography>
                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14}}>
                            <span style={{display: loader?"none":"", color: changeDetails && changeDetails.make ? "#FFA800": "#000000"}}>{
                                loader
                                ? ""
                                : e?.vehicle && JSON.parse(e?.make).name
                            }</span>
                        </Typography>
                        </div>
                        <div className="requirement-card-info">
                        <Typography className="description-label">Model{changeDetails && changeDetails.model ? <span className="change-tag">Changed</span> : null}</Typography>
                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14}}>
                            <span style={{display: loader?"none":"", color: changeDetails && changeDetails.model ? "#FFA800": "#000000"}}>{
                                loader
                                ? ""
                                : e?.vehicle && JSON.parse(e?.model).name
                            }</span>
                        </Typography>
                        </div>
                        <div className="requirement-card-info">
                        <Typography className="description-label">Variant{changeDetails && changeDetails.variant ? <span className="change-tag">Changed</span> : null}</Typography>
                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14}}>
                            <span style={{display: loader?"none":"", color: changeDetails && changeDetails.variant ? "#FFA800": "#000000"}}>{
                                loader
                                ? ""
                                : e?.vehicle && JSON.parse(e?.variant).name
                            }</span>
                        </Typography>
                        </div>
                        <div className="requirement-card-info">
                        <Typography className="description-label">Buying City{changeDetails && changeDetails.city ? <span className="change-tag">Changed</span> : null}</Typography>
                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14}}>
                            <span style={{display: loader?"none":"", color: changeDetails && changeDetails.city ? "#FFA800": "#000000"}}>{
                                loader
                                ? ""
                                : e.cityName
                            }</span>
                        </Typography>
                        </div>
                        <div className="requirement-card-info">
                        <Typography className="description-label">GEVL State{changeDetails && changeDetails.state ? <span className="change-tag">Changed</span> : null}</Typography>
                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14}}>
                            <span style={{display: loader?"none":"", color: changeDetails && changeDetails.state ? "#FFA800": "#000000"}}>{
                                loader
                                ? ""
                                : e.stateName
                            }</span>
                        </Typography>
                        </div>
                        <div className="requirement-card-info">
                        <Typography className="description-label">Registered To{changeDetails && changeDetails.registeredTo ? <span className="change-tag">Changed</span> : null}</Typography>
                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14}}>
                            <span style={{display: loader?"none":"", color: changeDetails && changeDetails.registeredTo ? "#FFA800": "#000000"}}>{
                                loader
                                ? ""
                                : e.registeredTo
                            }</span>
                        </Typography>
                        </div>
                        <div className="requirement-card-info">
                        <Typography className="description-label">Registration Type{changeDetails && changeDetails.registrationType ? <span className="change-tag">Changed</span> : null}</Typography>
                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14}}>
                            <span style={{display: loader?"none":"", color: changeDetails && changeDetails.registrationType ? "#FFA800": "#000000"}}>{
                                loader
                                ? ""
                                : e.registrationType
                            }</span>
                        </Typography>
                        </div>
                        <div className="requirement-card-info">
                        <Typography className="description-label">Payment Type{changeDetails && changeDetails.paymentType ? <span className="change-tag">Changed</span> : null}</Typography>
                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14}}>
                            <span style={{display: loader?"none":"", color: changeDetails && changeDetails.paymentType ? "#FFA800": "#000000"}}>{
                                loader
                                ? ""
                                : e.paymentType
                            }</span>
                        </Typography>
                        </div>
                        <div className="requirement-card-info">
                        <Typography className="description-label">Insurance Type{changeDetails && changeDetails.insuranceType ? <span className="change-tag">Changed</span> : null}</Typography>
                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14}}>
                            <span style={{display: loader?"none":"", color: changeDetails && changeDetails.insuranceType ? "#FFA800": "#000000"}}>{
                                loader
                                ? ""
                                : e.insuranceType
                            }</span>
                        </Typography>
                        </div>
                        <div className="requirement-card-info">
                        <Typography className="description-label">Road Tax Type{changeDetails && changeDetails.roadTaxType ? <span className="change-tag">Changed</span> : null}</Typography>
                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14}}>
                            <span style={{display: loader?"none":"", color: changeDetails && changeDetails.roadTaxType ? "#FFA800": "#000000"}}>{
                                loader
                                ? ""
                                : e.roadTaxType
                            }</span>
                        </Typography>
                        </div>
                        <div className="requirement-card-info">
                        <Typography className="description-label">No of Vehicles{changeDetails && changeDetails.vehicleCount ? <span className="change-tag">Changed</span> : null}</Typography>
                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14}}>
                            <span style={{display: loader?"none":"", color: changeDetails && changeDetails.vehicleCount ? "#FFA800": "#000000"}}>{
                                loader
                                ? ""
                                : e.vehicleCount
                            }</span>
                        </Typography>
                        </div>
                        <div className="requirement-card-info">
                        <Typography className="description-label">Tenure in Month{changeDetails && changeDetails.tenure ? <span className="change-tag">Changed</span> : null}</Typography>
                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14}}>
                            <span style={{display: loader?"none":"", color: changeDetails && changeDetails.tenure ? "#FFA800": "#000000"}}>{
                                loader
                                ? ""
                                : e.tenure && JSON.parse(e.tenure)?.name
                            }</span>
                        </Typography>
                        </div>
                        <div className="requirement-card-info">
                        <Typography className="description-label">Mileage per month(KM){changeDetails && changeDetails.mileage ? <span className="change-tag">Changed</span> : null}</Typography>
                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14}}>
                            <span style={{display: loader?"none":"", color: changeDetails && changeDetails.mileage ? "#FFA800": "#000000"}}>{
                                loader
                                ? ""
                                : e.mileage
                            }</span>
                        </Typography>
                        </div>
                        <div className="requirement-card-info">
                        <Typography className="description-label">Lease Type{changeDetails && changeDetails.leaseType ? <span className="change-tag">Changed</span> : null}</Typography>
                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14}}>
                            <span style={{display: loader?"none":"", color: changeDetails && changeDetails.leaseType ? "#FFA800": "#000000"}}>{
                                loader
                                ? ""
                                : e.leaseType
                            }</span>
                        </Typography>
                        </div>
                        { ((e?.accessories || e?.accessories) && ((_.isArray(JSON.parse(e?.accessories)) && JSON.parse(e?.accessories).length !== 0) || (_.isArray(JSON.parse(e?.services)) && JSON.parse(data?.services).length !== 0)))
                         && <Divider orientation="left" orientationMargin={0}><span style={{fontSize: 14}}>Accessories & Services</span></Divider> }
                        {  e.accessories !== null && JSON.parse(e.accessories)?.length!==0 &&
                        <div className="requirement-card-info-wide">
                            <Typography className="description-label">Accessories{changeDetails && changeDetails.accessories ? <span className="change-tag">Changed</span> : null}</Typography>
                            <div className={`${loader ? "shimmer-ui" : ""}`} style={{ display: 'flex', flexWrap: 'wrap'}}>
                                {JSON.parse(e.accessories)?.map((accessory: any, index: number) => (
                                    <Tag style={{marginBottom: 5}}>{accessory.name}</Tag>
                                ))}
                            </div>
                        </div>
                        }
                        {  e.services !== null && JSON.parse(e.services)?.length!==0 &&
                        <div className="requirement-card-info-wide">
                            <Typography className="description-label">Services{changeDetails && changeDetails.services ? <span className="change-tag">Changed</span> : null}</Typography>
                            <div className={`${loader ? "shimmer-ui" : ""}`} style={{ display: 'flex', flexWrap: 'wrap'}}>
                                {JSON.parse(e.services)?.map((service: any, index: number) => (
                                    <Tag style={{marginBottom: 5}}>{service.name}</Tag>
                                ))}
                            </div>
                        </div>
                        }
                        {  e.otherAccessoriesDescription   &&
                        <div className="requirement-card-info">
                            <Typography className="description-label">Other Accessories{changeDetails && changeDetails.otherAccessories ? <span className="change-tag">Changed</span> : null}</Typography>
                            <div className={`${loader ? "shimmer-ui" : ""}`} style={{ display: 'flex', flexWrap: 'wrap'}}>
                                {e.otherAccessoriesDescription.split(',')?.map((service: any, index: number) => (
                                <Tag style={{marginBottom: 5}}>{service}</Tag>
                              ))}
                            </div>
                        </div>
                        }

                        {e.remark && <div className="requirement-card-info" style={{width: "100%"}}>
                        <Typography className="description-label">Remark{changeDetails && changeDetails.remark ? <span className="change-tag">Changed</span> : null}</Typography>
                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14}}>
                            <span style={{display: loader?"none":"", color: changeDetails && changeDetails.remark ? "#FFA800": "#000000"}}>{
                                loader
                                ? ""
                                : e.remark
                            }</span>
                        </Typography>
                        </div>
                        }
                        
                    </div>
                </Card>
            })}
        </InfiniteScroll>

        </div>
        </Modal>
    )
}

export default RequirementVersionHistoryModal;