import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Form, Typography } from 'antd';
import { ReactComponent as DownMark } from '../../../../utils/SVGs/DownMark.svg';
import { ReactComponent as RightMark } from '../../../../utils/SVGs/RightMark.svg';
import { formatIndianRupee } from '../formUtils';

interface FreeCashFlowProps {
    form: any; 
    updateTotalCnt: number;
    viewModeActive: boolean;
}

const FreeCashFlowComponent: React.FC<FreeCashFlowProps> = ({ form, updateTotalCnt, viewModeActive }) => {
    const [freeCashFlow, setFreeCashFlow] = useState<number>(0);
    const [collapseFreeCashFlowFields, setCollapseFreeCashFlowFields] = useState<boolean>(false);
    const INR_SYMBOL: string = '\u20B9';
    
    useEffect(() => {
        const values = form.getFieldsValue(true);
        if (values.freeCashFlow)
        setFreeCashFlow(values.freeCashFlow);
    }, [updateTotalCnt]);

    const handleFieldChange = () => {
        const values = form.getFieldsValue();
        const cashFromOperations = Number(values.cashFromOperations);
        const capitalExpenditures = Number(values.capitalExpenditures);
        
        if (!cashFromOperations && !capitalExpenditures) {
            setFreeCashFlow(0);
            return;
        }

        const updatedFreeCashFlow = cashFromOperations - capitalExpenditures;
        form.setFieldsValue({ freeCashFlow: updatedFreeCashFlow });
        setFreeCashFlow(updatedFreeCashFlow);
    };

    return (
        <>
            <Row
                gutter={16}
                style={{ marginTop: '10px', paddingTop: '10px', borderBottom: '1px #E8E8E8 solid', paddingBottom: '20px' }}
            >
                <Col span={12}>
                    <span onClick={() => setCollapseFreeCashFlowFields((oldState) => !oldState)}>
                        {collapseFreeCashFlowFields ? <RightMark /> : <DownMark />}
                    </span>
                    <span style={{ fontSize: '14px', fontWeight: '600', marginLeft:'10px' }}>Free Cash Flow</span>
                </Col>
                <Col span={12} key={1}>
                    <Typography style={{ fontSize: 14, fontWeight: '600', paddingLeft: '10px' }}>
                        <span>{`${INR_SYMBOL} ${formatIndianRupee(freeCashFlow)}`}</span>
                    </Typography>
                </Col>
            </Row>
            {
                !collapseFreeCashFlowFields && (
                <>
                    <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px', borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                        <Col span={12}><p style={{ marginLeft: '20px' }}>Cash From Operations</p></Col>
                        <Col span={12} key={1}>
                            {viewModeActive ? (
                                <Typography style={{ paddingLeft: '10px' }}>
                                    <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue('cashFromOperations'))}`}</span>
                                </Typography>
                            ) : (
                                <Form.Item
                                    name={'cashFromOperations'}
                                    rules={[{ required: true, message: 'Please enter Cash From Operations value' }]}
                                >
                                    <Input
                                        type="number"
                                        onChange={handleFieldChange}
                                    />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px', borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                        <Col span={12}><p style={{ marginLeft: '20px' }}>Capital Expenditures</p></Col>
                        <Col span={12} key={1}>
                            {viewModeActive ? (
                                <Typography style={{ paddingLeft: '10px' }}>
                                    <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue('capitalExpenditures'))}`}</span>
                                </Typography>
                            ) : (
                                <Form.Item
                                    name={"capitalExpenditures"}
                                    rules={[{ required: true, message: 'Please enter Capital Expenditures value' }]}
                                >
                                    <Input
                                        type="number"
                                        onChange={handleFieldChange}
                                    />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                </>
                )
            }
        </>
    );
};

export default FreeCashFlowComponent;
