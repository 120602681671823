import React, { useEffect, useState, useRef} from 'react';

import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as CrossIcon } from '.././utils/SVGs/cross_icon.svg';
import { ReactComponent as EyeIcon } from '.././utils/SVGs/eye.svg';
import { ReactComponent as DisablePreviewIcon } from '.././utils/SVGs/DisablePreviewIcon.svg';
import { ReactComponent as DownloadIcon } from '../utils/SVGs/download_icon.svg';       
import { Button, Typography, Tooltip, Empty, Flex } from 'antd';
import { getRequest, getRequestForDownload } from '../../../../utils/handler/apiHandler';
import FileViewer from './Document/FileViewer';
import { DownloadOutlined } from '@ant-design/icons';
import { ReactComponent as PipeIcon } from '../../../../utils/icons/Pipe_Icon.svg';
interface ViewProofProps {
  showViewDocs: boolean;
  setShowViewDocs: (show: boolean) => void;  
  documentActionTargetId: number;
}
    
const ViewProof: React.FC<ViewProofProps> = ({ showViewDocs, setShowViewDocs, documentActionTargetId }) => {
  const [files, setFiles] = useState<any[]>([]);
  const [organizedFiles, setOrganizedFiles] = useState<any>({});
  const [previewFile, setPreviewFile] = useState<any>(null);
  const navigate = useNavigate();
  const fileTypeSet = new Set(['docx','doc', 'pptx', 'ppt', 'xlsx', 'xls','csv']);
  const url = useLocation();
  const cardRef = useRef<HTMLDivElement>(null);
  const salesRoles = new Set(['GEVL-SALES-HEAD', 'GEVL-SALES-MANAGER', 'GEVL-SALES-EXECUTIVE']);

  const handleDocumentModalCancel = ()=>{
    setShowViewDocs(false);
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
        handleDocumentModalCancel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    const fetchFiles = async ()=>{
        try {
            const res = await getRequest(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/viewRevisionProofFiles?requirementId=${documentActionTargetId}`,
            );
            
            if (res.status === 404 || res.status === 400) {
                navigate("/dashboard/prospect");
                return;
            }
            setFiles(res.data);
        } catch (error) {
            console.error("Error uploading document:", error);
        }
    }
    fetchFiles()

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(()=>{
    const organizedFilesTemp:any = {};

    files.forEach(item => {
        const createdAtDate = item.createdAt.split('T')[0]; // Extract date part
        if (!organizedFilesTemp[createdAtDate]) {
            organizedFilesTemp[createdAtDate] = []; // Initialize array if not exists
        }
        organizedFilesTemp[createdAtDate].push(item); // Push item to array
    });
    setOrganizedFiles(organizedFilesTemp);
  },[files])

  const handlePreview = (file:any) => {
    console.log(file)
    setPreviewFile(file)
  };
  function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  }

  const handleDownload = async (file: any) => {
    const url = `${file.file.url}`;  // Replace with your file URL
    console.log(url);

    try {
        const response = await fetch(url);
        const blob = await response.blob();
        console.log(blob)
        const downloadUrl = window.URL.createObjectURL(blob);

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', file.file.fileName || 'downloaded_image.png');  // Specify the file name dynamically

        // Append the anchor element to the body
        document.body.appendChild(link);

        // Create a click event
        const event = new MouseEvent('click');
        link.dispatchEvent(event);

        // Clean up: remove the anchor element and revoke the object URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
};

  const handlePreviewCancel = () => {
    setPreviewFile(null)
  };

    return ( 
        <div ref={cardRef} 
            style={{ 
                width:previewFile===null?'36rem':'66rem',
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                height: '60%',
                zIndex: 2,
                borderRadius: '8px 8px 0 0',
                backgroundColor: '#F6FAFF',
                boxShadow: '0px 6px 24px 0px rgba(0, 0, 0, 0.25)'
            }} >
                    <div style={{position:'absolute',left:'-0.6rem',top:'0.8rem',borderRadius: 99, width: '100%', display: "flex", justifyContent: 'flex-end', cursor: "pointer"}} onClick={_ => {handleDocumentModalCancel()}}>
                        <CrossIcon color='#686868'/>
                    </div>
                    <div style={{display:'flex', height:'100%'}}>
                        <div style={{width: previewFile===null ? '100%' : '50%', height:'100%', padding:'30px 22px', borderRight: `${previewFile===null ? '0px' : '2px'} rgba(0, 0, 0, 0.20) solid`}}>
                            <div style={{ height:'2%', display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom:'1.5rem'}}>
                                <Typography className="primary-heading" style={{color:'#404040'}}>Documents</Typography>
                                <div style={{display:'flex', justifyContent:'flex-end'}}>
                                </div>
                            </div>
                            <div style={{height:'95%', margin:'0.5rem 0.1rem',overflowY: 'auto'}}>
                            { files.length > 0?
                            (
                            <div>
                                {Object.entries<Record<string, any>>(organizedFiles).map(([date, filesArray]) => (
                                    <div key={date}>
                                        <div style={{color:'#464646',fontSize:'12px', fontWeight:'700', marginBottom:'0.8rem', marginTop:'0.8rem'}}>{
                                            formatDate(date)}
                                        </div>
                                        {(filesArray as any[]).map((file: any) => {
                                            const fileType = file.fileName.split('.').pop();
                                            const disablePreview = fileTypeSet.has(fileType);
                                            {
                                                return (
                                                    <div key={file.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '0.8rem', padding: '0.5rem 0.6rem', backgroundColor: "white", borderRadius: '8px' }}>
                                                        <Tooltip title={file.fileName} style={{ zIndex: '3' }}>
                                                            <span style={{ fontSize:'12px',fontWeight:'500', color:'#404040', maxWidth: '50%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                                {file.fileName}
                                                            </span>
                                                        </Tooltip>
                                                        <div style={{maxWidth:"250px"}}>
                                                        {
                                                        previewFile==file 
                                                        ? 
                                                            <Button icon={<DisablePreviewIcon color={'#2C66E3'}/>} 
                                                                style={{
                                                                    color: '#2C66E3', backgroundColor:'white', border:'none', boxShadow:'none', fontWeight: '500', 
                                                                
                                                                }} 
                                                                key="confirm"  onClick={()=>handlePreviewCancel()}>
                                                                    { 'Hide Preview' }
                                                            </Button>     
                                                        :
                                                            <Button icon={disablePreview ? <DisablePreviewIcon color={'grey'}/> : <EyeIcon color={'#2C66E3'}/>} 
                                                                disabled={disablePreview} 
                                                                style={{
                                                                    color: disablePreview ? 'grey':'#2C66E3', backgroundColor:'white', border:'none', boxShadow:'none', fontWeight: '500', 
                                                                
                                                                }} 
                                                                key="confirm"  onClick={()=>handlePreview(file)}>
                                                                    { disablePreview ? 'Preview not supported' : 'Preview' }
                                                            </Button>
                                                        }   
                                                        <span color={'grey'}><PipeIcon/>    </span>
                                                        <span style={{marginLeft:"0.5rem", color:"#2C66E3"}} onClick={()=>handleDownload({file})}><DownloadIcon /></span>
                                                        </div> 
                                                    </div>
                                                );
                                            }
                                            // else
                                            // {
                                            //     return(
                                            //         <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            //     );
                                            //     }
                                        })}
                                    </div>
                                ))}
                            </div>
                            )
                        :
                    <div>
                        <Empty style={{display:"flex", justifyContent:"center", alignItems:"center"}} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                    }                                
                            </div>
                        </div>
                        {
                            previewFile && 
                            <div style={{display:'flex', width:'50%',height:'100%', padding:'10px'}}>
                                <FileViewer previewFile={previewFile}  />
                            </div>
                        }
                    </div>
      </div>
        
    );
}

export default ViewProof;