// paginationFunctions.ts

export const handlePageSizeChange = (current: number, size: number, setPageSize: (size: number) => void, setCurrentPage: (page: number) => void) => {
    setPageSize(size);
    setCurrentPage(1);
  };
  
  export const handlePageChange = (page: number, setCurrentPage: (page: number) => void) => {
    setCurrentPage(page);
  };
  