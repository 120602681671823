import React, { useState, useEffect, useRef } from 'react';
import { Table, Spin, Select, Tooltip } from 'antd';
import { LeftOutlined, MinusOutlined, RightOutlined } from '@ant-design/icons';
import { useLocation} from 'react-router';
import { ProspectStageHistory } from '../utils/ProspectTypes';
import { getRequest } from '../../../../utils/handler/apiHandler';
import { ColumnsType } from 'antd/es/table';
import { Option } from "antd/es/mentions";
import { handlePageChange, handlePageSizeChange } from '../utils/paginationFunctions';
import { getFormattedDate } from '../../../../utils/helpers/utils';
import { getStageStyles } from '../utils/stageUtils';
import { getSampleQuoteStageStyles } from '../utils/SampleQuoteStages';

const OTHER = "Other";

const SampleQuotes: React.FC = () =>  {
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageCount, setPageCount] = useState<number>(0);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const defaultData: ProspectStageHistory[] = [];
    const [data, setData] = useState<ProspectStageHistory[]>(defaultData);
    const url = useLocation();
    const tableRef = useRef<HTMLDivElement>(null);
    const [tableHeight, setTableHeight] = useState<number>(400);
    const prospectId = url.pathname.split("/").pop()
    const FILTER_ICON_COLOR = '#1777FF';

    
    useEffect(() => {
        fetchSampleQuotesTableData();
    }, [currentPage, pageSize]);

    const fetchSampleQuotesTableData = async (): Promise<void> => {
        try {
          setLoading(true);
          let getApi = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/quoteRequestLogs?prospectId=${prospectId}&page=${currentPage-1}&size=${pageSize}`;
        
          const res = await getRequest(getApi);
          if (res.status === 200) {
            setData(res.data.data);
            if ('pageCount' in res.data) {
              setPageCount(res.data.pageCount);
            }
            if ('totalRecord' in res.data) {
              setTotalRecord(res.data.totalRecord);
            }
          } else {
            console.error('Error fetching SampleQuotes Table List:', res.msg);
          }
        } catch (error) {
          console.error('Error fetching SampleQuotes data:', error);
        } finally {
          setLoading(false);
        }
      };

    const handlePageSizeChangeLocal = (current: number, size: number) => {
        handlePageSizeChange(current, size, setPageSize, setCurrentPage);
    };

    const handlePageChangeLocal = (page: number) => {
        handlePageChange(page, setCurrentPage);
    };

    const getColumns = () : ColumnsType<any> => [
        {
          title: 'Requirements',
          dataIndex: 'requirementNumber',
          key: 'requirementNumber',
          render: (text : string) => `Requirement ${text}`,
          width: '14%',
        },
        {
            title: 'Vehicle Make',
            dataIndex: 'make',
            key: 'make',
            width: '12%',
        },
        {
            title: 'Vehicle Model',
            dataIndex: 'model',
            key: 'model',
            width: '12%',
        },
        {
            title: 'Variant',
            dataIndex: 'variant',
            key: 'variant',
            width: '12%',
        },{
            title: 'Buying City',
            dataIndex: 'city',
            key: 'city',
            width: '14%',
        },
        {
            title: 'Status',
            dataIndex: 'currentQuoteStatusName',
            render: (text: string, record: any) => {
              const { color, backgroundColor } = getSampleQuoteStageStyles(record?.currentQuoteStatusId);
                return(
                  <Tooltip title={text}>
                    <div style={{color : color, backgroundColor : backgroundColor, padding: '0 8px', borderRadius: '4px', display: 'inline-block', maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {text}
                    </div>
                  </Tooltip>
                );
            },
            key: 'currentQuoteStatusName',
            width: '19%',
        },
        
        {
            title: 'Status changed at',
            dataIndex: 'createdAt',
            render: (obj : string) => {
                const formattedDate = getFormattedDate(obj);
                return(
                formattedDate
                ); 
            },
            key: 'createdAt',
            width: '17%',
        },
    ];

    const rotatedMinusIconStyle = {
        transform: 'rotate(90deg)',
    };

    return ( 
        <div style={{ backgroundColor: '#F6FAFF', height: '100%'}}>
            <div ref={tableRef} style={{ height: 0.80 * tableHeight }}>
                <Spin spinning={loading} size="large">
                    <Table
                        columns={getColumns()}
                        dataSource={data || []}
                        pagination={{
                        pageSize: pageSize,
                        position: ["bottomRight"],
                        showSizeChanger: false,
                        showTotal: (total, range) => (
                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', marginTop:'-4px' }}>
                            <div style={{fontSize:'1rem'}}>
                                <span>Showing</span>
                                <Select defaultValue={10} style={{ width: 60, margin: '0 5px' }} onChange={(value) => handlePageSizeChangeLocal(1, value)}>
                                    <Option value="10">10</Option>
                                    <Option value="20">20</Option>  
                                    <Option value="50">50</Option>
                                    <Option value="100">100</Option>
                                </Select>
                                <span>Per Page</span>
                                <span style={{ marginRight:'10px', marginLeft:'10px', color:'grey'}}><MinusOutlined style={rotatedMinusIconStyle} /></span>
                                <span>{`Results: ${range[0]}-${range[1]} of ${total}`}</span>
                            </div>
                            </div>
                        ),
                        onChange: handlePageChangeLocal,
                        current: currentPage,
                        total: totalRecord,
                        itemRender: (current, type, originalElement) => {
                            if (type === 'prev') {
                            return <LeftOutlined style={{marginLeft:'4rem'}}/>;
                            }
                            if (type === 'next') {
                            return  <RightOutlined />;
                            }
                            if (type === 'page') {
                            return (
                                <span style={{cursor: 'pointer' }} onClick={() => handlePageChangeLocal(current)}>
                                {current}
                                </span>
                            );
                            }
                            if (type === 'jump-next' || type === 'jump-prev') {
                            return <a style={{color: 'black'}}><strong> ... </strong></a>;
                            }
                            return originalElement;
                        },
                        }}            
                        scroll={{ x: 'max-content', y: 0.80 * tableHeight }}
                        size="middle"
                        components={{
                        header: {
                            wrapper: (props: any) => <thead style={{ backgroundColor: '#CEE6FA', height: '3.5rem' }}>{props.children}</thead>,
                        },
                        }}
                    />
                </Spin>
            </div>
        </div>
    );
};

export default SampleQuotes;