import React from "react";
import { Input } from "antd";

// A debounced input react component
export default function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
  }: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
  } & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = React.useState(initialValue)
  
    React.useEffect(() => {
      setValue(initialValue)
    }, [initialValue])
  
    React.useEffect(() => {
      const timeout = setTimeout(() => {
        onChange(value)
      }, debounce)
  
      return () => clearTimeout(timeout)
    }, [value])
  
    return (
      <div style={{display: "flex", justifyContent: "flex-end"}}>
        <Input placeholder={props.placeholder} style={{ width: "100%", marginBottom: 10 }} value={value} onChange={e => setValue(e.target.value)} />
      </div>
    )
  }
