import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Form, Typography } from 'antd';
import { ReactComponent as DownMark } from '../../../../utils/SVGs/DownMark.svg';
import { ReactComponent as RightMark } from '../../../../utils/SVGs/RightMark.svg';
import { formatIndianRupee } from '../formUtils';

interface NonCurrentLiabilitiesProps {
    FYCount: number;
    form: any; // Form instance from Ant Design Form
    colSpan: number;
    labelColSpan: number;
    updateTotalCnt: number;
    viewModeActive: boolean;
}

const NonCurrentLiabilitiesComponent: React.FC<NonCurrentLiabilitiesProps> = ({ FYCount, form, colSpan, updateTotalCnt, viewModeActive, labelColSpan }) => {
    const [totalNonCurrentLiabilities, setTotalNonCurrentLiabilities] = useState<number[]>(Array(FYCount).fill(0));
    const [collapseNonCurrentLiabilitiesFields, setCollapseNonCurrentLiabilitiesFields] = useState<boolean>(false);
    const INR_SYMBOL: string = '\u20B9';
    
    useEffect(() => {
        const values = form.getFieldsValue(true);
        if (values.totalNonCurrentLiabilities)
            setTotalNonCurrentLiabilities(values.totalNonCurrentLiabilities);
    }, [updateTotalCnt]);

    const handleFieldChange = () => {
        const values = form.getFieldsValue();
        const updatedTotalNonCurrentLiabilities = values.longTermBorrowings.map(
            (item: number, i: number) =>
                Number(item) +
                Number(values.netDeferredTaxLiabilities[i]) +
                Number(values.otherLongTermLiabilities[i])
        );
        form.setFieldsValue({ totalNonCurrentLiabilities: updatedTotalNonCurrentLiabilities });
        setTotalNonCurrentLiabilities(updatedTotalNonCurrentLiabilities);
    };

    return (
        <>
            <Row
                gutter={16}
                style={{
                    marginTop: '20px',
                    paddingTop: '10px',
                    borderBottom: '1px #E8E8E8 solid',
                    paddingBottom: '20px',
                }}
            >
                <Col span={labelColSpan}>
                    <span onClick={() => setCollapseNonCurrentLiabilitiesFields((oldState) => !oldState)}>
                        {collapseNonCurrentLiabilitiesFields ? <RightMark /> : <DownMark />}
                    </span>
                    <span style={{ fontSize: '14px', fontWeight: '600', marginLeft: '10px' }}>
                        Total Non Current Liabilities
                    </span>
                </Col>
                {totalNonCurrentLiabilities.map((value, ind) => (
                    <Col span={colSpan} key={ind}>
                        <Typography style={{ fontSize: 14, fontWeight: '600', paddingLeft: '10px' }}>
                            <span>{`${INR_SYMBOL} ${formatIndianRupee(parseFloat(value.toFixed(2)))}`}</span>
                        </Typography>
                    </Col>
                ))}
            </Row>
            {!collapseNonCurrentLiabilitiesFields && (
                <>
                    <Row
                        gutter={16}
                        style={{
                            paddingTop: '20px', 
                            paddingBottom: viewModeActive?'20px':'0px',
                            borderBottom: '1px #E8E8E8 solid',
                            backgroundColor: '#F6F6F6',
                        }}
                    >
                        <Col span={labelColSpan}>
                            <p style={{ marginLeft: '20px' }}>Long Term Borrowings</p>
                        </Col>
                        {Array.from({ length: FYCount }, (_, ind) => (
                            <Col span={colSpan} key={ind}>
                                {viewModeActive ? (
                                    <Typography style={{ paddingLeft: '10px' }}>
                                        <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue(['longTermBorrowings', ind]))}`}</span>
                                    </Typography>
                                ) : (
                                    <Form.Item
                                        name={['longTermBorrowings', ind]}
                                        rules={[{ required: true, message: 'Please enter Long Term Borrowings value' }]}
                                    >
                                        <Input type="number" onChange={handleFieldChange} />
                                    </Form.Item>
                                )}
                            </Col>
                        ))}
                    </Row>

                    <Row
                        gutter={16}
                        style={{
                            paddingTop: '20px', 
                            paddingBottom: viewModeActive?'20px':'0px',
                            borderBottom: '1px #E8E8E8 solid',
                            backgroundColor: '#F6F6F6',
                        }}
                    >
                        <Col span={labelColSpan}>
                            <p style={{ marginLeft: '20px' }}>Net Deferred Tax Liabilities</p>
                        </Col>
                        {Array.from({ length: FYCount }, (_, ind) => (
                            <Col span={colSpan} key={ind}>
                                {viewModeActive ? (
                                    <Typography style={{ paddingLeft: '10px' }}>
                                        <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue(['netDeferredTaxLiabilities', ind]))}`}</span>
                                    </Typography>
                                ) : (
                                    <Form.Item
                                        name={['netDeferredTaxLiabilities', ind]}
                                        rules={[{ required: true, message: 'Please enter Net Deferred Tax Liabilities value' }]}
                                    >
                                        <Input type="number" onChange={handleFieldChange} />
                                    </Form.Item>
                                )}
                            </Col>
                        ))}
                    </Row>

                    <Row
                        gutter={16}
                        style={{
                            paddingTop: '20px', 
                            paddingBottom: viewModeActive?'20px':'0px',
                            borderBottom: '1px #E8E8E8 solid',
                            backgroundColor: '#F6F6F6',
                        }}
                    >
                        <Col span={labelColSpan}>
                            <p style={{ marginLeft: '20px' }}>Other Long Term Liabilities</p>
                        </Col>
                        {Array.from({ length: FYCount }, (_, ind) => (
                            <Col span={colSpan} key={ind}>
                                {viewModeActive ? (
                                    <Typography style={{ paddingLeft: '10px' }}>
                                        <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue(['otherLongTermLiabilities', ind]))}`}</span>
                                    </Typography>
                                ) : (
                                    <Form.Item
                                        name={['otherLongTermLiabilities', ind]}
                                        rules={[{ required: true, message: 'Please enter Other Long Term Liabilities value' }]}
                                    >
                                        <Input type="number" onChange={handleFieldChange} />
                                    </Form.Item>
                                )}
                            </Col>
                        ))}
                    </Row>
                </>
            )}
        </>
    );
};

export default NonCurrentLiabilitiesComponent;
