import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { useAppSelector } from '../../../app/hooks';
import { authSelector } from '../../../features/auth/authSlice';
import { userSelector } from '../../../features/auth/userSlice';
import { useLocation, useNavigate } from 'react-router';
import {
  Table,
  Column,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  sortingFns,
  getSortedRowModel,
  FilterFn,
  SortingFn,
  ColumnDef,
  flexRender,
  FilterFns,
  createColumnHelper,
} from '@tanstack/react-table'
import {
  RankingInfo,
  rankItem,
  compareItems,
} from '@tanstack/match-sorter-utils';
import axios from 'axios'
import { Button, Input, Switch, Popconfirm, Tooltip, Select, Tag, Modal, Descriptions, Spin, message, Typography } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, DownOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined, LoadingOutlined, PlusOutlined, ReconciliationOutlined, SendOutlined, UpOutlined } from '@ant-design/icons';
import MUITable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DebouncedInput from '../../../utils/helpers/DebounceInput';
import { getRequest } from '../../../utils/handler/apiHandler';
import dayjs from 'dayjs';
import { constant, isArray } from 'lodash';
import constants from "../../../utils/constants";
import { useSelector } from 'react-redux';
import { menuSelector } from '../../../features/menu/menuSlice';
import getDynamicAction from '../../../utils/helpers/dynamicAction';
const { Text, Link } = Typography;

type CreditRequest = {
    creditRequestId: number
    clientId: string;
    prospectName: string;
    entityName: string;
    legalBusinessName: string;
    businessSegment: string;
    dateOfApplication: string;
    authorizedSignatories: string;
    isCommercialMeetingHeld: boolean;
    productAgreed: string;
    type: string;
    entityDescription: string;
    averageInvestment: number;
    interestRateOffered: number;
    fleetProjection: number;
    currentYearPotential: number;
    leaseTenure: number;
    vehicleLocations: string;
    makeModels: string;
    creditStatus: string;
    createdBy: string;
}

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

function Filter({
  column,
  table,
}: {
  column: Column<any, unknown>
  table: Table<any>
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id)

  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )
  return typeof firstValue === 'number' ? (
    <div>
      <div className="flex space-x-2" style={{display: "flex"}}>
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          // placeholder="Min"
          placeholder="Min"
        />
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder="Max"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? '') as string}
        onChange={value => column.setFilterValue(value)}
        placeholder={`Filter`}
        className="w-36 border shadow rounded"
        list={column.id + 'list'}
      />
      <div className="h-1" />
    </>
  )
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)
  // Store the itemRank info
  addMeta({
    itemRank,
  })
  // Return if the item should be filtered in/out
  return itemRank.passed
}

function CreditRequestList() {
  const navigate = useNavigate();
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = React.useState('');
  const auth = useAppSelector(authSelector);
  const role = useAppSelector(userSelector).role;
  const columnHelper = createColumnHelper<CreditRequest>();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState<any>();
  const [approvalModalOpen, setApprovalModalOpen] = useState(false);
  const [approvalModalData, setApprovalModalData] = useState<any>();
  const [remark, setRemark] = useState("");
  const [approvalIconDisable, setApprovalIconDisable] = useState<any>(false);
  const [approvalLoader, setApprovalLoader] = useState<any>(false);

  const pageUrl = useLocation().pathname.split("/").pop();
  const menus = useAppSelector(menuSelector).menu;
  const actionItem = getDynamicAction(menus, pageUrl, "credit");
  const user = useAppSelector(userSelector);

  const columns = [
    columnHelper.accessor('entityName', {
      cell: info => info.getValue(),
      header: () => <span>Entity Name</span>,
  }),
    columnHelper.accessor('legalBusinessName', {
        cell: info => info.getValue(),
        header: () => <span>Legal Business Name</span>,
    }),
    columnHelper.accessor('dateOfApplication', {
        cell: info => info.getValue(),
        header: () => <span>Date Of Application</span>,
    }),
    columnHelper.accessor('businessSegment', {
        cell: info => info.getValue(),
        header: () => <span>Business Segment</span>,
    }),
    columnHelper.accessor('authorizedSignatories', {
        cell: info => JSON.parse(info.getValue()).map((e: any) => {
            return <Tag color="#1677ff" style={{margin: 1}}>{e}</Tag>
        }),
        header: () => <span>Authorized Signatories</span>,
    }),
  ]
  const defaultData: CreditRequest[] = [];

  const [data, setData] = useState<CreditRequest[]>(() => [...defaultData])

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })

  useEffect(() => {
    getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/credit-request`).then(res => {
      if(res.data)  
        setData(res.data);
    });
    getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/credit-request`).then(res => {
      if(res.data)  
        setData(res.data);
    });
  }, []);

const handleEdit = (data: any) => navigate(`/dashboard/credit/${data.creditRequestId}`);
const handleView = (data: any) => {
    setModalData(data);
    setModalOpen(true)
}

// const handleApproval = (data: any) => {
//   if(data.creditStatus == "Approved") {
//     message.loading("Loading...")
//     setApprovalLoader(true);
//     getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/credit-approval?creditRequestId=${data.creditRequestId}`).then(res => {
//       setApprovalLoader(false);
//       navigate("credit-approval", {state: {
//           ...res.data[0],
//           clientId: data.clientId
//         }})
//       message.destroy();
//       });
//     }
//   else navigate("credit-approval", {state: data});
// }

const handleApproval = (data: any) => navigate(`/dashboard/credit/credit-approval/${data.creditRequestId}`);

const handleCreditView = (data: any) => {
  if(data.creditStatus == "Approved") {
    message.loading("Loading...");
    getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/credit-approval?creditRequestId=${data.creditRequestId}`).then(res => {   
        setApprovalModalData(res.data[0]);
        setApprovalModalOpen(true);
        message.destroy();
      });
    }
}

const handlePageLengthDropdown = (value: string) => {
  console.log(`selected ${value}`);
  table.setPageSize(Number(value))
};

return (
  <div className="p-2 form-container">
    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end"}}>
      <DebouncedInput
        value={globalFilter ?? ''}
        onChange={value => setGlobalFilter(String(value))}
        style={{width: 300}}
        placeholder="Search all columns..."
      />
      <Button 
        type="primary" 
        icon={<PlusOutlined />} 
        onClick={_=> navigate("/dashboard/credit/credit-request")}
        style={{marginLeft: 10, height: 40, display: actionItem?.["Credit Request"] === "true" ? "block" : "none"}}
        >
        Credit Request
      </Button>
    </div>
    <div className="h-2" />
    <div style={{width: "100%", overflow: "scroll", borderRadius: 4}}>
      <TableContainer>
      <MUITable>
        <TableHead className='table-header'>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <TableCell style={{fontWeight: 600}} key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <UpOutlined />,
                            desc: <DownOutlined />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null}

                      </>
                    )}
                  </TableCell>
                )
              })}
              <TableCell style={{fontWeight: 600, width: 20}}>Status</TableCell>
              <TableCell style={{fontWeight: 600, width: 20}}>Action</TableCell>
              {
                actionItem?.["Approvals"]["Approval_column"] === "true" ? <TableCell style={{fontWeight: 600, width: 20}}>Approvals</TableCell> : null
              }
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map(row => {
            return (
              <TableRow className="table-row" key={row.id}>
                {row.getVisibleCells().map(cell => {
                    return (
                        <TableCell key={cell.id}>
                        {
                            flexRender(cell.column.columnDef.cell, cell.getContext())
                        }
                        </TableCell>
                    )
                })}
                <TableCell style={{display: "flex", justifyContent: "center", alignItems: "center", height: "72px", borderBottom: "none"}}>
                  {row?.original.creditStatus == "Approved" 
                  ? <Tooltip placement="bottom" title="Approved"><CheckCircleOutlined style={{color: "green"}} /></Tooltip>
                  : row?.original.creditStatus == "Reject" 
                  ? <Tooltip placement="bottom" title="Reject"><CloseCircleOutlined style={{color: "red"}} /></Tooltip>
                  : <Tooltip placement="bottom" title="Pending"><ExclamationCircleOutlined style={{color: "orange"}} /></Tooltip>
                  }
                </TableCell>


                <TableCell>
                  {
                    actionItem?.["Action"]["Edit"] === "true"
                    ? <Tooltip placement="bottom" title={actionItem?.["isAdmin"] === "true" || actionItem?.["isHead"] === "true" || row.original.createdBy === user.ssoId ? "Edit credit request" : "Can't edit credit request"}>
                          <a>{ row?.original?.creditStatus !== "Approved" ? <EditOutlined onClick={(e) => row.original.createdBy === user.ssoId || actionItem?.["isAdmin"] === "true" || actionItem?.["isHead"] === "true" ? handleEdit(row?.original) : null} style={{ marginRight: 10, color: row.original.createdBy === user.ssoId || actionItem?.["isAdmin"] === "true" || actionItem?.["isHead"] === "true" ? "#1677ff" : "grey"}} /> : <EditOutlined disabled style={{color: 'gray', marginRight: 10}} />}</a>
                      </Tooltip>
                    : null
                  }
                  {
                    actionItem?.["Action"]["View"] === "true"
                    ? <Tooltip placement="bottom" title="View credit request">
                        <a onClick={(e) => handleView(row?.original)}><EyeOutlined /></a>
                      </Tooltip>
                    : null
                  }
                </TableCell>

              {
                actionItem?.["Approvals"]["Approval_column"] === "true"
                  ? <TableCell>
                  {
                    actionItem?.["Approvals"]["Edit"] === "true"
                    ? <Tooltip placement="bottom" title="Approve request">
                            <a onClick={() => handleApproval(row?.original)}><ReconciliationOutlined style={{ marginRight: 10}} /></a>
                        </Tooltip>
                    : null
                  }
                  {
                    actionItem?.["Approvals"]["View"] === "true"
                    ? <Tooltip placement="bottom" title="View credit approval">
                          <a onClick={(e) => handleCreditView(row?.original)}> { row?.original.creditStatus == "Approved" ? <EyeOutlined /> : <EyeOutlined disabled style={{color: 'gray'}} /> }</a>
                        </Tooltip>
                    : null
                  }
                </TableCell>
                : null
              }
              </TableRow>
            )
          })}
        </TableBody>
      </MUITable>
      </TableContainer>
    </div>
    <div className="h-2" />
    <div className="pagination-section">
      <span>
      <Button
        className="border rounded p-1"
        onClick={() => table.setPageIndex(0)}
        disabled={!table.getCanPreviousPage()}
        style={{margin: 2}}
      >
        {'<<'}
      </Button>
      <Button
        className="border rounded p-1"
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
        style={{margin: 2}}
      >
        {'<'}
      </Button>
      <Button
        className="border rounded p-1"
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
        style={{margin: 2}}
      >
        {'>'}
      </Button>
      <Button
        className="border rounded p-1"
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        disabled={!table.getCanNextPage()}
        style={{margin: 2}}
      >
        {'>>'}
      </Button>
      </span>

      <span>
      <span className="flex items-center gap-1">
        <strong style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          {`Page ${table.getState().pagination.pageIndex + 1} of ${table.getPageCount()}`}
          <Input
          placeholder="Go to page"
          type="number"
          defaultValue={table.getState().pagination.pageIndex + 1}
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0
            table.setPageIndex(page)
          }}
          style={{width: 50, marginLeft: 10}}
        />
        </strong>
      </span>
      </span>
      <Select
          showSearch={true}
          defaultValue="10"
          style={{ width: 200 }}
          onChange={handlePageLengthDropdown}
          options={[
            { label: '5', value: '5' },
            { label: '10', value: '10' },
            { label: '15', value: '15' },
            { label: '20', value: '20' },
          ]}
        />
    </div>
    <div>{table.getPrePaginationRowModel().rows.length} Rows</div>

    <Modal
        centered
        open={modalOpen}
        width={1200}
        style={{margin: "10px 0"}}
        onCancel={() => setModalOpen(false)}
        footer={[
            <Button type="primary" key="cancel" onClick={() => setModalOpen(false)}>
              Close
            </Button>,
          ]}
    >
        <Descriptions bordered title={`${modalData?.entityName} - ${modalData?.clientId}`}>
            <Descriptions.Item label="Entity Name">{modalData?.entityName}</Descriptions.Item>
            <Descriptions.Item label="Legal Business Name">{modalData?.legalBusinessName}</Descriptions.Item>
            <Descriptions.Item label="Business Segment">{modalData?.businessSegment}</Descriptions.Item>
            <Descriptions.Item label="Application Date">{dayjs(modalData?.dateOfApplication).format("YYYY-MM-DD")}</Descriptions.Item>
            <Descriptions.Item label="Prospect Name">{modalData?.prospectName}</Descriptions.Item>
            <Descriptions.Item label="Commercial Meeting">{modalData?.isCommercialMeetingHeld ? "Held" : "Not held"}</Descriptions.Item>
            <Descriptions.Item label="Product Agreed">{modalData?.productAgreed}</Descriptions.Item>
            <Descriptions.Item label="Fleet Projection">{modalData?.fleetProjection}</Descriptions.Item>
            <Descriptions.Item label="Current Year Potential">{modalData?.currentYearPotential}</Descriptions.Item>
            <Descriptions.Item label="Average Investment">₹{modalData?.averageInvestment}</Descriptions.Item>
            <Descriptions.Item label="Type">{modalData?.type}</Descriptions.Item>
            <Descriptions.Item label="Lease Tenure">{modalData?.leaseTenure}</Descriptions.Item>
            <Descriptions.Item label="Management Fee">₹{modalData?.managementFee}</Descriptions.Item>
            <Descriptions.Item label="Interest Rate Offered">{modalData?.interestRateOffered}</Descriptions.Item>
            <Descriptions.Item label="Make & Models">{
                modalData?.makeModels ? JSON.parse(modalData?.makeModels).map((e: any) => {
                    return <Tag color="#1677ff" style={{margin: 1}}>{`${e.make}: ${e.model.join(", ")}`}</Tag>
                }) : null
            }</Descriptions.Item>
            <Descriptions.Item label="RV Percentage">{modalData?.residualValuePercentage} %</Descriptions.Item>
            <Descriptions.Item label="Mileage">{modalData?.mileage}</Descriptions.Item>
            <Descriptions.Item label="Vehicle Locations">{
                modalData?.vehicleLocations 
                ? JSON.parse(modalData?.vehicleLocations).map((e: any) => {
                    return <Tag color="#1677ff" style={{margin: 1}}>{e}</Tag>
                }) 
                : null
            }</Descriptions.Item>
            <Descriptions.Item label="Authorized Signatories">{
                modalData?.authorizedSignatories ? JSON.parse(modalData?.authorizedSignatories).map((e: any) => {
                    return <Tag color="#1677ff" style={{margin: 1}}>{e}</Tag>
                }) : null
            }</Descriptions.Item>
            <Descriptions.Item label="Entity Description">{modalData?.entityDescription}</Descriptions.Item>
            <Descriptions.Item label="Created By">{modalData?.createdByName}</Descriptions.Item>
            <Descriptions.Item label="Updated By">{modalData?.updatedByName}</Descriptions.Item>
        </Descriptions>
    </Modal>

    <Modal
        centered
        open={approvalModalOpen}
        width={1200}
        style={{margin: "10px 0"}}
        onCancel={() => setApprovalModalOpen(false)}
        footer={[
            <Button type="primary" key="cancel" onClick={() => setApprovalModalOpen(false)}>
              Close
            </Button>
          ]}
    >
        <Descriptions bordered title={`${approvalModalData?.entityName}`}>
            <Descriptions.Item label="Entity Name">{approvalModalData?.entityName}</Descriptions.Item>
            <Descriptions.Item label="Legal Business Name">{approvalModalData?.legalBusinessName}</Descriptions.Item>
            <Descriptions.Item label="Credit Status">{approvalModalData?.creditStatus}</Descriptions.Item>
            <Descriptions.Item label="Request Date">{approvalModalData?.requestDate}</Descriptions.Item>
            <Descriptions.Item label="Credit Approval Date">{approvalModalData?.creditApprovalDate}</Descriptions.Item>
            <Descriptions.Item label="Credit Limit">₹{approvalModalData?.creditLimit}</Descriptions.Item>
            <Descriptions.Item label="Approved Fleet Number">{approvalModalData?.approvedFleetNumber}</Descriptions.Item>
            <Descriptions.Item label="Approved Amount">₹{approvalModalData?.approvedAmount}</Descriptions.Item>
            <Descriptions.Item label="Valid From">{approvalModalData?.validFrom}</Descriptions.Item>
            <Descriptions.Item label="Valid Till">{approvalModalData?.validTill}</Descriptions.Item>
            <Descriptions.Item label="MLA Numbers">{
              approvalModalData?.contractConditions?.map((e: any) => {
                return <Tag color="#1677ff" style={{margin: 1}}>{e.mlaNumber}</Tag>
              })
            }</Descriptions.Item>
            <Descriptions.Item label="Updated By">{approvalModalData?.updatedByName}</Descriptions.Item>
        </Descriptions>
    </Modal>
  </div>
)
}

export default CreditRequestList;