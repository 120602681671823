import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router';
import {
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  sortingFns,
  getSortedRowModel,
  FilterFn,
  SortingFn,
  flexRender,
  createColumnHelper,
} from '@tanstack/react-table'
import {
  RankingInfo,
  rankItem,
  compareItems,
} from '@tanstack/match-sorter-utils';
import { Button, Input, Switch, Popconfirm, Tooltip, Select, message } from 'antd';
import { DownOutlined, EditOutlined, PlusOutlined, SendOutlined, UpOutlined } from '@ant-design/icons';
import TextArea from 'rc-textarea'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DebouncedInput from '../../../utils/helpers/DebounceInput';
import { getRequest, postRequest, putRequest } from '../../../utils/handler/apiHandler';

type Person = {
  name: string,
  emailId: string,
  department: string,
  designation: string,
  contactNo: string,
  location: string,
  dateOfJoining: string,
  role: string,
  active: boolean
}

const range = (len: number) => {
  const arr = []
  for (let i = 0; i < len; i++) {
    arr.push(i)
  }
  return arr
}

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)
  // Store the itemRank info
  addMeta({
    itemRank,
  })
  // Return if the item should be filtered in/out
  return itemRank.passed
}

function UserList() {
  const navigate = useNavigate();
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = React.useState('');
  const columnHelper = createColumnHelper<Person>();
  const [remark, setRemark] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    columnHelper.accessor('name', {
      cell: info => info.getValue(),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor('emailId', {
      cell: info => info.getValue(),
      header: () => <span>Email</span>,
    }),
    columnHelper.accessor('department', {
      cell: info => info.getValue(),
      header: () => <span>Department</span>,
    }),
    columnHelper.accessor('designation', {
      cell: info => info.getValue(),
      header: () => <span>Designation</span>,
    }),
    columnHelper.accessor('contactNo', {
      cell: info => info.getValue(),
      header: () => <span>Contact No</span>,
    }),
    columnHelper.accessor('location', {
      cell: info => info.getValue(),
      header: () => <span>Location</span>,
    }),
    columnHelper.accessor('dateOfJoining', {
      cell: info => info.getValue(),
      header: () => <span>Date Of Joining</span>,
    }),
    columnHelper.accessor('role', {
      cell: info => info.getValue(),
      header: () => <span>Role</span>,
    }),
  ]
  const defaultData: Person[] = [];

  const [data, setData] = useState<Person[]>(() => [...defaultData])

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })

  useEffect(() => {
    if (table.getState().columnFilters[0]?.id === 'fullName') {
      if (table.getState().sorting[0]?.id !== 'fullName') {
        table.setSorting([{ id: 'fullName', desc: false }])
      }
    }
  }, [table.getState().columnFilters[0]?.id])

  // Fetching all users details on loading page
  useEffect(() => {
  getRequest(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/users`).then(res => {
    if(res?.data)
      setData(res.data)
    else if(res?.err)
      console.log(res?.msg)
  })
  }, []);

  const editUser = async (payload: any) => {
    const data = payload;
    navigate(`/dashboard/users/${payload?.emailId}`, { state: {data}});
  }

const sendVerificationEmail = async (payload: any) => {
  console.log(payload);
  postRequest(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/users/${payload?.userId}/send-verification-email`, {}).then(res => {
    if(res.status === 200)
      messageApi.success("Verification email sent!");
    if(res.status === 400)
      messageApi.error("User Email is already Verified.");

  }).catch(err => {
      messageApi.error("Somthing went wrong");
      console.log("Error: ", err);
  });
}

const confirm = async (record: any) => {
  let payload: any = {};
  if(record.row.original.active)
      payload.enable = "false"
  else
      payload.enable = "true"
  payload.remark = remark;

  if(remark == "") {
      // messageApi.warning("Remark is required!")
      return;
  }
  putRequest(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/users/${record.row.original.userId}`, payload).then(res => {
    if(res.status === 200) {
      window.location.reload();
      messageApi.success("User status updated");
    }
  }).catch(err => {
      messageApi.error("Somthing went wrong");
      console.log("Error: ", err);
  });
};

const handlePageLengthDropdown = (value: string) => {
  console.log(`selected ${value}`);
  table.setPageSize(Number(value))
};

return (
  <>
    {contextHolder}
    <div className="p-2 form-container">
      <div style={{ width: "100%", display: "flex", justifyContent: "flex-end"}}>
        <DebouncedInput
          value={globalFilter ?? ''}
          onChange={value => setGlobalFilter(String(value))}
          style={{width: 300}}
          placeholder="Search all columns..."
        />
        <Button 
          type="primary" 
          icon={<PlusOutlined />} 
          onClick={_=> navigate("/dashboard/users/create-user")}
          style={{marginLeft: 10, height: 40}}
          >
          Add User
        </Button>
      </div>
      <div className="h-2" />
      <div style={{width: "100%", overflow: "scroll", borderRadius: 4}}>
      <TableContainer>
        <Table>
          <TableHead className='table-header'>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableCell style={{fontWeight: 600}} key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <UpOutlined />,
                              desc: <DownOutlined />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>
                            </div>
                          ) : null}
                        </>
                      )}
                    </TableCell>
                  )
                })}
                <TableCell style={{fontWeight: 600, width: 20}}>Action</TableCell>
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map(row => {
              return (
                <TableRow className="table-row" key={row.id}>
                  {row.getVisibleCells().map(cell => {
                      if(cell.id.split("_")[1] == "active") {
                          return (
                              <TableCell key={cell.id}>
                              {
                                  cell.getContext().row.original.active ? "Active" : "Inactive"
                              }
                              </TableCell>
                          )
                      }
                      return (
                          <TableCell key={cell.id}>
                          {
                              flexRender(cell.column.columnDef.cell, cell.getContext())
                          }
                          </TableCell>
                      )
                  })}
                  <TableCell>
                  <Tooltip placement="bottom" title="Edit prospect">
                    <a style={{marginRight: 4}} onClick={() => editUser(row?.original)}><EditOutlined /></a>
                  </Tooltip>
                  <Tooltip placement="bottom" title="Send verification email">
                    <a onClick={() => sendVerificationEmail(row.original)} style={{marginRight: 15}}><SendOutlined /></a>
                  </Tooltip>
                  <Tooltip placement="bottom" title="Set active/inactive">
                    <Popconfirm
                        title="Are you sure to change the status of the user?"
                        description={
                            <>
                                <TextArea style={{width: 300}} required onChange={(e) => setRemark(e.target.value)} rows={4} placeholder="Add remark." />
                            </>
                        }
                        onConfirm={() => confirm(row.getVisibleCells()[~~row.id])}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Switch style={{marginRight: 15}} size="small" checked={row.getVisibleCells()[~~row.id]?.row?.original?.active} defaultChecked />
                    </Popconfirm>
                  </Tooltip>

                  </TableCell> 
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        </TableContainer>
      </div>
      <div className="h-2" />
      <div className="pagination-section">
        <span>
        <Button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
          style={{margin: 2}}
        >
          {'<<'}
        </Button>
        <Button
          className="border rounded p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          style={{margin: 2}}
        >
          {'<'}
        </Button>
        <Button
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          style={{margin: 2}}
        >
          {'>'}
        </Button>
        <Button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
          style={{margin: 2}}
        >
          {'>>'}
        </Button>
        </span>

        <span>
        <span className="flex items-center gap-1">
          <strong style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            {`Page ${table.getState().pagination.pageIndex + 1} of ${table.getPageCount()}`}
            <Input
            placeholder="Go to page"
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            style={{width: 50, marginLeft: 10}}
          />
          </strong>
        </span>
        </span>
        <Select
            defaultValue="10"
            style={{ width: 200 }}
            onChange={handlePageLengthDropdown}
            options={[
              { label: '5', value: '5' },
              { label: '10', value: '10' },
              { label: '15', value: '15' },
              { label: '20', value: '20' },
            ]}
          />
      </div>
      <div>{table.getPrePaginationRowModel().rows.length} Rows</div>
    </div>
  </>
)
}

export default UserList;
