import { combineReducers } from "@reduxjs/toolkit";
import userSlice from "./auth/userSlice";
import authSlice from "./auth/authSlice";
import utilsSlice from "./utils/utilsSlice";
import masterSlice from "./master/masterSlice";
import quoteSlice from "./quoteSlices/quoteSlice";
import menuSlice from "./menu/menuSlice";

const rootReducer = combineReducers({
    user: userSlice,
    auth: authSlice,
    utils: utilsSlice,
    quote: quoteSlice,
    master: masterSlice,
    menu: menuSlice,
})

export default rootReducer;