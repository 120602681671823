import { Button, Card, Col, Empty, Form, Input, InputNumber, Row, Select, Tag, Tooltip, Typography } from "antd";
import "../Requirements.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { useAppSelector } from '../../../../app/hooks';

import { authSelector } from '../../../../features/auth/authSlice';

import { ReactComponent as EyeIcon } from './../../../../utils/icons/view_icon.svg';

import {
    fetchVehiclesData,
    fetchMakersData,
    fetchModelsData,
    fetchVariantsData,
    fetchCitiesData,
    fetchGevlStateListData,
    fetchTenuresData,
    fetchAccessoriesData,
    fetchServicesData,
  } from '../../Quote/QuoteUtils/dataFetchingUtils';
import {
    setVehicles,
    setMakers,
    setModels,
    setVariants,
    setCities,
    setGevlStateList,
    setTenures,
    setAccessories,
    setServices,
    } from '../../../../features/quoteSlices/quoteSlice';
import SelectSearch from "../../../../utils/SelectSearch";
import { getSelectOptionsValue } from "../utils/selectOptionsValue";
import _ from "lodash";
import { getFormattedDate } from "../../../../utils/helpers/utils";
import ViewDocuments from "./Document/ViewDocuments";
import ViewProof from "./ViewProof";
interface RequirementFormProps {
    form: any;
    formType: 'ADD' | 'EDIT' | 'DISPLAY';
    data: any;
}
const { Option } = Select;
const { TextArea } = Input;
const OTHER_SERVICE_ID = 9, OTHER_ACCESSORIES_ID = 5;
const roadTaxTypeOptions = [
  { value: "Financed", label: "Financed" },
  { value: "Reimbursed", label: "Reimbursed" },
  { value: "Not Managed", label: "Not Managed" },
];
const RequirementForm: React.FC<RequirementFormProps> = ({ form, formType, data }) => {
  const [selectedVehicleId,setSelectedVehicleId] = useState<number | null>(null);
  const [selectedMakeId,setSelectedMakeId] = useState<number | null>(null);
  const [selectedModelId, setSelectedModelId] = useState<number | null>(null); 
  const [selectedVariantId, setSelectedVariantId] = useState<number | null>(null); 
  const [isEdited, setIsEdited] = useState<boolean>(false); 
  const [vehicleChoice, setVehicleChoice] = useState<any>('vehicle');
  const [selectedCityId, setSelectedCityId] = useState<number | null>(null); 
  const [gevlStateId, setGevlStateId] = useState<number | null>(null); 
  const [selectedAccessories, setSelectedAccessories] = useState<string[]>([]);
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [isOtherAccessoriesSelected, setIsOtherAccessoriesSelected] = useState<boolean>(false); 
  const [isOtherServicesSelected, setIsOtherServicesSelected] = useState<boolean>(false);
  const [showViewDocs, setShowViewDocs] = useState<boolean> (false);
  const [documentActionTargetId, setDocumentActionTargetId] = useState<number> (-1);
  const [showUpload, setShowUpload] = useState<boolean> (false);
  const auth = useAppSelector(authSelector);

  const cities = useSelector((store: any) => store.quote.cities);
  const vehicles = useSelector((store: any) => store.quote.vehicles);
  const makers = useSelector((store: any) => store.quote.makers);
  const models = useSelector((store: any) => store.quote.models);
  const variants = useSelector((store: any) => store.quote.variants);
  const tenures = useSelector((store: any) => store.quote.tenures);
  const gevlStateList = useSelector((store: any) => store.quote.gevlStateList);
  const accessories = useSelector((store: any) => store.quote.accessories);
  const services = useSelector((store: any) => store.quote.services);

  const selectOptionsValue = getSelectOptionsValue(vehicles);
  const HARYANA_STATE_ID:number =6; 
  const dispatch = useDispatch();
  useEffect(()=>{
    const fetchVehicles = async () => dispatch(setVehicles(await fetchVehiclesData(auth)));      
    const fetchCities = async () => dispatch(setCities(await fetchCitiesData(auth)));      
    const fetchGevlStates = async () => dispatch(setGevlStateList(await fetchGevlStateListData(auth)));
    const fetchTenures = async () => dispatch(setTenures(await fetchTenuresData(auth)));      
    const fetchAccessories = async () => dispatch(setAccessories(await fetchAccessoriesData(auth)));      
    const fetchServices = async () => dispatch(setServices(await fetchServicesData(auth)));      
    
    if(vehicles.length===0) fetchVehicles();
    if(cities.length===0) fetchCities();
    if(gevlStateList.length===0) fetchGevlStates();
    if(tenures.length===0) fetchTenures();
    fetchAccessories();
    fetchServices();          
  },[]);
  useEffect(()=>{
    async function fetchMMV(){
      dispatch(setMakers(await fetchMakersData(auth, JSON.parse(data.vehicle)?.id)));
      dispatch(setModels(await fetchModelsData(auth, JSON.parse(data.make)?.id)));
      dispatch(setVariants(await fetchVariantsData(auth, JSON.parse(data.model)?.id)));
            
    }
    if(formType==="EDIT"){
    fetchMMV()
    form.setFieldValue("vehicleType", data.vehicleType);
    form.setFieldValue("vehicleId", JSON.parse(data.vehicle)?.id);
    form.setFieldValue("makeId", JSON.parse(data.make)?.id);
    form.setFieldValue("modelId", JSON.parse(data.model)?.id);
    form.setFieldValue("variantId", JSON.parse(data.variant)?.id);
    form.setFieldValue("cityId", data.cityId);
    form.setFieldValue("gevlStateId", data.stateId);
    form.setFieldValue("registeredTo", data.registeredTo);
    form.setFieldValue("registrationType", data.registrationType);
    form.setFieldValue("paymentType", data.paymentType);
    form.setFieldValue("insuranceType", data.insuranceType);
    form.setFieldValue("vehicleCount", data.vehicleCount);
    form.setFieldValue("tenure", JSON.parse(data.tenure)?.id);
    form.setFieldValue("mileage", data.mileage);
    form.setFieldValue("leaseType", data.leaseType);
    form.setFieldValue("accessories", JSON.parse(data.accessories)?.map((accesory:any)=>accesory.id));
    form.setFieldValue("services", JSON.parse(data.services)?.map((service:any)=>service.id));
    form.setFieldValue("roadTaxType", data.roadTaxType);
    form.setFieldValue("remark", data.remark);
    }
      
  },[formType])
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setMakers(await fetchMakersData(auth, selectedVehicleId)));
        dispatch(setModels([]));
        dispatch(setVariants([]));
        setSelectedMakeId(null);
        setSelectedModelId(null);
        setSelectedVariantId(null);
        setVehicleChoice('make');
      } catch (error) {
        console.log(`Got error while fetching setMakers data`,error);
      }
    };
    if(selectedVehicleId)
      fetchData(); 
          
  },[selectedVehicleId]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setModels(await fetchModelsData(auth, selectedMakeId)));
        dispatch(setVariants([]));
        setSelectedModelId(null);
        setSelectedVariantId(null);

        setVehicleChoice('model');
      } catch (error) {
        console.log(`Got error while fetching models data`,error);
      }
    };
    
    if(selectedMakeId)  
      fetchData();   
        
  },[selectedMakeId]);

  useEffect(()=>{
    const fetchAsyncVariantsData = async () => {
      try {
        dispatch(setVariants(await fetchVariantsData(auth, selectedModelId)));
        setSelectedVariantId(null);
        setVehicleChoice('variant');
      } catch (error) {
        console.log(`Got error while fetching variant data`,error);
      }
    };
    
    if(selectedModelId){
      fetchAsyncVariantsData();
    }

  },[selectedModelId])
  useEffect(()=>{
    if(vehicleChoice == `make`){
      form.setFieldsValue({
        makeId: null,
        modelId: null,
        variantId: null,
      });  
    } else if(vehicleChoice == `model`){
      form.setFieldsValue({
        modelId: null,
        variantId: null,
      });  
    } else if(vehicleChoice == `variant`){
      form.setFieldsValue({
        variantId: null,
      });   
    }
  }, [vehicleChoice]);
  useEffect(()=>{
    const otherAccesory = selectedAccessories?.find((accessoryId:any)=> accessoryId === OTHER_ACCESSORIES_ID)
    if(otherAccesory) {
      setIsOtherAccessoriesSelected(true); 
      form.setFieldsValue({
        otherAccessoriesDescription: data?.otherAccessoriesDescription,
      });  
    }
    else{
      setIsOtherAccessoriesSelected(false);
      form.setFieldsValue({
        otherAccessoriesDescription: '',
      });   
    }
  },[selectedAccessories])
  useEffect(()=>{
    const otherService = selectedServices?.find((serviceId:any)=> serviceId === OTHER_SERVICE_ID)
    if(otherService) {
      setIsOtherServicesSelected(true); 
      form.setFieldsValue({
        otherServicesDescription: data?.otherServicesDescription,
      });  
    }
    else{
      setIsOtherServicesSelected(false);
      form.setFieldsValue({
        otherServicesDescription: '',
      });   
    }
  },[selectedServices])
  useEffect(()=>{
    setSelectedAccessories(data?.accessories ? JSON.parse(data?.accessories)?.map((accessory:any)=>accessory?.id):[])
    setSelectedServices(data?.services ? JSON.parse(data?.services)?.map((service:any)=>service?.id):[])
  },[])
  let initialValues: { [fieldName: string]: any } = {};
  selectOptionsValue.forEach((option:any)=>initialValues[option.name]=option.values[0]);
  const optionsToRender = (options: any[]) => {
      if (options === tenures){
          return options.map((value: any) => (
            <Option key={value.id} value={value.id}>
              {value.inMonth}
            </Option>
          ));
        }
      if(!Array.isArray(options))  return;
      return options?.map((value: any) => (
        <Option key={value.id} value={value.id}>
          {value.name}
        </Option>
      ));
  };
  const handleVehicleIdSelect = async (id:any)=>{
      setSelectedMakeId(null); 
      setSelectedModelId(null); 
      setSelectedVariantId(null); 
      setSelectedVehicleId(id);
      if(!isEdited) setIsEdited(true);
  }
  const handleMakeIdSelect = async (id:any)=>{
    setSelectedModelId(null); 
    setSelectedVariantId(null); 
    setSelectedMakeId(id); 
    if(!isEdited) setIsEdited(true);
  }
  const handleModelIdSelect = async (id:any)=>{
    setSelectedVariantId(null); 
    setSelectedModelId(id); 
    if(!isEdited) setIsEdited(true);
  }
  const handleVariantIdSelect = async (id:any)=>{
    setSelectedVariantId(id);
  }
  const handleTenureSelectId = async (id:any)=>{
  }
  const handleCityIdSelect = async (cityId:any)=>{
    setSelectedCityId(cityId);
    const cityDetail = cities.find((city:any)=>city.id == cityId);
    const gevlStateDetail = gevlStateList.find((state:any)=> state.stateCode == cityDetail.stateCode);
    if(gevlStateDetail){
      setGevlStateId(gevlStateDetail.id);
      form.setFieldsValue({
        gevlStateId: gevlStateDetail.id, 
      });
    }
    else{
      setGevlStateId(HARYANA_STATE_ID);
      form.setFieldsValue({
        gevlStateId: HARYANA_STATE_ID, 
      });
    }
  }
  const handleGevlStateIdSelect = async (stateId:any)=>{
    if(stateId != gevlStateId){
      setGevlStateId(stateId);
    }
  }

  return (
    <div style={{ margin: '20px 0px' }}>
        <Form
            form={form}
            layout="vertical"
            initialValues={initialValues}
            requiredMark={false}
        >
              {formType === 'DISPLAY' ? (
                  <>
                    <Row style={{ display: 'flex', alignItems: 'flex-start', alignSelf: 'stretch' }}>              
                        <Col className="description-item">
                            <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Vehicle Type</Typography>
                            <Typography >
                                {data.vehicleType}
                            </Typography>
                        </Col>
                        <Col className="description-item">
                            <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Vehicle Category</Typography>
                            <Typography >
                                {JSON.parse(data.vehicle).name}
                            </Typography>
                        </Col>
                        <Col className="description-item">
                            <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Make</Typography>
                            <Typography >
                                {JSON.parse(data.make).name}
                            </Typography>
                        </Col>
                        <Col className="description-item">
                            <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Model</Typography>
                            <Typography >
                                {JSON.parse(data.model).name}
                            </Typography>
                        </Col>
                        <Col className="description-item">
                            <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Variant</Typography>
                            <Typography >
                                {JSON.parse(data.variant).name}
                            </Typography>
                        </Col>
                        <Col className="description-item">
                            <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Buying City</Typography>
                            <Typography >
                                {data.cityName}
                            </Typography>
                        </Col>
                        <Col className="description-item">
                            <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>GEVL State</Typography>
                            <Typography >
                                {data.stateName}
                            </Typography>
                        </Col>
                        <Col className="description-item">
                            <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Registered To</Typography>
                            <Typography >
                                {data.registeredTo}
                            </Typography>
                        </Col>
                        <Col className="description-item">
                            <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Registration Type</Typography>
                            <Typography >
                                {data.registrationType}
                            </Typography>
                        </Col>
                        <Col className="description-item">
                            <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Payment Type</Typography>
                            <Typography >
                                {data.paymentType}
                            </Typography>
                        </Col>
                        <Col className="description-item">
                            <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Insurance Type</Typography>
                            <Typography >
                                {data.insuranceType}
                            </Typography>
                        </Col>
                        <Col className="description-item">
                            <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Road Tax Type</Typography>
                            <Typography >
                                {data.roadTaxType}
                            </Typography>
                        </Col>
                        <Col className="description-item">
                            <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>No of Vehicles</Typography>
                            <Typography >
                                {data.vehicleCount}
                            </Typography>
                        </Col>
                        <Col className="description-item">
                            <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Tenure In Month</Typography>
                            <Typography >
                              {JSON.parse(data.tenure)?.name}
                            </Typography>
                        </Col>
                        <Col className="description-item">
                            <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Mileage Per Month(KM)</Typography>
                            <Typography >
                                {data.mileage}
                            </Typography>
                        </Col>
                        <Col className="description-item">
                            <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Lease Type</Typography>
                            <Typography >
                                {data.leaseType}
                            </Typography>
                        </Col>
                    </Row>  
                    {
                      ((data?.accessories || data?.accessories) && ((_.isArray(JSON.parse(data?.accessories)) && JSON.parse(data?.accessories).length !== 0) || (_.isArray(JSON.parse(data?.services)) && JSON.parse(data?.services).length !== 0))) 
                      && <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{fontWeight:'bolder'}}>Accessories & Services</span>
                        <div style={{ flex: '1', height: '1px', background: '#B2B2B2', marginLeft:'10px'}}></div>
                      </Row>
                    }
                        
                    <Row style={{display:'flex', alignItems:'flex-start', alignSelf:'stretch'}}>
                      { data.accessories !== null && JSON.parse(data.accessories)?.length!==0 &&
                        <Col className="description-item-wide">
                          <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Accessories</Typography>
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                              {JSON.parse(data.accessories)?.map((accessory: any, index: number) => (
                                  <Tag style={{marginBottom: '5px'}}>{accessory.name}</Tag>
                              ))}
                          </div>
                      </Col>
                      }

                      { data.otherAccessoriesDescription &&
                        <Col className="description-item-wide">
                          <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Other Accessories Description</Typography>
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                              {data.otherAccessoriesDescription.split(',')?.map((service: any, index: number) => (
                                  <Tag style={{marginBottom: '5px'}}>{service}</Tag>
                              ))}
                          </div>
                        </Col>
                      }

                      { data.services !== null && JSON.parse(data.services)?.length!==0 &&
                      <Col className="description-item-wide">
                          <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Services</Typography>
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                              {JSON.parse(data.services)?.map((service: any, index: number) => (
                                  <Tag style={{marginBottom: '5px'}}>{service.name}</Tag>
                              ))}
                          </div>
                      </Col>
                      }
                      {data.remark && 
                          <Col className="description-item" style={{width: "100%"}}>
                              <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Remark</Typography>
                              <Typography >
                                  {data.remark}
                              </Typography>
                          </Col>
                        }
                    </Row>
                    {(data?.quoteStatusId === 6 || data?.quoteStatusId === 7 || data?.quoteStatusId === 8 || (data?.quoteStatusId === 3 && data?.isReviseBypass === 1)) && 
                      <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{fontWeight:'bolder'}}>Quote Status Details</span> 
                          {(data?.quoteStatusId === 8 || (data?.quoteStatusId === 3 && data?.isReviseBypass === 1)) && data?.revisionProofDocCount > 0 &&
                          <Button 
                          onClick={() => {
                            setShowViewDocs(true);
                            setDocumentActionTargetId(parseInt(data.requirementId, 10));
                          }}
                            style={{boxShadow:"none" ,color : '#2C66E3', backgroundColor: 'transparent', border: 'none', fontSize: '12px', fontWeight: 500, paddingRight:"0px"}} > 
                          <EyeIcon style={{color : '#2C66E3', marginRight:'0.1rem', paddingTop:"0.3rem", height:"15px"}}/>
                            View Proof
                          </Button>
                          }
                        <div style={{ flex: '1', height: '1px', background: '#B2B2B2', marginLeft:'10px'}}></div>
                      </Row>
                    }
                    {
                    showViewDocs &&
                      <ViewProof showViewDocs={showViewDocs} setShowViewDocs={setShowViewDocs} documentActionTargetId={documentActionTargetId}/>
                    }
                    {
                      (data?.quoteStatusId === 6)
                      &&
                      <div>
                          <Row style={{display:'flex', alignItems:'flex-start', alignSelf:'stretch'}}>
                              <Typography style={{ fontSize: 12, fontWeight: 500 ,paddingTop : '5px', paddingBottom : '5px'}}>
                                Cancelled by : {data.rejectCancelReviseBy && JSON.parse(data.rejectCancelReviseBy).name} <span style={{color : '#B2B2B2', marginLeft : '5px', marginRight : '5px'}}> | 
                                </span> Cancelled at : {data.rejectCancelReviseAt && getFormattedDate((new Date(data.rejectCancelReviseAt.split(" ")[0] + "T" + data.rejectCancelReviseAt.split(" ")[1] + "Z")).toISOString())}
                              </Typography>
                          </Row>
                          <Row style={{display:'flex', alignItems:'flex-start', alignSelf:'stretch', paddingBottom : '20px'}}>
                              <Typography style={{ fontSize: 14, marginBottom: 5 }}><span style={{fontWeight : 500}}>
                                Remark : </span> {data?.rejectCancelReviseRemark}
                              </Typography>
                          </Row>
                      </div> 
                    }

                    {
                      (data?.quoteStatusId === 7)
                      &&
                      <div>
                          <Row style={{display:'flex', alignItems:'flex-start', alignSelf:'stretch'}}>
                              <Typography style={{ fontSize: 12, fontWeight: 500 ,paddingTop : '5px', paddingBottom : '5px'}}>
                                Rejected by : {data.rejectCancelReviseBy && JSON.parse(data.rejectCancelReviseBy).name} 
                                <span style={{color : '#B2B2B2', marginLeft : '5px', marginRight : '5px'}}> | 
                                </span>Rejected at : {data.rejectCancelReviseAt && getFormattedDate((new Date(data.rejectCancelReviseAt.split(" ")[0] + "T" + data.rejectCancelReviseAt.split(" ")[1] + "Z")).toISOString())}
                                </Typography>
                          </Row>
                          <Row style={{display:'flex', alignItems:'flex-start', alignSelf:'stretch', paddingBottom : '20px'}}>
                              <Typography style={{ fontSize: 14, marginBottom: 5 }}><span style={{fontWeight : 500}}>
                                Remark : </span> {data?.rejectCancelReviseRemark}
                              </Typography>
                          </Row>
                      </div> 
                    }

                    {
                      (data?.quoteStatusId === 8 || (data?.quoteStatusId === 3 && data?.isReviseBypass === 1))
                      &&
                      <div>
                          <Row style={{display:'flex', alignItems:'flex-start', alignSelf:'stretch'}}>
                              <Typography style={{ fontSize: 12, fontWeight: 500 ,paddingTop : '5px', paddingBottom : '5px'}}>
                                Revise by : {data?.rejectCancelReviseBy && JSON.parse(data?.rejectCancelReviseBy)?.name}
                                <span style={{color : '#B2B2B2', marginLeft : '5px', marginRight : '5px'}}> | 
                                </span>Revise at : {data.rejectCancelReviseAt && getFormattedDate((new Date(data.rejectCancelReviseAt.split(" ")[0] + "T" + data.rejectCancelReviseAt.split(" ")[1] + "Z")).toISOString())}
                              </Typography>
                          </Row>
                          <Row style={{display:'flex', alignItems:'flex-start', alignSelf:'stretch', paddingBottom : '20px'}}>
                              <Typography style={{ fontSize: 14, marginBottom: 5 }}><span style={{fontWeight : 500}}>
                                Remark : </span> {data?.rejectCancelReviseRemark === null ? "Sample quote revised by some update in prospect requirement" : data?.rejectCancelReviseRemark}
                              </Typography>
                          </Row>
                      </div> 
                    }
                  </>
                          
                  ) : (
                    <>
                    <Row style={{ display: 'flex', alignItems: 'flex-start', alignSelf: 'stretch' }}>
            
                          {/* Editable mode */}
                      <Form.Item style={{width:'12rem', marginRight:'20px'}}
                          label={ <p className="description-label">Vehicle Type</p> }
                          name="vehicleType"
                          rules={[{ required: true, message: 'Please enter Vehicle Type!' }]}
                      >
                          <Select showSearch={true} style={{ width: '100%' }}>
                            {selectOptionsValue.find((option)=>option.name==="vehicleType")?.values.map((value: any) => (
                              <Option key={value} value={value}>
                                {value}
                              </Option>
                            ))}
                          </Select>
                      </Form.Item>
                      <Form.Item style={{width:'12rem', marginRight:'20px'}}
                          label={ <p className="description-label">Vehicle Category</p> }
                          name = "vehicleId"
                          rules={[{ required: true, message: 'Please enter Vehicle Category!' }]}
                      >
                          
                          <SelectSearch name={'vehicleId'} placeholder={"Select Vehicle"} options={vehicles.map((id:any)=>{
                                return {
                                    id: id?.id,
                                    name: id?.name,
                                }
                              }) 
                            } onSelect={handleVehicleIdSelect}/>

                      </Form.Item>
                      <Form.Item style={{width:'12rem', marginRight:'20px'}}
                          label={ <p className="description-label">Make</p> }
                          name = "makeId"
                          rules={[{ required: true, message: 'Please enter Make!' }]}
                      >
                          <SelectSearch name={'makeId'} placeholder={"Select Vehicle Make"} options={makers.map((id:any)=>{
                                return {
                                    id: id?.id,
                                    name: id?.name,
                                }
                              }) 
                            } onSelect={handleMakeIdSelect}/>
                      </Form.Item>
                      
                      <Form.Item style={{width:'12rem', marginRight:'20px'}}
                          label={ <p className="description-label">Model</p> }
                          name = "modelId"
                          rules={[{ required: true, message: 'Please enter Model!' }]}
                      >
                          <SelectSearch name={'modelId'} placeholder={"Select Vehicle Model"} options={models.map((id:any)=>{
                                return {
                                    id: id?.id,
                                    name: id?.name,
                                }
                              }) 
                            } onSelect={handleModelIdSelect}/>
                      </Form.Item>
                      <Form.Item style={{width:'12rem', marginRight:'20px'}}
                          label={ <p className="description-label">Variant</p> }
                          name = "variantId"
                          rules={[{ required: true, message: 'Please enter Variant!' }]}
                      >
                          <SelectSearch name={'variantId'} placeholder={"Select Variant"} options={variants.map((id:any)=>{
                                return {
                                    id: id?.id,
                                    name: id?.name,
                                }
                              }) 
                            } onSelect={handleVariantIdSelect}/>
                      </Form.Item>
                      <Form.Item style={{width:'12rem', marginRight:'20px'}}
                          label={ <p className="description-label">Buying City</p> }
                          name="cityId"
                          rules={[{ required: true, message: 'Please enter Buying City!' }]}
                      >
                          <SelectSearch name={'cityId'} placeholder={"City"} options={cities.map((id:any)=>{
                                return {
                                    id: id?.id,
                                    name: id?.cityName,
                                }
                              }) 
                            } onSelect={handleCityIdSelect}/>
                      </Form.Item>
                      
                      <Form.Item style={{width:'12rem', marginRight:'20px'}}
                          label={ <p className="description-label">GEVL State</p> }
                          name="gevlStateId"
                          rules={[{ required: true, message: 'Please enter Vehicle Type!' }]}
                      >
                          <SelectSearch name={"gevlStateId"} placeholder={"State"} options={Array.isArray(gevlStateList)? gevlStateList.map((id:any)=>{
                                return {
                                    id: id?.id,
                                    name: id?.stateName,
                                }
                              }) : []
                            } onSelect={handleGevlStateIdSelect}/>
                      </Form.Item>
                      <Form.Item style={{width:'12rem', marginRight:'20px'}}
                          label={ <p className="description-label">Registered To</p> }
                          name="registeredTo"
                          rules={[{ required: true, message: 'Please enter Registered To!' }]}
                      >
                          <Select showSearch={true} style={{ width: '100%' }} >
                            {selectOptionsValue.find((option)=>option.name==="registeredTo")?.values.map((value: any) => (
                              <Option key={value} value={value}>
                                {value}
                              </Option>
                            ))}
                          </Select>
                      </Form.Item>
                      <Form.Item style={{width:'12rem', marginRight:'20px'}}
                          label={ <p className="description-label">Registration Type</p> }
                          name= "registrationType"
                          rules={[{ required: true, message: 'Please enter Registration Type!' }]}
                      >
                          <Select showSearch={true} style={{ width: '100%' }} >
                            {selectOptionsValue.find((option)=>option.name==="registrationType")?.values.map((value: any) => (
                              <Option key={value} value={value}>
                                {value}
                              </Option>
                            ))}
                          </Select>
                      </Form.Item>
                      
                      <Form.Item style={{width:'12rem', marginRight:'20px'}}
                          label={ <p className="description-label">Payment Type</p> }
                          name= "paymentType"
                          rules={[{ required: true, message: 'Please enter Payment Type!' }]}
                      >
                          <Select showSearch={true} style={{ width: '100%' }} >
                            {selectOptionsValue.find((option)=>option.name==="paymentType")?.values.map((value: any) => (
                              <Option key={value} value={value}>
                                {value}
                              </Option>
                            ))}
                          </Select>
                      </Form.Item>
                      <Form.Item style={{width:'12rem', marginRight:'20px'}}
                          label={ <p className="description-label">Insurance Type</p> }
                          name= "insuranceType"
                          rules={[{ required: true, message: 'Please enter Insurance Type!' }]}
                      >
                          <Select showSearch={true} style={{ width: '100%' }} >
                            {selectOptionsValue.find((option)=>option.name==="insuranceType")?.values.map((value: any) => (
                              <Option key={value} value={value}>
                                {value}
                              </Option>
                            ))}
                          </Select>
                      </Form.Item>
                      <Form.Item style={{width:'12rem', marginRight:'20px'}}
                            label={<p className="description-label">Road Tax</p>}
                            name="roadTaxType"
                            rules={[{ required: true, message: 'Please enter Road Tax!' }]}
                        >
                          <Select 
                              placeholder="Road Tax"
                              options={roadTaxTypeOptions}
                              className="select-input-override"
                              style={{ width: '100%' }}
                          />
                      </Form.Item>
                      <Form.Item style={{width:'12rem', marginRight:'20px'}}
                          label={ <p className="description-label">No of Vehicles</p> }
                          name="vehicleCount"
                          rules={[{ required: true, message: 'Please enter No of Vehicles!' }]}
                      >
                          <InputNumber placeholder="Enter no of Vehicles" style={{width:'12rem'}}/>
                      </Form.Item>
                      <Form.Item style={{width:'12rem', marginRight:'20px'}}
                          label={ <p className="description-label">Tenure In Month</p> }
                          name = "tenure"
                          rules={[{ required: true, message: 'Please enter Tenure In Month!' }]}
                      >
                          <Select placeholder="Select Tenure" style={{ width: '100%' }} showSearch={true} >
                              {optionsToRender(tenures)}
                          </Select>
                      </Form.Item>

                      <Form.Item style={{width:'12rem', marginRight:'20px'}}
                          label={ <p className="description-label">Mileage Per Month(KM)</p> }
                          name="mileage"
                          rules={[{ required: true, message: 'Please enter Mileage Per Month(KM)!' }]}
                      >
                          <InputNumber placeholder="Mileage per Month" style={{width:'12rem'}} />
                      </Form.Item>


                      <Form.Item style={{width:'12rem', marginRight:'20px'}}
                          label={ <p className="description-label">Lease Type</p> }
                          name= "leaseType"
                          rules={[{ required: true, message: 'Please enter Lease Type!' }]}
                      >
                          <Select showSearch={true} style={{ width: '100%' }} >
                            {selectOptionsValue.find((option)=>option.name==="leaseType")?.values.map((value: any) => (
                              <Option key={value} value={value}>
                                {value}
                              </Option>
                            ))}
                          </Select>
                      </Form.Item>
                    </Row>
                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{fontWeight:'bolder'}}>Accessories & Services <span style={{color:'#B2B2B2',marginLeft:'4px'}}>(Optional)</span></span>
                      <div style={{ flex: '1', height: '1px', background: '#B2B2B2', marginLeft:'10px'}}></div>
                    </Row>
                    <Row style={{display:'flex', alignItems:'flex-start', alignSelf:'stretch'}}>
                      <Form.Item style={{width:'30rem', marginRight:'20px'}}
                          label={ <p className="description-label">Accessories</p> }
                          name="accessories"
                          rules={[{ required: false, message: 'Please enter Accessories!' }]}
                      >
                            <Select
                                showSearch={true}
                                mode="multiple"
                                placeholder={`Select Accessories`}
                                value={selectedAccessories}
                                onChange={(values) => setSelectedAccessories(values)}
                            >
                                {optionsToRender(accessories)}
                            </Select>
                      </Form.Item>
                      {
                        isOtherAccessoriesSelected && 
                          <Form.Item style={{width:'30rem', marginRight:'20px'}}
                            label={ <p className="description-label">Other Accessories Description</p> }
                            name="otherAccessoriesDescription"
                            rules={[{ required: false }]}
                          >
                            {/* <Tooltip placement="bottom" title="Please enter multiple accessories separated by commas"> */}
                              <Input placeholder="Please enter multiple accessories separated by commas"/>
                            {/* </Tooltip> */}
                              
                          </Form.Item>
                      }
                      
                      <Form.Item style={{width:'30rem', marginRight:'20px'}}
                          label={ <p className="description-label">Services</p> }
                          name="services"
                          rules={[{ required: false, message: 'Please enter Services!' }]}
                      >
                            <Select
                              showSearch={true}
                              mode="multiple"
                              placeholder={`Select Services`}
                              value={selectedServices}
                              onChange={(values) => {
                                  setSelectedServices(values);
                                }
                              }
                            >
                              {optionsToRender(services)}
                            </Select>
                      </Form.Item>
                      {/* {
                        isOtherServicesSelected && 
                          <Form.Item style={{width:'30rem', marginRight:'20px'}}
                            label={ <p className="description-label">Other Services Description</p> }
                            name="otherServicesDescription"
                            rules={[{ required: false }]}
                          >
                              <Input placeholder="Please enter multiple services separated by commas"/>
                             
                          </Form.Item>
                      } */}
                      <Form.Item style={{width:'100%', marginRight:'20px'}}
                            label={<p className="description-label">Remark</p>}
                            name="remark"
                        >
                          <Input
                                name='remark'
                                placeholder='Remark'
                                className="input-override"
                            />
                      </Form.Item>
                  </Row>
                    </>
                  )}
        </Form>
    </div>
  );
    
}

export default RequirementForm;
