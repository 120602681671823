import { Button, Checkbox, Form, Input, Modal, Row, Select, Spin, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { ReactComponent as CrossIcon } from '../.././utils/SVGs/cross_icon.svg';
import { ReactComponent as TickIcon } from '../.././utils/SVGs/tick_icon_stroke.svg';

import { ReactComponent as PipeIcon } from '../../../../../utils/icons/Pipe_Icon.svg'; 
const { Option } = Select;
const OTHER = "Other";

interface SelectNewDocumentProps {
  documentsType: any[];
  setDocumentsType: (docsType: any[]) => void;
  documentsInternal: any[];
  handleClose: () => void;
  handleSave: (id: number, name:string) => void;
  
}

const SelectNewDocument: React.FC<SelectNewDocumentProps> = ({  
  documentsType,
  setDocumentsType,
  documentsInternal,
  handleClose,
  handleSave,
  
}) => {
  const [seletedDocumentId, setSelectedDocumentId] = useState<number|null>(null);
  const [showOtherOptionField, setShowOtherOptionField] = useState<boolean>(false);
  const [otherOptionDocName, setOtherOptionDocName] = useState<string>('');
  const OTHER_DOC_ID: number = 6;
  const handleDocumentSelect = (e:any)=>{
    const selectedId: number = parseInt(e,10);
    if(selectedId===OTHER_DOC_ID){
        setShowOtherOptionField(true);
    }
    setSelectedDocumentId(selectedId);

  }
  const handleSelectClick = ()=>{
    setDocumentsType(documentsType.map((docType:any)=> 
        documentsInternal.find((doc:any)=> doc.id == docType.id && doc.id!=OTHER_DOC_ID) ? {...docType,disabled:true} : {...docType,disabled:false}
    ))
  }
  
  return (
    <div style={{padding:'1rem 0.6rem', backgroundColor:'#E6F2FD', borderRadius:'6px'}}>
        {
            showOtherOptionField ?
            <div style={{display:'flex'}}>
                <span style={{margin:'0.3rem 0.3rem'}}>Other:</span>
                <Input value={otherOptionDocName} placeholder='Enter doc name' onChange={(e)=>setOtherOptionDocName(e.target.value)}></Input>
            </div>
            :
            <Select style={{width:'100%'}} placeholder='Select Document' onSelect={handleDocumentSelect} onClick={handleSelectClick}>
            { documentsType.map((docType:any)=><Option key={docType?.id} disabled={docType.disabled}>{docType?.name}</Option>) }
            </Select>
        }
        
        <div style={{display:'flex', justifyContent:'flex-end'}}>
            <Button icon={<CrossIcon />} style={{color:'#FF4D4F',border:'none', boxShadow:'none', fontWeight: '500', backgroundColor:'#E6F2FD'}} key="cancel" onClick={handleClose}>
                Cancel
            </Button>
            <div style={{ marginTop: '7px' }}><PipeIcon /> </div>
                    
            <Button icon={<TickIcon color={ ((seletedDocumentId!=OTHER_DOC_ID||otherOptionDocName!='') && seletedDocumentId) ? '#02B51F' : 'grey'}/>} 
                disabled={((seletedDocumentId!=OTHER_DOC_ID||otherOptionDocName!='') && seletedDocumentId) ? false : true} 
                style={{
                    color:((seletedDocumentId!=OTHER_DOC_ID||otherOptionDocName!='') && seletedDocumentId) ? '#02B51F':'grey', border:'none', boxShadow:'none', fontWeight: '500', backgroundColor:'#E6F2FD'
                }} 
                key="confirm"  onClick={()=>handleSave(seletedDocumentId||-1, otherOptionDocName)}>
                Save
            </Button>  
        </div>
    </div>
  );
};

export default SelectNewDocument;