import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface QuoteState {
    prospects: any[],
    cities: any[]; 
    vehicles: any[]; 
    makers: any[]; 
    models: any[]; 
    variants: any[]; 
    tenures: any[]; 
    accessories: any[]; 
    services: any[]; 
    gevlStateList: any[];
}


const initialState: QuoteState = {
    prospects: [],
    cities: [],
    vehicles: [],
    makers: [],
    models: [],
    variants: [],
    tenures: [],
    accessories: [],
    services: [],
    gevlStateList: [],
};

const quoteSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    setProspects: (state, action: PayloadAction<string[]>) => {
        state.prospects = action.payload;
    },
    setCities: (state, action: PayloadAction<string[]>) => {
        state.cities = action.payload;
    },
    setVehicles: (state, action: PayloadAction<string[]>) => {
        state.vehicles = action.payload;
    },
    setMakers: (state, action: PayloadAction<string[]>) => {
      state.makers = action.payload;
    },
    setModels: (state, action: PayloadAction<string[]>) => {
        state.models = action.payload;
    },
    setVariants: (state, action: PayloadAction<string[]>) => {
      state.variants = action.payload;
    },
    setTenures: (state, action: PayloadAction<string[]>) => {
      state.tenures = action.payload;
    },
    setAccessories: (state, action: PayloadAction<string[]>) => {
      state.accessories = action.payload;
    },
    setServices: (state, action: PayloadAction<string[]>) => {
      state.services = action.payload;
    },
    setGevlStateList: (state, action: PayloadAction<string[]>) => {
      state.gevlStateList = action.payload;
  },
  },
});

export const { setProspects, setCities, setVehicles, setMakers, setModels, setVariants,setTenures, setAccessories, setServices ,setGevlStateList} = quoteSlice.actions;
export default quoteSlice.reducer;
