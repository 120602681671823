import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Modal, Typography } from "antd";
import { ReactComponent as RightArrowIcon } from '../../SVGs/right_arrow_icon.svg';
import { ReactComponent as CrossIcon } from '../../SVGs/cross_icon.svg';
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;

interface ModalProps {
    view: boolean;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    setType: (type: boolean) => void;
    setAcceptRejectModal: (acceptRejectModal: boolean) => void;
    data: any
}

const SanctionPreviewModal: React.FC<ModalProps> = ({ view, showModal, setShowModal, setType, setAcceptRejectModal, data }) => {
    console.log(data)
    return (
        <div>
            <Modal
                title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 500, color: "#404040"}}>{!view && "Review Draft"} Sanction Form</Typography>}
                centered
                open={showModal}
                onCancel={() => setShowModal(false)}
                afterClose={() => {}}
                className="preview-modal"
                width={1000}
                footer={!view && <>
                        <Button
                            ghost
                            danger
                            onClick={_ => {setShowModal(false); setType(false); setAcceptRejectModal(true)}}
                            className="button-override danger"
                            icon={<CrossIcon />}
                            >
                            Reject
                        </Button>
                        <Button
                            type="primary"
                            onClick={_ => {setShowModal(false); setType(true); setAcceptRejectModal(true)}}
                            className="button-override"
                            >
                            Approve
                            <RightArrowIcon style={{marginLeft: 8}} />
                        </Button>
                </>}
            >
            <iframe
                title="docx"
                src={view ? data?.sanctionDocId : data?.creditSanction.sanctionDocId}
                style={{ width: "100%", height: "60vh", border: "none" }}
            />
            </Modal>
        </div>
    );
}

export default SanctionPreviewModal;