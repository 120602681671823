import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Form, Typography } from 'antd';
import { ReactComponent as DownMark } from '../../../../utils/SVGs/DownMark.svg';
import { ReactComponent as RightMark } from '../../../../utils/SVGs/RightMark.svg';
import { formatIndianRupee, formatRatioNumber } from '../formUtils';

interface InterestCoverageRatioProps {
    form: any; 
    updateTotalCnt: number;
    viewModeActive: boolean;
    ebidta: number;
}

const InterestCoverageRatioComponent: React.FC<InterestCoverageRatioProps> = ({ form, updateTotalCnt, viewModeActive, ebidta }) => {
    const [interestCoverageRatio, setInterestCoverageRatio] = useState<number>(0);
    const [collapseInterestCoverageRatioFields, setCollapseInterestCoverageRatioFields] = useState<boolean>(false);
    const INR_SYMBOL: string = '\u20B9';
    
    useEffect(() => {
        const values = form.getFieldsValue(true);
        if (values.interestCoverageRatio){
            setInterestCoverageRatio(values.interestCoverageRatio);
        }
    }, [updateTotalCnt]);

    useEffect(()=>{
        handleFieldChange();
    },[ebidta])

    const handleFieldChange = () => {
        const values = form.getFieldsValue();
        // const operatingIncomeEbidta = Number(values.operatingIncomeEbidta);
        const interestExpenses = form.getFieldValue(`interestExpenses`);
        
        if (!ebidta || !interestExpenses) {
            setInterestCoverageRatio(0);
            return;
        }

        const updatedInterestCoverageRatio = ebidta / interestExpenses;
        form.setFieldsValue({ interestCoverageRatio: updatedInterestCoverageRatio });
        setInterestCoverageRatio(updatedInterestCoverageRatio);
    };

    return (
        <>
            <Row
                gutter={16}
                style={{ marginTop: '10px', paddingTop: '10px', borderBottom: '1px #E8E8E8 solid', paddingBottom: '20px' }}
            >
                <Col span={12}>
                    <span onClick={() => setCollapseInterestCoverageRatioFields((oldState) => !oldState)}>
                        {collapseInterestCoverageRatioFields ? <RightMark /> : <DownMark />}
                    </span>
                    <span style={{ fontSize: '14px', fontWeight: '600', marginLeft:'10px' }}>Interest Coverage Ratio</span>
                </Col>
                <Col span={12} key={1}>
                    <Typography style={{ fontSize: 14, fontWeight: '600', paddingLeft: '10px' }}>
                        {formatRatioNumber(interestCoverageRatio)}
                    </Typography>
                </Col>
            </Row>
            {
                !collapseInterestCoverageRatioFields && (
                <>
                    <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: '20px', borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                        <Col span={12}><p style={{ marginLeft: '20px' }}>Operating Income (EBITDA)</p></Col>
                        <Col span={12} key={1}>
                                <Typography style={{ paddingLeft: '10px' }}>
                                    <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue('ebidta'))}`}</span>
                                </Typography>
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px', borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                        <Col span={12}><p style={{ marginLeft: '20px' }}>Interest Expenses</p></Col>
                        <Col span={12} key={1}>
                            {viewModeActive ? (
                                <Typography style={{ paddingLeft: '10px' }}>
                                    <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue('interestExpenses'))}`}</span>
                                </Typography>
                            ) : (
                                <Form.Item
                                    name={"interestExpenses"}
                                    rules={[{ required: true, message: 'Please enter Interest Expenses value' }]}
                                >
                                    <Input
                                        type="number"
                                        onChange={handleFieldChange}
                                    />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                </>
                )
            }
        </>
    );
};

export default InterestCoverageRatioComponent;
