import { Button, Card, Input, Modal, Spin, Tag, Typography } from "antd";
import { ReactComponent as DisabledIcon } from '../../SVGs/disabled_icon.svg';
import { LoadingOutlined } from "@ant-design/icons";
import getFormattedText from "../../../../utils/helpers/htmlToFormattedText";
import CreditAssessmentFormDetails from "./CreditAssessmentFormDetails";

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;

interface ModalProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    data: any
}

const ViewSanctionDetailsModal: React.FC<ModalProps> = ({ showModal, setShowModal, data }) => {
    return (
        <Modal
            title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 500, color: "#404040"}}>Credit Assessment Details</Typography>}
            centered
            open={showModal}
            onOk={() => setShowModal(false)}
            onCancel={() => setShowModal(false)}
            closable={true}
            className="preview-modal"
            footer={false}
            width={1400}
        >   
            <div style={{height: 500, overflow: "scroll", marginTop: 20}}>
            <Typography style={{color: "#242424", fontWeight: 500, fontSize: 14, marginBottom: 10}}>Assessment Details <Tag style={{color: data?.creditRequestStatusMaster?.color, backgroundColor: data?.creditRequestStatusMaster?.backgroundColor, border: "none", marginLeft: 10}}>{data?.creditRequestStatusMaster?.name}</Tag></Typography>
            <Card bordered={false} style={{marginBottom: 10}}>
                <Typography>{data?.sanctionRemark && getFormattedText(data?.sanctionRemark)}</Typography>
            </Card>
            <CreditAssessmentFormDetails />
            </div>
        </Modal>
    );
}

export default ViewSanctionDetailsModal;