import axios from "axios";
import { useState } from "react";
import type { UploadProps } from 'antd';
import { Col, message, Row, Tag, Upload } from 'antd';
import { Button, Modal, Spin, Typography } from "antd";
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { useAppSelector } from "../../../../app/hooks";
import { authSelector } from "../../../../features/auth/authSlice";
import { ReactComponent as UploadIcon } from '../../SVGs/upload_icon.svg';

const { Dragger } = Upload;
const antIcon = <LoadingOutlined style={{ fontSize: 16, marginLeft: 6 }} spin />;

interface UploadModalProps {
    title: string;
    statusChangeTypeId: any;
    requestId: any;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
}

const UploadModal: React.FC<UploadModalProps> = ({ title, statusChangeTypeId, requestId, showModal, setShowModal }) => {
    const auth = useAppSelector(authSelector);
    const [file, setFile] = useState<any>(null);
    const [uploadLoader, setUploadLoader] = useState<boolean>(false);

    const handleUpload = async () => {
        if(file) {
            setUploadLoader(true);
            try {
                let bodyFormData = new FormData();
                bodyFormData.append('file', file);
                bodyFormData.append('statusChangeTypeId', statusChangeTypeId);
                bodyFormData.append('requestId', requestId);
    
                const res = await axios({
                    method: "post",
                    url: `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/file/uploadPI`,
                    data: bodyFormData,
                    headers: { "Content-Type": "multipart/form-data", "Authorization": ` Bearer ${auth.accessToken}` },
                  });
                  if(res.status === 200) { 
                    message.success("Performa uploaded successfully");
                    setUploadLoader(false);
                    setTimeout(() => window.location.reload(), 1000)
                  }
                  setShowModal(false);
            } catch (err: any) {
                console.log("Error: ", err);
                message.error(err?.response?.data?.message);
                setUploadLoader(false)
            }
        }
    }

    return (
        <Modal
            title={<Typography style={{background: "#F6FAFF", fontSize: 14, fontWeight: 500, color: "#404040"}}>{title}</Typography>}
            centered
            open={showModal}
            afterClose={() => {setFile(null); setUploadLoader(false)}}
            onOk={() => { setShowModal(false); setFile(null) }}
            onCancel={() => { setShowModal(false); setFile(null) }}
            closable={true}
            className="preview-modal"
            footer={<>
                <div className="edit-section">
                    <Button
                        ghost
                        type="primary"
                        disabled={file === null}
                        onClick={_ => !uploadLoader && handleUpload()}
                        className={file === null ? "button-override disable" : "button-override primary"}
                        icon={uploadLoader ? <Spin indicator={antIcon} /> : <UploadIcon />}
                        >
                        Upload
                    </Button>
                </div>
            </>}
        >
            <Dragger
                maxCount={1}
                fileList={file && [file]}
                beforeUpload={(file) => { setFile(file); return false }}
            >
                <p className="ant-upload-drag-icon">
                <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                <Typography>Supported file format</Typography>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                <Row>
                    <Col><Tag style={{height: 22, background: "rgba(0, 0, 0, 0.02)", borderStyle: 'dashed',}}>pdf</Tag></Col>
                    <Col><Tag style={{height: 22, background: "rgba(0, 0, 0, 0.02)", borderStyle: 'dashed',}}>doc</Tag></Col>
                    <Col><Tag style={{height: 22, background: "rgba(0, 0, 0, 0.02)", borderStyle: 'dashed',}}>docx</Tag></Col>
                    <Col><Tag style={{height: 22, background: "rgba(0, 0, 0, 0.02)", borderStyle: 'dashed',}}>xls</Tag></Col>
                    <Col><Tag style={{height: 22, background: "rgba(0, 0, 0, 0.02)", borderStyle: 'dashed',}}>xlsx</Tag></Col>
                    <Col><Tag style={{height: 22, background: "rgba(0, 0, 0, 0.02)", borderStyle: 'dashed',}}>png</Tag></Col>
                </Row>
                <Row>
                    <Col><Tag style={{height: 22, background: "rgba(0, 0, 0, 0.02)", borderStyle: 'dashed',}}>jpeg</Tag></Col>
                    <Col><Tag style={{height: 22, background: "rgba(0, 0, 0, 0.02)", borderStyle: 'dashed',}}>jpg</Tag></Col>
                    <Col><Tag style={{height: 22, background: "rgba(0, 0, 0, 0.02)", borderStyle: 'dashed',}}>txt</Tag></Col>
                    <Col><Tag style={{height: 22, background: "rgba(0, 0, 0, 0.02)", borderStyle: 'dashed',}}>rtf</Tag></Col>
                    <Col><Tag style={{height: 22, background: "rgba(0, 0, 0, 0.02)", borderStyle: 'dashed',}}>ppt</Tag></Col>
                    <Col><Tag style={{height: 22, background: "rgba(0, 0, 0, 0.02)", borderStyle: 'dashed',}}>pptx</Tag></Col>
                </Row>
                </div>
                </p>
            </Dragger>
        </Modal>
    );
}

export default UploadModal;