import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col, Radio, Space, Select } from "antd";
import { getRequest, postRequest } from "../../../../utils/handler/apiHandler";
import TagsInput from "../../../../components/TagInput";
const { Option } = Select;

function Step2Form(props: any) {
    const form = props.form;
    const [file, setFile] = useState<any>(null);
    const [services, setServices] = useState<any>([]);
    const [master, setMaster] = useState<any>([]);
    const [signatories, setSignatories] = useState<any>(form.getFieldsValue(true).authorizeSignatories || []);
    const [accManager, setAccManager] = useState<any>(form.getFieldsValue(true).accountManagers || []);
    const [bdManager, setBdManager] = useState<any>(form.getFieldsValue(true).bdManagers || []);
    const [otherEnable, setOtherEnable] = useState<any>(form.getFieldsValue(true).primaryUseCase == "Other" ? true : false)
    const [mlaNumberTags, setMLANumberTags] = useState<any>(form.getFieldsValue(true).mlaNumbers == null ? [] : form.getFieldsValue(true).mlaNumbers);
    const [insurance, setInsurance] = useState<boolean>(form.getFieldsValue(true).insuranceManaged == null ? false : form.getFieldsValue(true).insuranceManaged)

    const registerTo: any = [
        {
            id: 1,
            type: "Lessor"
        },
        {
            id: 2,
            type: "Lessee"
        }
    ];
    const insuranceType: any = [
        {
            id: 1,
            type: "AYF"
        },
        {
            id: 2,
            type: "IYF"
        },
        {
            id: 3,
            type: "AYR"
        }
    ]
    const roadTax: any = [
        {
            id: 1,
            type: "Finance"
        },
        {
            id: 2,
            type: "Reimburse"
        }
    ]
    
    useEffect(() => {
        setSignatories(form.getFieldsValue().authorizeSignatories)
      }, []);


    const handleUsecase = (value: any) => {
        console.log(value);
        value === "Other" ? setOtherEnable(true) : setOtherEnable(false);
    }

    const handleUseCaseDropdown = () => {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/all-masters`).then(res => {
            setMaster(res.data);
        });
    }

    const handleServicesDropdown = () => {
        postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/services`, {}).then(res => {
            setServices(res.data);
        });
    }

    const optionsToRender = (options: any[], identifier: any) => {
        return options?.map((value: any) => (
            <Option key={value.id} value={value?.[identifier]}>
            {value?.[identifier]}
            </Option>
        ));
    };

    const selectedSignatories = (tags: any) => {
        setSignatories(tags)
        form.setFieldsValue({
            authorizeSignatories: tags
        })
    }
    const selectedAccManager = (tags: any) => {
        setAccManager(tags)
        form.setFieldsValue({
            accountManagers: tags
        })
    }
    const selectedBdManager = (tags: any) => {
        setBdManager(tags)
        form.setFieldsValue({
            bdManagers: tags
        })
    }
    const selectMLANumber = (tags: any)=> {
        setMLANumberTags(tags)
        form.setFieldsValue({
            mlaNumbers: tags
        })
    }
    return ( 
        <React.Fragment>
            <Row className="">
                <Col className="form-column">
                    <Form.Item
                        label="Register To"
                        name="registeredTo"
                        rules={[{ required: true, message: 'Please enter registration!' }]}
                    >
                        <Select
                            showSearch={true}
                            style={{ width: '100%' }}
                            placeholder="Register To"
                        >
                            {optionsToRender(registerTo, "type")}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Insurance Managed"
                        name="insuranceManaged"
                        rules={[{ required: true, message: 'Please enter insurance managed!' }]}
                    >
                        <Radio.Group defaultValue={false} onChange={(e) => setInsurance(e.target.value)}>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Insurance Type"
                        name="insuranceType"
                        rules={[{ required: true, message: 'Please enter insurance type!' }]}
                    >
                        <Select
                            showSearch={true}
                            disabled={!insurance}
                            style={{ width: '100%' }}
                            placeholder="Insurance Type"
                        >
                            {optionsToRender(insuranceType, "type")}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Road Tax"
                        name="roadTaxType"
                        rules={[{ required: true, message: 'Please enter road tax!' }]}
                    >
                        <Select
                            showSearch={true}
                            style={{ width: '100%' }}
                            placeholder="Road Tax"
                        >
                            {optionsToRender(roadTax, "type")}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="MLA Number"
                        name="mlaNumbers"
                        rules={[{ required: true, message: 'Please enter MLA number!' }]}
                    >
                        <TagsInput selectedTags={selectMLANumber} placeholder="MLA number" tags={mlaNumberTags}/>
                    </Form.Item>
                    <Form.Item
                        label="Account Manager"
                        name="accountManagers"
                        //rules={[{ required: true, message: 'Please enter legal business name!' }]}
                    >
                        <TagsInput selectedTags={selectedAccManager} placeholder="Authorized Signatories" tags={accManager}/>
                    </Form.Item>                    
                </Col>
                <Col className="form-column">
                    <Form.Item
                        label="Primary Use Case"
                        name="primaryUseCase"
                        rules={[{ required: true, message: 'Please enter primary use case!' }]}
                    >
                        <Select
                            showSearch={true}
                            style={{ width: '100%' }}
                            placeholder="Primary Use Case"
                            onChange={(value) => handleUsecase(value)}
                            onClick={handleUseCaseDropdown}
                        >
                            {optionsToRender(master?.client_use_case, "useCase")}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Other Usecase"
                        name="otherUseCase"
                        //rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input disabled={!otherEnable} placeholder="Other Use Case" />
                    </Form.Item>
                    <Form.Item
                        label="Insurance Zero Dep"
                        name="insuranceZeroDep"
                        rules={[{ required: true, message: 'Please enter insurance zero dep!' }]}
                    >
                        <Radio.Group disabled={!insurance}>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Service Inclusion"
                        name="serviceInclusions"
                        rules={[{ required: true, message: 'Please enter service inclusions!' }]}
                    >
                        <Select
                            showSearch={true}
                            style={{ width: '100%' }}
                            placeholder="Service Inclusion"
                            mode="multiple"
                            onClick={handleServicesDropdown}
                        >
                            {optionsToRender(services, "name")}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="BD Manager"
                        name="bdManagers"
                        //rules={[{ required: true, message: 'Please enter legal business name!' }]}
                    >
                        <TagsInput selectedTags={selectedBdManager} placeholder="BD Manager(s)" tags={bdManager}/>
                    </Form.Item>
                    <Form.Item
                        label="Authorized Signatories"
                        name="authorizeSignatories"
                        //rules={[{ required: true, message: 'Please enter legal business name!' }]}
                    >
                        <TagsInput selectedTags={selectedSignatories} placeholder="Authorized Signatories" tags={signatories}/>
                    </Form.Item>
                </Col>
            </Row>
        </React.Fragment>
     );
}

export default Step2Form;