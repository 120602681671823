import React, { useState } from "react";
import "../styles/Tags.scss"
import { Button, Checkbox, Input } from "antd";

const TagsInput = props => {
    const [tags, setTags] = useState(props?.tags);
    const [tempTag, setTempTag] = useState("");

    const handleTags = (e) => {
      setTempTag(e);
    }

    const removeTags = indexToRemove => {
      setTags([...props?.tags.filter((_, index) => index !== indexToRemove)]);
      let newTags = [...props?.tags];
      newTags.splice(indexToRemove, 1);
      props?.selectedTags(newTags);

    };
    const addTags = event => {
      if (event.target.value !== "") {
        setTags([...props?.tags, event.target.value]);
        props?.selectedTags([...props?.tags, event.target.value, ]);
        event.target.value = "";
      }
    };
    const toggle = e => {
      e.target.checked ? setTags(props?.checkbox?.data) : setTags([]);
      props?.selectedTags(e.target.checked ? [...props?.checkbox?.data] : []);
      e.target.value = "";
    };

    return (
      <div>
        <div className="tags-input" style={{display: "flex", justifyContent: "flex-start", minHeight: 35}}>
          <div style={{width: "90%"}}>
          <ul id="tags">
            {props?.tags?.map((tag, index) => (
              <li key={index} className="tag">
                <span className='tag-title'>{tag}</span>
                <span className='tag-close-icon'
                  onClick={() => {removeTags(index)} }
                >
                  x
                </span>
              </li>
            ))}
          </ul>
          <input
            style={{width: "100%", height: 35}}
            type="text"
            maxlength={props?.maxlength}
            onKeyUp={event => event.key === "Enter" ? addTags(tempTag) : null}
            onChange={(e) => handleTags(e)}
            placeholder={props?.placeholder}
          />
          </div>
          <a onClick={() => addTags(tempTag)} style={{width: "10%"}}><Button style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "4px 10px", height: 30, width: "100%", borderRadius: 4, marginTop: 3}} type="primary">Add</Button></a>
        </div>
        {
          props?.checkbox 
          ? <Checkbox checked={props?.tags?.includes(props?.checkbox?.data[0])} onChange={(e) => toggle(e)}>{props?.checkbox?.placeholder}</Checkbox>
          : null
        }
      </div>
    );
  };

export default TagsInput;
  