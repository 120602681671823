import { Tooltip, Select } from 'antd';
import { EditOutlined, EyeOutlined, BarsOutlined, FilterOutlined, MenuOutlined, ArrowUpOutlined, ArrowDownOutlined, CaretUpOutlined, CaretDownOutlined, SearchOutlined, EyeFilled } from '@ant-design/icons';
import { getStageStyles } from './stageUtils';
import type { ColumnsType } from 'antd/es/table';
import CustomSelectFilter from './CustomSelectFilter';
import './CustomSelectFilter.css'; // Import custom CSS for styling
import { ReactComponent as SortFilterIcon } from './../../../../utils/icons/Vector.svg'; // Importing SVG file
import { ReactComponent as EyeIcon } from './../../../../utils/icons/view_icon.svg'; // Importing SVG file
import UnionFilterIcon from "./SVGs/UnionFilterIcon";
import SortUpperFadedIcon from './SVGs/SortUpperFadedIcon';
import SortLowerFadedIcon from './SVGs/SortLowerFadedIcon';

const { Option } = Select;

export const getColumns = (
  stagesList: any[],
  businessSegmentsList: any[],
  selectedStages: string[],
  selectedBusinessSegments: string[],
  setSelectedStages: (selectedStages: string[]) => void,
  setSelectedBusinessSegments: (selectedBusinessSegments: string[]) => void,
  displayProspectSearchFilterScreen: boolean,
  setDisplayProspectSearchFilterScreen: (displayProspectSearchFilterScreen: boolean) => void,
  setDisplayOwnerSearchFilterScreen: (displayOwnerSearchFilterScreen: boolean) => void,
  editProspect: (payload: any) => void,
  agingSortingOrder: 'ascending' | 'descending' | null,
  handleSortAging: () => void,
  probabilityOfClosure: 'ascending' | 'descending' | null,
  handleProbabilityOfClosure: () => void,
  setMainCurrentPage: (currentPage: number) => void,
  selectedProspectsList: any[],
  selectedOwnersList: any[],
): ColumnsType<any> => {
  
  const AgingSortIcon = agingSortingOrder === 'ascending' ? <CaretUpOutlined style={{ fontSize: '1.3rem'}} /> : <CaretDownOutlined style={{ fontSize: '1.3rem'}}/>;
  const FILTER_ICON_COLOR = '#1777FF';
  const filterIcon = () => (
    <UnionFilterIcon fill={selectedStages.length===0 ? '#2C2C2C' : FILTER_ICON_COLOR} style={{ marginLeft: "12px" }} />
  );
  const segmentFilterIcon = (filtered:any) => (
    <UnionFilterIcon fill={selectedBusinessSegments.length===0 ? '#2C2C2C' : FILTER_ICON_COLOR} style={{ marginLeft: "12px" }} />
  );
  const sortProbabilityOfClosureFilterIcon = () => (
    probabilityOfClosure === 'descending' ? <SortUpperFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : probabilityOfClosure === 'ascending' ?  <SortLowerFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : <SortFilterIcon  style={{ marginLeft: "12px", marginTop:'5px' }} />
  );

  const sortFilterIcon = () => (
    agingSortingOrder === 'descending' ? <SortUpperFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : agingSortingOrder === 'ascending' ?  <SortLowerFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : <SortFilterIcon  style={{ marginLeft: "12px", marginTop:'5px' }} />
  );

  const handleProspectSearchIconClick = () => {
    setDisplayProspectSearchFilterScreen(!displayProspectSearchFilterScreen);
  };
  const handleOwnerSearchIconClick = () => {
    setDisplayOwnerSearchFilterScreen(true);
  };
  
  return [
    {
      title: 'ID',
      dataIndex: 'prospectId',
      key: 'prospectId',
      width: 80,
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{}}>Name</span>
          <SearchOutlined onClick={handleProspectSearchIconClick} style={{ marginLeft: '12px', cursor: 'pointer', color: selectedProspectsList.length!==0 ? FILTER_ICON_COLOR : 'black'}} />
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      width: 180,
      render: (text: string) => (
        <Tooltip title={text}>
          <div style={{ width: 160, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Stage',
      dataIndex: 'stage',
      key: 'stage',
      width: 180,
      filterIcon: filterIcon, 
      filterDropdown: (
        <div style={{ padding: 8, width: 180 }}>
          <CustomSelectFilter
            itemsList={stagesList}
            selectedItems={selectedStages}
            setSelectedItems={setSelectedStages}
            placeholder="Stages"
            setMainCurrentPage={setMainCurrentPage}
          />
        </div>
      ),
      onFilter: (value: any, record: any) => record.stage.id === value,
      render: (text: any) => {
        const { color, backgroundColor } = getStageStyles(text?.id);
        return (
          <div style={{display: "flex", alignItems: "center"}}>
            <Tooltip title={text?.name}>
              <div style={{ color: color, fontWeight: '500', backgroundColor: backgroundColor, padding: '0 8px', borderRadius: '4px', display: 'inline-block', maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {text?.name}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      dataIndex: 'stageAge',
      key: 'stageAge',
      width: 80,
      render: (text: string) => text != null ? `${text} Days` : '',
      sortDirections: ['ascend', 'descend'],
      title: (
        <div onClick={handleSortAging} style={{ cursor: 'pointer', display:'flex' }}>
          <span style={{marginRight:'0.5rem'}}>Aging</span> 
          {sortFilterIcon()}
        </div>
      ),
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{}}>Owned by</span>
          <SearchOutlined onClick={handleOwnerSearchIconClick} style={{ marginLeft: '12px', cursor: 'pointer', color: selectedOwnersList.length!==0 ? FILTER_ICON_COLOR : 'black' }} />
        </div>
      ),
      dataIndex: 'owner',
      key: 'owner',
      width: 140,
      render: (text: any) => {
        const ownerName = JSON.parse(text)?.name;
        return (
          <Tooltip title={ownerName}>
            <div style={{ maxWidth: 140, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {ownerName}
            </div>
          </Tooltip>
        )
      }
    },
    {
      title: 'Business Segment',
      dataIndex: 'segment',
      key: 'segment',
      width: 200,
      filterIcon: segmentFilterIcon, 
      filterDropdown: (
        <div style={{ padding: 8, width: 200 }}>
          <CustomSelectFilter
            itemsList={businessSegmentsList}
            selectedItems={selectedBusinessSegments}
            setSelectedItems={setSelectedBusinessSegments}
            placeholder="Business Segments"
            setMainCurrentPage={setMainCurrentPage}
          />
        </div>
      ),
      onFilter: (value: any, record: any) => record.segment.id === value,
      render: (text: any) => (
        <Tooltip title={text?.name}>
          <div style={{ maxWidth: 190, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {text?.name}
          </div>
        </Tooltip>
      ),
    },
    {
      dataIndex: 'probabilityOfClosure',
      key: 'probabilityOfClosure',
      width: 200,
      render: (text: string) => text != null ? `${text}%` : <span style={{fontSize: 12}}>NA</span>,
      sortDirections: ['ascend', 'descend'],
      title: (
        <div onClick={handleProbabilityOfClosure} style={{ cursor: 'pointer', display:'flex' }}>
          <span style={{marginRight:'0.5rem'}}>Probability of Closure</span> 
          {sortProbabilityOfClosureFilterIcon()}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: 150,
      align: "left",
      render: (text, record) => (
        <div style={{display: "flex", justifyContent: "flex-start"}}>
          <Tooltip placement="bottom" title={"View prospect"}>
            <a onClick={() => editProspect(record)}>
              <EyeIcon style={{marginRight:'0.6rem'}}/>
              View Prospect
            </a>
          </Tooltip>
        </div>

      ),
    },
    
  ] ;
};
