import React, { useEffect, useState, useRef } from 'react';
import { Form, message, Steps, Input, InputNumber, Select, Col, Row, Button, Switch, Tooltip, Modal, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import steps from '../../../utils/steps';
import submitTheFormData from './QuoteUtils/submitFormData';
import { stepItems } from './QuoteUtils/formUtils';

import { authSelector } from '../../../features/auth/authSlice';
import { useAppSelector } from '../../../app/hooks';
import _ from 'lodash';
import '../../../styles/formStyles.css';
import axios from "axios";


import {
  fetchProspectsData,
  fetchCitiesData,
  fetchVehiclesData,
  fetchMakersData,
  fetchModelsData,
  fetchVariantsData,
  fetchTenuresData,
  fetchAccessoriesData,
  fetchServicesData,
  fetchGevlStateListData,
} from './QuoteUtils/dataFetchingUtils';
import {
  setProspects,
  setCities,
  setVehicles,
  setMakers,
  setModels,
  setVariants,
  setTenures,
  setAccessories,
  setServices,
  setGevlStateList,
} from '../../../features/quoteSlices/quoteSlice';
import QuoteFinal from './QuoteFinal';
import { async } from 'q';
import getFinalQuoteComponentList from './QuoteUtils/getFinalQuoteComponentList';
import ConfirmSubmissionPopup from '../../../utils/ConfirmSubmissionPopup';
import { Interface } from 'readline';
import SelectSearch from '../../../utils/SelectSearch';
import { getRequest, postRequest} from '../../../utils/handler/apiHandler';
import ConfirmPopup from '../../../utils/ConfirmPopup';
export type FieldType = {
  [key: string]: string | number | boolean;
};

const { Step } = Steps;
const { Option } = Select;

interface ItemDetail {
  id: number;
  name: string;
}

function CreateQuote() {
  const DEBOUNCETIMER = 1000;
  const debounceForBasePriceAndGST = useRef(_.debounce(() => {}, DEBOUNCETIMER));
  const debounceForBasePrice = useRef(_.debounce(() => {}, DEBOUNCETIMER));
  const debounceForGSTCalculationCall = useRef(_.debounce(() => {}, DEBOUNCETIMER));
  const debounceForRVPercentageCall = useRef(_.debounce(() => {}, DEBOUNCETIMER));
  const debounceForManagementFeesCall = useRef(_.debounce(() => {}, DEBOUNCETIMER));
  const debounceForRMTYValueCall = useRef(_.debounce(() => {}, DEBOUNCETIMER));
  const debounceForReplacementVehicleCall = useRef(_.debounce(() => {}, DEBOUNCETIMER));
  const debounceForTCSCall = useRef(_.debounce(() => {}, DEBOUNCETIMER));
  const debounceForInsuranceAmountCall = useRef(_.debounce(() => {}, DEBOUNCETIMER));
  const debounceForFirstYearInsurancePremiumCall = useRef(_.debounce(() => {}, DEBOUNCETIMER));
  const payloadState = useLocation().state;
  
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState<FieldType>({});
  const [submitForm, setSubmitForm] = useState(0);
  const [nextButtonColor, setNextButtonColor] = useState('#72A0C1');
  const [closeButtonColor, setCloseButtonColor] = useState('#A52A2A');
  const [prevButtonColor, setPrevButtonColor] = useState('#72A0C1');
  const [formType, setFormType] = useState('');
  const [selectedVehicleId,setSelectedVehicleId] = useState<number | null>(null);
  const [selectedMakeId,setSelectedMakeId] = useState<number | null>(null);
  const [selectedModelId, setSelectedModelId] = useState<number | null>(null); 
  const [selectedVariantId, setSelectedVariantId] = useState<number | null>(null); 
  const [exShowroom, setExShowroom] = useState<number | null>(payloadState?.data?.exShowroom === undefined ? null : payloadState?.data?.exShowroom); 
  const [discount, setDiscount] = useState<number | null>(0); 
  const [basePrice, setBasePrice] = useState<number | null>(null); 
  const [gstPercentage, setGstPercentage] = useState<number | null>(null); 
  const [updatedGstPercentage, setUpdatedGstPercentage] = useState<number>(0); 
  const [tcs, setTcs] = useState<number | null>(null); 
  const [insuranceType, setInsuranceType] = useState<string | null>('AYF'); 
  const [roadTaxType, setRoadTaxType] = useState<string | null>('Financed'); 
  const [insuranceAmount, setInsuranceAmount] = useState<number | null>(0); 
  const [firstYearInsurancePremium, setFirstYearInsurancePremium] = useState<number | null>(0); 
  const [firstYearInsurancePremiumManualUpdateCounter, setFirstYearInsurancePremiumManualUpdateCounter] = useState<number>(0); 
  const [rvPercentage, setRvPercentage] = useState<number | null>(0); 
  const [tenure, setTenure] = useState<number | null>(null); 
  const [tenureId, setTenureId] = useState<number | null>(null); 
  const [mileage, setMileage] = useState<number | null>(null);  
  const [mileageChangeCnt, setMileageChangeCnt] = useState<number | null>(0); 
  const [managementFees, setManagementFees] = useState<number | null>(0);
  const [selectedVehicleIdForCalcy,setSelectedVehicleIdForCalcy] = useState<number | null>(null);
  const [selectedMakeIdForCalcy,setSelectedMakeIdForCalcy] = useState<number | null>(null);
  const [selectedModelIdForCalcy, setSelectedModelIdForCalcy] = useState<number | null>(null); 
  const [RMTYValue, setRMTYValue] = useState<number | null>(null); 
  const [isRMTYValueInServices, setIsRMTYValueInServices] = useState<boolean>(false); 
  const [fetchRMTYValueCounter, setFetchRMTYValueCounter] = useState<number>(0);  
  const [fetchReplacementVehicleValueCounter, setFetchReplacementVehicleValueCounter] = useState<number>(0); 
  
  const [replacementVehicleValue, setReplacementVehicleValue] = useState<number | null>(null); 
  const [isreplacementVehicleValueInServices, setIsreplacementVehicleValueInServices] = useState<boolean>(false); 
  const [isOtherAccessoriesSelected, setIsOtherAccessoriesSelected] = useState<boolean>(false); 
  
  const [isActiveQuote, setIsActiveQuote] = useState<boolean | null>(null);
  const [fameSubsidy, setFameSubsidy] = useState<number >(0);
  const [stateSubsidy, setStateSubsidy] = useState<number | null>(0);
  const [registeredTo, setRegisteredTo] = useState<string | null>('Lessor');
  
  const [accessoriesInputValues, setAccessoriesInputValues] = useState<any>([]);
  const [servicesInputValues, setServicesInputValues] = useState<any>([]);
  const [selectedAccessories, setSelectedAccessories] = useState<string[]>([]);
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [vehicleChoice, setVehicleChoice] = useState<any>('vehicle');
  const [quoteFinalList, setQuoteFinalList] = useState<any>([]);
  const [quoteFinalDetail, setQuoteFinalDetail] = useState<any>([]);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isMaxLeaseModalVisible, setIsMaxLeaseModalVisible] = useState(false);
  const [fabricationAmountTotal, setFabricationAmountTotal] = useState<number>(0);
  const [fabricationGST, setFabricationGST] = useState<number>(0);
  const [fabricationBaseAmount, setFabricationBaseAmount] = useState<number>(0);
  const [excessMileageChargePerKm, setExcessMileageChargePerKm] = useState<number>(0);
  const [selectedCityId, setSelectedCityId] = useState<number | null>(null); 
  const [gevlStateId, setGevlStateId] = useState<number | null>(null); 
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [pendingRequests, setPendingRequests] = useState<number>(0);
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(false);
  const  [maxLeaseMessage, setMaxLeaseMessage] = useState<string>('');
  const [makeIdForMaxLease, setMakeIdForMaxLease] = useState<number|null>(null);
  const [modelIdForMaxLease, setModelIdForMaxLease] = useState<number|null>(null);
  const [payload, setPayload] = useState<any>();

  const HARYANA_STATE_ID:number =6; 
  const filterOption = (input: string, option?: { label: string; value: string }) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  let options: any = [];

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate(); // Initialize useNavigate here

  const auth = useAppSelector(authSelector);
  // let payload: any;
  const formValues = form.getFieldsValue();
  let initialValues: { [fieldName: string]: any } = {};
  let quoteId: number = parseInt(useLocation().pathname.split("/").pop() || "");
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchAllReduxData() {
      dispatch(setProspects(await fetchProspectsData(auth)));
      dispatch(setCities(await fetchCitiesData(auth)));
      dispatch(setVehicles(await fetchVehiclesData(auth)));
      dispatch(setTenures(await fetchTenuresData(auth)));
      dispatch(setAccessories(await fetchAccessoriesData(auth)));
      dispatch(setServices(await fetchServicesData(auth)));
      dispatch(setGevlStateList(await fetchGevlStateListData(auth)));
    }
    fetchAllReduxData();
  }, [dispatch]);

  const prospects = useSelector((store: any) => store.quote.prospects);
  const cities = useSelector((store: any) => store.quote.cities);
  const vehicles = useSelector((store: any) => store.quote.vehicles);
  const makers = useSelector((store: any) => store.quote.makers);
  const models = useSelector((store: any) => store.quote.models);
  const variants = useSelector((store: any) => store.quote.variants);
  const tenures = useSelector((store: any) => store.quote.tenures);
  const accessories = useSelector((store: any) => store.quote.accessories);
  const services = useSelector((store: any) => store.quote.services);
  const gevlStateList = useSelector((store: any) => store.quote.gevlStateList);


  useEffect(() => {
    if (currentStep === 0) {
      setPrevButtonColor('#E8E8E8');
    } else if (currentStep === steps.length - 1) {
      setNextButtonColor('#E8E8E8');
    } else {
      setPrevButtonColor('#72A0C1');
      setNextButtonColor('#72A0C1');
    }
  }, [currentStep]);

  console.log(`form value=%o`, form.getFieldsValue(true));
  console.log(`servicesInputValues=%o`,servicesInputValues);
  console.log(`accessoriesInputValues=%o`,accessoriesInputValues);
  const quote_id: number = parseInt(useLocation().pathname.split("/").pop() || "");
  useEffect(() => {
    if (payloadState === null && !isNaN(quote_id)) {
      getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/${quote_id}`).then(res => {
        setPayload(res.data);
        // payload = res.data;
        quoteId = res?.data?.quoteId;
        const accessoriesIdList = JSON.parse(res.data.services || '[]').map((accessory:any) => accessory.id);
        const servicesIdList = JSON.parse(res.data.services || '[]').map((service:any)=>service.id);
        form.setFieldsValue({
          quoteId: res.data.quoteId || 0,
          prospectId: res.data.prospectId || 0,
          prospectName: res.data.prospectName || '',
          // parentQuoteId: null,
          ...res.data, // Add all other fields from payload
          vehicleId: JSON.parse(res.data?.vehicle)?.id,
          makeId: JSON.parse(res.data?.make)?.id,
          modelId: JSON.parse(res.data?.model)?.id,
          variantId: JSON.parse(res.data?.variant)?.id,
          tenureInMonthId: JSON.parse(res.data?.tenureInMonthDetail)?.id,
          accessories: accessoriesIdList,
          services: servicesIdList,
          firstYearInsurancePremium: JSON.parse(res.data?.projectedPremium)?.['1']||0,
        });

        setFormType('UPDATEQUOTE');
        const data = res.data;
        const accessories = JSON.parse(data.accessories || '[]');
        const services = JSON.parse(data.services || '[]');
        setAccessoriesInputValues(accessories);
        setSelectedAccessories(accessories.map((accessory: any) => accessory.id));
        setServicesInputValues(services);
        setSelectedServices(services.map((service: any) => service.id));
        const fetchMakeModelVehicle = async ()=>{
          const vehicleId = JSON.parse(res.data?.vehicle)?.id;
          const makeId = JSON.parse(res.data?.make)?.id;
          const modelId = JSON.parse(res.data?.model)?.id;
          const variantId = JSON.parse(res.data?.variant)?.id;
          dispatch(setMakers(await fetchMakersData(auth, vehicleId)));
          dispatch(setModels(await fetchModelsData(auth, makeId)));
          dispatch(setVariants(await fetchVariantsData(auth, modelId)));
        }
        fetchMakeModelVehicle();
        setExShowroom(res.data?.exShowroom);
        setGstPercentage(res.data?.gstOnVehicle);
        setBasePrice(res.data?.basePrice);
        setFameSubsidy(res.data?.fameSubsidy);
        setStateSubsidy(res.data?.stateSubsidy);
        setInsuranceAmount(res.data?.insuranceAmountTotal);
        setManagementFees(res.data?.managementFees);
        setRegisteredTo(res.data?.registeredTo);
        setInsuranceType(res.data?.insuranceType);
        setRoadTaxType(res.data?.roadTaxType);
        setRMTYValue(services.find((service:any)=>service.name==='RMTY')?.value);
        setReplacementVehicleValue(services.find((service:any)=>service.name==='Replacement Vehicle')?.inMonth);
        setFirstYearInsurancePremium(JSON.parse(res.data?.projectedPremium)?.['1']||0);
        setExcessMileageChargePerKm(res.data?.excessMileageChargePerKm);

        setTenureId(JSON.parse(res.data?.tenureInMonthDetail)?.id);
        setTenure(JSON.parse(res.data?.tenureInMonthDetail)?.name);
      
        setSelectedVehicleIdForCalcy(JSON.parse(res.data?.vehicle)?.id);
        setSelectedMakeIdForCalcy(JSON.parse(res.data?.make)?.id);
        setSelectedModelIdForCalcy(JSON.parse(res.data?.model)?.id);

        setMileage(res.data?.mileagePerMonth);
        setIsActiveQuote(res.data?.active);
        setFabricationAmountTotal(res.data?.fabricationAmountTotal);
        setFabricationGST(res.data?.fabricationGst);
        setRvPercentage(res.data?.rvPercentage);
        setTcs(res.data?.tcs);
        setMakeIdForMaxLease(JSON.parse(res?.data?.make)?.id);
        setModelIdForMaxLease(JSON.parse(res?.data?.model)?.id);
        
      })
    } 
    
    else if (payloadState?.prospectDataForCreateQuote) {
      setFormType('PROSPECTQUOTE');
    }

    else{
      initialValues['stateSubsidy'] = 0;
      setFormType('QUOTE');
    }
    const servicesList = JSON.parse(payloadState?.data?.services || '[]');

    }, []);

  if (payloadState?.data) {
    // payload = payloadState.data;
    // setPayload(payloadState.data);

    quoteId = payloadState?.data?.quoteId;
    const accessoriesIdList = accessoriesInputValues.map((accessory:any) => accessory.id);
    
    const servicesIdList = servicesInputValues.map((service:any)=>service.id);
    initialValues = {
      quoteId: payloadState.data.quoteId || 0,
      prospectId: payloadState.data.prospectId || 0,
      prospectName: payloadState.data.prospectName || '',
      parentQuoteId: null,
      ...payloadState.data, // Add all other fields from payload
      vehicleId: JSON.parse(payloadState?.data?.vehicle)?.id,
      makeId: JSON.parse(payloadState?.data?.make)?.id,
      modelId: JSON.parse(payloadState?.data?.model)?.id,
      variantId: JSON.parse(payloadState?.data?.variant)?.id,
      tenureInMonthId: JSON.parse(payloadState?.data?.tenureInMonthDetail)?.id,
      
      accessories: accessoriesIdList,
      services: servicesIdList,
      
    };
    
  } else if (payloadState?.prospectDataForCreateQuote) {
    // payload = payloadState.prospectDataForCreateQuote;
    // setPayload(payloadState.prospectDataForCreateQuote);
    initialValues = {
      prospectName: payloadState.prospectDataForCreateQuote?.prospectName || '',
      prospectId: payloadState.prospectDataForCreateQuote?.prospectId || 0,
      fameSubsidy: fameSubsidy,

    };
  }

  
  // Iterate through the steps and fields to initialize the fields based on predefined values
    steps.forEach((step) => {
      step.fields.forEach((field) => {
        if (field.values && field.values.length > 0) {
          // Assign the first value from the predefined values to the field
          if(!initialValues[field.name])
            initialValues[field.name] = field.values[0];
        }
      });
    });
  const maxLeasePopUp =  async ()=>{
    try {
      setPendingRequests(prevCounter => prevCounter + 1); 
  
      const response = await postRequest(
        `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/checkMaxlease?tenureId=${tenureId}&mileage=${mileage}&makeId=${makeIdForMaxLease}&modelId=${modelIdForMaxLease}`,
        {}
      );
  
      const message = response.data;
      console.log(`message=%o`,message);
      if(message){
        setMaxLeaseMessage(message);
        setIsMaxLeaseModalVisible(true);
      }
      else setCurrentStep(currentStep+1);
    } catch (error: any) {
      // Show error modal
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors[0]) ||
        'An error occurred while verifieng Max Lease';
      
    } finally {
      setPendingRequests(prevCounter => prevCounter - 1); 
    }
  }
  const handleNext = () => {
    form
      .validateFields()
      .then(() => {
        let values = form.getFieldsValue();
        if(currentStep==steps.length-2){
          maxLeasePopUp();
        }
        else  setCurrentStep(currentStep + 1);
        values.accessories = JSON.stringify(accessoriesInputValues);
        values.services = JSON.stringify(servicesInputValues);
        const prospectName = prospects.find((prospect: any) => prospect.id === formData.prospectId)?.name;
        const propsectObj = { "prospectName": prospectName };
        values = { ...values, ...propsectObj };
        const buyingCity = cities.find((city: any) => city.id === formData.buyingCityId)?.cityName;
        const buyingCityObj = { "buyingCityName": buyingCity };
        values = { ...values, ...propsectObj };
        values = { ...values, ...buyingCityObj };
        setFormData({ ...formData, ...values });
      })
      .catch((errors) => {
        console.log('Validation errors:', errors);
      });
  };
  

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };
    
  const onFinish = () => {
    setIsConfirmModalVisible(true);
  };

  const handleDynamicChange = (value: any, fieldName: any) => {
    if(fieldName === "vehicleId" ){
      setSelectedMakeId(null); 
      setMakeIdForMaxLease(null);
      setSelectedModelId(null); 
      setModelIdForMaxLease(null);
      setSelectedVariantId(null); 
      setSelectedVehicleId(value);
      if(!isEdited) setIsEdited(true);
      setSelectedVehicleIdForCalcy(value);
    }
    else if(fieldName === "makeId" ){
      setSelectedModelId(null); 
      setModelIdForMaxLease(null);
      setSelectedVariantId(null); 
      setSelectedMakeId(value); 
      setMakeIdForMaxLease(value);
      if(!isEdited) setIsEdited(true);
      setSelectedMakeIdForCalcy(value);
    }
    else if(fieldName === "modelId" ){
      setSelectedVariantId(null); 
      setSelectedModelId(value); 
      setModelIdForMaxLease(value);
      if(!isEdited) setIsEdited(true);
      setSelectedModelIdForCalcy(value);
    }
    else if(fieldName === "variantId" ){
      setSelectedVariantId(value); 
    }
    else if (fieldName === 'accessories') {
      const initialAccessoriesValues: any[] = [];
      const tempAccessoriesInputValues: any[] = [];
      value.forEach((id: any) => {
        const isIdPresent = accessoriesInputValues.some((obj: any) => obj.id === id);
        if (!isIdPresent) {
          const itemName = accessories.find((option: any) => option.id === id)?.name;
          initialAccessoriesValues.push({
            id,
            name: itemName || '',
            totalAmount: 0,
            gst: 0,
            baseAmount: 0,
          });
        }
        else{
          tempAccessoriesInputValues.push( accessoriesInputValues.find((option: any) => option.id === id));
        }
      });
      setAccessoriesInputValues([...tempAccessoriesInputValues, ...initialAccessoriesValues]);
      
    } else if (fieldName === 'services') {
      const initialServicesValues: any[] = [];
      const tempServicesInputValues: any[] = [];
      value.forEach((id: any) => {
        const isIdPresent = servicesInputValues.some((obj: any) => obj.id === id);
        const itemName = services.find((option: any) => option.id === id)?.name;
        if (!isIdPresent && itemName === 'RMTY') {
          initialServicesValues.push({
            id,
            name: itemName || '',
            value: RMTYValue,
          });
        }
        else if (!isIdPresent && itemName === 'Replacement Vehicle') {
          initialServicesValues.push({
            id,
            name: itemName || '',
            value: replacementVehicleValue,
          });
        }
        else if (!isIdPresent && itemName === 'Charging') {
          initialServicesValues.push({
            id,
            name: itemName || '',
            value: 0,
            inMonth: mileage || 0,
          });
        }
        else if (!isIdPresent) {
          const currService = services.find((option: any) => option.id === id);
          const itemName = currService?.name;
          initialServicesValues.push({
            id,
            name: itemName || '',
            value: 0,
            inMonth: currService?.defaultAmount || 0,
          });
        }
        else{
          tempServicesInputValues.push( servicesInputValues.find((option: any) => option.id === id));
        }
      });
      setServicesInputValues([...tempServicesInputValues, ...initialServicesValues]);
     
    }
    else if (fieldName === 'exShowroom'){
      if(!isEdited) setIsEdited(true);
      setExShowroom(value);
    }
    else if (fieldName === 'discount'){
      if(!isEdited) setIsEdited(true);
      setDiscount(value);
    }
    else if(fieldName === 'fameSubsidy'){
      if(!isEdited) setIsEdited(true);
      setFameSubsidy(value);
    }
    else if(fieldName === 'stateSubsidy'){
      if(!isEdited) setIsEdited(true);
      setStateSubsidy(value);
    }
    else if(fieldName === 'registeredTo'){
      if(!isEdited) setIsEdited(true);
      setRegisteredTo(value);
    }
    else if(fieldName === 'insuranceType'){
      if(!isEdited) setIsEdited(true);
      setInsuranceType(value);
    }
    else if(fieldName === 'roadTaxType'){
      if(!isEdited) setIsEdited(true);
      setRoadTaxType(value);
    }
    else if(fieldName === 'tenureInMonthId'){
      const tenureMonth = tenures.find((tenure:any)=> tenure.id === value)?.inMonth;
      if(!isEdited) setIsEdited(true);
      setTenure(tenureMonth);
      setTenureId(value);
    }
    else if(fieldName === 'mileagePerMonth'){
      if(!isEdited) setIsEdited(true);
      setMileage(value);
    }
    else if(fieldName === 'managementFees'){
      setManagementFees(value);
    }
    else if(fieldName === 'rvPercentage'){
      setRvPercentage(value);
    }
    else if(fieldName === 'firstYearInsurancePremium'){
      setFirstYearInsurancePremium(value);
      if(!isEdited) setIsEdited(true);
      setFirstYearInsurancePremiumManualUpdateCounter(firstYearInsurancePremiumManualUpdateCounter+1);
    }
    else if(fieldName === 'gstOnVehicle'){
      if(!isEdited) setIsEdited(true);
      setGstPercentage(value);
      setUpdatedGstPercentage((prevValue:number)=>prevValue+1);
    }
  
  };  
  console.log(`pendingRequests=%o`,pendingRequests)
  console.log(`managementFees=%o`,managementFees)
  console.log(`gstPercentage=%o`,gstPercentage)
  console.log(`basePrice=%o`,basePrice)
  useEffect(() => {
   
    const fetchBasePriceAndGst = async () => {
      try {
        setPendingRequests(prevCounter => prevCounter + 1);
  
        const response = await postRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/getBaseGSTPrice?amt=${exShowroom || 0}`,
          {}
        );
  
        // Check if the request was successful
        if (response.status === 200) {
          // Extract base price and GST percentage from response data
          const { baseAmt, gstAmt } = response.data;
  
          // Set base price and GST percentage
          setBasePrice(baseAmt);
          setGstPercentage(gstAmt);
        } else {
          // If the request was not successful, show error modal
          throw new Error('An error occurred while fetching Base Price and GST');
        }
      } catch (error: any) {
        // Show error modal
        // Modal.error({
        //   title: 'Error',
        //   content: 'An error occurred while fetching Base Price and GST',
        // });
      } finally {
        setPendingRequests(prevCounter => prevCounter - 1);
      }
    }; 
    if( formType === 'QUOTE' || formType === 'PROSPECTQUOTE' || ( formType === 'UPDATEQUOTE' && isEdited )){ // API Call will only take place once edit is made on Edit Quote Page to prevent Saved Data to be replace by default value from API call 
      // Cancel the previous debounced function and create a new one
      debounceForBasePriceAndGST.current.cancel();
      debounceForBasePriceAndGST.current = _.debounce(fetchBasePriceAndGst, DEBOUNCETIMER);

      // Execute the debounced function
      debounceForBasePriceAndGST.current();
    }
  
  }, [exShowroom]);

  useEffect(() => {
    
    const fetchBasePrice = async () => {
      try {
        setPendingRequests(prevCounter => prevCounter + 1);
    
        const response = await postRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/calBaseAmt?exshowAmt=${exShowroom || 0}&gstAmt=${gstPercentage || 0}`,
          {}
        );
    
        if (response.status === 200) {
          setBasePrice(response.data?.baseAmt);
        } else {
          throw new Error('An error occurred while fetching BasePrice');
        }
      } catch (error: any) {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors[0]) ||
          'An error occurred while fetching BasePrice';
    
        // Modal.error({
        //   title: 'Error',
        //   content: errorMessage,
        // });
      } finally {
        setPendingRequests(prevCounter => prevCounter - 1);
      }
    };
    if( formType === 'QUOTE' || formType === 'PROSPECTQUOTE' || ( formType === 'UPDATEQUOTE' && isEdited )){ // API Call will only take place once edit is made on Edit Quote Page to prevent Saved Data to be replace by default value from API call 
      // Cancel the previous debounced function and create a new one
      debounceForBasePrice.current.cancel();
      debounceForBasePrice.current = _.debounce(fetchBasePrice, DEBOUNCETIMER);

      // Execute the debounced function
      debounceForBasePrice.current();
    }

  }, [updatedGstPercentage]);

  useEffect(()=>{
    
    const fetchTcs = async () => {
      try {
        setPendingRequests(prevCounter => prevCounter + 1);
    
        const response = await postRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/getTcs?amt=${exShowroom}`,
          {}
        );
    
        // Check if the request was successful
        if (response.status === 200) {
          // Set TCS from response data
          setTcs(response.data?.tcsAmt);
        } else {
          // If the request was not successful, show error modal
          throw new Error('An error occurred while fetching TCS');
        }
      } catch (error: any) {
        // Show error modal
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors[0]) ||
          'An error occurred while fetching TCS';
    
        // Modal.error({
        //   title: 'Error',
        //   content: errorMessage,
        // });
      } finally {
        setPendingRequests(prevCounter => prevCounter - 1);
      }
    };
    if(exShowroom && (discount === 0 || discount)){
      if( formType === 'QUOTE' || formType === 'PROSPECTQUOTE' || ( formType === 'UPDATEQUOTE' && isEdited )){ // API Call will only take place once edit is made on Edit Quote Page to prevent Saved Data to be replace by default value from API call 
        // Cancel the previous debounced function and create a new one
        debounceForTCSCall.current.cancel();
        debounceForTCSCall.current = _.debounce(fetchTcs, DEBOUNCETIMER);

        // Execute the debounced function
        debounceForTCSCall.current();
      }
      
    }
  },[exShowroom, discount]);
  useEffect(()=>{
    
    const fetchInsuranceAmount = async () => {
      try {
        setPendingRequests(prevCounter => prevCounter + 1);
    
        const response = await postRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/getInsuranceAmt`,
          {
            insuranceType: insuranceType,
            exShowroom: exShowroom,
            tenure: tenure,
            fameSubsidy: fameSubsidy,
            stateSubsidy: stateSubsidy ? stateSubsidy : 0,
            registeredTo: registeredTo,
            isGreenCessApplicable: false,
            discount: discount ? discount : 0,
          }
        );
    
        // Check if the request was successful
        if (response.status === 200) {
          setInsuranceAmount(response.data?.insuranceAmt);
          setFirstYearInsurancePremium(response.data?.firstYrInsuranceAmt);
        } else {
          // If the request was not successful, show error modal
          throw new Error('An error occurred while fetching InsuranceAmount');
        }
      } catch (error: any) {
        // Show error modal
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors[0]) ||
          'An error occurred while fetching InsuranceAmount';
    
        // Modal.error({
        //   title: 'Error',
        //   content: errorMessage,
        // });
      } finally {
        setPendingRequests(prevCounter => prevCounter - 1);
      }
    };
    if(exShowroom && insuranceType && tenure && fameSubsidy>=0 && registeredTo){
      if( formType === 'QUOTE' || formType === 'PROSPECTQUOTE' || ( formType === 'UPDATEQUOTE' && isEdited )){ // API Call will only take place once edit is made on Edit Quote Page to prevent Saved Data to be replace by default value from API call 
        // Cancel the previous debounced function and create a new one
        debounceForInsuranceAmountCall.current.cancel();
        debounceForInsuranceAmountCall.current = _.debounce(fetchInsuranceAmount, DEBOUNCETIMER);

        // Execute the debounced function
        debounceForInsuranceAmountCall.current();
      }
      
    }
  },[exShowroom, insuranceType, tenure, fameSubsidy, stateSubsidy, registeredTo]);

  useEffect(()=>{
    const fetchFirstYearInsurancePremium = async () => {
      try {
        setPendingRequests(prevCounter => prevCounter + 1);
    
        const response = await postRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/getInsuranceAmtByFirstYrInsAmt`,
          {
            insuranceType: insuranceType,
            firstYrInsuranceAmt: firstYearInsurancePremium || 0,
            tenure: tenure,
            registeredTo: registeredTo,
            isGreenCessApplicable: false,
          }
        );
    
        // Check if the request was successful
        if (response.status === 200) {
          setInsuranceAmount(response.data?.insuranceAmt);
          setFirstYearInsurancePremium(response.data?.firstYrInsuranceAmt);
        } else {
          // If the request was not successful, show error modal
          throw new Error('An error occurred while fetching FirstYearInsurancePremium');
        }
      } catch (error: any) {
        // Show error modal
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors[0]) ||
          'An error occurred while fetching FirstYearInsurancePremium';
    
        // Modal.error({
        //   title: 'Error',
        //   content: errorMessage,
        // });
      } finally {
        setPendingRequests(prevCounter => prevCounter - 1);
      }
    };
    
    if( insuranceType && tenure && registeredTo){
      // if(firstYearInsurancePremium === null){
      //   debounceForInsuranceAmountCall.current.cancel();
      // }
      // else 
      if( formType === 'QUOTE' || formType === 'PROSPECTQUOTE' || ( formType === 'UPDATEQUOTE' && isEdited )){ // API Call will only take place once edit is made on Edit Quote Page to prevent Saved Data to be replace by default value from API call 
        // Cancel the previous debounced function and create a new one
        debounceForFirstYearInsurancePremiumCall.current.cancel();
        debounceForFirstYearInsurancePremiumCall.current = _.debounce(fetchFirstYearInsurancePremium, DEBOUNCETIMER);

        // Execute the debounced function
        debounceForFirstYearInsurancePremiumCall.current();
      }
      
    }
  },[firstYearInsurancePremiumManualUpdateCounter]);
  
  useEffect(()=>{
    const fetchRvPercentage = async () => {
      try {
        setPendingRequests(prevCounter => prevCounter + 1);
    
        const response = await postRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/getRVPercent?tenureId=${tenureId}&makeId=${selectedMakeIdForCalcy}&modelId=${selectedModelIdForCalcy}&mileage=${mileage}`,
          {}
        );
    
        // Check if the request was successful
        if (response.status === 200) {
          setRvPercentage(response.data?.rvPercent === null ? null : response.data?.rvPercent);
        } else {
          // If the request was not successful, show error modal
          throw new Error('An error occurred while fetching RvPercentage');
        }
      } catch (error: any) {
        // Show error modal
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors[0]) ||
          'An error occurred while fetching RvPercentage';
    
        // Modal.error({
        //   title: 'Error',
        //   content: errorMessage,
        // });
      } finally {
        setPendingRequests(prevCounter => prevCounter - 1);
      }
    };
    if(tenureId && selectedModelIdForCalcy && selectedMakeIdForCalcy && mileage){
      if( formType === 'QUOTE' || formType === 'PROSPECTQUOTE' || ( formType === 'UPDATEQUOTE' && isEdited )){ // API Call will only take place once edit is made on Edit Quote Page to prevent Saved Data to be replace by default value from API call 
        // Cancel the previous debounced function and create a new one
        debounceForRVPercentageCall.current.cancel();
        debounceForRVPercentageCall.current = _.debounce(fetchRvPercentage, DEBOUNCETIMER);

        // Execute the debounced function
        debounceForRVPercentageCall.current();

      }
    }
  },[tenureId, selectedModelIdForCalcy,selectedMakeIdForCalcy, mileage]);
 
  useEffect(()=>{
    const fetchManagementFees = async () => {
      try {
        setPendingRequests(prevCounter => prevCounter + 1);
    
        const response = await postRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/managementFeeByVehicleType?amt=${exShowroom}&vehType=${selectedVehicleIdForCalcy}`,
          {}
        );
    
        // Check if the request was successful
        if (response.status === 200) {
          setManagementFees(response.data?.managementFee === null ? null : response.data?.managementFee);
        } else {
          // If the request was not successful, show error modal
          throw new Error('An error occurred while fetching ManagementFees');
        }
      } catch (error: any) {
        // Show error modal
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors[0]) ||
          'An error occurred while fetching ManagementFees';
    
        // Modal.error({
        //   title: 'Error',
        //   content: errorMessage,
        // });
      } finally {
        setPendingRequests(prevCounter => prevCounter - 1);
      }
    };
    if(exShowroom && selectedVehicleIdForCalcy){
      if( formType === 'QUOTE' || formType === 'PROSPECTQUOTE' || ( formType === 'UPDATEQUOTE' && isEdited )){ // API Call will only take place once edit is made on Edit Quote Page to prevent Saved Data to be replace by default value from API call 
        // Cancel the previous debounced function and create a new one
        debounceForManagementFeesCall.current.cancel();
        debounceForManagementFeesCall.current = _.debounce(fetchManagementFees, DEBOUNCETIMER);

        // Execute the debounced function
        debounceForManagementFeesCall.current();
      }
    }
  },[exShowroom, selectedVehicleIdForCalcy]);

  useEffect(()=>{
    const fetchRMTYValue = async () => {
      try {
        setPendingRequests(prevCounter => prevCounter + 1);
    
        const response = await postRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/getServiceAmount?tenureId=${tenureId}&makeId=${selectedMakeIdForCalcy}&modelId=${selectedModelIdForCalcy}&mileage=${mileage}&serviceId=2`,
          {}
        );
    
        // Check if the request was successful
        if (response.status === 200) {
          setRMTYValue(response.data?.serviceAmt === null ? null : response.data?.serviceAmt);
        } else {
          // If the request was not successful, show error modal
          throw new Error('An error occurred while fetching RMTYValue');
        }
      } catch (error: any) {
        // Show error modal
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors[0]) ||
          'An error occurred while fetching RMTYValue';
    
        // Modal.error({
        //   title: 'Error',
        //   content: errorMessage,
        // });
      } finally {
        setPendingRequests(prevCounter => prevCounter - 1);
      }
    };
    if(tenureId && selectedMakeIdForCalcy && selectedModelIdForCalcy && mileage){
      if( formType === 'QUOTE' || formType === 'PROSPECTQUOTE' || ( formType === 'UPDATEQUOTE' && isEdited )){ // API Call will only take place once edit is made on Edit Quote Page to prevent Saved Data to be replace by default value from API call 
        // Cancel the previous debounced function and create a new one
        debounceForRMTYValueCall.current.cancel();
        debounceForRMTYValueCall.current = _.debounce(fetchRMTYValue, DEBOUNCETIMER);

        // Execute the debounced function
        debounceForRMTYValueCall.current();
      }
    }
  },[tenureId, selectedMakeIdForCalcy, selectedModelIdForCalcy, mileage, fetchRMTYValueCounter]);

  useEffect(()=>{
    const fetchReplacementVehicle = async () => {
      try {
        setPendingRequests(prevCounter => prevCounter + 1);
    
        const response = await postRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/getServiceAmountByType?tenureId=${tenureId}&mileage=${mileage}&serviceId=6&exShowRmPrice=${exShowroom}`,
          {}
        );
    
        // Check if the request was successful
        if (response.status === 200) {
          setReplacementVehicleValue(response.data?.serviceAmt === null ? null : response.data?.serviceAmt);
        } else {
          // If the request was not successful, show error modal
          throw new Error('An error occurred while fetching ReplacementVehicle');
        }
      } catch (error: any) {
        // Show error modal
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors[0]) ||
          'An error occurred while fetching ReplacementVehicle';
    
        // Modal.error({
        //   title: 'Error',
        //   content: errorMessage,
        // });
      } finally {
        setPendingRequests(prevCounter => prevCounter - 1);
      }
    };
    if(tenureId && exShowroom && mileage){
      if( formType === 'QUOTE' || formType === 'PROSPECTQUOTE' || ( formType === 'UPDATEQUOTE' && isEdited )){ // API Call will only take place once edit is made on Edit Quote Page to prevent Saved Data to be replace by default value from API call 
        // Cancel the previous debounced function and create a new one
        debounceForReplacementVehicleCall.current.cancel();
        debounceForReplacementVehicleCall.current = _.debounce(fetchReplacementVehicle, DEBOUNCETIMER);

        // Execute the debounced function
        debounceForReplacementVehicleCall.current();
      }
    }
  },[exShowroom, tenureId, mileage, fetchReplacementVehicleValueCounter]);

  useEffect(()=>{
    const fetchAsyncVariantsData = async () => {
      try {
        dispatch(setVariants(await fetchVariantsData(auth, selectedModelId)));
        setSelectedVariantId(null);
        setVehicleChoice('variant');
      } catch (error) {
        console.log(`Got error while fetching variant data`,error);
      }
    };
    const fetchExcessMileage = async () => {
      try {
        setPendingRequests(prevCounter => prevCounter + 1);
    
        const response = await postRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/excessMileageByModel?modelId=${selectedModelId}`,
          {}
        );
    
        // Check if the request was successful
        if (response.status === 200) {
          setExcessMileageChargePerKm(response.data?.excessMileageCharge === null ? 0 : response.data?.excessMileageCharge);
        } else {
          // If the request was not successful, show error modal
          throw new Error('An error occurred while fetching ExcessMileage');
        }
      } catch (error: any) {
        // Show error modal
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors[0]) ||
          'An error occurred while fetching ExcessMileage';
    
        // Modal.error({
        //   title: 'Error',
        //   content: errorMessage,
        // });
      } finally {
        setPendingRequests(prevCounter => prevCounter - 1);
      }
    };
    if(selectedModelId){
      fetchAsyncVariantsData();
      if( formType === 'QUOTE' || formType === 'PROSPECTQUOTE' || ( formType === 'UPDATEQUOTE' && isEdited )){ // API Call will only take place once edit is made on Edit Quote Page to prevent Saved Data to be replace by default value from API call 
        // Cancel the previous debounced function and create a new one
        debounceForReplacementVehicleCall.current.cancel();
        debounceForReplacementVehicleCall.current = _.debounce(fetchExcessMileage, DEBOUNCETIMER);

        // Execute the debounced function
        debounceForReplacementVehicleCall.current();
      }
    }

  },[selectedModelId])

  useEffect(()=>{
    if( formType === 'QUOTE' || formType === 'PROSPECTQUOTE' || ( formType === 'UPDATEQUOTE' && isEdited )){ // API Call will only take place once edit is made on Edit Quote Page to prevent Saved Data to be replace by default value from API call 
        let services: any = JSON.parse(formData.services?.toString() || '[]');
      services = services.map((service:any)=>{
        if(service.name==="Replacement Vehicle"){
          return {...service,'value':replacementVehicleValue};
        }
        else return service;
      })
      form.setFieldsValue({
        ...formValues,
        basePrice: basePrice,
        gstOnVehicle: gstPercentage,
        tcs: tcs,
        insuranceAmountTotal: insuranceAmount,
        firstYearInsurancePremium: firstYearInsurancePremium,
        managementFees: managementFees,
        rvPercentage: rvPercentage,
        'services': JSON.stringify(services),
      });
    }
  },[basePrice, tcs, insuranceAmount, firstYearInsurancePremium, managementFees, rvPercentage, replacementVehicleValue ]); 
 
  // useEffect(()=>{
  //   form.setFieldsValue({
  //     ...formValues,
  //     rvPercentage: rvPercentage,
  //   });
  // },[rvPercentage]);

  useEffect(()=>{
    if(mileageChangeCnt != 0){
      const tempServices = servicesInputValues.map((service:any)=>{
        if(service.name==='Charging'){
          return {
            ...service, inMonth:mileage || 0,
          }
        }
        else return service;
      })
      setServicesInputValues(tempServices);
    }
    else{
      setMileageChangeCnt(mileageChangeCnt+1);
    }
   
  }, [mileage])

  useEffect(()=>{
    let services: any = JSON.parse(formData.services?.toString() || '[]');
    services = services.map((service:any)=>{
      if(service.name==="RMTY"){
        return {...service,'value':RMTYValue};
      }
      else return service;
    })
    form.setFieldsValue({
      ...formValues,
      'services': JSON.stringify(services),
    });
  },[RMTYValue]);

  const handleConfirm = () => {
    setSubmitForm(submitForm+1);
    setDisableSubmitButton(true);
    setIsConfirmModalVisible(false);
  };

  const handleCancel = () => {
    setIsConfirmModalVisible(false);
  };  
  const handleMaxLeaseConfirm = () => {
    setCurrentStep(currentStep+1);
    setIsMaxLeaseModalVisible(false);
  };

  const handleMaxLeaseCancel = () => {
    setIsMaxLeaseModalVisible(false);
  };  
  const handleCloseScreen = () => {
    navigate(`/dashboard/quote`);
  };  

  useEffect(() => {
    async function callSubmitTheFormData() {
      if (currentStep !== steps.length - 1) {
        return;
      }
      try{
        await submitTheFormData(currentStep, steps.length, quoteId, formData, formType, auth, messageApi, navigate, setDisableSubmitButton);
        
      } catch (error) {
        console.log(`Got error while submitTheFormData `,error);
      }      
      
    }
    callSubmitTheFormData();    
  
  }, [submitForm]);

  useEffect(()=>{
    const getComponentList = async () => {
      if(currentStep === steps.length-1){

        const [componentList, quoteCalculatorResponse] = await getFinalQuoteComponentList( formData, auth, tenures, RMTYValue, replacementVehicleValue, fabricationAmountTotal, fabricationGST, setPendingRequests);
        const prospectName = formData.prospectName;
        const vehicleDetails =
          `${makers.find((make: any) => make.id === formData.makeId)?.name}-
          ${models.find((model: any) => model.id === formData.modelId)?.name}-
          ${variants.find((variant: any) => variant.id === formData.variantId)?.name}`;
        const tenureInMonth: number = tenures.find((tenure: any)=> tenure.id === formData.tenureInMonthId)?.inMonth;
        const mileagePerMonth: number = parseInt(formData.mileagePerMonth.toString(),10);
        const tenureAndMileage = `${tenureInMonth} Months / ${mileagePerMonth * tenureInMonth} KM`;
        const tempResp = {
          'prospectName':prospectName, 'vehicleDetails':vehicleDetails, 'tenureAndMileage':tenureAndMileage,
          'rvPercentage': formData.rvPercentage, 'city': cities.find((city: any) => city.id === formData.buyingCityId)?.cityName,
        
          'irr':parseFloat(formData.costOfFundPercentage.toString()||'0')+parseFloat(formData.offerRatePercentage.toString()||'0')
          };
        
        let tempFormData = { ...formData, 'services':JSON.stringify(servicesInputValues), 'accessories':JSON.stringify(accessoriesInputValues)};

        quoteCalculatorResponse.projectedPremium = JSON.stringify(quoteCalculatorResponse.projectedPremium);
        
        const { services, accessories, ...rest } = quoteCalculatorResponse;
        const tempServices = servicesInputValues.map((service:any)=>{
          const resp = quoteCalculatorResponse.services.find((resp:any)=>resp.name===service.name);
          const inMonthAmount = resp?.inMonthAmount;
          const value = resp?.totalAmount;
          return {...service,'inMonth':inMonthAmount,'value':value};
        });
        const tempAccessories = accessoriesInputValues.map((accessory:any)=>{
          const resp = quoteCalculatorResponse.accessories.find((resp:any)=>resp.name===accessory.name);
          const inMonthAmount = resp?.baseAmountInMonth;
          const value = resp?.totalAmount;
          return {...accessory,'inMonth':inMonthAmount,'value':value};
        });
        
        tempFormData = {...tempFormData,
          'services':JSON.stringify(tempServices),
          'accessories':JSON.stringify(tempAccessories),
          ...rest,
          'baseAccessoriesAmount':quoteCalculatorResponse.accessoriesAmountTotal,
          'accessoriesAmountTotal':quoteCalculatorResponse.accessoriesIncludingGst,
          'fabricationAmountTotal':quoteCalculatorResponse.fabricationAmountTotal,
          'fabricationAmountInMonth':quoteCalculatorResponse.fabricationAmountInMonth,
          'gstOnVehicle':quoteCalculatorResponse.gstOnVehicle,
          'buyingCityDetail':JSON.stringify({
            'id':formData.buyingCityId,
            'name': formData.buyingCityName,
          }),
          'vehicle':JSON.stringify({
            'id': formData.vehicleId,
            'name': vehicles.find((vehicle: any) => vehicle.id === formData.vehicleId)?.name,
          }),
          'make':JSON.stringify({
            'id': formData.makeId,
            'name': makers.find((make: any) => make.id === formData.makeId)?.name,
          }),
          'model':JSON.stringify({
            'id': formData.modelId,
            'name': models.find((model: any) => model.id === formData.modelId)?.name,
          }),
          'variant':JSON.stringify({
            'id': formData.variantId,
            'name': variants.find((variant: any) => variant.id === formData.variantId)?.name
          }),
          'tenureInMonthDetail':JSON.stringify({
            'id': formData.tenureInMonthId,
            'name': tenures.find((tenure: any) => tenure.id === formData.tenureInMonthId)?.inMonth,
          }),
          'gevlStateDetail':JSON.stringify({
            'id': formData.gevlStateId,
            'name': gevlStateList.find((state: any) => state.id === formData.gevlStateId)?.stateName,
          }),

        };

        // setServices(tempServices);
        setFormData(tempFormData);
        setQuoteFinalDetail(tempResp);
        setQuoteFinalList( componentList);
      }
    }
    
    getComponentList();
  },[currentStep]);

  useEffect(()=>{
     if(vehicleChoice == `make`){
      form.setFieldsValue({
        ...formValues,
        makeId: null,
        modelId: null,
        variantId: null,
      });  
    } else if(vehicleChoice == `model`){
      form.setFieldsValue({
        ...formValues,
        modelId: null,
        variantId: null,
      });  
    } else if(vehicleChoice == `variant`){
      form.setFieldsValue({
        ...formValues,
        variantId: null,
      });   
    }
  }, [vehicleChoice]);

  useEffect(()=>{   
    form.setFieldsValue({
      ...formValues,
      excessMileageChargePerKm: excessMileageChargePerKm,
    });   
   
 }, [excessMileageChargePerKm]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setMakers(await fetchMakersData(auth, selectedVehicleId)));
        dispatch(setModels([]));
        dispatch(setVariants([]));
        setSelectedMakeId(null);
        setMakeIdForMaxLease(null);
        setSelectedModelId(null);
        setModelIdForMaxLease(null);
        setSelectedVariantId(null);
        setVehicleChoice('make');
      } catch (error) {
        console.log(`Got error while fetching setMakers data`,error);
      }
    };
    if(selectedVehicleId)
      fetchData(); 
       
  },[selectedVehicleId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setModels(await fetchModelsData(auth, selectedMakeId)));
        dispatch(setVariants([]));
        setSelectedModelId(null);
        setModelIdForMaxLease(null);
        setSelectedVariantId(null);

        setVehicleChoice('model');
      } catch (error) {
        console.log(`Got error while fetching models data`,error);
      }
    };
    
    
    if(selectedMakeId)  
      fetchData();   
       
  },[selectedMakeId]);

  useEffect(()=>{
    
    if(RMTYValue){
      const RMTYId = services.find((service:any)=>service.name === 'RMTY')?.id;
      const RMTYOption = servicesInputValues.find((item: any) => item.id === RMTYId);
      const RMTYObj = {...RMTYOption, value: RMTYValue}
      const updatedServicesInputValues = servicesInputValues.map((service:any)=>
        service.name==="RMTY" ? RMTYObj : service);
      setServicesInputValues(updatedServicesInputValues);
    }
  }, [isRMTYValueInServices, RMTYValue])

  useEffect(()=>{
    
    if(replacementVehicleValue){
      const replcacementVehicleId = services.find((service:any)=>service.name === 'Replacement Vehicle')?.id;
      const replcacementVehicleOption = servicesInputValues.find((item: any) => item.id === replcacementVehicleId);
      const replcacementVehicleObj = {...replcacementVehicleOption, inMonth: replacementVehicleValue}
      const updatedServicesInputValues = servicesInputValues.map((service:any)=>
        service.name==="Replacement Vehicle" ? replcacementVehicleObj : service);
      setServicesInputValues(updatedServicesInputValues);
    }
  }, [isreplacementVehicleValueInServices, replacementVehicleValue])

  useEffect(()=>{
      getBaseAmountForGSTCalculation(fabricationAmountTotal||0, fabricationGST||0, null, null);
  },[fabricationAmountTotal, fabricationGST])

  useEffect(()=>{
    const otherAccesory = selectedAccessories.find((accessoryId:any)=> accessoryId === 5)
    if(otherAccesory) {
      setIsOtherAccessoriesSelected(true); 
      form.setFieldsValue({
        ...formValues,
        otherAccessoriesDescription: payload?.otherAccessoriesDescription,
      });  
    }
    else{
      setIsOtherAccessoriesSelected(false);
      form.setFieldsValue({
        ...formValues,
        otherAccessoriesDescription: '',
      });   
    }
  },[selectedAccessories])

  const optionsToRender = (options: any[]) => {
    if (options === tenures){
      return options.map((value: any) => (
        <Option key={value.id} value={value.id}>
          {value.inMonth}
        </Option>
      ));
    }
    if(!Array.isArray(options))  return;
    return options?.map((value: any) => (
      <Option key={value.id} value={value.id}>
        {value.name}
      </Option>
    ));
  };
  
  const getBaseAmountForGSTCalculation = async (value: number, gstPercentage: number, inputValues: any, option: any) => {
    const fetchBaseAmountForGSTCalculation = async () => {
      try {
        setPendingRequests(prevCounter => prevCounter + 1); 
    
        const response = await postRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/getAmtByPercent?amt=${value}&percent=${gstPercentage}`,
          {}
        );
    
        const baseAmount = response.data?.amt;
    
        if (inputValues === null && option === null) {
          // Set fabricationBaseAmount Case
          setFabricationBaseAmount(baseAmount);
        } else {
          // Set Accessories Base Amount Case
          const updatedAccessoriesInputValues = inputValues.map((item: any) =>
            item.id === option.id ? { ...item, 'baseAmount': baseAmount } : item
          );
          setAccessoriesInputValues(updatedAccessoriesInputValues);
        }
      } catch (error: any) {
        // Show error modal
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors[0]) ||
          'An error occurred while fetching BaseAmountForGSTCalculation';
    
        // Modal.error({
        //   title: 'Error',
        //   content: errorMessage,
        // });
      } finally {
        setPendingRequests(prevCounter => prevCounter - 1); 
      }
    };
  
    // Cancel the previous debounced function and create a new one
    debounceForGSTCalculationCall.current.cancel();
    debounceForGSTCalculationCall.current = _.debounce(fetchBaseAmountForGSTCalculation, DEBOUNCETIMER);

    // Execute the debounced function
    debounceForGSTCalculationCall.current();
  };
   
  // Cleanup the debounced function on component unmount
  const cleanupFunctions = [
    debounceForBasePriceAndGST.current.cancel,
    debounceForBasePrice.current.cancel,
    debounceForGSTCalculationCall.current.cancel,
    debounceForRVPercentageCall.current.cancel,
    debounceForManagementFeesCall.current.cancel,
    debounceForRMTYValueCall.current.cancel,
    debounceForReplacementVehicleCall.current.cancel,
    debounceForTCSCall.current.cancel,
    debounceForInsuranceAmountCall.current.cancel,
    debounceForFirstYearInsurancePremiumCall.current.cancel,
  ];
  useEffect(() => {
    return () => {
      cleanupFunctions.forEach(cleanup => cleanup());
    };
  }, []);
                
  const renderAccessoriesField = (fieldName: string) => {
    console.log(fieldName)
    const options = fieldName === 'accessories' ? accessories : services;
    return accessories.map((option: any) => {
      const selectedOption = accessoriesInputValues.find((item: any) => item.id === option.id);
      
      return selectedOption ? (
        <div key={option.id} style={{ width: '100%', display: 'flex', flexDirection: 'row',
         justifyContent: 'space-around', marginTop: '.25em', border: '0.5px grey solid' ,borderRadius: '0.3em' }}>
          <label style={{ width: '30%', padding: '0.4em' }}>
            {accessories.find((value: any) => value.id === option.id)?.name} </label>
          <Tooltip title="Price" placement="bottom">
            
          <Input

            style={{ width: '30%'  }}
            placeholder={`Price`}
            value={ accessoriesInputValues.find((item: any) => item.id === option.id)?.totalAmount } // Use the selected option's value
                         
            onChange={(e) => {
                const currItem = accessoriesInputValues.find((item:any)=> item.id === option.id);
                currItem.totalAmount = e.target.value;
                const currTotalAmount = currItem.totalAmount;
                const currGst = currItem.gst;
              
                let updatedAccessoriesInputValues = accessoriesInputValues.map((item: any) =>
                  item.id === option.id ? { ...item, ...currItem} : item
                );
                setAccessoriesInputValues(updatedAccessoriesInputValues)
                if(currTotalAmount>=0 && currGst>=0){
                  let baseAmount: any=0;
                  const  fetchBaseAmount = ()=>{
                    getBaseAmountForGSTCalculation(currTotalAmount||0,currGst||0, accessoriesInputValues, option);
                  }
                  fetchBaseAmount();
                }
                else setAccessoriesInputValues(updatedAccessoriesInputValues);
              
            }}
          />
          </Tooltip>
          
          <Tooltip title="GST Percentage" placement="bottom">
            <Input placeholder={`GST`}
              style={{ width: '14%'  }}
              value={accessoriesInputValues.find((item: any) => item.id === option.id).gst} // Use the selected option's value
              onChange={(e) => {
                const currItem = accessoriesInputValues.find((item:any)=> item.id === option.id);
                
                currItem.gst = e.target.value;
                const currTotalAmount = currItem.totalAmount;
                const currGst = currItem.gst;
              
                let updatedAccessoriesInputValues = accessoriesInputValues.map((item: any) =>
                  item.id === option.id ? { ...item, ...currItem} : item
                );
                setAccessoriesInputValues(updatedAccessoriesInputValues)
                if(currTotalAmount>=0 && currGst>=0){
                  let baseAmount: any=0;
                  const  fetchBaseAmount = ()=>{
                    getBaseAmountForGSTCalculation(currTotalAmount||0,currGst||0, accessoriesInputValues, option);
                  }
                  fetchBaseAmount();
                }
                else setAccessoriesInputValues(updatedAccessoriesInputValues);
                
              }}
            />
          </Tooltip>
          
          <Tooltip title="Base Amount" placement="bottom">
            <Input
              disabled={true}
              placeholder={`Base Amount for ${option.name}`}
              style={{ width: '25%' }}
              value={accessoriesInputValues.find((item: any) => item.id === option.id).baseAmount}
            />
          </Tooltip>
          
        </div>
      ) : null; // Only render if the option is selected
    });
  };
      
  const renderServicesField = (fieldName: string) => {
    return services.map((option: any) => {
      const selectedOption = servicesInputValues.find((item: any) => item.id === option.id);
      const isRMTY = fieldName==='services' && selectedOption?.name === 'RMTY';
      const isReplcacementVehicle = selectedOption?.name === 'Replacement Vehicle';
      if(isRMTYValueInServices === false && isRMTY === true)
        setIsRMTYValueInServices(true);
      if(isreplacementVehicleValueInServices === false && isReplcacementVehicle === true)
        setIsreplacementVehicleValueInServices(true);
      
      return selectedOption ? (
        <div key={option.id} style={{ width: '100%', display: 'flex', flexDirection: 'row',
         justifyContent: 'space-around', marginTop: '.25em', border: '0.5px grey solid' ,borderRadius: '0.3em' }}>
          <label style={{ width: '40%', padding: '0.4em' }}>
            {services.find((value: any) => value.id === option.id)?.name} </label>
          <Tooltip title="Price" placement="bottom">
          <Input
            style={{ width: '50%'  }}
            placeholder={`Price`}
            value={isRMTY ? RMTYValue : isReplcacementVehicle ? replacementVehicleValue  : selectedOption.inMonth} // Use the selected option's value
                         
            onChange={(e) => {
              if(isRMTY){
                setRMTYValue(parseInt(e.target.value != '' ? e.target.value : '0'));
              } 
              else if(isReplcacementVehicle){
                setReplacementVehicleValue(parseInt(e.target.value != '' ? e.target.value : '0'));
              }
              else if (fieldName === 'services') {
                const updatedServicesInputValues = servicesInputValues.map((item: any) =>
                  item.id === option.id ? { ...item, inMonth: e.target.value } : item
                );
                setServicesInputValues(updatedServicesInputValues);
              }
            }}
          />
          </Tooltip>
        </div>
      ) : null; // Only render if the option is selected
    });
  };

  const handleProspectIdSelect = (prospectId:any)=>{
    console.log(`selected prospect id=`,prospectId);
  }
  const handleCityIdSelect = async (cityId:any)=>{
    console.log(`selected city id=`,cityId);
    setSelectedCityId(cityId);
    const cityDetail = cities.find((city:any)=>city.id == cityId);
    const gevlStateDetail = gevlStateList.find((state:any)=> state.stateCode == cityDetail.stateCode);
    if(gevlStateDetail){
      setGevlStateId(gevlStateDetail.id);
      form.setFieldsValue({
        gevlStateId: gevlStateDetail.id, 
      });
    }
    else{
      setGevlStateId(HARYANA_STATE_ID);
      form.setFieldsValue({
        gevlStateId: HARYANA_STATE_ID, 
      });
    }
  }
  const handleGevlStateIdSelect = async (stateId:any)=>{
    console.log(`selected stateId =`,stateId);
    if(stateId != gevlStateId){
      setGevlStateId(stateId);
    }
  }
  
  return (
    <div style={{ position: 'relative', minHeight: '100vh' }} className="form-container">
      <Steps style={{ marginBottom: 40 }} size="small" current={currentStep} items={stepItems} />
      <div style={{ opacity: pendingRequests > 0 ? 0.5 : 1 }}>

        <Col className="container" style={{ marginLeft: '5rem', backgroundColor: "white" }}>
        <Form
          form={form}
          name="quoteForm"
          labelCol={{ span: 24}}
          wrapperCol={{ span: 24}}
          initialValues={initialValues}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          {
          steps[currentStep].fields.length === 0 ?
              <QuoteFinal quoteFinalListOrig={quoteFinalList} quoteFinalDetail = {quoteFinalDetail} />
          :
          <Row gutter={16}>
            { 
              steps[currentStep].fields.map((field: any) => {
              options =
                field.name === 'prospectId' ? prospects :
                field.name === 'buyingCityId' ? cities :
                field.name === 'gevlStateId' ? gevlStateList :
                field.name === 'vehicleId' ? vehicles :
                field.name === 'tenureInMonthId' ? tenures :
                field.name === 'makeId' ? makers :
                field.name === 'modelId' ? models :
                field.name === 'variantId' ? variants :
                field.name === 'accessories' ? accessories :
                field.name === 'services' ? services : [];
              return (
                <Col span={12} key={field.name}>
                  <Form.Item
                    label={field.label}
                    name={field.name}
                    rules={[{ required: (field?.name==='roadTaxTotal' && roadTaxType==='Not Managed') ? false: field.required, message: `Please input ${field.label}!` }]}
                    hidden={(formType!='UPDATEQUOTE' && field?.name==='active')||(field?.name==='otherAccessoriesDescription' && !isOtherAccessoriesSelected)||(field?.name==='roadTaxTotal' && roadTaxType==='Not Managed')}
                  >
                    {
                       field.name === 'prospectId' && formType === 'UPDATEQUOTE'? (
                        <Select showSearch={true} style={{ width: '100%' }} value={payload.prospectId} disabled={true} >{optionsToRender(options)}</Select>
                      ) : field.name === 'prospectId' && formType !== 'PROSPECTQUOTE' ? (
                        <SelectSearch name={field.name} placeholder={field.label} options={options} onSelect={handleProspectIdSelect}/>
                      ) : field.name === 'prospectId' ? (
                        <Select showSearch={true} style={{ width: '100%' }} value={payload.prospectId} disabled={true} >{optionsToRender(options)}</Select>
                      ) : (field.name === 'buyingCityId' ) ? (
                        <SelectSearch name={field.name} placeholder={field.label} options={options.map((id:any)=>{
                              return {
                                  id: id?.id,
                                  name: id?.cityName,
                              }
                            }) 
                          } onSelect={handleCityIdSelect}/>
                      ) : (field.name === 'gevlStateId' ) ? (
                        <SelectSearch name={field.name} placeholder={field.label} options={Array.isArray(options)? options.map((id:any)=>{
                              return {
                                  id: id?.id,
                                  name: id?.stateName,
                              }
                            }) : []
                          } onSelect={handleGevlStateIdSelect}/>
                      ) : (field.name === 'tenureInMonthId' ) ? (
                        <Select showSearch={true} style={{ width: '100%' }}  onChange={(value)=> handleDynamicChange(value, field.name)}
                          >{optionsToRender(options)}
                        </Select>
                      ) :( field.name === 'vehicleId' || field.name === 'makeId' || field.name === 'modelId' 
                          || field.name === 'variantId') ? (
                        <Select style={{ width: '100%' }}
                          showSearch={true}
                          onChange={(value) => handleDynamicChange(value, field.name)}
                          value={formValues[field.name]} // Use formValues to set the value from the form state
                          >{optionsToRender(options)}</Select>
                      ) : field.name === 'accessories' || field.name === 'services' ? (
                        <div className="fixed-width-input" style={{ display: 'flex', flexDirection: 'column' }}>
                          <Select
                            showSearch={true}
                            mode="multiple"
                            placeholder={`Select ${field.label}`}
                            value={field.name === 'accessories' ? selectedAccessories : selectedServices}
                            onChange={(values) => {
                              if (field.name === 'accessories') {
                                setSelectedAccessories(values);
                              } else if (field.name === 'services') {
                                const newValues = values.filter((value)=>{
                                  if(selectedServices.includes(value))
                                    return false;
                                  else return true;
                                });
                                  
                                if(newValues.length>0 && newValues[0] == "2"){
                                  if(!isEdited) setIsEdited(true);
                                  setFetchRMTYValueCounter(fetchRMTYValueCounter+1);
                                } 
                                if(newValues.length>0 && newValues[0] == "6"){
                                  if(!isEdited) setIsEdited(true);
                                  setFetchReplacementVehicleValueCounter(fetchReplacementVehicleValueCounter+1);
                                } 
                                
                                setSelectedServices(values);
                              }
                              handleDynamicChange(values, field.name);
                            }}
                          >
                            {optionsToRender(options)}
                          </Select>
                          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            {field.name === 'services'?renderServicesField(field.name):renderAccessoriesField(field.name)}
                          </div>
                        </div>
                      ) : ( field.name === 'exShowroom' || field.name === 'discount' || field.name === 'mileagePerMonth' ||
                            field.name === 'fameSubsidy' || field.name === 'stateSubsidy' ) ? (
                        <InputNumber onChange={(value)=> handleDynamicChange(value, field.name)} className="fixed-width-input" />
                      ) : ( field.name === 'firstYearInsurancePremium') ? (
                        <InputNumber disabled={insuranceType=="Not Managed" ?true : false } 
                          onChange={(value)=> handleDynamicChange(value, field.name)} className="fixed-width-input" />
                      ) : ( field.name === 'basePrice' ||  field.name === 'tcs' || field.name === 'insuranceAmountTotal') ? (
                        <InputNumber 
                          disabled = {!field.editable}
                          className="fixed-width-input"
                        />
                      ) : (field.name === 'rvPercentage' || field.name === 'managementFees' ||  field.name === 'gstOnVehicle') ? (
                       <InputNumber
                         onChange={(value)=> handleDynamicChange(value, field.name)} 
                         className="fixed-width-input"
                       /> 
                     ) : (field.name === 'insuranceType' || field.name === 'registeredTo' || field.name === 'roadTaxType') ? (
                        <Select showSearch={true} style={{ width: '100%' }} onChange={(value)=> handleDynamicChange(value, field.name)}>
                          {field.values.map((value: any) => (
                            <Option key={value} value={value}>
                              {value}
                            </Option>
                          ))}
                        </Select>
                      ) : ( field.name === 'roadTaxTotal') ? (
                        <InputNumber
                          className="fixed-width-input" />
                      ) : field.values ? (
                        <Select showSearch={true} style={{ width: '100%' }}>
                          {field.values.map((value: any) => (
                            <Option key={value} value={value}>
                              {value}
                            </Option>
                          ))}
                        </Select>
                      ): field.name === 'active' ? (
                        <Switch
                          checked={isActiveQuote === null ? payload?.active : isActiveQuote}
                          onChange={() => setIsActiveQuote(!isActiveQuote)}
                        />
                      ) : field.name === 'otherAccessoriesDescription' ? (
                        <Input className="fixed-width-input" />
                      ) :field.name === 'fabricationAmountTotal' ? (
                        <div>
                          <Tooltip title="Amount">
                            <InputNumber
                              value={fabricationAmountTotal}
                              onChange={(value)=> setFabricationAmountTotal(value||0)
                              } 
                              style={{width:"40%"}}
                              placeholder='Amount'
                            />
                          </Tooltip>
                          <Tooltip title="GST %">
                            <InputNumber
                              value={fabricationGST}
                              onChange={(value)=> setFabricationGST(value||0)
                              } 
                              style={{width:"19%", marginLeft:"0.5%"}}
                              placeholder='GST %'
                            /> 
                          </Tooltip>
                          <Tooltip title="Base Amount">
                            <InputNumber
                              disabled={true}
                              value={fabricationBaseAmount}
                              style={{width:"40%", marginLeft:"0.5%"}}
                              placeholder='Base Amount'
                            /> 
                          </Tooltip>
                          
                        </div>
                      ): field.isNumber ? (
                        <InputNumber className="fixed-width-input" />
                      ) : (
                        <Input className="fixed-width-input" />
                      )
                    }
                  </Form.Item>
                </Col>
              );
            })}
          </Row>
          }
          
          <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              disabled={currentStep === 0 || pendingRequests>0}
              type="default"
              onClick={handlePrev}
              style={{ backgroundColor: prevButtonColor, margin: '1rem', minWidth: '8rem', minHeight: '2.5rem' }}
            >
              Previous
            </Button>
            <Button
              disabled={currentStep === steps.length - 1 || pendingRequests>0}
              type="default"
              onClick={handleNext}
              style={{ backgroundColor: nextButtonColor, margin: '1rem', minWidth: '8rem', minHeight: '2.5rem' }}
            >
              Next
            </Button>
            {currentStep === steps.length - 1 && (
              <Spin spinning={disableSubmitButton}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={disableSubmitButton}
                  style={{ backgroundColor: 'teal', margin: '1rem', minWidth: '8rem', minHeight: '2.5rem' }}
                >
                  Submit
                </Button>
              </Spin>
            )}

            <Button
              type="default"
              onClick={handleCloseScreen}
              style={{ backgroundColor: closeButtonColor, margin: '1rem', minWidth: '8rem', minHeight: '2.5rem' }}
            >
              Close
            </Button>
            <ConfirmSubmissionPopup
              visible={isConfirmModalVisible}
              onClose={handleCancel}
              onConfirm={handleConfirm}
            />
            <ConfirmPopup
                  visible={isMaxLeaseModalVisible}
                  message={maxLeaseMessage}
                  onClose={handleMaxLeaseCancel}
                  onConfirm={handleMaxLeaseConfirm}
              />
          </Row>
        </Form>
      </Col>
      </div>
          
      {pendingRequests > 0 && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999, // Ensure it appears on top of other elements
            backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent white background
          }}
        >
          <Spin size="large" />
        </div>
      )}
      
    
    </div>
  );
}

export default CreateQuote;
