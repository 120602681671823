import { useEffect, useState } from "react";
import { Layout } from 'antd';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setUser } from "../../features/auth/userSlice";
import { userSelector } from "../../features/auth/userSlice";
import { authSelector } from "../../features/auth/authSlice";
import SideNav from "../../components/SideNav";
import Topnav from '../../components/Topnav';
import { Outlet } from "react-router";
import jwtDecode from "jwt-decode";
import "../../styles/Pages.sass"
import "../../styles/Nav.sass"

const { Header, Content, Sider } = Layout;

function Dashboard() {
  const dispatch = useAppDispatch();
  const selectedUser = useAppSelector(userSelector);
  const selectAuth = useAppSelector(authSelector);

  useEffect(() => {
    const data: any = jwtDecode(selectAuth.accessToken);
    const role = data.realm_access.roles.filter((str: any) => str.includes("GEVL"))[0]
    dispatch(setUser( { name: data.name, email: data.email, role: role, ssoId : data.sub }));

    return
  }, [selectedUser]);

  return (
    <div style={{ minHeight: '100%', display: "flex", width: "100%" }}>
      <div className="side-nav">
        <SideNav />
      </div>
      <Layout className="container">
        <Header style={{ padding: 0, height: 60 }}>
          <Topnav {...selectedUser} />
        </Header>
        <Content className="page-container">
          <Outlet />
        </Content>
      </Layout>
    </div>
  );
};

export default Dashboard;