export const getSelectOptionsValue = (vehicles:any)=>( [
    {   
        "name":"vehicleType",
        "values":["New", "Used"],
    },
    {
        "name":"vehicle",
        "values":vehicles.map((vehicle:any)=>vehicle.name),
    },
    {
        "name": "registeredTo",
        "values": ["Lessor", "Lessee"],
    },
    {
        "name": "registrationType",
        "values": ["Commercial", "Private"],
    },
    {
        "name": "paymentType",
        "values": ["Arrear", "Advance"],
    },
    {
        "name": "insuranceType",
        "values": ["AYF", "IYF", "AYR", "Not Managed"],
    },
    {
        "name": "leaseType",
        "values": ["Wet", "Dry"],
    },
]);