import { ReactComponent as EyeIcon } from './../../../../utils/icons/view_icon.svg'; 
import { ReactComponent as PipeIcon } from '../../../../utils/icons/Pipe_Icon.svg';   
import { ReactComponent as CrossIcon } from '../../../../utils/icons/Cross_Icon.svg'; 
import { ReactComponent as DisabledCrossIcon } from '../../../../utils/icons/Disabled_Cross_Icon.svg'; 
import { ReactComponent as RetryIcon } from '../../Prospect/utils/SVGs/RetryIcon.svg';
import { ReactComponent as DocumentIcon } from '../../Prospect/utils/SVGs/document_icon.svg';
import { ReactComponent as TickIcon } from '../../Prospect/utils/SVGs/tick_icon.svg';
import { ReactComponent as AddIcon } from '../../Prospect/utils/SVGs/add_icon.svg';
import React, { useEffect, useState } from 'react';
import { Table, Input, DatePicker, Button, message, Space, Modal, Typography, Col, Row, Spin, Tooltip, Upload } from 'antd';
import { EyeInvisibleFilled, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../../../app/hooks';
import { authSelector } from '../../../../features/auth/authSlice';
import axios from 'axios';
import dayjs from "dayjs";

interface UploadModalDemoProps {
    title: string;
    requestId: any;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    statusChangeTypeId: any;
    files: any[];
    setFiles: (files : any[]) => void;
    fileSelectionFlag: boolean;
}

const UploadModalDemo: React.FC<UploadModalDemoProps> = ({title, showModal, setShowModal,requestId, statusChangeTypeId, files,  setFiles, fileSelectionFlag}) => {
  const auth = useAppSelector(authSelector);
  const [uploadLoader, setUploadLoader] = useState<boolean>(false);
  const [isPreviewVisible,setIsPreviewVisible]=useState(false);
  const [selectedDoc, setSelectedDoc] = useState<string | undefined>(undefined);
  const [selectedFile, setSelectedFile] = useState<any>(undefined);
  const [tableData, setTableData] = useState<any[]>([]);
  const [atLeastOneFailed, setAtLeastOneFailed] = useState<boolean>(false);
  const [allSucceed, setAllSucceed] = useState<boolean>(false);
  const [finishProcessTriggered, setFinishProcessTriggered] = useState<boolean>(false);

  
  const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;
  const fileTypeSet = new Set(['docx','doc', 'pptx', 'ppt', 'xlsx', 'xls','csv']);    
  
  useEffect(() => {
    initialTableData();
  }, [fileSelectionFlag]);

  function disabledDate(current: any) {
    return current && current < new Date().setHours(0, 0, 0, 0);
    }

  const handleChange = (key: string, index: number, value: string) => {
    setTableData(
      tableData.map((item, i) => (i === index ? { ...item, [key]: value } : item))
    );
  };

  const handleMultipleUpload = async () => {
    const tempTable = [...tableData];

    for(let i=0; i<tempTable.length; i++){
      if(tempTable[i]?.validity === null){
        tempTable[i].isValid = false;
      }
  }
  setTableData(tempTable);

    for(let i=0; i<tableData.length; i++){
        if(tableData[i]?.validity === null) {
          message.error("Please enter all validity dates");
          return;
        }
    }
          setUploadLoader(true);
          const url = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/file/uploadPI?requestId=${requestId}`;
          for(let i=0; i<tableData.length; i++) {
            if(tableData[i]?.status === 'Waiting' || tableData[i]?.status === 'Failed'){
              const formData = new FormData();
              formData.append('file', tableData[i]?.file?.originFileObj);
              try 
              {
                  tableData[i].status = 'In-Progress';
                  await axios.post(url, formData, {
                      headers: {
                          'Authorization': `Bearer ${auth.accessToken}`,
                          'Content-Type': 'multipart/form-data'
                      }
                  }).then(response => {
                      if(response.status===200)
                      {
                        tableData[i].status = 'Success';
                        const docURL=response?.data?.DocumentUrl;
                        tableData[i].docUrl = docURL;
                      }
                      else
                      {
                        tableData[i].status = 'Failed';
                        message.error("error in uploading file")
                      }
                  })
              } catch (error) {
                tableData[i].status = 'Failed';
                console.error('Upload error:', error);
              }
            }
          }
      
          let successCounter = 0;
          for(let i=0; i<tableData.length; i++){
              if(tableData[i]?.status === 'Failed'){
                setAtLeastOneFailed(true);
              }else if(tableData[i]?.status === 'Success'){
                successCounter++;
              }
          }
          if(successCounter === tableData.length){
            setAllSucceed(true);
          }
      
          setUploadLoader(false);
        
  }

  const handleAllDocUpload = async () => {
    setUploadLoader(true);
    const allDocDTO = [];
    for(let i=0; i<tableData.length; i++){
        if(tableData[i]?.status != 'Failed'){
          const docURL=tableData[i]?.docUrl;
          const fileName = tableData[i]?.file?.name;
          const contentType = fileName?.split('.').pop();
          let jsonObj = {
            fileName: fileName,
            contentType: contentType,
            piDocFileURL: docURL,
            remarks: tableData[i]?.remarks,
            validityTill: tableData[i]?.validity
          }
          allDocDTO.push(jsonObj);
        }
    }
    
    const url = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/addProformaInvoiceDocDetails?requestId=${requestId}&statusChangeTypeId=${statusChangeTypeId}`;
    try {
      const response = await axios.post(url, allDocDTO, {
          headers: {
              'Authorization': `Bearer ${auth.accessToken}`,
              'Content-Type': 'application/json'
          }
      });
      setUploadLoader(false);
      setShowModal(false);
      setTimeout(() => window.location.reload(), 1000);
    }
     catch(err) {
    console.log("Error: ", err)
    }
  }

  const handlePreview = (record: any ) =>{
    setSelectedDoc(record?.file?.name === selectedDoc ? undefined : record?.file?.name);
    setSelectedFile(record?.file?.name === selectedDoc ? undefined : URL.createObjectURL(record?.file?.originFileObj));
    setIsPreviewVisible(record?.file?.name !== selectedDoc);
  }

  const deleteRow = (record: any) => {
    const tempTable = [];
    for(let i=0; i<tableData.length; i++){
      if(tableData[i]?.key !== record?.key){
        tempTable.push(tableData[i]);
      }
    }
    setTableData(tempTable);
    setIsPreviewVisible(record?.file?.name === selectedDoc ? false : isPreviewVisible);
    setSelectedDoc(record?.file?.name === selectedDoc ? undefined : selectedDoc)
  };

  const initialTableData = () => { 
    const jsonArray = [];
    for(let i=0; i<files.length; i++){
        let jsonObj = {
          key : files[i].uid,
          docUrl : '',
          remarks: '',
          action: 'Preview',
          fileUID: files[i].uid,
          file : files[i],
          status : 'Waiting',
          isValid : true
        }
        jsonArray.push(jsonObj);
    }
    setTableData(jsonArray);
  }

  const handleExtraFiles = (fileList: any) => {
    const tempFile = [...files, ...fileList];
    setFiles(tempFile);
    const jsonArray = [];
    for(let i=0; i<fileList.length; i++){
        let jsonObj = {
          key : fileList[i].uid,
          docUrl : '',
          remarks: '',
          action: 'Preview',
          fileUID: fileList[i].uid,
          file : fileList[i],
          status : 'Waiting',
          isValid : true
        }
        jsonArray.push(jsonObj);
    }
    setTableData([...tableData, ...jsonArray]);
  }

  
  const columns = [
    {
      title:<span style={{fontWeight:'bolder'}}>Document Name</span>,
      dataIndex: 'document',
      key: 'document',
      render: (text: any, record: any, index: number) => (
        <Tooltip title={record?.file?.name} style={{ zIndex: '3' }}>
          <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace : 'nowrap'}}>
            {record?.file?.name}
          </div>
      </Tooltip>
      ),
      width : '22%'
    },
    {
      title:<span style={{fontWeight:'bolder'}}>Upload Status</span>,
      dataIndex: 'status',
      key: 'status',
      render: (text: any, record: any, index: number) => {
        let color = 'rgba(135, 44, 227, 1)';
        let backgroundColor = 'rgba(135, 44, 227, 0.1)';
        if(record?.status === 'In-Progress'){
          color = 'rgba(227, 154, 44, 1)';
          backgroundColor = 'rgba(227, 154, 44, 0.1)';
        }else if(record?.status === 'Success'){
          color = 'rgba(2, 181, 31, 1)';
          backgroundColor = 'rgba(2, 181, 31, 0.1)';
        }else if (record?.status === 'Failed'){
          color = 'rgba(208, 14, 14, 1)';
          backgroundColor = 'rgba(208, 14, 14, 0.1)'
        }
        return(          
        <span style={{color: color, backgroundColor : backgroundColor, padding : '5px 10px', borderRadius: '5px', boxShadow: '0px 6px 30px 0px rgba(0, 0, 0, 0.15)', width: '20%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {record?.status}
        </span>)
      },
      width : '15%'
    },
    {
      title: <span style={{fontWeight:'bolder'}}>Remarks <span style={{color:'#B2B2B2',marginLeft:'4px'}}>(Optional)</span></span>,
      dataIndex: 'remarks',
      key: 'remarks',
      render: (text: any, record: any, index: number) => (
        <Input
          placeholder='Enter Remarks'
          disabled={record?.status === 'Success' ? true : false}
          onChange={(e) => handleChange('remarks', index, e.target.value)}
          value={record?.remarks}
        />
      ),
      width:"27%"
    },
    {
      title: <span style={{fontWeight:'bolder'}}>Action</span>,
      key: 'action',
      render: (text: any, record:any,  index: number) => (
        <Space size="middle">
          <Button
          disabled={record?.status === 'Success' ? true : false}
          onClick={() => deleteRow(record)}
          style={{ padding: 5, color: record?.status === 'Success' ? 'grey' :'#FF4D4F', border: 'none', backgroundColor: 'transparent', boxShadow: 'none', fontSize: '12px',  paddingRight:"0px"}}          >
            <span > {record?.status === 'Success' ? <DisabledCrossIcon  /> : <CrossIcon  />}  Remove</span>
          </Button>
          <span><PipeIcon /></span>
          <Button
          disabled={fileTypeSet.has(record?.file?.name?.split('.').pop())}
          type='link'
          onClick={() => handlePreview(record)}
          icon={fileTypeSet.has(record?.file?.name?.split('.').pop())? <EyeInvisibleFilled /> :selectedDoc === record?.file?.name ? <EyeInvisibleFilled /> : <EyeIcon />} 
          style={{boxShadow:"none" ,color :fileTypeSet.has(record?.file?.name?.split('.').pop())?'grey':'#2C66E3', backgroundColor: 'transparent', border: 'none', fontSize: '12px', fontWeight: 500, paddingLeft:"0px"}} 
          >
            {fileTypeSet.has(record?.file?.name?.split('.').pop())? 'Preview not Available' : selectedDoc === record?.file?.name ? 'Hide preview' : 'Preview'}
          </Button>
        </Space>
      ),
      width:"20%"
    },
  ];

  return (
    <Modal
    title={<Typography style={{background: "#F6FAFF", fontSize: 14, fontWeight: 500, color: "#404040"}}>{title}</Typography>}
    centered
    open={showModal}
    afterClose={() => { setFiles([]); setTableData([]); setUploadLoader(false); setIsPreviewVisible(false); setAtLeastOneFailed(false); setAllSucceed(false);}}
    onOk={() => { setShowModal(false);}}
    onCancel={() => { setShowModal(false); setFiles([]); setTableData([]);}}
    closable={true}
    className="preview-modal"
    width={isPreviewVisible? "95%" : "68%" }
    footer={
        <div>
          {
            !atLeastOneFailed && !allSucceed && 
            <div>
              <Upload
                  accept=".docx,.doc,.pptx,.ppt,.xlsx,.xls,.pdf,.jpg,.jpeg,.png"
                  multiple={true}
                  onChange={(info) => {handleExtraFiles(info.fileList);}}
                  showUploadList={false}
                  fileList={[]}
                  beforeUpload={() => false}
                  >
                  <Button 
                    onClick={() => {}}
                    icon={<AddIcon/>}
                    style={{color:'#2C66E3', backgroundColor : '#E8F1FF', borderRadius: '4px', border: '1px solid #2C66E3'}}
                    >
                        Add More Document
                  </Button>
              </Upload>
              <Button 
              className={tableData.length==0?"disable":""} 
              type="primary" 
              onClick={() => tableData.length !== 0 && handleMultipleUpload()} 
              style={{marginLeft:"0.5rem"}}
              icon={uploadLoader ? <Spin indicator={antIcon}/> : <UploadOutlined />}
              >
                  Upload PI
              </Button>
            </div>
          }
          {
            atLeastOneFailed &&
            <div>
              <Button 
                onClick={() => {!finishProcessTriggered && handleAllDocUpload(); setFinishProcessTriggered(true); }}
                icon={<DocumentIcon/>}
                style={{color:'#2C66E3', backgroundColor : '#E8F1FF', borderRadius: '4px', border: '1px solid #2C66E3'}}
                >
                  Proceed without retry
              </Button>
              <Button 
              className={tableData.length==0?"disable":""} 
              type="primary" 
              onClick={() => {handleMultipleUpload();}} 
              style={{marginLeft:"0.5rem"}}
              icon={uploadLoader ? <Spin indicator={antIcon}/> : <RetryIcon />}
              >
                  Retry Upload
              </Button>
            </div>
          }
          {
            allSucceed &&
            <div>
              <Button 
              className={tableData.length==0?"disable":""} 
              type="primary" 
              onClick={() => {!finishProcessTriggered && handleAllDocUpload(); setFinishProcessTriggered(true);}} 
              style={{marginLeft:"0.5rem"}}
              icon={uploadLoader ? <Spin indicator={antIcon}/> : <TickIcon />}
              >
                  Finish Process
              </Button>
            </div>
          }

        </div>
    }
    >
      <Row>
        <Col span={isPreviewVisible ? 14 : 24}>
                        <div className='custom-table'>
                          <Table
                              scroll={{ y: 300 }}
                              dataSource={tableData}
                              columns={columns}
                              pagination={false}
                              rowKey="key"  
                          />
                        </div>

                    </Col>
                    {isPreviewVisible && (
                        <Col span={10}>
                            <div style={{ border: '1px solid #ddd', padding: '16px' }}>
                                <h3>{selectedDoc} Preview</h3>
                                <iframe src={selectedFile} width="100%" height="400px" title="Document Preview"></iframe>
                            </div>
                        </Col>
                    )}
        </Row>
    </Modal>
  );
};

export default UploadModalDemo;