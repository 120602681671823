import React, { useEffect, useState, useRef } from 'react';
import { ReactComponent as NoDocuments } from '../.././utils/SVGs/NoDocuments.svg';
import { ReactComponent as CrossIcon } from '../.././utils/SVGs/cross_icon.svg';
import { ReactComponent as TickIcon } from '../.././utils/SVGs/tick_icon.svg';
import { ReactComponent as Cancel } from '../.././utils/SVGs/Cancel.svg';
import { ReactComponent as AddIcon } from '../.././utils/SVGs/add_icon.svg';
import { ReactComponent as UploadIcon } from '../.././utils/SVGs/Upload.svg';
import { ReactComponent as CautionIcon } from '../.././utils/SVGs/CautionIcon.svg';
import { ReactComponent as RetryIcon } from '../.././utils/SVGs/RetryIcon.svg';
import { ReactComponent as DocumentIcon } from '../.././utils/SVGs/document_icon.svg';
import { ReactComponent as PipeIcon } from '../../../../../utils/icons/Pipe_Icon.svg';
import { ReactComponent as EyeIcon } from '../.././utils/SVGs/eye.svg';
import { ReactComponent as DisablePreviewIcon } from '../.././utils/SVGs/DisablePreviewIcon.svg';
import { Button, Upload, Typography, Tooltip, Progress } from 'antd';
import axios from 'axios';
import { store } from '../../../../../app/store';
import FilePreview from './FilePreview';

interface UploadDocumentsProps {
  showUpload: boolean;
  setShowUpload: (show: boolean) => void;
  documentActionTargetId: number;
  setReloadDocumentTab: (reload: number) => void;
}

const UploadDocuments: React.FC<UploadDocumentsProps> = ({ showUpload, setShowUpload, documentActionTargetId, setReloadDocumentTab }) => {
  const [files, setFiles] = useState<any[]>([]);
  const [removedFilesUid, setRemovedFilesUid] = useState<Set<string>>(new Set<string>());
  const [previewFile, setPreviewFile] = useState<any>(null);
  const [uploadingStatus, setUploadingStatus] = useState<'Waiting' | 'Uploading' | 'Completed' | 'Success' | 'Failure'>('Waiting');
  const [filesUploadStatus, setFilesUploadStatus] = useState<string[]>([]);
  const [uploadingPercent, setUploadingPercent] = useState<number>(0);
  const [uploadFailureCount, setUploadFailureCount] = useState<number>(0);
  // const [hideUpload, setHideUpload] = useState<boolean>(false);

  const uploadRef = useRef<HTMLButtonElement>(null);

  
  const fileTypeSet = new Set<string>(['docx', 'doc', 'pptx', 'ppt', 'xlsx', 'xls', 'csv']);
  const cardRef = useRef<HTMLDivElement>(null);
  const abortController = useRef<AbortController | null>(null); // Ref for AbortController
  
  useEffect(() => {
    // Trigger a click event on the upload button when the component mounts
    if (uploadRef.current) {
      uploadRef.current.click();
      // setHideUpload(true);
    }
  }, []);

  useEffect(()=>{
    // Cleanup function
    return () => {
      // Abort the upload request if the component unmounts
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  },[])

  const handleStopUpload = () => {
    if (abortController.current) {
      abortController.current.abort(); // Abort the current file upload
    }
  };

  useEffect(() => {
    if ( filesUploadStatus.length !== files.length ) // will only reset to all In Queue for empty case
      setFilesUploadStatus(files.map((file: any) => "In Queue"));
  }, [files]);

  useEffect(() => {
    if (uploadingStatus === 'Completed') {
      const failureCount = filesUploadStatus.reduce((cnt: number, status: string) =>
        cnt + (status === 'Upload Failed' ? 1 : 0), 0);
      setUploadFailureCount(failureCount);
      if (failureCount > 0) {
        setUploadingStatus('Failure');
      } else {
        setUploadingStatus('Success');
      }
    }
  }, [uploadingStatus]);

  const handleDocumentModalCancel = () => {
    handleStopUpload(); // Ensure the current upload is canceled
    setShowUpload(false);
    setReloadDocumentTab(Math.random());
  };

  const handleSave = (uploadType: string) => {
    const uploadAllFiles = async () => {
      setUploadingStatus('Uploading');
      const accessToken = store.getState().auth.accessToken;

      for (const [index, file] of files.entries()) {
        abortController.current = new AbortController(); // Initialize a new AbortController for each file

        if (uploadType === 'Upload' || filesUploadStatus[index] === 'Upload Failed') {
          try {
            setFilesUploadStatus((filesUploadStatus: any) => filesUploadStatus.map((fileStatus: string, internalIndex: number) =>
              internalIndex === index ? "Uploading" : fileStatus));
            let bodyFormData = new FormData();
            bodyFormData.append('file', file.originFileObj);

            await axios.post(
              `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/prospectDoc/uploadNew?prospectDocId=${documentActionTargetId}`,
              bodyFormData,
              {
                headers: {
                  "Authorization": `Bearer ${accessToken}`,
                  'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent: any) => {
                  const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                  setUploadingPercent(percentCompleted);
                },
                signal: abortController.current.signal // Assign signal to axios request
              }
            );

            setFilesUploadStatus((filesUploadStatus: any) => filesUploadStatus.map((fileStatus: string, internalIndex: number) =>
              internalIndex === index ? "Upload Completed" : fileStatus));

          } catch (error) {
            setFilesUploadStatus((filesUploadStatus: any) => filesUploadStatus.map((fileStatus: string, internalIndex: number) =>
              internalIndex === index ? "Upload Failed" : fileStatus));
            console.error("Error uploading document with error,id: ", error, index);
          }
          setUploadingPercent(0);
        }
      }

      setUploadingStatus('Completed');
    };

    uploadAllFiles();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
        handleDocumentModalCancel();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleUpload = (fileList: any) => {
    setFiles(fileList.filter((file: any) => !removedFilesUid.has(file.uid)));
  };

  const handleRemoveFile = (deletedFile: any) => {
    setRemovedFilesUid((prevSet) => {
      const newSet = new Set(prevSet);
      newSet.add(deletedFile.uid);
      return newSet;
    });
    setFiles(files.filter((file: any) => file.uid !== deletedFile.uid));
    setPreviewFile(null);
  };

  const handlePreview = (file: any) => {
    setPreviewFile(file);
  };

  const handlePreviewCancel = () => {
    setPreviewFile(null);
  };

  return (
    <div ref={cardRef}
      style={{
        width: previewFile === null ? '36rem' : '66rem',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '34rem',
        // opacity: hideUpload ? '0' : '1',
        zIndex: 2,
        borderRadius: '8px 8px 0 0',
        backgroundColor: '#F6FAFF',
        boxShadow: '0px 6px 24px 0px rgba(0, 0, 0, 0.25)'
      }} >
      
      <div style={{ display: 'flex', height: '85%', borderBottom: `${previewFile === null ? '0px' : '2px'} rgba(0, 0, 0, 0.20) solid` }}>
        <div style={{
          width: previewFile === null ? '100%' : '50%', height: '100%',
          borderRight: `${previewFile === null ? '0px' : '2px'} rgba(0, 0, 0, 0.20) solid`
        }}>
          <div style={{ margin: '20px 20px 10px 20px', height: '10%', display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography className="primary-heading">Upload</Typography>
            <Upload
              multiple={true}
              onChange={(info) => { 
                handleUpload(info.fileList);
                // setHideUpload(false);
               }}
              showUploadList={false}
              beforeUpload={() => false}
            >
              <Button
                type="primary"
                ghost
                className={"button-override primary"}
                ref={uploadRef} 
                // onClick={()=>setHideUpload(true)}
                // disabled={uploadingStatus !== 'Waiting'} 
                >
                <span style={{ marginRight: '0.4rem' }}>
                  <AddIcon />
                </span>
                Add Files
              </Button>
            </Upload>
          </div>
          <div style={{ height: '82%', margin: '10px 20px', paddingBottom: '10px', overflowY: 'auto', }}>
            {files.length === 0
              ?
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <NoDocuments />
                <p style={{ fontSize: '20px', fontWeight: '500', color: '#5C5C5C', marginLeft: '2rem', marginTop: '-5rem' }}> No Documents Available </p>
              </div>
              : (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {
                    files.map((file: any, internalIndex: number) => {
                      const fileType = file.name.split('.').pop();
                      const disablePreview = fileTypeSet.has(fileType);
                      return (
                        <div key={file.uid} style={{
                          display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                          marginBottom: '0.8rem', padding: '0.5rem 0.6rem', backgroundColor: "white", borderRadius: '8px'
                        }}>
                          <Tooltip title={file.name} style={{ zIndex: '3' }}>
                            <span style={{ maxWidth: '55%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                              {file.name}
                            </span>
                          </Tooltip>
                          <div>
                            {
                              uploadingStatus === 'Waiting' ?
                                <div style={{ display: 'flex' }}>
                                  <Button icon={<CrossIcon color='#FF4D4F' />} style={{ color: '#FF4D4F', border: 'none', boxShadow: 'none', fontWeight: '500' }} key="cancel" onClick={() => handleRemoveFile(file)}>
                                    Remove
                                  </Button>
                                  <div style={{ marginTop: '7px' }}><PipeIcon /> </div>
                                  {
                                    previewFile === file
                                      ?
                                      <Button icon={<DisablePreviewIcon color={'#2C66E3'} />}
                                        style={{
                                          color: '#2C66E3', backgroundColor: 'white', border: 'none', boxShadow: 'none', fontWeight: '500',
                                        }}
                                        key="confirm" onClick={() => handlePreviewCancel()}>
                                        { 'Hide Preview' }
                                      </Button>
                                      :
                                      <Button icon={disablePreview ? <DisablePreviewIcon color={'grey'} /> : <EyeIcon color={'#2C66E3'} />}
                                        disabled={disablePreview}
                                        style={{
                                          color: disablePreview ? 'grey' : '#2C66E3', backgroundColor: 'white', border: 'none', boxShadow: 'none', fontWeight: '500',
                                        }}
                                        key="confirm" onClick={() => handlePreview(file)}>
                                        {disablePreview ? 'Preview not supported' : 'Preview'}
                                      </Button>
                                  }
                                </div>
                                :
                                <div>
                                  {
                                    filesUploadStatus[internalIndex] === 'Uploading' ?
                                      <div style={{ display: 'flex', width: '190px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', color: '#14BE43', width: '150px' }}>
                                          <div style={{ display: 'flex' }}>
                                            <div style={{ marginRight: '0.5rem', marginTop: '0.1rem' }}>
                                              <UploadIcon />
                                            </div>
                                            <p>Uploading </p>
                                          </div>
                                          <div style={{ color: '#14BE43' }}>
                                            <Progress percent={uploadingPercent} strokeColor={'#14BE43'} showInfo={false} />
                                          </div>
                                        </div>
                                        <div style={{ width: '40px', color: '#14BE43', marginTop: '10px' }}>
                                          <p>{`${uploadingPercent} %`}</p>
                                        </div>
                                      </div>
                                      : filesUploadStatus[internalIndex] === 'Upload Completed' ?
                                        <div style={{ display: 'flex', color: '#14BE43' }}>
                                          <div style={{ marginRight: '0.5rem', marginTop: '0.04rem' }}>
                                            <TickIcon />
                                          </div>
                                          <p>Upload Completed</p>
                                        </div>
                                        : filesUploadStatus[internalIndex] === 'Upload Failed' ?
                                          <div style={{ display: 'flex', color: '#E39A2C' }}>
                                            <div style={{ marginRight: '0.5rem', marginTop: '0.1rem' }}>
                                              <CautionIcon />
                                            </div>
                                            <p>Upload Failed</p>
                                          </div>
                                          : <div style={{ display: 'flex', color: '#E39A2C' }}>
                                            <p>In Queue</p>
                                          </div>
                                  }
                                </div>
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                  {uploadingStatus === 'Success' ?
                    <div style={{ display: 'flex', justifyContent: 'center', color: '#14BE43', marginTop: '1rem' }}>
                      <div style={{ marginRight: '0.5rem', marginTop: '0.04rem' }}>
                        <TickIcon />
                      </div>
                      <p>All Files Uploaded Successfully</p>
                    </div> :
                    uploadingStatus === 'Failure' ?
                      <div style={{ display: 'flex', justifyContent: 'center', color: '#E39A2C', margin: '0.8rem 0rem' }}>
                        <div style={{ marginRight: '0.5rem', marginTop: '0.04rem' }}>
                          <CautionIcon />
                        </div>
                        <p>{`${uploadFailureCount} File could not be Uploaded`}</p>
                      </div> :
                      ''
                  }
                </div>
              )}
          </div>
        </div>
        {
          previewFile &&
          <div style={{ padding: '10px 10px 0px 5px', display: 'flex', width: '50%', maxHeight: '100%' }}>
            <FilePreview previewFile={previewFile} />
          </div>
        }
      </div>
      {
        uploadingStatus === 'Waiting' ?
          <div style={{ margin: '10px 20px 20px 20px', display: 'flex', height: '14%', justifyContent: 'flex-end' }}>
            <Button icon={<Cancel />} style={{ height: '2.5rem', color: '#FF4D4F', backgroundColor: 'rgba(255, 77, 79, 0.10)', borderColor: '#FF4D4F', fontWeight: '500' }} key="cancel" onClick={handleDocumentModalCancel}>
              Cancel
            </Button>
            <Button disabled={files.length === 0} icon={<UploadIcon color={files.length === 0 ? '#AFAFAF' : 'white'} />}
              style={{ color: files.length === 0 ? '#AFAFAF' : '#FFF', backgroundColor: files.length === 0 ? '#DCDCDC' : '#1777FF', height: '2.5rem', marginLeft: '0.6rem', fontWeight: '500' }}
              key="confirm" type="primary" onClick={() => handleSave('Upload')}>
              Upload
            </Button>
          </div> :
          uploadingStatus === 'Success' ?
            <div style={{ display: 'flex', flexDirection: 'column', height: '15%' }}>
              <div style={{ margin: '10px 20px 20px 20px', display: 'flex', justifyContent: 'flex-end', marginBottom: '50px' }}>
                <Button disabled={files.length === 0} icon={<TickIcon color={'#FFFFFF'} />}
                  style={{ color: '#FFFFFF', backgroundColor: '#1777FF', height: '2.5rem', marginLeft: '0.6rem', fontWeight: '400', fontSize: '14px' }}
                  key="confirm" type="primary" onClick={handleDocumentModalCancel}>
                  Finish Process
                </Button>
              </div>
            </div>
            :
            uploadingStatus === 'Failure' ?
              <div style={{ display: 'flex', flexDirection: 'column', height: '15%' }}>
                <div style={{ margin: '10px 20px 20px 20px', display: 'flex', justifyContent: 'flex-end', marginBottom: '50px' }}>
                  <div>
                    <Button disabled={files.length === 0} icon={<DocumentIcon color={'#1777FF'} />}
                      style={{ color: '#1777FF', backgroundColor: '#E8F1FF', height: '2.5rem', marginLeft: '0.6rem', fontWeight: '500', borderColor: '#1777FF' }}
                      key="confirm" type="primary" onClick={handleDocumentModalCancel}>
                      Proceed Without Retry
                    </Button>
                    <Button disabled={files.length === 0} icon={<RetryIcon color={'#FFFFFF'} />}
                      style={{ color: '#FFFFFF', backgroundColor: '#1777FF', height: '2.5rem', marginLeft: '0.6rem', fontWeight: '500' }}
                      key="confirm" type="primary" onClick={() => handleSave('Retry Upload')}>
                      Retry Upload
                    </Button>
                  </div>
                </div>
              </div> :
              uploadingStatus === 'Uploading' ?
                <div style={{ margin: '20px 20px ', display: 'flex', justifyContent: 'flex-end' }}>
                  <Button icon={<Cancel />} style={{
                    height: '2.5rem', color: '#FF4D4F',
                    backgroundColor: 'rgba(255, 77, 79, 0.10)', borderColor: '#FF4D4F', fontWeight: '500'
                  }}
                    key="cancel" onClick={handleStopUpload}>
                    Cancel Upload
                  </Button>
                </div> :
                ''
      }
    </div>
  );
}

export default UploadDocuments;
