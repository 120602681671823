import React, { useState, useEffect, useRef } from 'react';
import { Checkbox, Input, Button, Spin, Modal, Tooltip } from 'antd';
import { CheckOutlined, SearchOutlined } from '@ant-design/icons';
import './CustomSelectFilter.css';
const MODAL_FOOTER_HEIGHT: number = 40;
const INITITAL_SEARCH_MODEL_HEIGHT: number= 100;
interface CustomSearchSelectFilterProps {
  placeholder: string;
  fetchData: (searchQuery: string, isInfiniteScroll: boolean) => void; 
  selectedItems: any[];
  setSelectedItems: (selectedItems: string[]) => void;
  items: any[];
  setItems: (items: string[]) => void;
  setDisplayProspectSearchFilterScreen: (displayProspectSearchFilterScreen: boolean) => void;
  setCurrentPage: (currentPage: number) => void;
  currentPage: number;
  totalPageCount: number;
  setMainCurrentPage: (currentPage: number) => void;
  modalHeight: number;
  setModalHeight: (height: number) => void;
  searchKey : string;
  minSearchLength : number;
}

const CustomSearchSelectFilter: React.FC<CustomSearchSelectFilterProps> = ({
  placeholder,
  fetchData,
  selectedItems,
  setSelectedItems,
  items,
  setItems,
  setDisplayProspectSearchFilterScreen,
  setCurrentPage,
  currentPage,
  totalPageCount,
  setMainCurrentPage,
  modalHeight,
  setModalHeight,
  searchKey,
  minSearchLength
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedItemsInternal, setSelectedItemsInternal] = useState<string[]>(selectedItems);
  const [loading, setLoading] = useState<boolean>(false);
  const itemListRef = useRef<HTMLDivElement>(null);
  const SEARCH_KEY_MIN_LENGTH: number = minSearchLength;
  useEffect(() => {
    setSelectedItemsInternal(selectedItems);
  }, [selectedItems]);
  useEffect(()=>{
    if (searchValue.length < SEARCH_KEY_MIN_LENGTH) {
        return;
      }
    fetchData(searchValue, true);
  },[currentPage]);

  useEffect(()=>{
    setModalHeight((items.length !== 0 ? MODAL_FOOTER_HEIGHT : 0) + Math.min(260, INITITAL_SEARCH_MODEL_HEIGHT + Math.min(260, 25*items.length)));
  },[items]);
  
  const handleItemSelection = (itemId: string) => {
    const index = selectedItemsInternal.indexOf(itemId);
    if (index === -1) {
      setSelectedItemsInternal([...selectedItemsInternal, itemId]);
    } else {
      const updatedItems = [...selectedItemsInternal];
      updatedItems.splice(index, 1);
      setSelectedItemsInternal(updatedItems);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = async () => {
    setSelectedItemsInternal([]);
    if (searchValue.length < SEARCH_KEY_MIN_LENGTH) {
      return;
    }
    setLoading(true);
    try {
      await fetchData(searchValue, false);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = async () => {
    const { scrollTop, clientHeight, scrollHeight } = itemListRef.current!;
    if (scrollTop + clientHeight >= 0.90 * scrollHeight && !loading) {
      if(currentPage !== totalPageCount)
      setCurrentPage(currentPage + 1); // Increment page number
    }
};

  return (
    <div style={{ height: '100%', maxHeight: 260 }}>
      <div className="search-container">
        <Input
          placeholder={placeholder}
          value={searchValue}
          onChange={handleSearchChange}
          onPressEnter={searchValue.length < SEARCH_KEY_MIN_LENGTH ? undefined : handleSearch} // Handle search when Enter key is pressed only if search value length is sufficient
          style={{ borderColor: '#899499', borderWidth: '2px' }}
          suffix={
            <Tooltip title={searchValue.length < SEARCH_KEY_MIN_LENGTH ? `Search term must be at least ${SEARCH_KEY_MIN_LENGTH} characters` : ''}>
              <SearchOutlined
                style={{ color: '#1777FF', cursor: searchValue.length < SEARCH_KEY_MIN_LENGTH ? 'not-allowed' : 'pointer' }}
                onClick={searchValue.length < SEARCH_KEY_MIN_LENGTH ? undefined : handleSearch}
              />
            </Tooltip>
          }
        />
      </div>

      <div className="item-list" onScroll={handleScroll} ref={itemListRef}>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontSize: 14, padding: 1 }}>
            <Spin />
          </div>
        ) : (
          items.map(item => (
            <div key={item.id} className="item-item">
              <Checkbox
                checked={selectedItemsInternal.includes(item[searchKey])}
                onChange={() => handleItemSelection(item[searchKey] as string)}
                className="custom-checkbox"
              />
              <Tooltip title={item?.name}>
                    <span style={{ marginLeft: '8px', maxWidth: 190, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {item.name}
                    </span>
              </Tooltip>
            </div>
          ))
        )}
      </div>

      {
        items.length !== 0 && (
          <div style={{ margin: '10px', display: 'flex', justifyContent: 'flex-end', gap: '5px' }}>
        <Button
          onClick={() => {
            setSelectedItemsInternal([])
            setSelectedItems([]);
            setDisplayProspectSearchFilterScreen(false);
            setModalHeight(modalHeight-MODAL_FOOTER_HEIGHT);
            setItems([]);
          }}
          style={{
            textAlign: 'center',
            color: '#1777FF',
            backgroundColor: '#F6FAFF',
            fontWeight: 'bold',
            flex: '1',
            height: '40px',
            border: '2px solid #1777FF', // Add border style here
          }}
        >
          Reset
        </Button>

        <Button
          onClick={() => {
            setMainCurrentPage(1);
            setSelectedItems(selectedItemsInternal);
            setDisplayProspectSearchFilterScreen(false);
          }}
          style={{
            textAlign: 'center',
            color: '#FFFFFF',
            fontWeight: 'bold',
            backgroundColor: '#1777FF',
            borderColor: '#1777FF',
            flex: '1',
            height: '40px',
          }}
        >
          <CheckOutlined style={{
              fontSize: "18px",
              color: "#FFFFFF",
              strokeWidth: "50", // --> higher value === more thickness the filled area
              stroke: "#FFFFFF",
              }} 
            />
          Confirm
        </Button>
      </div>
        )
      }
    </div>
      
  );
};

export default CustomSearchSelectFilter;
