import React, { useState, useEffect } from 'react';
import { Checkbox, Input, Button, Tooltip } from 'antd';
import { CheckOutlined, SearchOutlined } from '@ant-design/icons';
import './CustomSelectFilter.css'; // Import custom CSS for styling
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { getStageStyles } from './stageUtils'; 

interface Item {
  id: string;
  name: string;
}

interface CustomSelectFilterProps {
  itemsList: Item[];
  selectedItems: string[]; // Add selectedItems prop to receive previously selected items
  setSelectedItems: (selectedItems: string[]) => void;
  placeholder: string;
  setMainCurrentPage: (currentPage: number) => void;
}

const CustomSelectFilter: React.FC<CustomSelectFilterProps> = ({ itemsList, selectedItems: initialSelectedItems, setSelectedItems, placeholder, setMainCurrentPage }) => {
  const [selectedItemsInternal, setSelectedItemsInternal] = useState<string[]>(initialSelectedItems); // Initialize state with initialSelectedItems
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false); // State for select all checkbox
  const [searchValue, setSearchValue] = useState<string>(''); // State for search input value

  useEffect(()=>{
    if(selectedItemsInternal.length === itemsList.length)
      setSelectAllChecked(true);
    else setSelectAllChecked(false);
  },[selectedItemsInternal])
  // Function to handle item selection
  const handleItemSelection = (itemId: string) => {
    const index = selectedItemsInternal.indexOf(itemId);
    if (index === -1) {
      // Item not selected, add it to the selected items
      setSelectedItemsInternal([...selectedItemsInternal, itemId]);
    } else {
      // Item already selected, remove it from the selected items
      const updatedItems = [...selectedItemsInternal];
      updatedItems.splice(index, 1);
      setSelectedItemsInternal(updatedItems);
    }
  };


  // Function to handle select all checkbox change
  const handleSelectAllChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    setSelectAllChecked(checked);
    if (checked) {
      // If select all is checked, set all items as selected
      setSelectedItemsInternal(itemsList.map(item => item.id));
    } else {
      // If select all is unchecked, clear all selected items
      setSelectedItemsInternal([]);
    }
  };

  // Function to handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  // Function to handle search button click
  const handleSearch = () => {
    // Perform search or any other action here
    setMainCurrentPage(1);           
    setSelectedItems(selectedItemsInternal);
  };

  // Update selectedItemsInternal when initialSelectedItems change
  useEffect(() => {
    setSelectedItemsInternal(initialSelectedItems);
  }, [initialSelectedItems]);

  const isAllSelected = ()=>{
    if(selectedItemsInternal.length===itemsList.length)
      return true;
    else return false;  
  }
  return (
    <div className="custom-select-filter">
      <div className="item-list">
        {itemsList.map((item :any, index) => {
          let nameStyle = {}; // Initialize nameStyle
          if (placeholder === 'Stages') {
            const { color, backgroundColor } = getStageStyles(parseInt(item.id,10));
            nameStyle = { color: color, backgroundColor: backgroundColor, marginLeft:'10px' }; // Assign styles
          }
          if (placeholder === 'Status') {
            const color =item.color, backgroundColor =  item.backgroundColor;
            nameStyle = { color: color, backgroundColor: backgroundColor, marginLeft:'10px' }; // Assign styles
          }
          else if (placeholder === 'Status') {
            const color = item?.textColor;
            const backgroundColor = item?.backgroundColor;
            nameStyle = { color: color, backgroundColor: backgroundColor, marginLeft:'10px' };
          }
          return (
            <>
              {index === 0 && (
                <div key={item.id} className="item-item" >
                <Checkbox
                    checked={selectAllChecked}
                    onChange={handleSelectAllChange}
                    className="custom-checkbox"
                />
                <span style={{maxWidth: 190, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontWeight: 'bold', fontSize: '1.1rem'}}>{placeholder}</span>
              </div>
              )}
              <div className="item-item">
                  <Checkbox
                    checked={selectedItemsInternal.includes(item.id)}
                    onChange={() => handleItemSelection(item.id)}
                    className="custom-checkbox" // Apply custom checkbox style class
                  />
                  {
                    placeholder !== 'Tenure' &&
                    <Tooltip title={item?.name}>
                      <span style={{ maxWidth: 190, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', ...nameStyle }}>
                          {item.name}
                      </span>
                    </Tooltip>
                  }
                  {
                    placeholder === 'Tenure' &&
                    <Tooltip title={item?.inMonth}>
                      <span style={{ maxWidth: 190, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', ...nameStyle }}>
                          {item.inMonth}
                      </span>
                    </Tooltip>
                  }
              </div>
            </>
          );
        })}
      </div>
      {
        itemsList.length !== 0 && (
      <div style={{ margin: '10px', display: 'flex', justifyContent: 'flex-end', gap: '5px' }}>
        <Button
            onClick={() => {
              setMainCurrentPage(1);            
              setSelectedItemsInternal([]);
              setSelectedItems([]);
            }}
            style={{
              textAlign: 'center',
              color: '#1777FF',
              backgroundColor: '#F6FAFF',
              width:'50%',
              fontWeight: 'bold',
              height: '40px',
              border: '2px solid #1777FF', // Add border style here
          }}
          >
            Reset
        </Button>
        <Button 
          style={{
            textAlign: 'center',
            color: '#FFFFFF',
            backgroundColor: '#1777FF',
            fontWeight: 'bold',
            borderColor: '#1777FF',
            width:'50%',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            height: '40px',
          }}
          type="primary" 
          onClick={handleSearch}
          >
            <CheckOutlined style={{
              fontSize: "16px",
              color: "#FFFFFF",
              strokeWidth: "50", // --> higher value === more thickness the filled area
              stroke: "#FFFFFF",
              }} 
            />

            <span style={{marginLeft:'5px'}}>Confirm</span>
        </Button>
      </div>)
      }
      
    </div>
  );
};

export default CustomSelectFilter;
