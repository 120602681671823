import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Form, Typography } from 'antd';
import { ReactComponent as DownMark } from '../../../../utils/SVGs/DownMark.svg';
import { ReactComponent as RightMark } from '../../../../utils/SVGs/RightMark.svg';
import { formatIndianRupee } from '../formUtils';

interface TotalCurrentAssetsProps {
    FYCount: number;
    form: any; // Form instance from Ant Design Form
    colSpan: number;
    labelColSpan: number;
    updateTotalCnt: number;
    viewModeActive: boolean;
}

const TotalCurrentAssetsComponent: React.FC<TotalCurrentAssetsProps> = ({ FYCount, form, colSpan, updateTotalCnt, viewModeActive, labelColSpan }) => {
    const [totalCurrentAssets, setTotalCurrentAssets] = useState<number[]>(Array(FYCount).fill(0));
    const [collapseCurrentAssetsFields, setCollapseCurrentAssetsFields] = useState<boolean>(false);
    const INR_SYMBOL: string = '\u20B9';
    
    useEffect(() => {
        const values = form.getFieldsValue(true);
        if(values.totalCurrentAssets)
            setTotalCurrentAssets(values.totalCurrentAssets);
    }, [updateTotalCnt]);

    const handleFieldChange = () => {
        const values = form.getFieldsValue();
        const updatedTotalCurrentAssets = values.inventory.map((item: number, i: number) =>
            Number(item) +
            Number(values.tradeReceivables[i]) +
            Number(values.cashAndBankBalances[i]) +
            Number(values.shortTermLoanAndAdvances[i]) +
            Number(values.otherCurrentAssets[i])
        );
        form.setFieldsValue({ totalCurrentAssets: updatedTotalCurrentAssets });
        setTotalCurrentAssets(updatedTotalCurrentAssets);
    };

    return (
        <>
            <Row
                gutter={16}
                style={{ marginTop: '20px', paddingTop: '10px', paddingBottom: '20px', borderBottom: '1px #E8E8E8 solid' }}
            >
                <Col span={labelColSpan}>
                    <span onClick={() => setCollapseCurrentAssetsFields((oldState) => !oldState)}>
                        {collapseCurrentAssetsFields ? <RightMark /> : <DownMark />}
                    </span>
                    <span style={{ fontSize: '14px', fontWeight: '600', marginLeft: '10px' }}>Total Current Assets</span>
                </Col>
                {totalCurrentAssets.map((value, ind) => (
                    <Col span={colSpan} key={ind}>
                        <Typography style={{ fontSize: 14, fontWeight: '600', paddingLeft: '10px' }}>
                            <span>{`${INR_SYMBOL} ${formatIndianRupee(parseFloat(value.toFixed(2)))}`}</span>
                        </Typography>
                    </Col>
                ))}
            </Row>
            {!collapseCurrentAssetsFields && (
                <>
                    <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px',  borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                        <Col span={labelColSpan}><p style={{ marginLeft: '20px' }}>Inventory</p></Col>
                        {Array.from({ length: FYCount }, (_, ind) => (
                            <Col span={colSpan} key={ind}>
                                {viewModeActive ? (
                                    <Typography style={{ paddingLeft: '10px' }}>
                                        <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue(['inventory', ind]))}`}</span>
                                    </Typography>
                                ) : (
                                    <Form.Item
                                        name={['inventory', ind]}
                                        rules={[{ required: true, message: 'Please enter Inventory value' }]}
                                    >
                                        <Input type="number" onChange={handleFieldChange} />
                                    </Form.Item>
                                )}
                            </Col>
                        ))}
                    </Row>

                    <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px',  borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                        <Col span={labelColSpan}><p style={{ marginLeft: '20px' }}>Trade Receivables</p></Col>
                        {Array.from({ length: FYCount }, (_, ind) => (
                            <Col span={colSpan} key={ind}>
                                {viewModeActive ? (
                                    <Typography style={{ paddingLeft: '10px' }}>    
                                        <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue(['tradeReceivables', ind]))}`}</span>
                                    </Typography>
                                ) : (
                                    <Form.Item
                                        name={['tradeReceivables', ind]}
                                        rules={[{ required: true, message: 'Please enter Trade Receivables value' }]}
                                    >
                                        <Input type="number" onChange={handleFieldChange} />
                                    </Form.Item>
                                )}
                            </Col>
                        ))}
                    </Row>

                    <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px',  borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                        <Col span={labelColSpan}><p style={{ marginLeft: '20px' }}>Cash & Bank Balances</p></Col>
                        {Array.from({ length: FYCount }, (_, ind) => (
                            <Col span={colSpan} key={ind}>
                                {viewModeActive ? (
                                    <Typography style={{ paddingLeft: '10px' }}>
                                        <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue(['cashAndBankBalances', ind]))}`}</span>
                                    </Typography>
                                ) : (
                                    <Form.Item
                                        name={['cashAndBankBalances', ind]}
                                        rules={[{ required: true, message: 'Please enter Cash And Bank Balances value' }]}
                                    >
                                        <Input type="number" onChange={handleFieldChange} />
                                    </Form.Item>
                                )}
                            </Col>
                        ))}
                    </Row>

                    <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px',  borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                        <Col span={labelColSpan}><p style={{ marginLeft: '20px' }}>Short Term Loan & Advances</p></Col>
                        {Array.from({ length: FYCount }, (_, ind) => (
                            <Col span={colSpan} key={ind}>
                                {viewModeActive ? (
                                    <Typography style={{ paddingLeft: '10px' }}>
                                        <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue(['shortTermLoanAndAdvances', ind]))}`}</span>
                                    </Typography>
                                ) : (
                                    <Form.Item
                                        name={['shortTermLoanAndAdvances', ind]}
                                        rules={[{ required: true, message: 'Please enter Short Term Loan And Advances value' }]}
                                    >
                                        <Input type="number" onChange={handleFieldChange} />
                                    </Form.Item>
                                )}
                            </Col>
                        ))}
                    </Row>

                    <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px',  borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                        <Col span={labelColSpan}><p style={{ marginLeft: '20px' }}>Other Current Assets</p></Col>
                        {Array.from({ length: FYCount }, (_, ind) => (
                            <Col span={colSpan} key={ind}>
                                {viewModeActive ? (
                                    <Typography style={{ paddingLeft: '10px' }}>
                                        <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue(['otherCurrentAssets', ind]))}`}</span>
                                    </Typography>
                                ) : (
                                    <Form.Item
                                        name={['otherCurrentAssets', ind]}
                                        rules={[{ required: true, message: 'Please enter Other Current Assets value' }]}
                                    >
                                        <Input type="number" onChange={handleFieldChange} />
                                    </Form.Item>
                                )}
                            </Col>
                        ))}
                    </Row>
                </>
            )}
        </>
    );
};

export default TotalCurrentAssetsComponent;
