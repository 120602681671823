import React, { useEffect, useState } from "react";
import { Form, Input, Button, Divider, DatePicker, Row, Col, message, Select, Modal } from "antd";
import { authSelector } from "../../../features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { UserOutlined } from '@ant-design/icons';
import axios from "axios";
import { format } from 'date-fns';
import { Option } from "antd/es/mentions";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import {
    fetchProspectsData,
    fetchCitiesData,
    fetchVehiclesData,
    fetchMakersData,
    fetchModelsData,
    fetchVariantsData,    
} from '../Quote/QuoteUtils/dataFetchingUtils';

import {
  setProspects,
  setCities,
  setVehicles,
  setMakers,
  setModels,
  setVariants,
} from '../../../features/quoteSlices/quoteSlice';
import SelectSearch from "../../../utils/SelectSearch";

interface FormDetails {
    assetNo: number,
    regNo: number,
    deviceId: number,
    chassisNo: number,
    vtsTypeId: number,
    vehicleId: number,
    makerId: number,
    modelId: number,
    variantId: number,
    clientId: number,
    locationId: string,
    subscriptionStart:string,
    subscriptionEnd:string,
    deliveryDate:string,
    contractedMonth:number,
    contractedMileage:number,
}

const initFormState = {
    assetNo: 0,
    regNo: 0,
    deviceId: 0,
    chassisNo: 0,
    vtsTypeId: 0,
    vehicleId: 0,
    makerId: 0,
    modelId: 0,
    variantId: 0,
    clientId: 0,
    locationId: "",
    subscriptionStart:"",
    subscriptionEnd:"",
    deliveryDate:"",
    contractedMonth:0,
    contractedMileage:0,
}

      
const CreateFleet = () => {      
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [formData, setFormData] = useState<FormDetails>(initFormState);

    const [vehicleChoice, setVehicleChoice] = useState<any>('vehicle');
    const [selectedVehicleId,setSelectedVehicleId] = useState<number | null>(null);
    const [selectedMakeId,setSelectedMakeId] = useState<number | null>(null);
    const [selectedModelId, setSelectedModelId] = useState<number | null>(null); 
    const [selectedVariantId, setSelectedVariantId] = useState<number | null>(null); 
    const [selectedVtsTypeId, setSelectedVtsTypeId] = useState<number | null>(null); 
    const [vtsTypes, setVtsTypes] = useState<any>([]); 
    
    const vehicles = useSelector((store: any) => store.quote.vehicles);
    const makers = useSelector((store: any) => store.quote.makers);
    const models = useSelector((store: any) => store.quote.models);
    const variants = useSelector((store: any) => store.quote.variants);
    const prospects = useSelector((store: any) => store.quote.prospects);
    const cities = useSelector((store: any) => store.quote.cities);

    const dispatch = useAppDispatch(); 
    const navigate = useNavigate();
    const auth = useAppSelector(authSelector);
    const formValues = form.getFieldsValue();
   
    useEffect(()=>{
        const submitProspectForm = async () => {
            try {
                const reqObj = {
                    ...formData,
                    subscriptionStart: formData.subscriptionStart ? format(new Date(formData.subscriptionStart), 'yyyy-MM-dd') : null,
                    subscriptionEnd: formData.subscriptionEnd ? format(new Date(formData.subscriptionEnd), 'yyyy-MM-dd') : null,
                    deliveryDate: format(new Date(formData.deliveryDate), 'yyyy-MM-dd'),
                    vehicleDetail: JSON.stringify({
                        id: formData.vehicleId, 
                        name: vehicles.find((make:any)=> make.id ==formData.vehicleId)?.name,
                    }),
                    makerDetail: JSON.stringify({
                        id:formData.makerId, 
                        name: makers.find((make:any)=> make.id ==formData.makerId)?.name,
                    }),
                    modelDetail: JSON.stringify({
                        id:formData.modelId, 
                        name: models.find((model:any)=> model.id ==formData.modelId)?.name,
                    }),
                    variantDetail: JSON.stringify({
                        id:formData.variantId, 
                        name: variants.find((variant:any)=> variant.id ==formData.variantId)?.name,
                    }),
                    clientDetail: JSON.stringify({
                        id:formData.clientId, 
                        name: prospects.find((client:any)=> client.id ==formData.clientId)?.name,
                    }),
                    locationDetail: JSON.stringify({
                        id:formData.locationId, 
                        name: cities.find((city:any)=> city.id ==formData.locationId)?.cityName,
                    }),
                    vtsTypeDetails: JSON.stringify({
                        id:formData.vtsTypeId, 
                        name: vtsTypes.find((vtsType:any)=> vtsType.id ==formData.vtsTypeId)?.name,
                    })
                    
                }

                const response = await axios.post(
                  `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/reportservice/fleet/addFleet`,
                  reqObj, {
                    headers: {
                        "Authorization": `Bearer ${auth.accessToken}`
                    }
                  }
                );
                
                messageApi.success('Asset Created!');
                setTimeout(()=>{
                    navigate(`/dashboard/asset`);
                },1000);
                
            } catch (error:any) {
                console.error('Error In Adding Asset:', error);
                // Extract error message from the API response
                const errorMessage = (error.response && error.response.data && error.response.data.errors && 
                    error.response.data.errors[0]) || 'An error occurred';

                // Display error in a modal (pop-up)
                Modal.error({
                    title: 'Error',
                    content: errorMessage,
                });
            }
        };
        if(formData?.assetNo!==0)
            submitProspectForm();
    },[formData]);

    useEffect(()=>{
        const fetchVtsTypes = async ()=>{
            try{
                const response = await axios.get(
                    `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/reportservice/fleet/vtsType`,
                    {
                        headers: {
                            "Authorization": `Bearer ${auth.accessToken}`
                        }
                    }
                );
                setVtsTypes(response?.data?.vtsTypes);
            } catch (error) {
                console.error('Error In Fetching VTS Types:', error);
            }
        }
        fetchVtsTypes();
       
        async function fetchAllReduxData() {
            dispatch(setProspects(await fetchProspectsData(auth)));
            dispatch(setCities(await fetchCitiesData(auth)));
            dispatch(setVehicles(await fetchVehiclesData(auth)));
        }
        fetchAllReduxData();
     
    },[]);
    const onFinish = async (values: FormDetails) => {
        setFormData(values);
        
    };
     
    function disabledPastDate(current: any) {
         const subscriptionStartValue = form.getFieldValue('subscriptionStart');
         // Disable dates before the selected hpaFrom date
         return current && current < subscriptionStartValue;
    } 
    
    useEffect(()=>{
        if(vehicleChoice == `make`){
         form.setFieldsValue({
           ...formValues,
           makerId: null,
           modelId: null,
           variantId: null,
         });  
       } else if(vehicleChoice == `model`){
         form.setFieldsValue({
           ...formValues,
           modelId: null,
           variantId: null,
         });  
       } 
       else if(vehicleChoice == `variant`){
        form.setFieldsValue({
          ...formValues,
          variantId: null,
        });  
      } 
     }, [vehicleChoice]);

    const handleVehicleDetailChange = (value: any, fieldName: any)=> {
        if(fieldName === "vehicleId" ){
            setSelectedMakeId(null); 
            setSelectedModelId(null); 
            setSelectedVariantId(null); 
            setSelectedVehicleId(value);
          }
          else if(fieldName === "makerId" ){
            setSelectedModelId(null); 
            setSelectedVariantId(null); 
            setSelectedMakeId(value); 
          }
          else if(fieldName === "modelId" ){
            setSelectedVariantId(null); 
            setSelectedModelId(value); 
          }
          else if(fieldName === "variantId" ){
            setSelectedVariantId(value); 
          }
          else if(fieldName === "vtsType" ){
            setSelectedVtsTypeId(value); 
          }

    } 

    useEffect(() => {
        const fetchData = async () => {
          try {
            dispatch(setMakers(await fetchMakersData(auth, selectedVehicleId)));
            dispatch(setModels([]));
            dispatch(setVariants([]));            
            setSelectedMakeId(null);
            setSelectedModelId(null);
            setSelectedVariantId(null);
            setVehicleChoice('make');
          } catch (error) {
            console.log(`Got error while fetching makers data`,error);
          }
        };

        if(selectedVehicleId)
          fetchData(); 
           
    },[selectedVehicleId]);
    
    useEffect(() => {
        const fetchData = async () => {
          try {
            dispatch(setModels(await fetchModelsData(auth, selectedMakeId)));
            dispatch(setVariants([]));
            setSelectedModelId(null);
            setSelectedVariantId(null);
            setVehicleChoice('model');
          } catch (error) {
            console.log(`Got error while fetching models data`,error);
          }
        };
        
        if(selectedMakeId)  
          fetchData();   
           
      },[selectedMakeId]);    
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            dispatch(setVariants(await fetchVariantsData(auth, selectedModelId)));
            setSelectedVariantId(null);
            setVehicleChoice('variant');
          } catch (error) {
            console.log(`Got error while fetching variant data`,error);
          }
        };
        
        if(selectedModelId)  
          fetchData();   
           
      },[selectedModelId]); 
    
    const handleCityIdSelect = async (cityId:any)=>{
        console.log(`selected city id=`,cityId);
        
    }
    return (
        <React.Fragment>
        {contextHolder}
        <Form 
            form={form}
            onFinish={onFinish}
            layout="vertical"
            className="form-container"
        >
        <h4>Add Fleet</h4>
            <Row className="" style={{display:"flex", justifyContent:"space-around"}}>
                <Col className="form-column "  >
                    <Form.Item
                        label="Asset Number"
                        name="assetNo"
                        rules={[{ required: true, message: 'Please Enter Asset Number!' }]}
                    >
                        <Input placeholder="assetNo" />
                    </Form.Item>
                    <Form.Item
                        label="Registration Number"
                        name="regNo"
                        rules={[{ required: true, message: 'Please enter Registration Number!' }]}
                    >
                        <Input placeholder="regNo" />
                    
                    </Form.Item>
                    <Form.Item
                        label="Device Id"
                        name="deviceId"
                        rules={[{ required: true, message: 'Please enter Device Id!' }]}
                    >
                        <Input placeholder="deviceId" />
                    
                    </Form.Item>
                    <Form.Item
                        label="Chassis No"
                        name="chassisNo"
                        rules={[{ required: true, message: 'Please Enter Chassis No!' }]}
                        >
                         <Input placeholder="chassisNo" />
                    
                    </Form.Item>
                    <Form.Item
                        label="Vts Type"
                        name="vtsTypeId"
                        rules={[{ required: true, message: 'Please Enter Vts Type!' }]}
                        >
                         <Select onChange={(value)=>handleVehicleDetailChange(value,'vtsType')}>
                                {
                                    vtsTypes?.map((value: any) => (
                                    <Option key={value.id} value={value.id}>
                                        {value.name}
                                    </Option>
                                    ))
                                }
                            </Select>
                    </Form.Item>
                    <Form.Item
                        label="Client"
                        name="clientId"
                        rules={[{ required: true, message: 'Please Enter Client Id!' }]}
                        >
                         <SelectSearch name={'clientId'} placeholder={'Client'} options={prospects.map((id:any)=>{
                            return {
                                id: id?.id,
                                name: id?.name,
                            }
                            }) 
                        } onSelect={handleCityIdSelect}/>
                    </Form.Item>
                    <Form.Item
                        label="Contracted Month"
                        name="contractedMonth"
                        rules={[{ required: true, message: 'Please enter Contracted Month!' }]}
                    >
                        <Input placeholder="Contracted Month" />
                    </Form.Item>
                    <Form.Item
                        label="Contracted Mileage(KMS)"
                        name="contractedMileage"
                        rules={[{ required: true, message: 'Please enter Contracted Mileage Per Month(KMS)!' }]}
                    >
                        <Input placeholder="Contracted Mileage Per Month(KMS)" />
                    </Form.Item>
                    
                </Col>

                <Col className="form-column" >
                    <Form.Item
                        label="Vehicle Category"
                        name="vehicleId"
                        rules={[{ required: true, message: 'Please Enter Vehicle Category!' }]}
                        >
                            <Select onChange={(value)=>handleVehicleDetailChange(value,'vehicleId')}>
                                {
                                    vehicles?.map((value: any) => (
                                    <Option key={value.id} value={value.id}>
                                        {value.name}
                                    </Option>
                                    ))
                                }
                            </Select>
                         
                    </Form.Item>
                    <Form.Item
                        label="Make"
                        name="makerId"
                        rules={[{ required: true, message: 'Please Enter Make!' }]}
                        >
                            <Select onChange={(value)=>handleVehicleDetailChange(value,'makerId')}>
                                {
                                    makers?.map((value: any) => (
                                    <Option key={value.id} value={value.id}>
                                        {value.name}
                                    </Option>
                                    ))
                                }
                            </Select>
                         
                    </Form.Item>
                    <Form.Item
                        label="Model"
                        name="modelId"
                        rules={[{ required: true, message: 'Please Enter Model!' }]}
                        >
                         <Select onChange={(value)=>handleVehicleDetailChange(value,'modelId')}>
                                {
                                    models?.map((value: any) => (
                                    <Option key={value.id} value={value.id}>
                                        {value.name}
                                    </Option>
                                    ))
                                }
                            </Select>
                    </Form.Item>
                    <Form.Item
                        label="Variant"
                        name="variantId"
                        rules={[{ required: true, message: 'Please Enter Variant!' }]}
                        >
                         <Select onChange={(value)=>handleVehicleDetailChange(value,'variantId')}>
                                {
                                    variants?.map((value: any) => (
                                    <Option key={value.id} value={value.id}>
                                        {value.name}
                                    </Option>
                                    ))
                                }
                            </Select>
                    </Form.Item>
                    <Form.Item
                        label="Location"
                        name="locationId"
                        rules={[{ required: true, message: 'Please Enter Location!' }]}
                        >
                            <SelectSearch name={'locationId'} placeholder={'Location'} options={cities.map((id:any)=>{
                                return {
                                    id: id?.id,
                                    name: id?.cityName,
                                }
                                }) 
                            } onSelect={handleCityIdSelect}/>
                    </Form.Item>
                    
                    <Form.Item
                        label="Subscription Start"
                        name="subscriptionStart"
                    >
                        <DatePicker
                            style={{ width: "100%"}}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Subscription End"
                        name="subscriptionEnd"
                    >
                        <DatePicker
                            style={{ width: "100%"}}
                            disabledDate={disabledPastDate}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Delivery Date"
                        name="deliveryDate"
                        rules={[{ required: true, message: 'Please enter Delivery Date' }]}
                    
                    >
                        <DatePicker
                            style={{ width: "100%"}}
                        />
                    </Form.Item>
                </Col>
            </Row>
       
        <Form.Item>
        <Button type="primary" htmlType="submit">
            Submit
        </Button>
        </Form.Item>
        </Form>
        </React.Fragment>
    );
};

export default CreateFleet;