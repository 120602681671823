import React, { useRef } from 'react';
import * as ExcelJS from 'exceljs';
import { Button, Table } from 'antd';
import TwoColumnThreeRowTable from './QuoteUtils/TwoColumnThreeRowTable';
import '../../../styles/tableStyles.css';

interface QuoteFinalProps {
  quoteFinalListOrig: Array<any>;
  quoteFinalDetail: any;
}

const QuoteFinal: React.FC<QuoteFinalProps> = ({ quoteFinalListOrig, quoteFinalDetail }) => {
  const excelLink = useRef<HTMLAnchorElement | null>(null);
  const quoteFinalList = quoteFinalListOrig.map((row:any)=>{
    return [row[0],row[1],row[2],row[3]===''?row[3]:parseInt(row[3],10)];
  })
  console.log(`quoteFinalDetail=%o`,quoteFinalDetail);
  console.log(`quoteFinalList=%o`,quoteFinalList);
  let dataSource = quoteFinalList.map((item: any, index: any) => ({
    col1: item[0],
    col2: item[1],
    col3: item[2],
    col4: item[3],
    isBold: index >= quoteFinalList.length - 4,
  }));

    const handleDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Quote');

    worksheet.addRow(['Quote No. ', quoteFinalDetail.quoteNo || 'Sample Quote', 'RV',quoteFinalDetail.rvPercentage+'%']).font = { bold: true };
    worksheet.addRow(['Prospect Name', quoteFinalDetail.prospectName, 'IRR',quoteFinalDetail.irr+'%']).font = { bold: true };
    worksheet.addRow(['City',quoteFinalDetail.city]).font = { bold: true };
    worksheet.addRow(['Vehicle Details', quoteFinalDetail.vehicleDetails.replace(/\s+/g, '')]).font = { bold: true };
    worksheet.addRow(['Tenure / Contracted Mileage', quoteFinalDetail.tenureAndMileage]).font = { bold: true };
    worksheet.addRow([]); // Blank row
    worksheet.addRow(['Component', 'Description', 'Total', 'Monthly']).font = { bold: true };

    // Add data
    quoteFinalList.forEach((item, ind) => {
      const row = worksheet.addRow(item);

      // Make the third column (index 2, 0-based index) bold
      
      if(ind===quoteFinalList.length-2){
        worksheet.addRow([]);
      }
      else if(ind===quoteFinalList.length-1){
        row.font = { bold: true };
      }
    });
    
    // Set column widths
    worksheet.columns = [
      { width: 30 },
      { width: 30 },
      { width: 15 },
      { width: 12 }
    ];

    // Create a Blob from the ExcelJS workbook
    const blob = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(new Blob([blob]));

    // Trigger download
    if (excelLink.current) {
      excelLink.current.href = url;
      excelLink.current.download = quoteFinalDetail.quoteNo ? `${quoteFinalDetail.prospectName}_${quoteFinalDetail.quoteNo}.xlsx`:`${quoteFinalDetail.prospectName}.xlsx`;
      excelLink.current.click();
    }
  };

  if (quoteFinalList.length === 0 || quoteFinalDetail.length === 0) return <></>;

  const columns = [
    {
      title: <span className="bold-text">Component</span>,
      dataIndex: 'col1',
      key: 'col1',
      width: '30%',
    },
    {
      title: <span className="bold-text">Description</span>,
      dataIndex: 'col2',
      key: 'col2',
      width: '30%',
    },
    {
      title: <span className="bold-text">Total</span>,
      dataIndex: 'col3',
      key: 'col3',
      width: '20%',
    },
    {
      title: <span className="bold-text">Monthly</span>,
      dataIndex: 'col4',
      key: 'col4',
      width: '20%',
    },
  ];

  return (
    <div style={{ width: '85%' }}>
      <Button type='primary' style={{ marginBottom: '1rem' }} onClick={handleDownload}>
        Download Excel
      </Button>
      <a href="#" ref={excelLink} style={{ display: 'none' }} />
        <a
          href="#"
          ref={excelLink}
          style={{ display: 'none' }} // Hide the anchor element
        />
        <TwoColumnThreeRowTable
          prospectName={quoteFinalDetail.prospectName}
          vehicleDetails={quoteFinalDetail.vehicleDetails}
          tenureAndMileage={quoteFinalDetail.tenureAndMileage}
        />
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          rowClassName={(record) => (record.isBold ? 'bold-row' : '')}
        />

    </div>
  );
};

export default QuoteFinal;
