import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col } from 'antd';
import EquityComponent from './EquityComponent';
import NonCurrentLiabilitiesComponent from './NonCurrentLiabilitiesComponent';
import TotalCurrentLiabilitiesComponent from './TotalCurrentLiabilitiesComponent';
import TotalCurrentAssetsComponent from './TotalCurrentAssetsComponent';
import { ReactComponent as ArrowDown } from '../../../../utils/SVGs/ArrowDown.svg';
import { ReactComponent as ArrowRight } from '../../../../utils/SVGs/ArrowRight.svg';
import { formatFormData, formatUpdateFormData } from '../formUtils';
import { sendFormData, sendUpdateFormData } from '../apiCalls';
import OtherFinancialSpreadingComponent from './OtherFinancialSpreadingComponent';
import OperationFinancialSpreadingComponent from './OperationFinancialSpreadingComponent';

interface FinancialSpreadingProps {
    FYCount: number;
    calenderYear: boolean;
    creditRequestId: number;
    assessmentFormFlag: number;
    financialSpreading: any;
    submitForm: boolean;
    setSubmitForm: (flag: boolean)=>void;
    setReloadComponentCnt: (value:number) => void;
    parentAssessmentFrom: boolean;
}

const FinancialSpreading: React.FC<FinancialSpreadingProps> = ({ FYCount, calenderYear, creditRequestId, assessmentFormFlag, 
        financialSpreading, submitForm, setSubmitForm, setReloadComponentCnt, parentAssessmentFrom }) => {
    const [collapseFinancialSpreading, setCollapseFinancialSpreading] = useState<boolean>(true);
    const [colSpan, setColSpan] = useState<number>(0);
    const [labelColSpan, setLabelColSpan] =  useState<number>(5);
    const [updateTotalCnt, setUpdateTotalCnt] = useState<number>(0);
    const [viewModeActive, setViewModeActive] = useState<boolean>(false);
    const [form] = Form.useForm();

    const FORM_ID: number = 1;
    useEffect(()=>{
        setUpdateTotalCnt(( oldValue:number ) => oldValue+1 );
        // const shareCapitalList = form.getFieldValue('shareCapital');
        const shareCapitalString = financialSpreading?.shareCapital;
        const shareCapitalList = shareCapitalString ? JSON.parse(shareCapitalString) : [];
        // if( Array.isArray(shareCapitalList) && shareCapitalList.length!==FYCount){
            const updatedInitialValues = generateInitialValuesForFYChange(FYCount);
            form.setFieldsValue(updatedInitialValues);
        // }
        const idealColSpan = Math.floor(24/(FYCount+1));
        const tempLabelColSpan = Math.max(5,idealColSpan);
        setColSpan(Math.floor((24-tempLabelColSpan) / (FYCount)));
        setLabelColSpan(tempLabelColSpan);
    },[FYCount]);

    useEffect(()=>{
        setUpdateTotalCnt(( oldValue:number ) => oldValue+1 );
        form.setFieldsValue(generateInitialValues(FYCount, parsedFinancialSpreading));
    },[financialSpreading]);

    useEffect(()=>{
        if(assessmentFormFlag && assessmentFormFlag !== FORM_ID){
            setViewModeActive(true);
        }
        else if(assessmentFormFlag && assessmentFormFlag === FORM_ID){
            setViewModeActive(false);
        }
    },[assessmentFormFlag]);

    useEffect(()=>{
        if(submitForm===true && assessmentFormFlag === FORM_ID){
            setSubmitForm(false);
            onFinish();
        }
    },[submitForm])

    const onFinish = async () => {
        try {
            await form.validateFields();
            if(financialSpreading?.id){
                const formattedData = formatUpdateFormData(form.getFieldsValue(true), calenderYear, FYCount);
                await sendUpdateFormData(formattedData,financialSpreading?.id, creditRequestId, parentAssessmentFrom);
                setCollapseFinancialSpreading(true);
            }
            else{
                const formattedData = formatFormData(form.getFieldsValue(true), calenderYear, FYCount);
                await sendFormData(formattedData, creditRequestId, parentAssessmentFrom);
            }
            setReloadComponentCnt(Math.random());
        } catch (error) {
            console.error('Error while sending form data:', error);
        }
    };

    // Parse financialSpreading data if available
    const parsedFinancialSpreading = financialSpreading ? {
        ...financialSpreading,
        shareCapital: JSON.parse(financialSpreading.shareCapital),
        reserveAndSurplus: JSON.parse(financialSpreading.reserveAndSurplus),
        totalEquity: JSON.parse(financialSpreading.totalEquity),
        longTermBorrowings: JSON.parse(financialSpreading.longTermBorrowings),
        netDeferredTaxLiabilities: JSON.parse(financialSpreading.netDeferredTaxLiabilities),
        otherLongTermLiabilities: JSON.parse(financialSpreading.otherLongTermLiabilities),
        totalNonCurrentLiabilities: JSON.parse(financialSpreading.totalNonCurrentLiabilities),
        shortTermBorrowings: JSON.parse(financialSpreading.shortTermBorrowings),
        tradePayables: JSON.parse(financialSpreading.tradePayables),
        otherCurrentLiabilities: JSON.parse(financialSpreading.otherCurrentLiabilities),
        totalCurrentLiabilities: JSON.parse(financialSpreading.totalCurrentLiabilities),
        totalNetFixedAssets: JSON.parse(financialSpreading.totalNetFixedAssets),
        inventory: JSON.parse(financialSpreading.inventory),
        tradeReceivables: JSON.parse(financialSpreading.tradeReceivables),
        cashAndBankBalances: JSON.parse(financialSpreading.cashAndBankBalances),
        shortTermLoanAndAdvances: JSON.parse(financialSpreading.shortTermLoanAndAdvances),
        otherCurrentAssets: JSON.parse(financialSpreading.otherCurrentAssets),
        totalCurrentAssets: JSON.parse(financialSpreading.totalCurrentAssets),
        netRevenue: JSON.parse(financialSpreading.netRevenue),
        otherIncome: JSON.parse(financialSpreading.otherIncome),
        operatingProfits: JSON.parse(financialSpreading.operatingProfits),
        netProfits: JSON.parse(financialSpreading.netProfits),


        operatingCosts: JSON.parse(financialSpreading.operatingCosts),
        costOfMaterialsConsumes: JSON.parse(financialSpreading.costOfMaterialsConsumes),
        employeeBenefitExpenses: JSON.parse(financialSpreading.employeeBenefitExpenses),
        financeCosts: JSON.parse(financialSpreading.financeCosts)
        // Add parsing for other fields as needed
    } : undefined;

    // Helper function to generate initial values
    const generateInitialValues = (FYCount: number, financialSpreading?: any) => ({
        shareCapital: financialSpreading ? financialSpreading.shareCapital : Array.from({ length: FYCount }, () => 0),
        reserveAndSurplus: financialSpreading ? financialSpreading.reserveAndSurplus : Array.from({ length: FYCount }, () => 0),
        totalEquity: financialSpreading ? financialSpreading.totalEquity : Array.from({ length: FYCount }, () => 0),
        longTermBorrowings: financialSpreading ? financialSpreading.longTermBorrowings : Array.from({ length: FYCount }, () => 0),
        netDeferredTaxLiabilities: financialSpreading ? financialSpreading.netDeferredTaxLiabilities : Array.from({ length: FYCount }, () => 0),
        otherLongTermLiabilities: financialSpreading ? financialSpreading.otherLongTermLiabilities : Array.from({ length: FYCount }, () => 0),
        totalNonCurrentLiabilities: financialSpreading ? financialSpreading.totalNonCurrentLiabilities : Array.from({ length: FYCount }, () => 0),
        shortTermBorrowings: financialSpreading ? financialSpreading.shortTermBorrowings : Array.from({ length: FYCount }, () => 0),
        tradePayables: financialSpreading ? financialSpreading.tradePayables : Array.from({ length: FYCount }, () => 0),
        otherCurrentLiabilities: financialSpreading ? financialSpreading.otherCurrentLiabilities : Array.from({ length: FYCount }, () => 0),
        totalCurrentLiabilities: financialSpreading ? financialSpreading.totalCurrentLiabilities : Array.from({ length: FYCount }, () => 0),
        totalNetFixedAssets: financialSpreading ? financialSpreading.totalNetFixedAssets : Array.from({ length: FYCount }, () => 0),
        inventory: financialSpreading ? financialSpreading.inventory : Array.from({ length: FYCount }, () => 0),
        tradeReceivables: financialSpreading ? financialSpreading.tradeReceivables : Array.from({ length: FYCount }, () => 0),
        cashAndBankBalances: financialSpreading ? financialSpreading.cashAndBankBalances : Array.from({ length: FYCount }, () => 0),
        shortTermLoanAndAdvances: financialSpreading ? financialSpreading.shortTermLoanAndAdvances : Array.from({ length: FYCount }, () => 0),
        otherCurrentAssets: financialSpreading ? financialSpreading.otherCurrentAssets : Array.from({ length: FYCount }, () => 0),
        totalCurrentAssets: financialSpreading ? financialSpreading.totalCurrentAssets : Array.from({ length: FYCount }, () => 0),
        netRevenue: financialSpreading ? financialSpreading.netRevenue : Array.from({ length: FYCount }, () => 0),
        otherIncome: financialSpreading ? financialSpreading.otherIncome : Array.from({ length: FYCount }, () => 0),
        operatingProfits: financialSpreading ? financialSpreading.operatingProfits : Array.from({ length: FYCount }, () => 0),
        netProfits: financialSpreading ? financialSpreading.netProfits : Array.from({ length: FYCount }, () => 0),


        operatingCosts: financialSpreading ? financialSpreading.operatingCosts : Array.from({ length: FYCount }, () => 0),
        costOfMaterialsConsumes: financialSpreading ? financialSpreading.costOfMaterialsConsumes : Array.from({ length: FYCount }, () => 0),
        employeeBenefitExpenses: financialSpreading ? financialSpreading.employeeBenefitExpenses : Array.from({ length: FYCount }, () => 0),
        financeCosts: financialSpreading ? financialSpreading.financeCosts : Array.from({ length: FYCount }, () => 0)
        // Add defaults for other fields
    });

    const generateInititalValueForFYChange = (label: string)=>{
        const formValue = form.getFieldValue(label);
        const formValueList = Array.isArray(formValue) ? formValue : [];
        if(formValueList.length < FYCount){
            const resList = [...formValueList, ...Array.from({ length: FYCount-formValueList.length }, () => 0)];
            return resList;
        }
        else{
            const res = formValueList.filter((val:number, ind:number)=>ind<FYCount);
            return res;
        }
    }
    
    // Generate initial values function
    const generateInitialValuesForFYChange = (FYCount: number) => ({
        shareCapital: generateInititalValueForFYChange('shareCapital'),
        reserveAndSurplus: generateInititalValueForFYChange('reserveAndSurplus'),
        totalEquity: generateInititalValueForFYChange('totalEquity'),
        longTermBorrowings: generateInititalValueForFYChange('longTermBorrowings'),
        netDeferredTaxLiabilities: generateInititalValueForFYChange('netDeferredTaxLiabilities'),
        otherLongTermLiabilities: generateInititalValueForFYChange('otherLongTermLiabilities'),
        totalNonCurrentLiabilities: generateInititalValueForFYChange('totalNonCurrentLiabilities'),
        shortTermBorrowings: generateInititalValueForFYChange('shortTermBorrowings'),
        tradePayables: generateInititalValueForFYChange('tradePayables'),
        otherCurrentLiabilities: generateInititalValueForFYChange('otherCurrentLiabilities'),
        totalCurrentLiabilities: generateInititalValueForFYChange('totalCurrentLiabilities'),
        totalNetFixedAssets: generateInititalValueForFYChange('totalNetFixedAssets'),
        inventory: generateInititalValueForFYChange('inventory'),
        tradeReceivables: generateInititalValueForFYChange('tradeReceivables'),
        cashAndBankBalances: generateInititalValueForFYChange('cashAndBankBalances'),
        shortTermLoanAndAdvances: generateInititalValueForFYChange('shortTermLoanAndAdvances'),
        otherCurrentAssets: generateInititalValueForFYChange('otherCurrentAssets'),
        totalCurrentAssets: generateInititalValueForFYChange('totalCurrentAssets'),
        netRevenue: generateInititalValueForFYChange('netRevenue'),
        otherIncome: generateInititalValueForFYChange('otherIncome'),
        operatingProfits: generateInititalValueForFYChange('operatingProfits'),
        netProfits: generateInititalValueForFYChange('netProfits'),

        // Add defaults for other fields
        operatingCosts: generateInititalValueForFYChange('operatingCosts'),
        costOfMaterialsConsumes: generateInititalValueForFYChange('costOfMaterialsConsumes'),
        employeeBenefitExpenses: generateInititalValueForFYChange('employeeBenefitExpenses'),
        financeCosts: generateInititalValueForFYChange('financeCosts'),
    });
    

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: '#FFF', padding: '20px', marginTop: '20px', borderRadius: '8px' }}>
                <div >
                    <span onClick={() => setCollapseFinancialSpreading(oldState => !oldState)}>
                        {collapseFinancialSpreading ? <ArrowRight /> : <ArrowDown />}
                    </span>
                    <span style={{ color: '#242424', fontSize: '14px', fontWeight: '700', marginLeft: '10px' }}>
                        Financial Spreading
                    </span>
                </div>
                {!collapseFinancialSpreading && (
                    <>
                        <Form
                            form={form}
                            style={{ marginTop:'10px', borderRight: '1px #E8E8E8 solid', borderLeft: '1px #E8E8E8 solid', padding: '0px 7px 0px 7px' }}
                            onFinish={onFinish}
                        >
                            <Row gutter={16} style={{ backgroundColor: '#CEE6FA', padding: '15px 10px 15px 16px' }}>
                                <Col span={labelColSpan}></Col>
                                {Array.from({ length: FYCount }, (_, ind) => (
                                    <Col span={colSpan} key={ind} style={{ fontWeight: '600' }}>
                                        {calenderYear ? `31 Dec ${2023 - ind}` :`31 Mar ${2023 - ind}`}
                                    </Col>
                                ))}
                            </Row>
                            <EquityComponent FYCount={FYCount} form={form} colSpan={colSpan} labelColSpan={labelColSpan} updateTotalCnt={updateTotalCnt}
                                viewModeActive={viewModeActive}/>
                            <NonCurrentLiabilitiesComponent FYCount={FYCount} form={form} colSpan={colSpan} labelColSpan={labelColSpan} updateTotalCnt={updateTotalCnt}
                                viewModeActive={viewModeActive}/>
                            <TotalCurrentLiabilitiesComponent FYCount={FYCount} form={form} colSpan={colSpan} labelColSpan={labelColSpan} updateTotalCnt={updateTotalCnt}
                                viewModeActive={viewModeActive}/>
                            <TotalCurrentAssetsComponent FYCount={FYCount} form={form} colSpan={colSpan} labelColSpan={labelColSpan} updateTotalCnt={updateTotalCnt}
                                viewModeActive={viewModeActive}/>
                            <OperationFinancialSpreadingComponent FYCount={FYCount} form={form} colSpan={colSpan} labelColSpan={labelColSpan} updateTotalCnt={updateTotalCnt}
                                viewModeActive={viewModeActive}/>
                            <OtherFinancialSpreadingComponent FYCount={FYCount} form={form} colSpan={colSpan} labelColSpan={labelColSpan} 
                                viewModeActive={viewModeActive}/>
                        </Form>
                        
                    </>
                )}

            </div>
        </>
    );
};

export default FinancialSpreading;
