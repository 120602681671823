import React, { Fragment } from "react";
import { Tabs, Typography } from "antd";
const TabPane = Tabs.TabPane;
import "../../../styles/Pages.sass";
import InprogressSampleQuoteList from "./components/InprogressSampleQuoteList";
import CompletedSampleQuoteList from "./components/CompletedSampleQuoteList";
import AllSampleQuoteList from "./components/AllSampleQuoteList";

function SampleQuoteLayout() {
    return (
    <Fragment>
    <div className="parent-container">
        <div className="container-layout">
            <Typography style={{fontSize: 20, fontWeight: 700}}>Sample Quotes</Typography>
            <div className="container-body">
            <Tabs defaultActiveKey="1" className="tab" onTabClick={(e) => console.log(e)}>
                <TabPane tab={<span className="tab-heading">All Quotes</span>} key="1">
                    <AllSampleQuoteList />
                </TabPane>
                <TabPane tab={<span className="tab-heading">In progress Quotes</span>} key="2">
                    <InprogressSampleQuoteList />
                </TabPane>
                <TabPane tab={<span className="tab-heading">Completed Quotes</span>} key="3">
                    <CompletedSampleQuoteList />
                </TabPane>
                {/* <TabPane tab={<span className="tab-heading">Finalised Quotes</span>} key="4">
                    <FinalisedSampleQuoteList />
                </TabPane> */}
            </Tabs>
            </div>
        </div>
    </div>
    </Fragment>
    );
}

export default SampleQuoteLayout;