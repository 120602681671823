import React, { useEffect, useState, createContext } from 'react';
import { Button, Form, Steps, Input, Row, Col, Select, DatePicker, InputNumber, Radio, Checkbox, Space, Spin, message } from 'antd';
import Step1Form from './StepForm/Step1';
import Step2Form from './StepForm/Step2';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router';
import TagsInput from "../../../components/TagInput";
import { titleCase } from "../../../utils/helpers/titleCase";
import { getRequest, postRequest } from "../../../utils/handler/apiHandler";
import Upload from 'rc-upload';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../../app/hooks';
import { authSelector } from '../../../features/auth/authSlice';
import axios from 'axios';
import { Divider } from 'rc-menu';
const { Option } = Select;

const CreditRequestApproval = () => {
  const state = useLocation().state;
  const [form] = Form.useForm();
  const navigation = useNavigate();
  const [current, setCurrent] = useState(0);
  const [loader, setLoader] = useState(false)
  const formData = form.getFieldsValue(true);
  const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;
  const [payload, setPayload] = useState<any>({});

  const steps = [
    {
      title: 'First',
      content: <Step1Form data={payload} form={form} />,
      fields: ['approvedFleetNumber', 'creditApprovedDate', 'approvedAmount', 'validity', 'creditLimit', 'creditStatus', 'entityName', 'legalBusinessName', 'requestDate']
    },
    {
      title: 'Second',
      content: <Step2Form data={payload} form={form} />,
      fields: ['securityDepositApplicable', 'securityDepositType', 'securityDepositValue', 'downPaymantApplicable', 'downPayment', 'bankGuarantee', 'amount', 'corporateGuarantee', 'processingFeeAmount', 'processingFeeApplicable']
    },
  ];
  const next = async () => {
    // setCurrent(current + 1);
    try {
      await form.validateFields(['approvedFleetNumber', 'creditApprovedDate', 'approvedAmount', 'validity', 'creditLimit', 'creditStatus', 'securityDepositApplicable', 'securityDepositType', 'securityDepositValue', 'downPaymantApplicable', 'downPayment', 'bankGuarantee', 'amount', 'corporateGuarantee', 'processingFeeAmount', 'processingFeeApplicable']);
      setCurrent((prev)=>prev + 1);
  } catch {}
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle: React.CSSProperties = {
    marginTop: 16,
  };

  const page = useLocation().pathname.split('/').pop();
  useEffect(() => {
    getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/credit-approval?creditRequestId=${page}`).then(res => {
      if(res.data.length !== 0) {
        const data = res.data[0];
        form.setFieldsValue({
          requestDate: dayjs(data.requestDate),
          creditRequestId: data.creditRequestId,
          clientId: data.clientId,
          entityName: data.entityName,
          legalBusinessName: data.legalBusinessName,
          creditStatus: data.creditStatus,
          creditApprovedDate: data.creditApprovalDate ? dayjs(data.creditApprovalDate) : null,
          creditLimit: data.creditLimit,
          approvedFleetNumber: data.approvedFleetNumber,
          creditCondition: data.creditCondition,
          approvedAmount: data.approvedAmount,
          validity: data.validFrom && data.validTill ? [dayjs(data.validFrom), dayjs(data.validTill)] : null,
          contractConditions: data.contractConditions,
        });
        setPayload(data);
      } else {
          getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/credit-request?creditRequestId=${page}`).then(res => {
            const data = res.data[0];
            form.setFieldsValue({
              requestDate: dayjs(data.dateOfApplication),
              creditRequestId: data.creditRequestId,
              clientId: data.clientId,
              entityName: data.entityName,
              legalBusinessName: data.legalBusinessName,
              creditStatus: data.creditStatus,
            })
            setPayload(data);
          });
      }
    })
  }, []);

  const onFinish = async () => {
    const formData = form.getFieldsValue(true);

    const contractConditions:any = []
    formData.contractConditions.map((condition: any) => {
      contractConditions.push({
        mlaNumber: condition.mlaNumber == undefined ? null : condition.mlaNumber,
        mlaDocumentUrl: condition.mlaDocumentUrl == undefined ? null : condition.mlaDocumentUrl,

        securityDepositApplicable: condition.securityDepositApplicable == undefined ? false : condition.securityDepositApplicable,
        securityDepositType: condition.securityDepositType == undefined || condition.securityDepositApplicable == false ? null : condition.securityDepositType,
        securityDepositValue: condition.securityDepositValue == undefined || condition.securityDepositApplicable == false ? null : condition.securityDepositValue,

        bankGuaranteeApplicable: condition.bankGuaranteeApplicable == undefined ? false : condition.bankGuaranteeApplicable,
        bankGuaranteeAmount: condition.bankGuaranteeAmount == undefined || condition.bankGuaranteeApplicable == false ? null : condition.bankGuaranteeAmount,
        bankDocumentUrl: condition.bankDocumentUrl == undefined || condition.bankGuaranteeApplicable == false ? null : condition.bankDocumentUrl,

        downPaymentApplicable: condition.downPaymentApplicable == undefined ? false : condition.downPaymentApplicable,
        downPaymentPercentage: condition.downPaymentPercentage == undefined || condition.downPaymentApplicable == false ? null : condition.downPaymentPercentage,
        
        processingFeeApplicable: condition.processingFeeApplicable == undefined ? false : condition.processingFeeApplicable,
        processingFeeAmount: condition.processingFeeAmount== undefined || condition.processingFeeApplicable == false ? null : condition.processingFeeAmount,
        
        corporateGuaranteeApplicable: condition.corporateGuaranteeApplicable == undefined ? false : condition.corporateGuaranteeApplicable,
        corporateGuarantee: condition.corporateGuarantee == undefined  || condition.corporateGuaranteeApplicable == false ? null : condition.corporateGuarantee,
        corporateDocumentUrl: condition.corporateDocumentUrl == undefined  || condition.corporateGuaranteeApplicable == false ? null : condition.corporateDocumentUrl,
        
        letterOfComfortDocumentUrl: condition.letterOfComfortDocumentUrl == undefined ? null : condition.letterOfComfortDocumentUrl,
      })
    });

    const payload: any = {
      creditRequestId: formData.creditRequestId || null,
      clientId: formData.clientId,
      requestDate: formData.requestDate !== null ? dayjs(formData.requestDate).format("YYYY-MM-DD") : null,
      entityName: formData.entityName || null,
      legalBusinessName: formData.legalBusinessName || null,
      creditStatus: formData.creditStatus || null,
      creditApprovalDate:  formData.creditStatus == "Approved" ? formData.creditApprovedDate !== null ? dayjs(formData.creditApprovedDate).format("YYYY-MM-DD") : null : null,
      creditLimit: formData.creditStatus == "Approved" ? formData.creditLimit : null,
      approvedFleetNumber: formData.creditStatus == "Approved" ? formData.approvedFleetNumber : null,
      creditCondition: formData.creditStatus == "Approved" ? formData.creditCondition : null,
      approvedAmount: formData.creditStatus == "Approved" ? formData.approvedAmount : null,
      contractConditions: contractConditions,
      validTill:  formData.creditStatus == "Approved" ? formData.validity !== null ? dayjs(formData.validity[1]).format("YYYY-MM-DD") : null : null,
      validFrom:  formData.creditStatus == "Approved" ? formData.validity !== null ? dayjs(formData.validity[0]).format("YYYY-MM-DD") : null : null,
    }

    console.log(payload)
    setLoader(true);
    postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/credit-approval`, payload).then(res => {
      setLoader(false);
      if(!res.err)
        navigation("/dashboard/credit");
    });
  }

  return (
    <div className="form-container">
      <Steps current={current} items={items} />
      <div style={contentStyle}>
        <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        onKeyDown={(e)=> e.key == "Enter" ? e.preventDefault(): ''}
        >
            {steps[current].content}

        <Form.Item>
        <div style={{ marginTop: 24 }}>
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" htmlType="submit">
            Done
            {loader ? <Spin indicator={antIcon} /> : ""}
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </div>
        </Form.Item>

        </Form>
        </div>
    </div>
  );
};

export default CreditRequestApproval;