import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Form, Typography } from 'antd';
import { ReactComponent as DownMark } from '../../../../utils/SVGs/DownMark.svg';
import { ReactComponent as RightMark } from '../../../../utils/SVGs/RightMark.svg';
import { formatIndianRupee, formatRatioNumber } from '../formUtils';

interface DebtToCapitalRatioProps {
    form: any; 
    updateTotalCnt: number;
    viewModeActive: boolean;
}

const DebtToCapitalRatioComponent: React.FC<DebtToCapitalRatioProps> = ({ form,updateTotalCnt, viewModeActive }) => {
    const [debtToCapitalRatio, setDebtToCapitalRatio] = useState<number>(0);
    const [collapseDebtToCapitalRatioFields, setCollapseDebtToCapitalRatioFields] = useState<boolean>(false);
    const INR_SYMBOL: string = '\u20B9';
    
    useEffect(() => {
        const values = form.getFieldsValue(true);
        const totalDebt = Number(values.totalDebt);
        const totalDebtAndTotalEquity = Number(values.totalDebtAndTotalEquity);
        const updatedDebtToCapitalRatio = totalDebt / totalDebtAndTotalEquity;
        setDebtToCapitalRatio(updatedDebtToCapitalRatio);
        form.setFieldsValue({ debtToCapitalRatio: updatedDebtToCapitalRatio });
        
    }, [updateTotalCnt]);
    
    return (
        <>
            <Row
                gutter={16}
                style={{ marginTop: '10px', paddingTop: '10px', borderBottom: '1px #E8E8E8 solid', paddingBottom: '20px' }}
            >
                <Col span={12}>
                    <span onClick={() => setCollapseDebtToCapitalRatioFields((oldState) => !oldState)}>
                        {collapseDebtToCapitalRatioFields ? <RightMark /> : <DownMark />}
                    </span>
                    <span style={{ fontSize: '14px', fontWeight: '600', marginLeft: '10px' }}>Debt to Capital Ratio</span>
                </Col>
                <Col span={12} key={1}>
                    <Typography style={{ fontSize: 14, fontWeight: '600', paddingLeft: '10px' }}>
                        { formatRatioNumber(debtToCapitalRatio) }
                    </Typography>
                </Col>
            </Row>
            {
                !collapseDebtToCapitalRatioFields && (
                    <>
                        <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: '20px', borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                            <Col span={12}><p style={{ marginLeft: '20px' }}>Total Debt</p></Col>
                            <Col span={12} key={1}>
                                <Typography style={{ paddingLeft: '10px' }}>
                                    <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue('totalDebt'))}`}</span>
                                </Typography>
                            </Col>
                        </Row>

                        <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: '20px', borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                            <Col span={12}><p style={{ marginLeft: '20px' }}> Total Debt + Total Equity </p></Col>
                            <Col span={12} key={1}>
                                <Typography style={{ paddingLeft: '10px' }}>
                                    <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue('totalDebtAndTotalEquity'))}`}</span>
                                </Typography>
                            </Col>
                        </Row>
                    </>
                )
            }
        </>
    );
};

export default DebtToCapitalRatioComponent;
