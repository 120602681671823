import React, { useEffect, useState } from "react";
import { Button, Card, Col, Divider, Empty, Form, Input, Modal, Row, Select, Typography, message } from "antd";
import { ReactComponent as EditIcon } from "../utils/SVGs/edit_icon.svg";
import { ReactComponent as CrossIcon } from "../utils/SVGs/cross_icon.svg";
import { ReactComponent as TickIcon } from "../utils/SVGs/tick_icon.svg";
import { ReactComponent as TrashIcon } from "../utils/SVGs/trash_icon.svg";
import { ReactComponent as AddIcon } from "../utils/SVGs/add_icon.svg";
import { getRequest, putRequest } from "../../../../utils/handler/apiHandler";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../app/hooks";
import { menuSelector } from "../../../../features/menu/menuSlice";
import {
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import dayjs from "dayjs";
import "../styles.css";
import { color } from "@mui/system";

const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;
const { confirm } = Modal;
const probabilityOfClosure = [
  { id: 0, value: "0%", title: "0%" },
  { id: 1, value: "10%", title: "10%" },
  { id: 2, value: "20%", title: "20%" },
  { id: 3, value: "30%", title: "30%" },
  { id: 4, value: "40%", title: "10%" },
  { id: 5, value: "50%", title: "10%" },
  { id: 6, value: "60%", title: "10%" },
  { id: 7, value: "70%", title: "10%" },
  { id: 8, value: "80%", title: "10%" },
  { id: 9, value: "90%", title: "10%" },
  { id: 10, value: "100%", title: "10%" },
];
function ProspectBasicDetails(props: any) {
  const disable = props.disableEditOrAddSection;
  const setDisable = props.setDisableEditOrAddSection;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [editable, setEditable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(true);
  const [designationList, setDesignationList] = useState<any>([]);
  const [prospectData, setProspectData] = useState<any>([]);
  const [spocList, setSpocList] = useState<any>([]);
  const [deletedSpoc, setDeletedSpoc] = useState<number[]>([]);
  const [segmentList, setSegmentList] = useState<any>([]);
  const [segment, setSegment] = useState<any>(
    form.getFieldsValue(true).segment
  );

  const url = useLocation();
  const prospectId = url.pathname.split("/").pop();
  const menus = useAppSelector(menuSelector).menu;

  const optionsToRender = (options: any[], identifier: any) => {
    return options?.map((value: any) => (
      <Option key={value.id} value={value?.[identifier]}>
        {value?.[identifier]}
      </Option>
    ));
  };
  const getDesignationList = () => {
    getRequest(
      `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/designations`
    ).then((res) => {
      setDesignationList(res.data);
    });
  };
  const handleDesignationChange = (name: any) => {
    if (form.getFieldValue(["spoc", name, "designation"]) === "Other") {
      form.setFieldValue(["spoc", name, "isOtherDesignation"], true);
    } else {
      form.setFieldValue(["spoc", name, "isOtherDesignation"], false);
    }
  };

  const onDelete = (spocId: number) => {
    let deleted: number[] = [...deletedSpoc];
    deleted.push(spocId);
    setDeletedSpoc(deleted);
    const items = [...spocList];
    const valueToRemove = spocId;
    const filteredItems = items.filter(function (item) {
      return item.spocId !== valueToRemove;
    });
    setSpocList(filteredItems);
    console.log(items, filteredItems);
  };
  const showDeleteConfirm = (spocId: number) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: (
        <span style={{ fontWeight: 500 }}>
          Are you sure you want to delete this SPOC?
        </span>
      ),
      centered: true,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onDelete(spocId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const showProspectConfirm = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: <span style={{ fontWeight: 600 }}>Conform submission.</span>,
      content: <span>Are you sure you want save the changes?</span>,
      centered: true,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        handleUpdate();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleUpdate = async () => {
    try {
      const formData = form.getFieldsValue(true);
      delete formData.stage.active;
      delete formData.stage.order;
      delete formData.source.order;

      if (_.isArray(formData.spoc))
        formData.spoc.map(function (item: any) {
          item.emailId = item.emailId || null;
          item.contactNumber = item.contactNumber || null;
          item.designation = item.isOtherDesignation
            ? item.other
            : item.designation;
          delete item.other;
          return item;
        });

      let prospectPayload: any = {
        onboardDate: dayjs(formData.onboardDate).toISOString(),
        aboutCompany: formData.aboutCompany,
        fleetSize: ~~formData.fleetSize,
        remarks: formData.remarks,
        segment: formData.other ? { id: null, name: formData.other } : segment,
        isOtherSegment: formData.other ? true : false,
        isOtherSource: false,
        source: formData.source,
        stage: formData.stage,
        probabilityOfClosure: ~~`${formData?.probabilityOfClosure}`.split(
          "%"
        )[0],
        stageRemark: formData.stageRemark,
        spocRequests: formData.spoc,
      };

      putRequest(
        `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/prospect/${prospectId}`,
        prospectPayload
      ).then((res) => {
        if (!_.isEmpty(deletedSpoc))
          deletedSpoc.map(async (spocId: any) => {
            await putRequest(
              `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/spoc/${spocId}`,
              { active: false }
            );
            message.destroy();
          });

        let newSpoc: any = [];
        if (_.isArray(formData.spoc)) {
          setSpocList([...spocList, ...formData?.spoc]);
          newSpoc = formData.spoc.map(function (item: any) {
            item.emailId = item?.emailId || null;
            item.contactNumber = item?.contactNumber || null;
            item.designation = item.isOtherDesignation
              ? item.other
              : item.designation;
            delete item.other;
            return item;
          });
        }
        if (prospectPayload.probabilityOfClosure)
          prospectPayload.probabilityOfClosure =
            prospectPayload.probabilityOfClosure + "%";
        setProspectData(prospectPayload);
        setEditable(false);
        setDeletedSpoc([]);
        form.setFieldsValue({ spoc: [] });

        message.destroy();
        message.success("Updated Successfully");
        setLoader(true);
        setTimeout(() => window.location.reload(), 1000);
      });
    } catch (err) {
      message.error("Error occurred while while updating the prospect.");
      console.log("Error occurred while: ", err);
    }
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      showProspectConfirm();
      // setDisable(false);
    } catch (err) {
      console.log("Error occurred.");
    }
  };
  const handleCancel = () => {
    form.setFieldsValue(prospectData);
    form.setFieldsValue({ spoc: [] });
    setSpocList(form.getFieldsValue(true).spocList);
    setEditable(false);
  };

  useEffect(() => {
    try {
      setLoader(true);
      getRequest(
        `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/prospect/${prospectId}`
      ).then((res) => {
        if (res.status === 404 || res.status === 400) {
          navigate("/dashboard/prospect");
          return;
        }
        const data = res.data;
        data.createdByName = JSON.parse(data?.createdBy)?.name;
        let tempProspectData = res.data;
        if (tempProspectData?.probabilityOfClosure)
          tempProspectData.probabilityOfClosure =
            tempProspectData?.probabilityOfClosure + "%";
        setProspectData(tempProspectData);
        setSpocList(res.data.spocList);
        setLoader(false);
        form.setFieldsValue(data);
        form.setFieldsValue({
          onboardDate: dayjs(res.data.onboardDate),
          segment: res.data.segment.name,
        });
      });
    } catch (err) {
      console.log("Error: ", err);
    }
  }, []);

  const getSegmentsList = () => {
    getRequest(
      `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/segments`
    ).then((res) => {
      setSegmentList(res.data);
    });
  };

  const handleSegmentUpdate = (i: any) => {
    setSegment({
      id: i.key,
      name: i.value,
    });
  };

  return (
    <React.Fragment>
      <div className="container-inner-section-body">
        <div className="container-inner-section-body-header">
          <Title style={{ margin: 0, fontWeight: 700 }} level={5}>
            Basic Details
          </Title>
          {props.roleAccess && (
            <div className="edit-section">
              <Button
                type="primary"
                ghost
                icon={<EditIcon />}
                disabled={disable}
                onClick={(_) => {
                  setEditable(true);
                  setDisable(true);
                }}
                className={
                  disable
                    ? "button-override disable"
                    : "button-override primary"
                }
                style={{ display: editable ? "none" : "" }}
              >
                Edit
              </Button>
              <Button
                danger
                ghost
                icon={<CrossIcon />}
                onClick={(_) => {
                  handleCancel();
                  setDisable(false);
                }}
                className="button-override danger"
                style={{ display: editable ? "" : "none" }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                ghost
                icon={<TickIcon />}
                onClick={handleSubmit}
                className="button-override primary"
                style={{ display: editable ? "" : "none" }}
              >
                Save Changes
              </Button>
            </div>
          )}
        </div>
        <div className="container-inner-section-body-section">
          <Card bordered={false}>
            <div className="">
              <Form
                name="dynamic_form_nest_item"
                form={form}
                autoComplete="off"
                layout="vertical"
                requiredMark={false}
              >
                <Row className="detail-description-item-container">
                  <Col className="detail-description-item">
                    <Typography className="description-label">
                      Onboarding Date
                    </Typography>
                    <Typography
                      className={`${loader ? "shimmer-ui" : ""}`}
                      style={{ fontSize: 14 }}
                    >
                      <span style={{ display: loader ? "none" : "" }}>
                        {loader
                          ? ""
                          : dayjs(prospectData.onboardDate).format(
                              "DD MMM, YYYY"
                            )}
                      </span>
                    </Typography>
                  </Col>
                  <Col className="detail-description-item">
                    <Typography className="description-label">
                      Created By
                    </Typography>
                    <Typography
                      className={`${loader ? "shimmer-ui" : ""}`}
                      style={{ fontSize: 14 }}
                    >
                      {loader ? "" : prospectData.createdByName}
                    </Typography>
                  </Col>
                  <Col className="detail-description-item">
                    <Typography className="description-label">
                      Source
                    </Typography>
                    <Typography
                      className={`${loader ? "shimmer-ui" : ""}`}
                      style={{ fontSize: 14 }}
                    >
                      {loader ? "" : prospectData.source?.name}
                    </Typography>
                  </Col>
                  <Col className="detail-description-item">
                    <Typography className="description-label">
                      Business Segment
                    </Typography>
                    {!editable ? (
                      <>
                        <Typography
                          className={`${loader ? "shimmer-ui" : ""}`}
                          style={{ fontSize: 14 }}
                        >
                          {loader ? "" : prospectData.segment?.name}
                        </Typography>
                      </>
                    ) : (
                      <div style={{ display: "flex", alignItems: "end" }}>
                        <Form.Item
                          name="segment"
                          // label="Business Segment"
                          className="form-label"
                          style={{ margin: 0, fontSize: 12, width: "100%" }}
                          rules={[{ required: true }]}
                        >
                          <Select
                            showSearch={true}
                            onClick={getSegmentsList}
                            placeholder="Business Segment"
                            onChange={(e, i: any) => handleSegmentUpdate(i)}
                          >
                            {optionsToRender(segmentList, "name")}
                          </Select>
                        </Form.Item>
                        {form.getFieldsValue(true).segment === "Other" ? (
                          <Form.Item
                            className="secondary-field"
                            name="other"
                            style={{ margin: 0, fontSize: 12, width: "150%" }}
                            rules={[{ required: true, message: "" }]}
                          >
                            <Input
                              style={{ marginLeft: 5 }}
                              placeholder="Enter Business Segment Here"
                            />
                          </Form.Item>
                        ) : null}
                      </div>
                    )}
                  </Col>

                  <Col className="detail-description-item">
                    {!editable ? (
                      <>
                        <Typography className="description-label">
                          Probability of Closure
                        </Typography>
                        <Typography
                          className={`${loader ? "shimmer-ui" : ""}`}
                          style={{ fontSize: 14 }}
                        >
                          {loader ? (
                            ""
                          ) : prospectData.probabilityOfClosure ? (
                            `${prospectData.probabilityOfClosure}`
                          ) : (
                            <span
                              style={{
                                opacity: 0.3,
                                fontWeight: 600,
                                fontSize: 12,
                                display: loader ? "none" : "",
                              }}
                            >
                              No data
                            </span>
                          )}
                        </Typography>
                      </>
                    ) : (
                      <Form.Item
                        name="probabilityOfClosure"
                        label={
                          <>
                            Probability of Closure{" "}
                            <span className="optional-label">(Optional)</span>
                          </>
                        }
                        className="form-label"
                        style={{ margin: 0, fontSize: 12 }}
                        // rules={[{ required: true, message: 'Please enter probability of closure!' }]}
                      >
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Probability of Closure"
                          options={probabilityOfClosure}
                        />
                      </Form.Item>
                    )}
                  </Col>
                  <Col className="detail-description-item">
                    {!editable ? (
                      <>
                        <Typography className="description-label">
                          Current Fleet Size
                        </Typography>
                        <Typography
                          className={`${loader ? "shimmer-ui" : ""}`}
                          style={{ fontSize: 14 }}
                        >
                          {loader ? (
                            ""
                          ) : prospectData.fleetSize ? (
                            prospectData.fleetSize
                          ) : (
                            <span
                              style={{
                                opacity: 0.3,
                                fontWeight: 600,
                                fontSize: 12,
                                display: loader ? "none" : "",
                              }}
                            >
                              No data
                            </span>
                          )}
                        </Typography>
                      </>
                    ) : (
                      <Form.Item
                        name="fleetSize"
                        label={
                          <>
                            Current Fleet Size{" "}
                            <span className="optional-label">(Optional)</span>
                          </>
                        }
                        className="form-label"
                        style={{ margin: 0 }}
                        rules={[
                          {
                            required: false,
                            message: "Fleet Size is required",
                          },
                          {
                            validator: async () => {
                              if (
                                form.getFieldsValue()?.fleetSize?.length > 8
                              ) {
                                return Promise.reject(
                                  new Error(
                                    `Fleet size shouldn't be more than 8 digit.`
                                  )
                                );
                              }
                            },
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          maxLength={8}
                          onWheel={(event) => event.currentTarget.blur()}
                          placeholder="Current Fleet Size"
                        />
                      </Form.Item>
                    )}
                  </Col>
                  <Col className="detail-description-item">
                    {!editable ? (
                      <>
                        <Typography className="description-label">
                          About Company
                        </Typography>
                        <Typography
                          className={`${
                            loader ? "shimmer-ui" : "multine-ellipsis"
                          }`}
                        >
                          {loader ? (
                            ""
                          ) : prospectData.aboutCompany ? (
                            prospectData.aboutCompany
                          ) : (
                            <span
                              style={{
                                opacity: 0.3,
                                fontWeight: 600,
                                fontSize: 12,
                                display: loader ? "none" : "",
                              }}
                            >
                              No data
                            </span>
                          )}
                        </Typography>
                      </>
                    ) : (
                      <Form.Item
                        name="aboutCompany"
                        label={
                          <>
                            About Company{" "}
                            <span className="optional-label">(Optional)</span>
                          </>
                        }
                        className="form-label"
                        style={{ margin: 0 }}
                        // rules={[{ required: false, message: 'Please enter about company!' }]}
                      >
                        <TextArea rows={2} placeholder="About Company" />
                      </Form.Item>
                    )}
                  </Col>
                  <Col className="detail-description-item">
                    {!editable ? (
                      <>
                        <Typography className="description-label">
                          Onboarding Remarks
                        </Typography>
                        <Typography
                          className={`${
                            loader ? "shimmer-ui" : "multine-ellipsis"
                          }`}
                          style={{ fontSize: 14 }}
                        >
                          {loader ? (
                            ""
                          ) : prospectData.remarks ? (
                            prospectData.remarks
                          ) : (
                            <span
                              style={{
                                opacity: 0.3,
                                fontWeight: 600,
                                fontSize: 12,
                                display: loader ? "none" : "",
                              }}
                            >
                              No data
                            </span>
                          )}
                        </Typography>
                      </>
                    ) : (
                      <Form.Item
                        name="remarks"
                        label={
                          <>
                            Onboarding Remarks{" "}
                            <span className="optional-label">(Optional)</span>
                          </>
                        }
                        className="form-label"
                        style={{ margin: 0 }}
                        // rules={[{ required: false, message: 'Please enter remarks!' }]}
                      >
                        <TextArea rows={2} placeholder="Onboarding Remarks" />
                      </Form.Item>
                    )}
                  </Col>
                </Row>

                <Divider style={{ margin: "0 0 18px 0" }} />

                <Form.List name="spoc">
                  {(fields, { add, remove }) => (
                    <>
                      <Row justify="space-between" align="middle">
                        <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                          SPOC
                        </Typography>
                        <Button
                          type="link"
                          style={{
                            fontSize: 12,
                            fontWeight: 600,
                            display: editable ? "" : "none",
                          }}
                          icon={<AddIcon />}
                          onClick={async () => {
                            try {
                              console.log(await form.validateFields());
                              add();
                            } catch (err) {}
                          }}
                        >
                          Add SPOC
                        </Button>
                      </Row>
                      <Row
                        justify="space-between"
                        className={
                          editable
                            ? "list-item-container-editable"
                            : "list-item-container"
                        }
                      >
                        <Col>Name</Col>
                        <Col>Designation</Col>
                        <Col>Contact Number</Col>
                        <Col>
                          Email Id{" "}
                          {editable && (
                            <span style={{ opacity: 0.3, fontWeight: 600 }}>
                              (Optional)
                            </span>
                          )}
                        </Col>
                        <Col
                          style={{
                            display: editable ? "block" : "none",
                            width: 180,
                          }}
                        >
                          Action
                        </Col>
                      </Row>

                      {loader ? (
                        <>
                          <Row
                            justify="space-between"
                            className="shimmer-ui"
                            style={{
                              height: 40,
                              borderRadius: 12,
                              marginBottom: 8,
                            }}
                          ></Row>
                          <Row
                            justify="space-between"
                            className="shimmer-ui"
                            style={{
                              height: 40,
                              borderRadius: 12,
                              marginBottom: 8,
                            }}
                          ></Row>
                        </>
                      ) : (
                        <>
                          {_.isEmpty(prospectData.spocList) ? (
                            !editable && (
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )
                          ) : (
                            <>
                              {spocList.map((e: any, key: number) => {
                                return (
                                  <SPOCItem
                                    item={e}
                                    editable={editable}
                                    form={form}
                                    delete={showDeleteConfirm}
                                    loader={deleteLoading}
                                    spocList={spocList}
                                  />
                                );
                              })}
                            </>
                          )}
                        </>
                      )}

                      <div style={{ display: editable ? "" : "none" }}>
                        {fields.map(({ key, name, ...restField }) => (
                          <Row
                            key={key}
                            justify="space-between"
                            style={{ minHeight: 50 }}
                            className="list-item-container-editable list-item"
                          >
                            <Col style={{ width: "20%" }}>
                              <Form.Item
                                {...restField}
                                name={[name, "name"]}
                                style={{ margin: 0 }}
                                rules={[{ required: true, message: "" }]}
                              >
                                <Input
                                  className="input-override"
                                  style={{ fontSize: 12 }}
                                  placeholder="Enter Name"
                                />
                              </Form.Item>
                            </Col>
                            <Col style={{ margin: "10px 0" }}>
                              <Form.Item
                                {...restField}
                                name={[name, "designation"]}
                                style={{
                                  margin: 0,
                                  width: "100%",
                                  marginBottom: 5,
                                }}
                                rules={[{ required: true, message: "" }]}
                              >
                                <Select
                                  className="select-input-override"
                                  showSearch={true}
                                  onClick={getDesignationList}
                                  placeholder="Select Designation"
                                  onChange={() => handleDesignationChange(name)}
                                >
                                  {optionsToRender(designationList, "name")}
                                </Select>
                              </Form.Item>
                              {form.getFieldValue([
                                "spoc",
                                name,
                                "designation",
                              ]) === "Other" ? (
                                <Form.Item
                                  name={[name, "other"]}
                                  style={{ margin: 0, width: "100%" }}
                                  rules={[{ required: true, message: "" }]}
                                >
                                  <Input
                                    className="input-override"
                                    placeholder="Enter Designation Here"
                                  />
                                </Form.Item>
                              ) : null}
                            </Col>
                            <Col style={{ width: "20%" }}>
                              <Form.Item
                                {...restField}
                                name={[name, "contactNumber"]}
                                style={{ margin: 0 }}
                                // rules={[{ required: true, message: "" }]}
                                rules={[
                                  { required: true, message: "" },
                                  {
                                    validator: async () => {
                                      if (
                                        (
                                          form.getFieldValue([
                                            "spoc",
                                            name,
                                            "contactNumber",
                                          ]) || ""
                                        ).toString().length > 10
                                      ) {
                                        return Promise.reject(new Error(``));
                                      }
                                    },
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  className="input-override"
                                  style={{ fontSize: 12 }}
                                  maxLength={10}
                                  onWheel={(event) =>
                                    event.currentTarget.blur()
                                  }
                                  placeholder="Enter Contact no."
                                />
                              </Form.Item>
                            </Col>
                            <Col style={{ width: "20%" }}>
                              <Form.Item
                                {...restField}
                                name={[name, "emailId"]}
                                style={{ margin: 0 }}
                                rules={[{ required: false, message: "" }]}
                              >
                                <Input
                                  className="input-override"
                                  style={{ fontSize: 12 }}
                                  placeholder="Enter Email ID"
                                />
                              </Form.Item>
                            </Col>

                            <Col style={{ width: 180 }}>
                              <Button
                                danger
                                ghost
                                type="link"
                                icon={<CrossIcon />}
                                onClick={(_) => remove(name)}
                                style={{ padding: "0 5px", fontSize: 12 }} // add this styling to global file
                              >
                                Cancel
                              </Button>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </>
                  )}
                </Form.List>
              </Form>
            </div>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProspectBasicDetails;

const SPOCItem = (props: any) => {
  const [edit, setEdit] = useState(false);
  const [designationList, setDesignationList] = useState<any>([]);

  const getDesignationList = () => {
    getRequest(
      `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/designations`
    ).then((res) => {
      setDesignationList(res.data);
    });
  };
  const handleDesignationChange = (name: any) => {
    if (props.form.getFieldValue(["spoc", name, "designation"]) === "Other") {
      props.form.setFieldValue(["spoc", name, "isOtherDesignation"], true);
    } else {
      props.form.setFieldValue(["spoc", name, "isOtherDesignation"], false);
    }
  };
  const optionsToRender = (options: any[], identifier: any) => {
    return options?.map((value: any) => (
      <Option key={value.id} value={value?.[identifier]}>
        {value?.[identifier]}
      </Option>
    ));
  };
  return (
    <>
      {
        <Row
          justify="space-between"
          style={{ minHeight: 50 }}
          className={
            props.editable
              ? "list-item-container-editable list-item"
              : "list-item-container list-item"
          }
        >
          <Col>
            {edit ? (
              <Form.Item
                name={[props.form.name, "name"]}
                style={{ margin: 0 }}
                rules={[
                  { required: true, message: "Please enter contact name!" },
                ]}
              >
                <Input
                  className="input-number-override"
                  style={{ fontSize: 12 }}
                  placeholder="Name"
                />
              </Form.Item>
            ) : (
              props.item?.name
            )}
          </Col>
          <Col>
            {edit ? (
              <Form.Item
                name={[props.form.name, "designation"]}
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter contact designation!",
                  },
                ]}
              >
                <Select
                  showSearch={true}
                  onClick={getDesignationList}
                  className="select-input-custom"
                  placeholder="Designation"
                  onChange={() => handleDesignationChange(props.form.name)}
                >
                  {optionsToRender(designationList, "name")}
                </Select>
              </Form.Item>
            ) : (
              props.item?.designation
            )}
          </Col>
          <Col>
            {edit ? (
              <Form.Item
                name={[props.form.name, "contactNumber"]}
                style={{ margin: 0 }}
                rules={[
                  { required: true, message: "Please enter contact number!" },
                ]}
              >
                <Input
                  style={{ fontSize: 12 }}
                  maxLength={10}
                  placeholder="Contact Number"
                />
              </Form.Item>
            ) : (
              props.item?.contactNumber
            )}
          </Col>
          <Col>
            {edit ? (
              <Form.Item
                name={[props.form.name, "emailId"]}
                style={{ margin: 0 }}
                rules={[{ required: true, message: "" }]}
              >
                <Input style={{ fontSize: 12 }} placeholder="Email" />
              </Form.Item>
            ) : (
              props.item?.emailId
            )}
          </Col>

          <Col
            style={{
              display: props.editable ? "block" : "none",
              width: 180,
              padding: 5,
            }}
          >
            {props.spocList.length > 1 ? (
              <Button
                style={{ padding: "0 5px", fontSize: 12 }}
                type="link"
                danger
                icon={props.loader ? <TrashIcon /> : <></>}
                onClick={() => props.delete(props.item.spocId)}
              >
                Delete
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      }
    </>
  );
};
