import { Button, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import getHtmlToText from '../../utils/helpers/htmlToFormattedText';
import 'react-quill/dist/quill.snow.css';
import { ReactComponent as EditIcon } from '../../../src/pages/Dashboard/Prospect/utils/SVGs/edit_note.svg'; 
import { ReactComponent as SaveIcon } from '../../../src/pages/Dashboard/Prospect/utils/SVGs/save_icon.svg'; 

import { ReactComponent as Cancel } from '../../pages/Dashboard/Prospect/utils/SVGs/Cancel.svg';
import { convert } from 'html-to-text';
import "./helpers.css";

interface TextEditorProps {
  visible: boolean;
  note: string;
  setNoteText: (text: string) => void;
  onEditorClose: () => void;
  onSave: () => void;
  editable: boolean;
  setEditable: (editable: boolean) => void;
  title: string;
  placeholder: string;
  showEditButton?: boolean;
  isRejectFeedback ?: boolean;
  viewOnly ?: boolean;
}

const RemarkAndFeedbackEditor: React.FC<TextEditorProps> = ({  
  visible,
  note,
  setNoteText,
  onEditorClose,
  onSave,
  editable,
  setEditable,
  title,
  placeholder,
  showEditButton,
  isRejectFeedback,
  viewOnly,
}) => {
  
    const handleChange = (content: any) => {
    setNoteText(content);
  };

  const handleAction = () => {
    if(editable){
      onSave();
    }
    else{
      setEditable(true);
    }
  };

  const handleGoAheadAction = () => {
    onSave();
  };
  const getNoteLength = (note : string) => {
    const finalText  = convert(note);
    if(finalText == '\n') return 0;
    else return finalText.length;
  }
  const getSaveOrEditText = ()=>{
    return (editable ? "Save" : placeholder!='' ? `Edit ${placeholder==='Remarks' ? 'Remark' : 'Feedback'}` :"Edit");
  }
  console.log(`editable=%o`,editable)
  return (
    <Modal
      title={
        editable ? <span className="modal-header-font">{placeholder!='' ? placeholder : 'Edit Note'}</span> 
        : <>
            <span className="modal-header-font">
                {placeholder && <div style={{fontSize:'16px', fontWeight:'700'}}>{placeholder}</div>}
                 </span>
            {
            placeholder==='Feedback' && title==='View Only' ?
            <span style={{fontSize: 16, fontWeight: 500, color: "#404040"}}>
             
            </span>
            :  
            <span style={{fontSize: 16, fontWeight: 500, color: "#404040"}}>
              Created at - {title}
            </span>
            }
          </>
      }
      visible={visible}
      width={600}
      onCancel={onEditorClose}
      className='modal-override'
      centered={true}
      footer={[
        placeholder!='' && 
        <Button icon={<Cancel />} style={{height:'2.5rem',color:'#FF4D4F',backgroundColor:'rgba(255, 77, 79, 0.10)',borderColor:'#FF4D4F', fontWeight: '500'}} key="cancel" onClick={onEditorClose}>
        Cancel
       </Button>,
       
        (
          !showEditButton ?
          ''
          :
          <Button style={{height:'2.5rem'}} disabled={(viewOnly || (!note)) || 
              ( (getSaveOrEditText().startsWith("Edit")) ? false : (getNoteLength(note) > 0 ? false : true ))} 
            icon={editable ? <SaveIcon/>: <EditIcon/>} key="confirm" type="primary"  onClick={handleAction}>
            {getSaveOrEditText()}
          </Button>),
        (
          isRejectFeedback && !editable  &&
          <Button style={{height:'2.5rem'}} disabled={!note || (getNoteLength(note) > 0 ? false : true)}  icon={<SaveIcon/>} key="confirm" type="primary"  onClick={handleGoAheadAction}>
            { "Proceed" }
          </Button>),
    
      ]}
    >
      <div>
        {
          !editable
          ? <div style={{overflow: "auto", maxHeight: "150px"}}>
              {
                !note && (placeholder==='Remarks' || placeholder==='Feedback') ? `No ${placeholder} has been added`:
                getHtmlToText(note)
              }
            </div>
          : <ReactQuill
              theme="snow"
              value={note}
              onChange={handleChange}
              placeholder= {placeholder!='' ? 'Enter Remarks here':'Type note here'}
              style={{display : "flex", flexDirection : "column-reverse", width: "550px", height : "350px",  maxHeight: '250px'}}
              modules={{
                toolbar: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['code-block'],
                  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                  [{ 'align': [] }],
                  ['link', true],
                  ['clean'],
                  [{ 'header': [1, 2, false] }],
                ],
              }}
            />
        }
      </div>
    </Modal>
  );
};

export default RemarkAndFeedbackEditor;
