import React, { useState, useEffect, useRef } from 'react';
import { Table, Spin, Select, Tooltip } from 'antd';
import { EyeFilled, EyeOutlined, LeftOutlined, MinusOutlined, RightOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router';
import { ProspectNote } from '../utils/ProspectTypes';
import { getRequest, putRequest } from '../../../../utils/handler/apiHandler';
import { ReactComponent as EyeIcon } from '../utils/SVGs/eye.svg';
import { ColumnsType } from 'antd/es/table';
import { Option } from "antd/es/mentions";
import { handlePageChange, handlePageSizeChange } from '../utils/paginationFunctions';
import { getFormattedDate } from '../../../../utils/helpers/utils';
import MyRichTextEditor from '../../../../utils/helpers/MyRichTextEditor';
import { convert } from 'html-to-text';

function Notes (props : any)  {
    const refreshNoteData = props.refreshNoteData;
    const setRefreshNoteData = props.setRefreshNoteData;
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageCount, setPageCount] = useState<number>(0);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const defaultData: ProspectNote[] = [];
    const [data, setData] = useState<ProspectNote[]>(defaultData);
    const url = useLocation();
    const tableRef = useRef<HTMLDivElement>(null);
    const [isTextEditorModalVisible, setIsTextEditorModalVisible] = useState(false);
    const [viewerTitle, setViewerTitle] = useState("");
    const [noteText, setNoteText] = useState("");
    const [tableHeight, setTableHeight] = useState<number>(400);
    const [noteIdToUpdate, setNoteIdToUpdate] = useState<number>();
    const prospectId = url.pathname.split("/").pop();
    const [editable, setEditable] = useState(false);


    useEffect(() => {
        fetchProspectNoteTableData();
        setRefreshNoteData(false);
    }, [currentPage, pageSize, refreshNoteData == true]);


    const fetchProspectNoteTableData = async (): Promise<void> => {
        try {
          setLoading(true);
          const res = await getRequest(
            `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/notes?prospectId=${prospectId}&page=${currentPage-1}&size=${pageSize}`
          );
          if (res.status === 200) {
            setData(res.data.data);
            if ('pageCount' in res.data) {
              setPageCount(res.data.pageCount);
            }
            if ('totalRecord' in res.data) {
              setTotalRecord(res.data.totalRecord);
            }
          } else {
            console.error('Error fetching Prospects Table List:', res.msg);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };

    const updateNote = async (): Promise<void> => {
      try{
        setLoading(true);
        const payload = {data : noteText}
        await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/note/${noteIdToUpdate}`,
        payload);
        fetchProspectNoteTableData();
      }catch( error) {
        console.error('Error in updating prospect note:', error);
      }finally{
        setLoading(false);
      }
    };

    const handlePageSizeChangeLocal = (current: number, size: number) => {
        handlePageSizeChange(current, size, setPageSize, setCurrentPage);
    };

    const handlePageChangeLocal = (page : number) => {
        handlePageChange(page, setCurrentPage);
    };

    const editProspectNote = (record : any) => {
        setViewerTitle(getFormattedDate(record?.createdAt))
        setNoteIdToUpdate(record?.noteId);
        setIsTextEditorModalVisible(true);
        setNoteText(record?.note);
    }

    const onEditorClose = () => {
      setIsTextEditorModalVisible(false);
      setEditable(false);
    }

    const onSave = () => {
      updateNote();
      setIsTextEditorModalVisible(false);
      setEditable(false);
    }


    const getColumns = () : ColumnsType<any> => [
        {
          title: 'S. No.',
          dataIndex: 'serialNumber',
          key: 'serialNumber',
          width: '6%',
        },
        {
            title: 'Note',
            dataIndex: 'note',
            render: (text: string) => {
              const options = {
                wordwrap: 130,
              };
              const finalText = convert(text, options);
              const tooltipTitle = finalText.slice(0, Math.min(finalText.length, 250)) + " ...";
                return(
                  <Tooltip title={tooltipTitle}>
                    <div style={{display: 'inline-block', width: 300, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {finalText}
                    </div>
                  </Tooltip>
                );
            },
            key: 'note',
            width: '30%',
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            render: (obj: string) => {
                const formattedDate = getFormattedDate(obj);;
                return(
                    formattedDate
                ); 
            },
            key: 'createdAt',
            width: '15%',
        },
        {
            title: 'Created by',
            dataIndex: 'createdBy',
            render: (obj : string) => JSON.parse(obj).name,
            key: 'createdBy',
            width: '13%',
        },
        {
            title: 'Updated at',
            dataIndex: 'updatedAt',
            render: (obj: string) => {
                const formattedDate = getFormattedDate(obj);;
                return(
                    formattedDate
                ); 
            },
            key: 'updatedAt',
            width: '15%',
        },
        {
            title: 'Updated by',
            dataIndex: 'updatedBy',
            render: (obj : string) => JSON.parse(obj).name,
            key: 'updatedBy',
            width: '13%',
        },
        {
            title: 'Action',
            key: 'action',
            width: '6%',
            align: "left",
            render: (text :any , record :any) => (
              <div style={{display: "flex", justifyContent: "flex-start"}}>
                <Tooltip placement="bottom" title={"View note"}>
                  <a onClick={() => editProspectNote(record)}>
                    <EyeIcon />
                  </a>
                </Tooltip>
              </div>
            ),
        },
    ];

    const rotatedMinusIconStyle = {
        transform: 'rotate(90deg)',
    };

    return ( 
        <React.Fragment>
            <div style={{ backgroundColor: '#F6FAFF', height: '100%'}}>
              <div ref={tableRef} style={{ height: 0.80 * tableHeight }}>
                  <Spin spinning={loading} size="large">
                      <Table
                          columns={getColumns()}
                          dataSource={data || []}
                          pagination={{
                          pageSize: pageSize,
                          position: ["bottomRight"],
                          showSizeChanger: false,
                          showTotal: (total, range) => (
                              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', marginTop:'-4px' }}>
                              <div style={{fontSize:'1rem'}}>
                                  <span>Showing</span>
                                  <Select defaultValue={10} style={{ width: 60, margin: '0 5px' }} onChange={(value) => handlePageSizeChangeLocal(1, value)}>
                                      <Option value="10">10</Option>
                                      <Option value="20">20</Option>  
                                      <Option value="50">50</Option>
                                      <Option value="100">100</Option>
                                  </Select>
                                  <span>Per Page</span>
                                  <span style={{ marginRight:'10px', marginLeft:'10px', color:'grey'}}><MinusOutlined style={rotatedMinusIconStyle} /></span>
                                  <span>{`Results: ${range[0]}-${range[1]} of ${total}`}</span>
                              </div>
                              </div>
                          ),
                          onChange: handlePageChangeLocal,
                          current: currentPage,
                          total: totalRecord,
                          itemRender: (current, type, originalElement) => {
                              if (type === 'prev') {
                              return <LeftOutlined style={{marginLeft:'4rem'}}/>;
                              }
                              if (type === 'next') {
                              return  <RightOutlined />;
                              }
                              if (type === 'page') {
                              return (
                                  <span style={{cursor: 'pointer' }} onClick={() => handlePageChangeLocal(current)}>
                                  {current}
                                  </span>
                              );
                              }
                              if (type === 'jump-next' || type === 'jump-prev') {
                              return <a style={{color: 'black'}}><strong> ... </strong></a>;
                              }
                              return originalElement;
                          },
                          }}            
                          scroll={{ x: 'max-content', y: 0.80 * tableHeight }}
                          size="middle"
                          components={{
                          header: {
                              wrapper: (props: any) => <thead style={{ backgroundColor: '#CEE6FA', height: '3.5rem' }}>{props.children}</thead>,
                          },
                          }}
                      />
                  </Spin>
              </div>
          </div>
          <MyRichTextEditor
            visible = {isTextEditorModalVisible}
            title={editable ? "Edit Text" : viewerTitle}
            note = {noteText}
            setNoteText={setNoteText}
            onEditorClose = {onEditorClose}
            onSave = {onSave}
            editable={editable}
            setEditable={setEditable}
            isAddModal={false}
            placeholder=''
          />
        </React.Fragment>
    );
};

export default Notes;