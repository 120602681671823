import axios from 'axios';
import { any } from 'prop-types';

type FieldType = {
  [key: string]: string | number | boolean;
};

async function getFinalQuoteComponentList(
  formData: FieldType,
  auth: { accessToken: string },
  tenures: any,
  RMTYValue: null|number,
  replcacementVehicleValue:null|number,
  fabricationAmountTotal: number,
  fabricationGST: number,
  setPendingRequests: React.Dispatch<React.SetStateAction<number>>,
) {

  const services: any = JSON.parse(formData.services?.toString() || '[]');
  const accessories: any = JSON.parse(formData.accessories?.toString() || '[]');
  
  const servicesObjList = services.map((service: any) => {
    return {
      'name': service.name, 
      'totalAmount': parseInt(service.name==='RMTY'? RMTYValue : service.name==='Replacement Vehicle'? replcacementVehicleValue : service.inMonth,10)}
  });
  const accessoriesObjList = accessories.map((accessory: any) => {
    return {
      'name': accessory.name, 
      'totalAmount': parseInt(accessory.totalAmount,10)||0,
      'gst': parseFloat(accessory.gst).toFixed(2) || 0,
    }
  });
  const quoteCalculatorRequest = {
    'tenureInMonth': tenures.find((tenure: any)=> tenure.id === formData.tenureInMonthId)?.inMonth,
    'mileage': formData.mileagePerMonth,
    "exShowroom": formData.exShowroom,
    "fameSubsidy": formData.fameSubsidy == undefined ? 0 : formData.fameSubsidy,
    "stateSubsidy": formData.stateSubsidy == undefined ? 0 : formData.stateSubsidy,
    "discount": formData.discount == undefined ? 0 : formData.discount,
    "roadTax": formData.roadTaxType=='Not Managed' ? 0 : formData.roadTaxTotal,
    "roadTaxType": formData.roadTaxType,
    "insuranceType": formData.insuranceType,
    "firstYrInsuranceAmt": formData.firstYearInsurancePremium || 0,
    "registeredTo": formData.registeredTo,
    "managementFee": formData.managementFees,
    "fabrication": fabricationAmountTotal ? fabricationAmountTotal : 0,
    "fabricationGst": fabricationGST ? fabricationGST : 0,
    "services": servicesObjList,
    "accessories":accessoriesObjList,
    "paymentType": formData.paymentType,
    "isGreenCessApplicable":false,
    "costOfFund": formData.costOfFundPercentage,
    "offerRate": formData.offerRatePercentage,
    "rvPercentage":formData.rvPercentage,
    "tcs":formData.tcs,
    "gstOnVehicle":formData.gstOnVehicle,
  };
 
  let quoteCalculatorResponse;
  try {
    setPendingRequests(prevCounter => prevCounter + 1); 
    const response = await axios.post(
      `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/quoteCalculator`,
      quoteCalculatorRequest,
      { headers: { Authorization: `Bearer ${auth.accessToken}` } }
    );
  
    quoteCalculatorResponse = response.data;
  } catch (err: any) {
    if(err.response.status === 401)
      window.location.reload();
  } finally {
    setPendingRequests(prevCounter => prevCounter - 1); 
  }
  let componentList: any[] = [['Base Price','Vehicle Base Price', quoteCalculatorResponse.basePrice, ``],
     ['GST @5%', 'GST on Vehicle', quoteCalculatorResponse.gstOnVehicle, ''],
     ['Ex Showroom', 'Vehicle Price', quoteCalculatorResponse.exShowroom, ''], 
     ['Fame Subsidy', 'Subsidy', quoteCalculatorResponse.fameSubsidy, ''], 
     ['State Subsidy', '', quoteCalculatorResponse.stateSubsidy, ''], 
     ['TCS', '', quoteCalculatorResponse.tcs, ''], 
     ['Discount (Ex Showroom)', 'Discount', quoteCalculatorResponse.discount, ''], 
     [`${formData.roadTaxType}`, 'Total EMI for Road Tax with Fastag', quoteCalculatorResponse.roadTaxTotal, quoteCalculatorResponse.roadTaxInMonth], 
     ['Insurance', 'Total EMI for Insurance', quoteCalculatorResponse.insuranceAmountTotal, quoteCalculatorResponse.insuranceAmountInMonth], 
     ['Fabrication','Fabrication',quoteCalculatorResponse.fabricationBaseAmountTotal, quoteCalculatorResponse.fabricationBaseAmountInMonth],
    ];
  quoteCalculatorResponse.accessories.forEach((accessory: any )=> {
    componentList.push([accessory.name,'Accessory', accessory.baseAmount || 0, accessory.baseAmountInMonth?.toFixed(2) || 0]);
  });  

  quoteCalculatorResponse.services.forEach((service: any )=> {
  componentList.push([service.name,'Service', service.totalAmount, service.inMonthAmount.toFixed(2)]);
  }); 

  const moreComponent: any[] = [
    ['Management Fees','Management Fees', '', quoteCalculatorResponse.managementFeeInMonth],
    ['GST on Services','FMGST', '', quoteCalculatorResponse.gstOnServicesInMonth], 
    ['Fund Blockage Charges', 'FBC', '', quoteCalculatorResponse.fbcInMonth],
    ['GST on FBC', '', '', quoteCalculatorResponse.gstOnFbcInMonth],
    ['Total Lease Rental(without tax)', 'Base Lease Rental', '', quoteCalculatorResponse.leaseRentWithoutTaxInMonth], 
    ['Fleet Management', 'Services and Management Fees', '', quoteCalculatorResponse.fleetManagementInMonth], 
    ['Total Taxes', 'GST(Finance + Services + FBC)', '', quoteCalculatorResponse.totalTaxInMonth],
    ['Total Lease Rental(with tax)', 'Total EMI', '', quoteCalculatorResponse.leaseRentWithTaxInMonth]]  ;
  componentList.push(...moreComponent);
  quoteCalculatorResponse.rvWithGst = quoteCalculatorResponse.rvWithGst.toFixed(2);
  return  [componentList, quoteCalculatorResponse] ; 
}

export default getFinalQuoteComponentList;
