import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, message, Select, DatePicker } from "antd";
import { authSelector } from "../../../features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { MailOutlined, UserOutlined } from '@ant-design/icons';
import { useLocation } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { setCities, utilsSelector } from "../../../features/utils/utilsSlice";
import moment from 'moment';

const { TextArea } = Input;
const { Option } = Select;

interface FormDetails {
    email: string,
    firstname: string,
    lastname: string,
    role: string[],
    contactNo: string,
    department: string,
    designation: string,
    location: string,
    date_of_joining: string,
}

const initFormState = {
    email: "",
    firstname: "",
    lastname: "",
    role: [],
    contactNo: "",
    department: "",
    designation: "",
    location: "",
    date_of_joining: ""
    }

const handleChange = (value: string) => {
    console.log(`selected ${value}`);
};

const EditUser = () => {
    const payload = useLocation().state;
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [fromData, setFormData] = useState<FormDetails>(initFormState);
    const [location, setLocation] = useState<any>([]);
    const [designation, setDesignation] = useState<any>([]);
    const [department, setDepartment] = useState<any>([]);
    const [role, setRole] = useState<any>();
    const [roles, setRoles] = useState<any>();
    const [userRoles, setUserRoles] = useState<any>();
    const auth = useAppSelector(authSelector);
    const dispatch = useAppDispatch();
    const utils = useAppSelector(utilsSelector);
    const cities = utils.cities;


    const prevDate = dayjs(payload.data.dateOfJoining);
    console.log(dayjs(payload.data.dateOfJoining).format("YYYY-MM-DD"), payload.data.dateOfJoining)


    const onFinish = async (values: any) => {
        setFormData(values);

        for (let key in values) {
            if (values[key] === undefined) {
              delete values[key];
            }
          }
          if(values.role)
                values.role = [values.role]
        console.log(values)

        try {
            // Note: update api endpoint
            await axios.put(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/users/${payload.data.userId}`, values, {
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`,
                    "Content-Type": "application/json"
                }
            }).then(res => {
                if(res.status === 200) {
                    messageApi.success("User details updates!");
                    setTimeout(() => {
                        window.open(`${process.env.REACT_APP_REDIRECT_ENDPOINT}/dashboard/users`, "_self");
                    }, 1000);
                }
                else 
                    messageApi.error("Failed to update user details!");
            })
        } catch (err) {
            console.log(err);
            messageApi.error("Somthing went wrong");
        }
    };
    
    // fetching all roles
    useEffect(() => {
        const fetchData = async () => {
          try {
            await axios.post(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/cities`, {}, {
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`,
                    "Content-Type": "application/json"
                }
            }).then(res => {
                dispatch(setCities(res.data));
            })

            await axios.get(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/departments`, {
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`,
                    "Content-Type": "application/json"
                }
            }).then(res => {
                let input: any = [];
                res.data.map((e: any) => {
                    input.push({
                        label: e.departmentName,
                        value: e.departmentName
                    });
                })
                setDepartment(input);
            })

            await axios.get(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/designations`, {
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`,
                    "Content-Type": "application/json"
                }
            }).then(res => {
                let input: any = [];
                res.data.map((e: any) => {
                    input.push({
                        label: e.name,
                        value: e.name
                    });
                })
                setDesignation(input);
            })

            await axios.get(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/roles`, {
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`,
                    "Content-Type": "application/json"
                }
            }).then(res => {
                let input: any = [];
                res.data.map((e: any) => {
                    input.push({
                        label: e.name,
                        value: e.name
                    });
                })
                setRole(input);
            })
            // fetching roles of user
            await axios.get(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/users/user-roles?id=${payload.data.id}`,{
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`,
                    "Content-Type": "application/json"
                }
            }).then(res => {
                setUserRoles(res.data.roles);
            });
          } catch (error) {
            console.error('Error fetching dbdata:', error);
          }
        };
        fetchData();
      }, []);

    const optionsToRender = (options: any[]) => {
    return options.map((value: any) => (
        <Option key={value.id} value={value.name}>
        {value.name}
        </Option>
    ));
    };

    function disabledDate(current: any) {
    // Can not select days future dates
    return current && current > moment().endOf('day');
    }
    return (
        <React.Fragment>
        {contextHolder}
        <Form
            // initialValues={initialValues}
            form={form}
            onFinish={onFinish}
            layout="vertical"
            className="form-container"
        >
        <h4>Edit User</h4>
        <Row className="">
                <Col className="form-column">
                    <Form.Item
                        label="First Name"
                        name="firstname"
                    >
                    <Input disabled defaultValue={payload.data.name.split(" ")[0]} placeholder="First name" />
                    </Form.Item>
                    <Form.Item
                        label="Last Name"
                        name="lastname"
                    >
                    <Input disabled defaultValue={payload.data.name.split(" ")[1]} placeholder="Last name" />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                    >
                        <Input disabled defaultValue={payload.data.emailId} placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        label="Designation"
                        name="designation"
                        // rules={[{ required: true, message: 'Please enter designation!' }]}
                    >
                        <Select
                            showSearch={true}
                            defaultValue={payload.data.designation}
                            style={{ width: '100%' }}
                            placeholder="Designations"
                            onChange={handleChange}
                            options={designation}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Location"
                        name="location"
                        // rules={[{ required: true, message: 'Please enter location!' }]}
                    >
                        <Select
                            showSearch={true}
                            defaultValue={payload.data.location}
                            style={{ width: '100%' }}
                            placeholder="Locations"
                            onChange={handleChange}
                            // options={location}
                        >
                            {optionsToRender(cities)}
                        </Select>
                    </Form.Item>
                </Col>
                <Col className="form-column">
                    <Form.Item
                        label="Date of joining"
                        name="date_of_joining"
                        // rules={[{ required: true, message: 'Please enter date of joining!' }]}
                    >
                        <DatePicker
                            disabled
                            format="YYYY-MM-DD"
                            defaultValue={dayjs(payload.data.dateOfJoining)}
                            style={{ width: "100%"}}
                            disabledDate={disabledDate}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Role"
                        name="role"
                        // rules={[{ required: true, message: 'Please input user roles!' }]}
                    >
                        <Select
                            showSearch={true}
                            defaultValue={payload.data.role}
                            style={{ width: '100%' }}
                            placeholder="Roles"
                            onChange={handleChange}
                            options={role}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Department"
                        name="department"
                        // rules={[{ required: true, message: 'Please enter department!' }]}
                    >
                        <Select
                            showSearch={true}
                            defaultValue={payload.data.department}
                            style={{ width: '100%' }}
                            placeholder="Department"
                            onChange={handleChange}
                            options={department}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Contact Number"
                        name="contactNo"
                        // rules={[{ required: true, message: 'Please input your contact number!' }]}
                    >
                        <Input maxLength={10} defaultValue={payload.data.contactNo} placeholder="Contact number" />
                    </Form.Item>
                    <Form.Item
                        label="Remark"
                        name="remark"
                        rules={[{ required: true, message: 'Please provide the remark!' }]}
                    >
                        <TextArea
                        showCount
                        maxLength={100}
                        placeholder="Add remark..."
                        />
                    </Form.Item>
                </Col>
            </Row>

        <Form.Item>
        <Button type="primary" htmlType="submit">
            Submit
        </Button>
        </Form.Item>
        </Form>
        </React.Fragment>
    );
};

export default EditUser;