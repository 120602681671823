import React from 'react';
import { Tabs, Typography } from 'antd';
import Notes from './Notes';
import LeadHistory from './LeadHistory';
import SampleQuotes from './SampleQuotes';

const TabPane = Tabs.TabPane;
const { Title } = Typography;

function ProspectHistory(props: any) {
    return ( 
        <React.Fragment>
            <div className="container-inner">
                <Title style={{fontSize: 16}} level={5}>Prospect History</Title>
                <Tabs defaultActiveKey="1" className="sub-tab" onTabClick={(e) => console.log(e)}>
                    <TabPane tab={<span className="sub-tab-heading">Notes</span>} key="1">
                        <Notes 
                            refreshNoteData = {props.refreshNoteData}
                            setRefreshNoteData = {props.setRefreshNoteData}
                        />
                    </TabPane>
                    <TabPane tab={<span className="sub-tab-heading">Lead stage history</span>} key="2">
                        <LeadHistory />
                    </TabPane>
                    <TabPane tab={<span className="sub-tab-heading">Sample Quotes</span>} key="3">
                        <SampleQuotes />
                    </TabPane>
                </Tabs>
            </div>
        </React.Fragment>
     );
}

export default ProspectHistory;