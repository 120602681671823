import React from 'react';

const SortUpperFadedIcon = ({ iconFill, style }) => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    {/* Background */}
    <rect width="100%" height="100%" fill="#CEE6FA"/>

    {/* Icon path */}
    <path
      id="Vector"
      d="M0.624974 6.96458H6.80572C7.36146 6.96458 7.63934 7.74529 7.2472 8.20045L4.15683 11.7875C3.91271 12.0708 3.51798 12.0708 3.27646 11.7875L0.183492 8.20045C-0.208647 7.74529 0.0692264 6.96458 0.624974 6.96458ZM7.2472 3.79955L4.15683 0.212509C3.91271 -0.0708365 3.51798 -0.0708365 3.27646 0.212509L0.183492 3.79955C-0.208647 4.25471 0.0692264 5.03542 0.624974 5.03542H6.80572C7.36146 5.03542 7.63934 4.25471 7.2472 3.79955Z"
      fill={iconFill || "#000"} // Default icon fill color is black
    />

    {/* Apply transparency to upper half of the icon */}
    <path
      id="transparencyMask"
      d="M0 0h8v6H0z"
      fill="#CEE6FA"
      fillOpacity="0.7"
    />
  </svg>
);

export default SortUpperFadedIcon;
