import React, { useEffect, useState } from "react"
import { Button, Card, Form, message, Modal, Spin, Typography } from "antd";
import { ReactComponent as DisabledIcon } from "../../../SVGs/disabled_icon.svg";
import { ReactComponent as CrossIcon } from '../../../SVGs/cross_icon.svg';
import { ReactComponent as RightArrowIcon } from '../../../SVGs/right_arrow_icon.svg';
import CreditRequestForm from "../../../Credit/components/CreditRequestForm";
import CreditRequestPreview from "../../../Credit/components/CreditRequestPreview";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest, postRequest, putRequest } from "../../../../../utils/handler/apiHandler";
import { LoadingOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppSelector } from "../../../../../app/hooks";
import { userSelector } from "../../../../../features/auth/userSlice";
import RemarkModal from "../../../Credit/components/RemarkModal";

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;

interface DocumentActionProps {
    roleAccess: boolean;
    segment: string;
    creditRequestStatus: number;
    reviewForRequestAllowed: boolean;
    stageId: number;
    buttonFlag: boolean;
}
const DocumentAction: React.FC<DocumentActionProps> = ({ segment, creditRequestStatus, stageId, buttonFlag }) => {
    const user = useAppSelector(userSelector);
    const [form] = Form.useForm();
    const notify = () => toast.success('Credit assessment request raised successfully.');
    const [loader, setLoader] = useState(false);
    const [formOpen, setFormOpen] = useState(false);
    const [submitData, setSubmitData] = useState(false);
    const [businessSegment, setBusinessSegment] = useState([]);
    const prospectId = useLocation().pathname.split("/").pop() as string;
    const [addParent, setAddParent] = useState(false);
    const [selectedBusinessSegment, setSelectedBusinessSegment] = useState<any>(null);
    const [bypassModal, setBypassModal] = useState<boolean>(false);
    const [remark, setRemark] = useState<string>("");
    const navigate = useNavigate();
    
    const flag = (user.role === "GEVL-CREDIT-MANAGER" || user.role === "GEVL-CREDIT-HEAD" || user.role === "GEVL-ADMIN") && buttonFlag;

    const showModal = () => {
        setFormOpen(true);
    };
  
    useEffect(() => {
        console.log(form.getFieldsValue(true));

        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/segments`).then(res => {
            setBusinessSegment(res.data);
        })
        form.setFieldsValue({businessSegment: segment})
    }, []);

    useEffect(() => {
        form.setFieldsValue({ addParent: !addParent });
    }, [addParent]);
    
    const hideModal = () => {
        setFormOpen(false);
    };

    const onSubmit = async (process: number) => {
        try {
            await form.validateFields()
            setSubmitData(true); 
            const formData = form.getFieldsValue(true);
            const segment: any = businessSegment.filter((item: any) => item["name"] === formData.businessSegment)[0];

            console.log(process);

            let creditRequirement: any = [];
            formData.requirementDetails.map((e: any) => {
                creditRequirement.push({
                    vehicleCategory: JSON.stringify(e.vehicleCategoryObj),
                    noOfVehicle: e.noOfVehicle,
                    leaseTerm: JSON.stringify(e.leaseTermObj),
                    interestRateOffered: e.interestRateOffered,
                    investmentPerVehicle: e.investmentPerVehicle,
                    totalValue: e.investmentPerVehicle*e.noOfVehicle
                })
            })
            if(submitData) {
                setLoader(true);
                const payload = {
                    prospectId: ~~prospectId,
                    childEntityName: formData.legalBusinessName,
                    addParent: addParent,
                    parentEntity: formData.parentEntityObj || null,
                    isCommercialMeetingHeld: formData.commercialPhysicalMeeting ? true : false,
                    currentYearFleetRequirement: formData.currentYearFleetRequirement,
                    entityDescription: formData.entityDescription || null,
                    remark: formData.addRemark || null,
                    creditRequirement: creditRequirement,
                    segment: segment?.id === 14 ? { id: null, name: formData.otherBusinessSegment} : segment,
                    isOtherSegment: segment?.id === 14 ? true : false,
                    statusChangeTypeId: process === 0 ? 1 : 7,
                    byPassRemark: process === 1 ? remark : null,
                }
                handleSubmit(payload);
            }
        } catch (err) {
            setLoader(false)
            console.log(err);
        }
    }

    const handleSubmit = (payload: any) => {
        postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit`, payload).then(res => {
            message.destroy();
            if(res.status === 201) {
                setFormOpen(false);
                setLoader(true);
                notify();
                navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`);
                setBypassModal(false);
                setTimeout(() => window.location.reload(), 1000);
            }
        })
    }
    
    const handleResetForm = () => {
        form.resetFields(["legalBusinessName", "parentName", "otherParentName", "otherBusinessSegment", "currentYearFleetRequirement", "entityDescription", "addRemark", "requirementDetails", "commercialPhysicalMeeting"])
    }

    return (
        <React.Fragment>
            {
                flag &&
                <div className="" style={{display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: 20}}>
                    {/* <Button 
                        type="primary" 
                        icon={<DisabledIcon />} 
                        disabled={true} 
                        className={"button-override disable"}
                    >
                        Bypass Process
                    </Button> */}
                    <Button 
                        type="primary" 
                        className="button-override" 
                        style={{marginLeft: 10}} 
                        onClick={showModal}
                    >
                        Raise Credit Request
                    </Button>
                </div>
            }

        <Modal
            title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 500, color: "#404040"}}>{ !submitData ? "Raise Credit Request" : "Review Details"}</Typography>}
            centered
            closable={true}
            open={formOpen}
            onCancel={() => {setFormOpen(false); handleResetForm(); }}
            width={"80%"}
            className="preview-modal"
            footer={<>
                <div className="edit-section">
                    {!submitData && 
                    <Button
                    danger
                    ghost
                    icon={<CrossIcon />}
                    className="button-override danger"
                    onClick={_=> { hideModal(); handleResetForm(); }}
                    >
                    Cancel
                    </Button>
                    }
                    {(submitData) && <>
                    <Button
                        ghost
                        type="primary"
                        className="button-override primary"
                        icon={<RightArrowIcon style={{ rotate: "180deg"}}/>}
                        onClick={_=> setSubmitData(false)}
                        >
                        Go Back
                    </Button>
                    <Button
                        ghost
                        type="primary"
                        className="button-override secondary"
                        icon={<DisabledIcon style={{ rotate: "180deg"}}/>}
                        onClick={_=> {setBypassModal(true)}}
                        >
                        Bypass Assessment
                    </Button>
                    </>}
                    <Button
                    type="primary"
                    className="button-override"
                    onClick={_=> {onSubmit(0)}}
                    >
                    {submitData ? "Raise Credit Request" : "Proceed"}
                    {
                        loader
                        ? <Spin indicator={antIcon} />
                        : <RightArrowIcon style={{ marginLeft: 10 }}/>
                    }
                    </Button>
                </div>
            </>}
        >
            <Typography>{ !submitData ? "Provide these details to raise credit request" : "Review these details before raising credit request"}</Typography>
            {
                !submitData 
                ? <Card style={{marginTop: 20, border: "none", borderRadius: 4}}>
                    <CreditRequestForm segment={segment} form={form} setAddParent={setAddParent} addParent={addParent} setSelectedBusinessSegment={setSelectedBusinessSegment}/>
                </Card>
                : <CreditRequestPreview form={form} data={null} setAddParent={setAddParent} addParent={addParent} />
            }
            
        </Modal>
        <RemarkModal title="To bypass credit assessment, Enter the reason for the same." subTitle="Enter reason for bypass" btnText="Bypass Process" loader={false} showModal={bypassModal} setShowModal={setBypassModal} remark={remark} setRemark={setRemark} handler={() => onSubmit(1)} type="bypass" />
        <ToastContainer />
        </React.Fragment>
    )
}

export default DocumentAction;