import { PlusOutlined, ReloadOutlined, UploadOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Button, Table, Tooltip, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAppSelector } from '../../../app/hooks';
import { authSelector } from '../../../features/auth/authSlice';
import { menuSelector } from '../../../features/menu/menuSlice';
import getDynamicAction from '../../../utils/helpers/dynamicAction';

interface VaahanInput {
    chassisNo: number,
    regNo: number,
    updateByUsername:string,
    status: number,
    terminationDate:string,
}

type DataIndex = keyof VaahanInput;

function VaahanHPTList() {
    const navigate = useNavigate();
    const [data, setData] = useState<VaahanInput[]>([]);
    
    const [messageApi, contextHolder] = message.useMessage();
    
    const auth = useAppSelector(authSelector);

    const pageUrl = useLocation().pathname.split("/").pop();
    const menus = useAppSelector(menuSelector).menu;
    const actionItem = getDynamicAction(menus, pageUrl, "vaahan");

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get<VaahanInput[]>(
              `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/reportservice/vaahan/allVaahanHpt`,
            {
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`
                }
            }
            );
            setData(response.data);
          } catch (error) {
            console.error('Error fetching dbdata for allVaahanHpt:', error);
          }
        };
    
         fetchData();
      }, []);

    const resubmitHpt = (data: any) => {
        const fetchHPTById = async ()=>{
            try {
                const response = await axios.post<any>(
                  `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/reportservice/vaahan/vaahanHptById?id=${data?.id}`,
                  {},
                {
                    headers: {
                        "Authorization": `Bearer ${auth.accessToken}`
                    }
                }
                );
                messageApi.success('Vaahan HPT Resubmited Successfully!');
                setTimeout(()=>{
                    navigate(`/dashboard/vaahan-hpt`);
                },1000);
              } catch (error) {
                console.error('Error fetching resubmitResponse:', error);
              }
        };
        fetchHPTById();
    }   


    const columns: ColumnsType<VaahanInput> = [
        {
            title: 'Chassis No.',
            dataIndex: 'chassisNo',
            key: 'chassisNo',
            width: '15%',
        },
        {
            title: 'Registration No.',
            dataIndex: 'regNo',
            key: 'regNo',
            width: '15%',
        },
        {
            title: 'Termination Date',
            dataIndex: 'terminationDate',
            key: 'terminationDate',
            width: '15%',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '15%',
            render: (record, rowIndex) => {
              let textColor = ''; // Default color
              let statusInText = '';
              if (record === 1) {
                textColor = 'green';
                statusInText = 'Submited';
              } else if (record === -1) {
                textColor = 'orange';
                statusInText = 'Pending';
              } else {
                textColor = 'red';
                statusInText = 'Error';
              }
                    
              return (
                <p style={{ color: textColor }}>{statusInText}</p>
              );
            },
        },
        {
            title: 'Updated By',
            dataIndex: 'updateByUsername',
            key: 'updateByUsername',
            width: '15%',
        },
          
        {
            title: 'Actions',
            width: '7%',
            render: (record, rowIndex) =>
                 {
                    const isDisabled = record.status === 1;
                    
                    return (
                        <Tooltip placement="bottom" title={isDisabled ? "Action Disabled" : "Resubmit Pending HPT"}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '0.6rem' }}>
                                <a
                                onClick={_ => !isDisabled && resubmitHpt(record)}
                                style={{
                                    pointerEvents: isDisabled ? 'none' : 'auto',
                                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                                    opacity: isDisabled ? 0.3 : 1, // Adjust the opacity value as needed
                                    transition: 'opacity 0.3s ease-in-out', // Add a smooth transition effect
                                }}
                                >
                                <ReloadOutlined />
                                </a>
                            </div>
                        </Tooltip>
                    )
                },
        },
        
    ];

    return (
        <React.Fragment>
            <div className="form-container">
                <div className="page-header" style={{display:'flex', justifyContent:'space-between'}}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={_=> navigate("/dashboard/vaahan-hpt/create-hpt")}  style={{display: actionItem?.["Add New HPT Entry"] === "true" ? "block" : "none"}}>
                        Add New HPT Entry
                    </Button>
                </div>
                <Table 
                    columns={columns} 
                    dataSource={data}
                    pagination={{
                        pageSize: 8,
                    }}
                    scroll={{ x: 800 }}
                />
            </div>
        </React.Fragment>     
    )
            
};

export default VaahanHPTList;