import { Button, Tag } from 'antd';
import { ReactComponent as AddIcon } from '../.././utils/SVGs/add_icon.svg';
import { ReactComponent as EditIcon } from '../.././utils/SVGs/edit_icon.svg';
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from 'react';
import { SALES_ROLE, SALES_EDITABLE_DOCUMENT_STATE, CREDIT_ROLE, CREDIT_EDITABLE_DOCUMENT_STATE } from '../../../constants';
import {getDisableModifyButtonFlag} from './documentUtils'
import AddDocumentSelectModal from './AddDocumentSelectModal';

function DocumentHeader(props: any) {
  const [addDocumentSelectModalVisible, setAddDocumentSelectModalVisible] = useState<boolean> (false);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const navigate = useNavigate();
  const url = useLocation();
  const prospectId = url.pathname.split("/").pop();
  const disableAddModifyDocsButton =  getDisableModifyButtonFlag(props.userRole, props.docUploadStatus?.name);
  
  const onAddDocumentSelectModalSave = () => {
    setAddDocumentSelectModalVisible(false);
    props.setReloadDocumentTab(Math.random());          
  } 
  const onAddDocumentSelectModalClose = () => {
    setAddDocumentSelectModalVisible(false);
  }
  
  // console.log(props.stageId === 10 || disableAddModifyDocsButton)
  return ( 
            <div style={{display: 'flex', justifyContent:'space-between'}}>
                <div style={{display:'flex'}}>
                  <p style={{fontSize:'16px', fontWeight:'700'}}>Documents</p>
                  {
                    props.docUploadStatus && !SALES_ROLE.has(props.userRole) ?
                    <a onClick={()=>navigate("/dashboard/credit-document-review-request")}>
                      <Tag style={{marginLeft: 10, padding:'4px 8px', border: "none", color: props.docUploadStatus?.color, backgroundColor: props.docUploadStatus?.backgroundColor, fontWeight: 500}}>{props.docUploadStatus?.name}</Tag>
                    </a>
                    :
                    <p style={{marginLeft:'1.8rem', padding:'4px 8px',borderRadius:'6px', height:'1.8rem', 
                        fontSize:'14px', fontWeight:'500', color: props.docUploadStatus?.color,backgroundColor: props.docUploadStatus?.backgroundColor}} >
                        {props.docUploadStatus?.name}
                    </p>
                  }
                </div>
                
                <Button
                    // disabled={(props.stageId === 10 || disableAddModifyDocsButton)}
                    disabled={disableAddModifyDocsButton}
                    type="primary"
                    ghost
                    onClick={_ => setAddDocumentSelectModalVisible(true)}
                    // className={(props.stageId === 10 || disableAddModifyDocsButton) ? "button-override disable" : "button-override primary"}
                    className={ disableAddModifyDocsButton ? "button-override disable" : "button-override primary"}
                    // className={"button-override primary"}
                  >
                    <span style={{ marginRight:'0.4rem' }}>
                      {props.buttonLabel==='Add Docs' ?<AddIcon /> : <EditIcon/>}
                    </span>
                    {props.buttonLabel}
                </Button>
                {
                  addDocumentSelectModalVisible && 
                  <AddDocumentSelectModal
                      visible = {addDocumentSelectModalVisible}
                      selectedItems={selectedItems}
                      documents={props.documents}
                      placeholder={`${props.buttonLabel == 'Add Docs' ? 'Add Documents' : 'Modify Documents'}`}
                      setSelectedItems={setSelectedItems}
                      onSave = {onAddDocumentSelectModalSave}
                      onEditorClose = {onAddDocumentSelectModalClose}
                />
                }
                
            </div>
       
    );
}

export default DocumentHeader;