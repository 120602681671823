import { useState, useEffect } from 'react';

export function useGlobalSearchFilter<T extends Record<string, any>>(data: T[], initialData: T[] = [], debounceInterval: number = 500) {
  const [searchText, setSearchText] = useState<string>('');
  const [filteredData, setFilteredData] = useState<T[]>(initialData);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      const updatedFilteredData = data.filter(
        (record) =>
          Object.values(record).some((value) =>
            value ? value.toString().toLowerCase().includes(searchText.toLowerCase()) : false
          )
      );
      setFilteredData(updatedFilteredData);
    }, debounceInterval);

    return () => clearTimeout(debounceTimer); // Clear the timeout on component unmount or when searchText changes
  }, [searchText, data, debounceInterval]);

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  return { filteredData, handleSearch };
}
