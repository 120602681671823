export const ADMIN = "GEVL-ADMIN";
export const AS_HEAD = "GEVL-AS-HEAD";
export const AS_MANAGER = "GEVL-AS-MANAGER";
export const SALES_HEAD = "GEVL-SALES-HEAD";
export const SALES_MANAGER = "GEVL-SALES-MANAGER";
export const PROCOUREMENT_HEAD = "GEVL-PROCOUREMENT-HEAD";
export const PROCOUREMENT_MANAGER = "GEVL-PROCOUREMENT-MANAGER";
export const SALES_ROLE = new Set(['GEVL-SALES-HEAD', 'GEVL-SALES-MANAGER', 'GEVL-SALES-EXECUTIVE']);
export const CREDIT_ROLE = new Set(['GEVL-CREDIT-HEAD','GEVL-CREDIT-MANAGER']);
export const SALES_EDITABLE_DOCUMENT_STATE = new Set(['null', 'Request Canceled', 'Feedback Submitted', 'Review Completed']);
export const CREDIT_EDITABLE_DOCUMENT_STATE = new Set(['Review Requested', 'Review Completed']);