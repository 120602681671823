import { useState } from "react";
import { setAuth } from "../features/auth/authSlice";
import { setUser, userSelector } from "../features/auth/userSlice";
import { useAppDispatch } from "../app/hooks";
import { useAppSelector } from "../app/hooks";
import { authSelector } from "../features/auth/authSlice";
import {keycloakConfig} from "../utils/keycloak.config";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as HomeIcon } from '../utils/icons/home_icon.svg';
import Sidenav from "./SideNav";
import { Dropdown, 
         Space, 
         Typography, 
         Avatar, 
         Breadcrumb, 
         MenuProps, 
         Drawer } from 'antd';
import { titleCase } from "../utils/helpers/titleCase";
import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import "../styles/Nav.sass"
import axios from "axios";
const { Paragraph, Text, Link } = Typography;

const { clientId, baseURL } = keycloakConfig;

function Topnav(props: any) {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(authSelector);
  const user = useAppSelector(userSelector);
  const navigate = useNavigate();
  const url = useLocation();
  const breadCrum = url.pathname.split("/");
  breadCrum.shift();
  const [open, setOpen] = useState(false);
  // const [messageApi, contextHolder] = message.useMessage();

  const signout = async () => {
    try {
      await axios.post(`${baseURL}/realms/GensolEvLease/protocol/openid-connect/logout`, {
        client_id: clientId,
        refresh_token: auth.refreshToken,
        // redirect_uri: process.env.REACT_APP_REDIRECT_ENDPOINT,
      },{
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }).then(res => {
        if(res.status === 204) {
          dispatch(setUser({
            name: "",
            email: "",
            role: "",
            ssoId: "",
          }));
          dispatch(setAuth({
            isAdmin: false,
            isAuth: false,
            isFinance: false,
            accessToken: "",
            refreshToken: "",
            idToken: "",
          }));
        }
        // window.open(process.env.REACT_APP_ENDPOINT, "_self");
      });
    } catch (err) {
      console.log("Error: ", err);
    }
  }

  const upadtePassword = async () => {
    try{
      await axios.post(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/users/${user.ssoId}/send-password-update-email`,
      {}, 
      {
        headers: { "Authorization": `Bearer ${auth.accessToken}`,
                    "Content-Type": "application/json"
                  }
      }).then(res => {
        // if(res.status == 200)
          // messageApi.success(`Update Password Link sent to your Email`);
        // else
          // messageApi.error("Something went wrong!");
      });
    } catch(err){
        // messageApi.error("Something went wrong!");
        console.log("Error: ", err);
    }
  }

const items: MenuProps['items'] = [
  {
    key: '1',
    label: (
      <Typography style={{color: "#1877FF"}} onClick={upadtePassword}>Update Password</Typography>
    ),
  },
  {
    key: '2',
    label: (
      <Typography style={{color: "red"}} onClick={signout}>Sign out</Typography>
    ),
  },
];

const showDrawer = () => {
  setOpen(true);
};

const onClose = () => {
  setOpen(false);
};

const avatar = user?.name == "" ? "" : `${user?.name.split(" ")[0][0]}${user?.name.split(" ")[1][0]}`

const role = user?.role?.split("-");
role.splice(0, 1);

return (
  <>
  {/* {contextHolder} */}
  <div className="top-nav">
        <div style={{display: "flex", alignItems: "center"}}>
          <MenuOutlined className="hamburger" style={{marginRight: "10px"}} onClick={showDrawer} />
          <Drawer
            placement="left"
            closable={false}
            className="drawer"
            width={"15rem"}
            onClose={onClose}
            open={open}
            key="left"
          >
            <Sidenav/>
          </Drawer>
          <Breadcrumb style={{ margin: '16px 0', cursor: "pointer"}}>
            {
              breadCrum.map((e, i) => {
                return<>
                  <Breadcrumb.Item 
                    onClick={_=> i === 0 ? null : navigate(`/${breadCrum.slice(0, i+1).join("/")}`)}>
                      { e === "dashboard" ? <HomeIcon /> : <span style={{fontSize: 14}}>{titleCase(e.split("-").join(" "))}</span>}
                  </Breadcrumb.Item>
                </>
              })
            }
          </Breadcrumb>
        </div>
        <Dropdown menu={{ items }}>
          <Space>
            <Avatar style={{ backgroundColor: "#f56a00", verticalAlign: 'middle' }} size="large" gap={4}>
              {avatar}
            </Avatar>
            <span style={{ display: "flex", flexDirection: "column", justifyContent: "center", margin: 0}}>
              <Text style={{color: "white"}} strong>{user.name}</Text>
              <Text style={{color: "white"}}>{titleCase(role.join(" ") || "")}</Text>
            </span>
          </Space>
        </Dropdown>
    </div>
  </>
    
  );
}

export default Topnav;