import React, { useEffect, useState } from "react";
import { Form, Input, Button, Divider, DatePicker, Row, Col, message, Select, InputNumber } from "antd";
import { authSelector } from "../../../features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { PlusOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import moment from 'moment';
import ConfirmSubmissionPopup from "../../../utils/ConfirmSubmissionPopup";
import { getRequest, postRequest } from "../../../utils/handler/apiHandler";
const { Option } = Select;

interface ProspectDataForCreateQuote{
    prospectName: string,
    prospectId: number
}

interface Props {
    designationList: any[]; 
    businessSegmentList: any[]; 
    stageList: any[];
}
      
const CreateProspect = () => {      
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [prospectDataForCreateQuote, setProspectDataForCreateQuote] = useState<ProspectDataForCreateQuote>({
        "prospectName": "",
        "prospectId" : 0
    });
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [stagesList, setStagesList] = useState<any>([]);
    const [segmentList, setSegmentList] = useState<any>([]);
    const [designationList, setDesignationList] = useState<any>([]);
    const [sourceList, setSourceList] = useState<any>([]);


    useEffect(() => {
        if(prospectDataForCreateQuote.prospectName !== "")
            navigate(`/dashboard/quote/create-quote`, { state: {prospectDataForCreateQuote}});
    }, [prospectDataForCreateQuote]);

    const navigate = useNavigate();
    const auth = useAppSelector(authSelector);
    const itemInputs = [{}];

    const handleConfirm = () => {
        setIsConfirmModalVisible(false);
        submitProspectForm();
    };
    
    const handleCancel = () => {
        setIsConfirmModalVisible(false);
    }; 

    const handleDesignationChange = (name: any) => {
        if(form.getFieldValue(["spocList", name, "designation"]) === "Other") {
            form.setFieldValue(["spocList", name, "isOtherDesignation"], true);
        } else {
            form.setFieldValue(["spocList", name, "isOtherDesignation"], false);
        }
    } 

    const submitProspectForm = async () => {
        const date = form.getFieldsValue(true).onboardDate;
        const dateISO = dayjs(date).toISOString();
        const formData = form.getFieldsValue(true);
        
        formData.spocList.map(function(item: any) {
            item.emailId = item.emailId || null;
            item.contactNumber = item.contactNumber || null;
            item.designation = item.isOtherDesignation ? item.other : item.designation
            delete item.other; 
            return item; 
        });

        const payload = {
            name : formData.name,
            stage : {
                id : formData.stageId,
                name : formData.stageName
            },
            onboardDate : dateISO,
            active : true,
            segment : formData.businessSegment === "Other" ? {
                id : null,
                name: formData.other
            } : {
                id : formData.segmentId,
                name : formData.segmentName
            },
            isOtherSegment : formData.businessSegment === "Other",
            remarks : formData.remarks || null,
            fleetSize : formData.fleetSize || null,
            source : formData.source === "Other" ? {
                id: null,
                name : formData.otherSource
            } : {
                id : formData.sourceId,
                name : formData.source
            },
            isOtherSource : formData.source === "Other",
            aboutCompany : formData.aboutCompany || null,
            spocRequests: formData.spocList
        }
    
        postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/prospect`, payload).then(res => {
            navigate('/dashboard/prospect');
        });
    };

    const onFinish = async () => {
        setIsConfirmModalVisible(true);
    };

    function disabledDate(current: any) {
    // Can not select days future dates
    return current && current > moment().endOf('day');
    }

    const getStagesList = () => {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/stages`)
            .then(res => {
                setStagesList(res.data);
        })
    }

    const getSourceList = () => {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/sources`)
            .then(res => {
                setSourceList(res.data);
        })
    }

    const getSegmentsList = () => {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/segments`)
            .then(res => {
                setSegmentList(res.data);
        })
    }

    const getDesignationList = () => {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/designations`).then(res => {
            setDesignationList(res.data);
        })
    }

    const optionsToRender = (options: any[], identifier: any) => {
        return options?.map((value: any) => (
            <Option key={value.id} value={value?.[identifier]}>
            {value?.[identifier]}
            </Option>
        ));
    };

    const setStageFormData = (key : any, value : any) => {
        form.setFieldValue("stageId", value.key);
        form.setFieldValue("stageName", value.value);
    }

    const setBusinessSegmentFormData = (key : any, value : any) => {
        form.setFieldValue("segmentId", value.key);
        form.setFieldValue("segmentName", value.value);
    }

    const setSourceFormData = (key : any, value : any) => {
        form.setFieldValue("sourceId", value.key);
        form.setFieldValue("source", value.value);
    }

    return (
        <React.Fragment>
        {contextHolder}
        <Form 
            form={form}
            onFinish={onFinish}
            layout="vertical"
            className="form-container"
        >
        <h4>Add Prospect</h4>
            <Row className="">
                <Col className="form-column"  >
                    <Form.Item
                        label="Prospect Name"
                        name="name"
                        rules={[{ required: true, message: 'Please enter prospect name!' }]}
                    >
                        <Input placeholder="Name" />
                    </Form.Item>
                    <Form.Item
                        label="Prospect Stage"
                        name="stage"
                        rules={[{ required: true, message: 'Please enter prospect stage!' }]}
                    >
                        <Select showSearch={true} style={{ width: '100%' }} placeholder="Prospect Stage" onClick={getStagesList} onChange={(key, value) => setStageFormData(key, value)}>
                            {optionsToRender(stagesList, "name")}
                        </Select>
                    </Form.Item>

                    <div style={{display: "flex", alignItems: "flex-end"}}>
                    <Form.Item
                        style={{width: "100%"}}
                        label="Prospect Source"
                        name="source"
                        rules={[{ required: true, message: 'Please enter prospect source!' }]}
                    >
                        <Select showSearch={true} style={{ width: '100%' }} placeholder="Source" onClick={() => getSourceList()} onChange={(key, value) => setSourceFormData(key, value)}>
                            {optionsToRender(sourceList, "name")}
                        </Select>
                    </Form.Item>
                    {
                        form.getFieldsValue(true).source === "Other"
                        ? <Form.Item
                            className="secondary-field"
                            name='otherSource'
                            rules={[{ required: true, message: 'Please enter Prospect Source' }]}
                        >
                            <Input placeholder="Other Source" />
                        </Form.Item>
                        : null
                    }
                    </div>

                    <Form.Item
                        label="Remarks"
                        name="remarks"
                        rules={[{ required: false, message: 'Please enter Remarks!' }]}
                    >
                        <TextArea rows={3}  placeholder="Remarks" />
                    </Form.Item>
                </Col>
                <Col className="form-column" >
                    <Form.Item
                        label="Onboard Date"
                        name="onboardDate"
                        rules={[{ required: true, message: 'Please enter date of onboarding!' }]}
                        >
                        <DatePicker
                            style={{ width: "100%"}}
                            disabledDate={disabledDate}
                        />
                    </Form.Item>
                    <div style={{display: "flex", alignItems: "flex-end"}}>
                    <Form.Item
                        style={{width: "100%"}}
                        label="Business Segment"
                        name="businessSegment"
                        rules={[{ required: true, message: 'Please enter business segment!' }]}
                    >
                        <Select showSearch={true} style={{ width: '100%' }} placeholder="Business Segment" onClick={getSegmentsList} onChange={(key, value) => setBusinessSegmentFormData(key, value)}>
                            {optionsToRender(segmentList, "name")}
                        </Select>
                    </Form.Item>
                    {
                        form.getFieldsValue(true).businessSegment === "Other"
                        ? <Form.Item
                            className="secondary-field"
                            name='other'
                            rules={[{ required: true, message: 'Please enter Business Segment' }]}
                        >
                            <Input placeholder="Other business segment" />
                        </Form.Item>
                        : null
                    }
                    </div>
                    <Form.Item
                        label="Fleet Size"
                        name="fleetSize"
                        rules={[{ required: false, message: 'Please enter fleet size!' }]}
                    >
                        <InputNumber style={{width: "100%"}} type="number" onWheel={event => event.currentTarget.blur()} onKeyDown={ e => ( e.key === '.' || e.key === 'e' ) && e.preventDefault() } placeholder="Fleet Size" />
                    </Form.Item>
                    <Form.Item
                        label="About Company"
                        name="aboutCompany"
                        rules={[{ required: false, message: 'Please enter About Company!' }]}
                    >
                        <TextArea rows={3}  placeholder="About Company" />
                    </Form.Item>
                </Col>
            </Row>
        <Divider />

        <h4>Add SPOC</h4>
        <Form.List name="spocList" initialValue={itemInputs}>
            {(fields, { add, remove }) => (
                <>
                {fields.map(({ key, name, ...restField }, i) => (
                    <div style={{width: "100%"}}>
                        <Row>
                            <Col className="form-column">
                                <Form.Item
                                    {...restField}
                                    label="Name"
                                    name={[name, 'name']}
                                    rules={[{ required: true, message: 'Please enter Name!' }]}
                                >
                                    <Input placeholder="Name" />
                                </Form.Item>
                                <div style={{display: "flex", alignItems: "flex-end"}}>
                                    <Form.Item
                                        style={{width: "100%"}}
                                        label="Designation"
                                        name={[name, 'designation']}
                                        rules={[{ required: true, message: 'Please enter Designation' }]}
                                    >
                                        <Select showSearch={true} style={{ width: '100%' }} onClick={getDesignationList} placeholder="Designation" onChange={() => handleDesignationChange(name)}>
                                            {optionsToRender(designationList, "name")}
                                        </Select>
                                    </Form.Item>
                                    {
                                        form.getFieldValue(["spocList", name, "designation"]) === "Other"
                                        ? <Form.Item
                                            className="secondary-field"
                                            name={[name, 'other']}
                                            rules={[{ required: true, message: 'Please enter Designation' }]}
                                            >
                                            <Input placeholder="Other designation" />
                                        </Form.Item>
                                        : null
                                    }
                                </div>
                            </Col>
                            <Col className="form-column">
                                <Form.Item
                                    {...restField}
                                    label="Email"
                                    name={[name, 'emailId']}
                                    rules={[{ required: false, message: 'Please enter Email' }]}
                                >
                                    <Input placeholder="Email" />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    label="Contact Number"
                                    name={[name, 'contactNumber']}
                                    rules={[{ required: false, message: 'Please enter Contact Number' }]}
                                >
                                    <Input maxLength={10} placeholder="Contact Number" />
                                </Form.Item>
                            </Col>
                        </Row>
                    <Button 
                        type="primary" 
                        danger 
                        style={{maxWidth: "5rem", margin: "0 0 30px 0"}} 
                        onClick={() => remove(name)}>
                            Delete
                    </Button>
                    </div>
                ))}
                <Form.Item>
                    <Button
                    type="dashed"
                    style={{width: "83%"}}
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                    >
                    Add SPOC
                    </Button>
                </Form.Item>
                </>
            )}
        </Form.List>
        <ConfirmSubmissionPopup
              visible={isConfirmModalVisible}
              onClose={handleCancel}
              onConfirm={handleConfirm}
        />
        <Form.Item>
        <Button type="primary" htmlType="submit">
            Submit
        </Button>
        </Form.Item>
        </Form>
        </React.Fragment>
    );
};

export default CreateProspect;