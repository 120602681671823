import React, { useState, useEffect, useRef } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { Button, Table, Spin, Modal, Select } from 'antd'; // Import Modal component from antd
import { LeftOutlined, LoadingOutlined, MinusOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { menuSelector } from '../../../features/menu/menuSlice';
import getDynamicAction from '../../../utils/helpers/dynamicAction';
import { getColumns } from './utils/columns';
import { userSelector } from '../../../features/auth/userSlice';
import { ProspectInput } from './utils/ProspectTypes';
import { fetchBusinessAndStagesData, fetchOwnerNamesDataFunction, fetchProspectNamesDataFunction, fetchProspectTableData } from './utils/apiFunctions';
import { useNavigationFunctions } from './utils/navigationFunctions';
import { handlePageSizeChange, handlePageChange } from './utils/paginationFunctions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useResizeEffect } from './utils/useResizeEffect';
import CustomSearchSelectFilter from './utils/CustomSearchSelectFilter';
import Icon from '@ant-design/icons';
import { ReactComponent as DownloadIcon } from '../../../utils/icons/download_icon.svg'; // Importing SVG file
import { getRequest, handleFileDownload } from '../../../utils/handler/apiHandler';
import { Option } from "antd/es/mentions";
import _ from "lodash";
import './styles.css';


const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "#1677ff", marginLeft: 6 }} spin />;
const INITITAL_SEARCH_MODEL_HEIGHT: number= 100;

const ProspectsList: React.FC = () => {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageCount, setPageCount] = useState<number>(0);
  const [totalRecord, setTotalRecord] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [prospectPageSize, setProspectPageSize] = useState<number>(10);
  const [prospectPageCount, setProspectPageCount] = useState<number>(0);
  const [prospectCurrentPage, setProspectCurrentPage] = useState<number>(1);
  const [ownerPageSize, setOwnerPageSize] = useState<number>(10);
  const [ownerPageCount, setOwnerPageCount] = useState<number>(0);
  const [ownerCurrentPage, setOwnerCurrentPage] = useState<number>(1);
  const [tableHeight, setTableHeight] = useState<number>(500);
  const [loading, setLoading] = useState<boolean>(false);
  const { navigateToEdit, navigateToDisplay } = useNavigationFunctions();
  const pageUrl = useLocation().pathname.split('/').pop();
  const menus = useAppSelector(menuSelector).menu;
  const actionItem = getDynamicAction(menus, pageUrl, 'prospect');
  const defaultData: ProspectInput[] = [];
  const [data, setData] = useState<ProspectInput[]>(defaultData);
  const [stagesList, setStagesList] = useState<any[]>([]);
  const [selectedStages, setSelectedStages] = useState<string[]>([]);
  const [businessSegmentsList, setBusinessSegmentsList] = useState<any[]>([]);
  const [selectedBusinessSegments, setSelectedBusinessSegments] = useState<string[]>([]);
  const [prospectsList, setProspectsList] = useState<any[]>([]);
  const [selectedProspectsList, setSelectedProspectsList] = useState<any[]>([]);
  const [ownersList, setOwnersList] = useState<any[]>([]);
  const [selectedOwnersList, setSelectedOwnersList] = useState<any[]>([]);
  const [prospectSearchText, setProspectSearchText] = useState<string>('');
  const [displayProspectSearchFilterScreen, setDisplayProspectSearchFilterScreen] = useState<boolean>(false);
  const [displayOwnerSearchFilterScreen, setDisplayOwnerSearchFilterScreen] = useState<boolean>(false);
  const [prospectModalHeight, setProspectModalHeight] = useState<number>(INITITAL_SEARCH_MODEL_HEIGHT);
  const [ownerModalHeight, setOwnerModalHeight] = useState<number>(INITITAL_SEARCH_MODEL_HEIGHT);
  const tableRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector(userSelector);
  const [agingSortingOrder, setAgingSortingOrder] = useState<'ascending' | 'descending' | null>(null);
  const [probabilityOfClosure, setProbabilityOfClosure] = useState<'ascending' | 'descending' | null>(null);
  const [loader, setLoader] = useState(false);

  const getAgingSortOrder = () => `stageChangeDate:${agingSortingOrder === 'ascending' ? 'asc' : 'desc'}`;
  const getProbabilityOfClosureSortOrder = () => `probabilityOfClosure:${probabilityOfClosure === 'ascending' ? 'asc' : 'desc'}`;

  useEffect(() => {
    const filterPayload = getFilterPayload();
    fetchProspectTableData(filterPayload, setData, setPageCount, setTotalRecord, setLoading);
  }, [currentPage, pageSize, selectedStages, selectedBusinessSegments, agingSortingOrder, probabilityOfClosure, selectedProspectsList, selectedOwnersList]);

  useEffect(() => {
    fetchBusinessAndStagesData(setStagesList, setBusinessSegmentsList);
  }, []);
  useEffect(()=>{
    if(!displayOwnerSearchFilterScreen && selectedProspectsList.length===0)  
      setProspectsList([])
  }, [displayProspectSearchFilterScreen]);
  useEffect(()=>{
    if(!displayOwnerSearchFilterScreen && selectedOwnersList.length===0)  
      setOwnersList([])
  }, [displayOwnerSearchFilterScreen]);
  
  
  useResizeEffect(tableRef, setTableHeight);

  const sortOrder = () => {
    const order = [];
    if (probabilityOfClosure !== null) {
      order.push(getProbabilityOfClosureSortOrder());
    }
    if (agingSortingOrder !== null) {
      order.push(getAgingSortOrder());
    }
    return order.length > 0 ? order : undefined;
  };
  
  function getFilterPayload(){
    return {
      'page': currentPage - 1,
      'size': pageSize,
      'stages': selectedStages.length === 0 ? undefined : selectedStages,
      'segments': selectedBusinessSegments.length === 0 ? undefined : selectedBusinessSegments,
      'sort': sortOrder(),
      'prospectId': selectedProspectsList.length === 0 ? undefined : selectedProspectsList,
      'owners': selectedOwnersList.length === 0 ? undefined : 
        selectedOwnersList.map((selectedOwnerId: number)=>{
          const ownerDetail = ownersList.find((owner:any)=>owner.id === selectedOwnerId);
          return `${ownerDetail.ssoId}:${ownerDetail.name}`;
        }),
    }
  }

  const handlePageSizeChangeLocal = (current: number, size: number) => {
    handlePageSizeChange(current, size, setPageSize, setCurrentPage);
  };

  const handlePageChangeLocal = (page: number) => {
    handlePageChange(page, setCurrentPage);
  };

  const handleSortAging = () => {
    setAgingSortingOrder(agingSortingOrder === null ? 'ascending' : agingSortingOrder ==='ascending' ? 'descending' : null );
  };

  const handleProbabilityOfClosure = () => {
    setProbabilityOfClosure(probabilityOfClosure === null ? 'ascending' : probabilityOfClosure ==='ascending' ? 'descending' : null );
  };

  const columns = getColumns(
    stagesList,
    businessSegmentsList,
    selectedStages,
    selectedBusinessSegments,
    setSelectedStages,
    setSelectedBusinessSegments,
    displayProspectSearchFilterScreen,
    setDisplayProspectSearchFilterScreen,
    setDisplayOwnerSearchFilterScreen,
    navigateToEdit,
    agingSortingOrder,
    handleSortAging,
    probabilityOfClosure,
    handleProbabilityOfClosure,
    setCurrentPage,
    selectedProspectsList,
    selectedOwnersList,
  );

  const handleFetchProspectNames = async (searchKey: string, isInfiniteScroll: boolean) => {
    try {
      const data = await fetchProspectNamesDataFunction(
        searchKey,
        prospectCurrentPage,
        prospectPageSize,
        setProspectsList,
        setProspectCurrentPage,
        setProspectPageCount,
        isInfiniteScroll
      );
    } catch (error) {
      console.error('Error fetching prospect names:', error);
    }
  };

  const handleFetchOwnerNames = async (searchKey: string, isInfiniteScroll: boolean) => {
    try {
      const data = await fetchOwnerNamesDataFunction(
        searchKey,
        ownerCurrentPage,
        ownerPageSize,
        setOwnersList,
        setOwnerCurrentPage,
        setOwnerPageCount,
        isInfiniteScroll
      );
    } catch (error) {
      console.error('Error fetching owner names:', error);
    }
  };

  const handleCancelModal = () => {
    setDisplayProspectSearchFilterScreen(false);
    setDisplayOwnerSearchFilterScreen(false);
  }
  
  const rotatedMinusIconStyle = {
    transform: 'rotate(90deg)',
  };

  const handleProspectDownload = async () => {
    setLoader(true);
    const date = new Date();
    const doc_name = "Prospect_List_" + (date.getDate() + 1).toString().padStart(2, "0") + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getFullYear();
    console.log(doc_name);
    const res = await handleFileDownload(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/downloadProspects`, doc_name, "xlsx");
    if(res === 200) setLoader(false);
  }

  return (
    <div style={{ backgroundColor: '#F6FAFF', height: '100%', padding: "26px 32px" }}>
      <div className="page-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
        <p className='redonly' style={{ fontSize: '20px', fontStyle: 'normal', fontWeight: 700, lineHeight: 'normal' }}>Prospects</p>

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Button
            type="primary"
            ghost
            icon={<Icon component={DownloadIcon} />}
            onClick={_ => handleProspectDownload()}
            style={{ fontWeight: 500, background: "rgba(23, 119, 255, 0.10)", marginTop: 15, marginLeft: 10, height: 40, display: actionItem?.['Add Prospect'] === 'true' ? 'block' : 'none' }}
          >
            Download Prospect
            {loader ? <Spin indicator={antIcon} /> : ""}
          </Button>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={(_) => navigate('create-prospect')}
            style={{ fontWeight: 500, marginTop: 15, marginLeft: 10, height: 40, display: actionItem?.['Add Prospect'] === 'true' ? 'block' : 'none' }}
          >
            Add New Prospect
          </Button>
        </div>
      </div>
      {
        displayProspectSearchFilterScreen &&
          (
            <Modal
              visible={displayProspectSearchFilterScreen}
              onCancel={handleCancelModal}
              footer={null}
              width={250}
              bodyStyle={{ }}
              style={{padding: "0px", width: "100px", maxHeight: 260}}
              wrapClassName="modal-wrapper-prospect" 
              mask={false} // Prevents the background from being faded
              closable={false}
            >
              <CustomSearchSelectFilter
                placeholder="Enter 3 letters to search"
                fetchData={handleFetchProspectNames}
                selectedItems={selectedProspectsList}
                setSelectedItems={setSelectedProspectsList}
                items={prospectsList}
                setItems={setProspectsList}
                setDisplayProspectSearchFilterScreen={setDisplayProspectSearchFilterScreen}
                setCurrentPage={setProspectCurrentPage}
                currentPage={prospectCurrentPage}
                totalPageCount={prospectPageCount}
                setMainCurrentPage={setCurrentPage}
                modalHeight={prospectModalHeight}
                setModalHeight={setProspectModalHeight}
                searchKey="id"
                minSearchLength={3}
              />
            </Modal>
          )
      }
      {
        displayOwnerSearchFilterScreen &&
          <Modal
          visible={displayOwnerSearchFilterScreen}
          onCancel={handleCancelModal}
          footer={null}
          width={250}
          bodyStyle={{ }}
          style={{padding: "0px", width: "100px"}}
          wrapClassName="modal-wrapper-owner" 
          mask={false} // Prevents the background from being faded
          closable={false}
        >
          <CustomSearchSelectFilter
            placeholder="Enter 3 letters to search"
            fetchData={handleFetchOwnerNames}
            selectedItems={selectedOwnersList}
            setSelectedItems={setSelectedOwnersList}
            items={ownersList}
            setItems={setOwnersList}
            setDisplayProspectSearchFilterScreen={setDisplayOwnerSearchFilterScreen}
            setCurrentPage={setOwnerCurrentPage}
            currentPage={ownerCurrentPage}
            totalPageCount={ownerPageCount}
            setMainCurrentPage={setCurrentPage}
            modalHeight={ownerModalHeight}
            setModalHeight={setOwnerModalHeight}
            searchKey="ssoId"
            minSearchLength={3}
          />
        </Modal>
      }      
      
      <div ref={tableRef} style={{ height: 0.80 * tableHeight }}>
        <Spin spinning={loading} size="large">
          <Table
            columns={columns}
            dataSource={data || []}
            pagination={{
              pageSize: pageSize,
              position: ["bottomRight"],
              showSizeChanger: false, // Remove the default page size changer
              showTotal: (total, range) => (
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', marginTop:'-4px' }}>
                  <div style={{fontSize:'1rem'}}>
                    <span>Showing</span>
                    <Select defaultValue={10} style={{ width: 60, margin: '0 5px' }} onChange={(value) => handlePageSizeChangeLocal(1, value)}>
                      <Option value="10">10</Option>
                      <Option value="20">20</Option>
                      <Option value="50">50</Option>  
                      <Option value="100">100</Option>
                    </Select>
                    <span>Per Page</span>
                    <span style={{ marginRight:'10px', marginLeft:'10px', color:'grey'}}><MinusOutlined style={rotatedMinusIconStyle} /></span>
                    <span>{`Results: ${range[0]}-${range[1]} of ${total}`}</span>
                  </div>
                </div>
              ),
              onChange: handlePageChangeLocal,
              current: currentPage,
              total: totalRecord,
              itemRender: (current, type, originalElement) => {
                if (type === 'prev') {
                  return <LeftOutlined style={{marginLeft:'4rem'}}/>;
                }
                if (type === 'next') {
                  return  <RightOutlined />;
                }
                if (type === 'page') {
                  return (
                    <span style={{cursor: 'pointer' }} onClick={() => handlePageChangeLocal(current)}>
                      {current}
                    </span>
                  );
                }
                if (type === 'jump-next' || type === 'jump-prev') {
                  return <a style={{color: 'black'}}><strong> ... </strong></a>;
                }
                return originalElement;
              },
            }}            
            scroll={{ x: 'max-content', y: 0.80 * tableHeight }}
            size="middle"
            components={{
              header: {
                wrapper: (props: any) => <thead style={{ backgroundColor: '#CEE6FA', height: '3.5rem' }}>{props.children}</thead>,
              },
            }}
          />
        </Spin>
      </div>
    </div>
  );
};

export default ProspectsList;
