import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Form, Typography } from 'antd';
import { ReactComponent as DownMark } from '../../../../utils/SVGs/DownMark.svg';
import { ReactComponent as RightMark } from '../../../../utils/SVGs/RightMark.svg';
import { formatIndianRupee } from '../formUtils';

interface OperationFinancialSpreadingProps {
    FYCount: number;
    form: any; // Form instance from Ant Design Form
    colSpan: number;
    labelColSpan: number;
    updateTotalCnt: number;
    viewModeActive: boolean;
}

const OperationFinancialSpreadingComponent: React.FC<OperationFinancialSpreadingProps> = ({ FYCount, form, colSpan, updateTotalCnt, viewModeActive, labelColSpan }) => {
    const [operatingCosts, setOperatingCosts] = useState<number[]>(Array(FYCount).fill(0));
    const [collapseCurrentAssetsFields, setCollapseCurrentAssetsFields] = useState<boolean>(false);
    const INR_SYMBOL: string = '\u20B9';
    
    useEffect(() => {
        const values = form.getFieldsValue(true);
        if(values.operatingCosts)
            setOperatingCosts(values.operatingCosts);
    }, [updateTotalCnt]);

    const handleFieldChange = () => {
        const values = form.getFieldsValue();        
        const updatedTotalCurrentAssets = values.costOfMaterialsConsumes.map((item: number, i: number) =>
            Number(values.costOfMaterialsConsumes[i]) +
            Number(values.employeeBenefitExpenses[i]) +
            Number(values.financeCosts[i])
        );
        form.setFieldsValue({ operatingCosts: updatedTotalCurrentAssets });
        setOperatingCosts(updatedTotalCurrentAssets);
    };

    return (
        <>
            <Row
                gutter={16}
                style={{ marginTop: '20px', paddingTop: '10px', paddingBottom: '20px', borderBottom: '1px #E8E8E8 solid' }}
            >
                <Col span={labelColSpan}>
                    <span onClick={() => setCollapseCurrentAssetsFields((oldState) => !oldState)}>
                        {collapseCurrentAssetsFields ? <RightMark /> : <DownMark />}
                    </span>
                    <span style={{ fontSize: '14px', fontWeight: '600', marginLeft: '10px' }}>Operation Cost</span>
                </Col>
                {operatingCosts.map((value, ind) => (
                    <Col span={colSpan} key={ind}>
                        <Typography style={{ fontSize: 14, fontWeight: '600', paddingLeft: '10px' }}>
                            <span>{`${INR_SYMBOL} ${formatIndianRupee(parseFloat(value.toFixed(2)))}`}</span>
                        </Typography>
                    </Col>
                ))}
            </Row>
            {!collapseCurrentAssetsFields && (
                <>
                    <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px',  borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                        <Col span={labelColSpan}><p style={{ marginLeft: '20px' }}>Cost of Materials Consumed</p></Col>
                        {Array.from({ length: FYCount }, (_, ind) => (
                            <Col span={colSpan} key={ind}>
                                {viewModeActive ? (
                                    <Typography style={{ paddingLeft: '10px' }}>
                                        <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue(['costOfMaterialsConsumes', ind]))}`}</span>
                                    </Typography>
                                ) : (
                                    <Form.Item
                                        name={['costOfMaterialsConsumes', ind]}
                                        rules={[{ required: true, message: 'Please enter Cost of Materials Consumed value' }]}
                                    >
                                        <Input type="number" onChange={handleFieldChange} />
                                    </Form.Item>
                                )}
                            </Col>
                        ))}
                    </Row>

                    <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px',  borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                        <Col span={labelColSpan}><p style={{ marginLeft: '20px' }}>Employee Benefit Expenses</p></Col>
                        {Array.from({ length: FYCount }, (_, ind) => (
                            <Col span={colSpan} key={ind}>
                                {viewModeActive ? (
                                    <Typography style={{ paddingLeft: '10px' }}>    
                                        <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue(['employeeBenefitExpenses', ind]))}`}</span>
                                    </Typography>
                                ) : (
                                    <Form.Item
                                        name={['employeeBenefitExpenses', ind]}
                                        rules={[{ required: true, message: 'Please enter Trade Receivables value' }]}
                                    >
                                        <Input type="number" onChange={handleFieldChange} />
                                    </Form.Item>
                                )}
                            </Col>
                        ))}
                    </Row>

                    <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px',  borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                        <Col span={labelColSpan}><p style={{ marginLeft: '20px' }}>Finance Costs</p></Col>
                        {Array.from({ length: FYCount }, (_, ind) => (
                            <Col span={colSpan} key={ind}>
                                {viewModeActive ? (
                                    <Typography style={{ paddingLeft: '10px' }}>
                                        <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue(['financeCosts', ind]))}`}</span>
                                    </Typography>
                                ) : (
                                    <Form.Item
                                        name={['financeCosts', ind]}
                                        rules={[{ required: true, message: 'Please enter Short Term Loan And Advances value' }]}
                                    >
                                        <Input type="number" onChange={handleFieldChange} />
                                    </Form.Item>
                                )}
                            </Col>
                        ))}
                    </Row>
                </>
            )}
        </>
    );
};

export default OperationFinancialSpreadingComponent;
