import React, { useEffect, useState } from 'react';
import { Button, Form, message, Spin, Steps, theme } from 'antd';
import Step1Form from './StepForm/Step1From';
import Step2Form from './StepForm/Step2From';
import Step3Form from './StepForm/Step3From';
import { getRequest, postRequest } from '../../../utils/handler/apiHandler';
import { Context } from '../../../utils/helpers/Context';
import dayjs from 'dayjs';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import axios from 'axios';

const CreateClientRequest = () => {
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);    
  const [loader, setLoader] = useState(false);
  const navigation = useNavigate();
  const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;
  const [contextState, setContextState] = useState<any>({
      master: [],
      clientId: "",
      business: [],
      businessSegement: []
  });

  const steps = [
    {
      title: 'First',
      content: <Step1Form form={form} />,
      // fields: ['entityName', 'mlaNumbers', 'entityGstNumber', 'entityPanNumber','entityCinNumber', 'clientOnBoardingDate', 'state', 'registrationAddress', 'legalBusinessName', 'agreementType', 'parentGstNumber', 'parentCinNumber', 'businessSegment']
    },
    {
      title: 'Second',
      content: <Step2Form form={form} />,
      // fields: ['registeredTo', 'downPaymentApplicable', 'insuranceType', 'serviceInclusions', 'roadTaxType', 'insuranceZeroDep', 'insuranceManaged', 'serviceInclusions', 'primaryUseCase']
    },
    {
      title: 'Third',
      content: <Step3Form form={form} />,
    },
  ];

  const next = async () => {
    try {
      await form.validateFields(['entityName', 'mlaNumbers', 'entityGstNumber', 'entityPanNumber','entityCinNumber', 'state', 'registrationAddress', 'clientOnBoardingDate', 'legalBusinessName', 'agreementType', 'parentGstNumber', 'parentCinNumber', 'businessSegment', 'registeredTo', 'downPaymentApplicable', 'insuranceType', 'serviceInclusions','primaryUseCase', 'roadTaxType', 'insuranceZeroDep', 'insuranceManaged']);
      setCurrent((prev)=>prev + 1);
  } catch {}
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  useEffect(() => {
    getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/entity-on-boarding`).then(res => {
        setContextState({ 
            ...contextState, 
            business: res.data
          })
    });
    getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/all-masters`).then(res => {
      setContextState({ 
        ...contextState, 
        master: res.data 
      })
    });
    getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/segments`).then(res => {
      setContextState({ 
        ...contextState, 
        businessSegement: res.data
      })
    })
}, []);

  const initialValues = {
    insuranceManaged: false,
    insuranceType: "AYF",
    insuranceZeroDep: false,
  }

  const onFinish = async (values: any) => {
    const formData = form.getFieldsValue(true);
    const payload = {
      creditRequestApprovalId: formData.creditRequestApprovalId,
      clientId: formData.clientId,
      entityName: formData.entityName,
      legalBusinessName: formData.legalBusinessName,
      mlaNumbers: formData.mlaNumbers.join(","),
      agreementType: formData.agreementType,
      state: formData.state,
      registrationAddress: formData.registrationAddress,
      registrationDocUrl: formData.registrationDocUrl || null,
      invoiceAddresses: formData.invoiceAddress !== undefined ? formData.invoiceAddress.join(" |$#$| ") : "",
      communicationAddresses: formData.communicationAddresses !== undefined ? formData.communicationAddresses.join(" |$#$| ") : "",
      isOtherSegment: formData.businessSegment === "Other",
      businessSegment: formData.businessSegment === "Other" ? formData.other : formData.businessSegment,
      entityGstNumber: formData.entityGstNumber,
      entityCinNumber: formData.entityCinNumber,
      entityPanNumber: formData.entityPanNumber,
      parentGstNumber: formData.parentGstNumber,
      parentCinNumber: formData.parentCinNumber,
      parentPanNumber: formData.parentPanNumber,
      clientOnBoardingDate: dayjs(formData.clientOnBoardingDate).format("YYYY-MM-DD"),
      contactNumbers: JSON.stringify(formData.contactNumbers) || null,
      emailIds: JSON.stringify(formData.emailIds) || null,
      registeredTo: formData.registeredTo,
      primaryUseCase: formData.primaryUseCase,
      otherUseCase: formData.otherUseCase !== undefined ? formData.otherUseCase: null,
      insuranceManaged: formData.insuranceManaged,
      insuranceType: formData.insuranceManaged ? formData.insuranceType : null,
      insuranceZeroDep: formData.insuranceManaged ? formData.insuranceZeroDep : null,
      roadTaxType: formData.roadTaxType,
      authorizeSignatories: JSON.stringify(formData.authorizeSignatories) || null,
      accountManagers: JSON.stringify(formData.accountManagers) || null,
      bdManagers: JSON.stringify(formData.bdManagers) || null,
      serviceInclusions: JSON.stringify(formData.serviceInclusions),
      paymentType: formData.paymentType,
      paymentMode: formData.paymentMode,
      billingType: formData.billingType,
      lockInPeriod: formData.lockInPeriod,
      leaseRentalPayable: formData.leaseRentalPayable,
      foreclosurePenaltyPercentage: ~~formData.foreclosurePenaltyPercentage || null,
      delayedPaymentChargePercentage: ~~formData.delayedPaymentChargePercentage || null,
      otherClause: formData.otherClause || null
    }

    setLoader(true)
    if(formData.primaryUseCase == "Other") {
      postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/client-use-case`, {
        data: formData.otherUseCase
      }).then(res => {
        message.destroy();
        postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/client-on-boarding`, payload).then(res => {
          setLoader(false)
        })
        navigation("/dashboard/client");
      })
    }
    else {
      payload.otherUseCase = null;
      postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/client-on-boarding`, payload).then(res => {
        setLoader(false);
        navigation("/dashboard/client");
      })
    }
  }

  return (
    <Context.Provider value={{ contextState, setContextState }}>
      <div className="form-container">
        <Steps current={current} items={items} />
        <div style={{marginTop: 16}}>
          <Form
          form={form}
          initialValues={initialValues}
          onFinish={onFinish}
          layout="vertical"
          onKeyDown={(e)=> e.key == "Enter" ? e.preventDefault(): ''}
          >
              {steps[current].content}

          <Form.Item>
          <div style={{ marginTop: 24 }}>
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" htmlType="submit">
              Done
              {loader ? <Spin indicator={antIcon} /> : ""}
            </Button>
          )}
          {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Previous
            </Button>
          )}
        </div>
          </Form.Item>

          </Form>
        </div>
      </div>
    </Context.Provider>
  );
};

export default CreateClientRequest;