import React, { useEffect, useState } from "react";
import { Form, Input, Button, Divider, DatePicker, Row, Col, message, Switch, Select, Modal } from "antd";
import { authSelector } from "../../../features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { format } from 'date-fns';

import { UserOutlined } from '@ant-design/icons';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import ConfirmSubmissionPopup from "../../../utils/ConfirmSubmissionPopup";

interface FormDetails {
    chassisNo: number,
    regNo: number,
    terminationDate:string,
}

const initFormState = {
    chassisNo: 0,
    regNo: 0,
    terminationDate:"",
}
      
const CreateHPT = () => {      
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [formData, setFormData] = useState<FormDetails>(initFormState);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [submitForm, setSubmitForm] = useState(0);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const auth = useAppSelector(authSelector);
   
    useEffect(()=>{
        const submitProspectForm = async () => {
            try {
                const response = await axios.post(
                  `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/reportservice/vaahan/addVaahanHpt`,
                  formData, {
                    headers: {
                        "Authorization": `Bearer ${auth.accessToken}`
                    }
                  }
                );
                
                messageApi.success('Vaahan Added!');
                setTimeout(()=>{
                    navigate(`/dashboard/vaahan-hpt`);
    
                    
                },1000);
                
            } catch (error:any) {
                console.error('Error In Adding Vaahan:', error);
                // Extract error message from the API response
                const errorMessage = (error.response && error.response.data && error.response.data.errors && 
                    error.response.data.errors[0]) || 'An error occurred';

                // Display error in a modal (pop-up)
                Modal.error({
                    title: 'Error',
                    content: errorMessage,
                });
            }
        };
        if(formData?.chassisNo != 0)
            submitProspectForm();
    },[submitForm]);

    const onFinish = async (values: FormDetails) => {
        const tempHPATerminationDateString = format(new Date(values.terminationDate), 'yyyy-MM-dd');  
        values.terminationDate = tempHPATerminationDateString;
        setFormData(values);
        setIsConfirmModalVisible(true);
        
    };

    const handleConfirm = () => {
        setSubmitForm(submitForm+1);
        setIsConfirmModalVisible(false);
    };
    
    const handleCancel = () => {
    setIsConfirmModalVisible(false);
    };  

    return (
        <React.Fragment>
        {contextHolder}
        <Form 
            form={form}
            onFinish={onFinish}
            layout="vertical"
        >
            <h4>Add Vaahan</h4>

            <Row className="" style={{display:"flex", justifyContent:"space-around"}}>
                <Col className="form-column "  >
                    <Form.Item
                        label="Chasis No."
                        name="chassisNo"
                        rules={[{ required: true, message: 'Please Enter Chasis No.!' }]}
                    >
                        <Input  prefix={<UserOutlined className="site-form-item-icon" />} placeholder="chassisNo" />
                    </Form.Item>
                    <Form.Item
                        label="Registration No."
                        name="regNo"
                        rules={[{ required: true, message: 'Please enter Registration Number!' }]}
                    >
                        <Input  prefix={<UserOutlined className="site-form-item-icon" />} placeholder="regNo" />
                    
                    </Form.Item>
                </Col>

                <Col className="form-column" >
                    <Form.Item
                        label="Termination Date"
                        name="terminationDate"
                        rules={[{ required: true, message: 'Please Select Termination Date Date!' }]}
                    >
                        <DatePicker
                            style={{ width: "100%"}}
                        />
                    </Form.Item>
                </Col>

            </Row>
            <Row>

                <Col>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{display:"flex", width:"7rem", justifyContent:"center"}}>
                            Submit
                        </Button>
                    </Form.Item>
                </Col>
                
            </Row>
            <ConfirmSubmissionPopup
              visible={isConfirmModalVisible}
              onClose={handleCancel}
              onConfirm={handleConfirm}
            />
        </Form>

        </React.Fragment>
    );
};

export default CreateHPT;