import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col, InputNumber, Radio, Space, Select, Upload, Button, message, Divider, Modal, Tooltip } from "antd";
import { FilePdfOutlined, LinkOutlined, LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from 'antd';
import { authSelector } from "../../../../features/auth/authSlice";
import { useAppSelector } from "../../../../app/hooks";
import { postRequest } from "../../../../utils/handler/apiHandler";
import axios from "axios";

function CreditCondition(props: any) {
    const form = props.form;
    const auth = useAppSelector(authSelector);
    const [downPayment, setDownPayment] = useState<boolean>(form.getFieldValue(["contractConditions", props.name, "downPaymentApplicable"])?true:false);
    const [processingFeeApplicable, setProcessingFeeApplicable] = useState<boolean>(form.getFieldValue(["contractConditions", props.name, "processingFeeApplicable"])?true:false)
    const [securityDepositApplicable, setSecurityDepositApplicable] = useState<boolean>(form.getFieldValue(["contractConditions", props.name, "securityDepositApplicable"])?true:false)
    const [bankGuarantee, setBankGuarantee] = useState<boolean>(form.getFieldValue(["contractConditions", props.name, "bankGuaranteeApplicable"])?true:false)
    const [corporateGuarantee, setCorporateGuarantee] = useState<boolean>(form.getFieldValue(["contractConditions", props.name, "corporateGuaranteeApplicable"])?true:false)
    
    const [bankDocumentUrl, setBankGuaranteeDocument] = useState<any>(null)
    const [corporateGuaranteeDocument, setCorporateGuaranteeDocument] = useState<any>(null)
    const [mlaDocument, setMLADocument] = useState<any>(null)
    const [letterOfComfortDocument, setLetterOfComfortDocument] = useState<any>(null);
    const [disabled, setDisabled] = useState<any>(form.getFieldsValue(true).creditStatus == "Approved" ? false : true);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalDocUrl, setModalDocUrl] = useState<any>("");
    const [fileLoader1, setFileLoader1] = useState(false);
    const [fileLoader2, setFileLoader2] = useState(false);
    const [fileLoader3, setFileLoader3] = useState(false);
    const [fileLoader4, setFileLoader4] = useState(false);
    const tooltip1 = form.getFieldValue(["contractConditions", props.name, "corporateDocumentUrl"]) || "";
    const tooltip2 = form.getFieldValue(["contractConditions", props.name, "bankDocumentUrl"]) || "";
    const tooltip3 = form.getFieldValue(["contractConditions", props.name, "letterOfComfortDocumentUrl"]) || "";
    const tooltip4 = form.getFieldValue(["contractConditions", props.name, "mlaDocumentUrl"]) || "";

    const options = [
        {
          value: 'Percentage',
          label: 'Percentage',
        },
        {
            value: 'Months of LR',
            label: 'Months of LR',
        },
        {
            value: 'Amount',
            label: 'Amount',
        }
    ];
    useEffect(() => {
        if(corporateGuaranteeDocument !== null) {
            setFileLoader1(true)
            let bodyFormData = new FormData();
            bodyFormData.append('file', corporateGuaranteeDocument);
            bodyFormData.append('clientId', props.data.clientId);
            bodyFormData.append('clientName', props.data.entityName);
            bodyFormData.append('documentType', "Corporate Guarantee Document");
            axios({
                method: "post",
                url: `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/file/upload`,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data", "Authorization": ` Bearer ${auth.accessToken}` },
              }).then(function (response) {
                    form.setFieldValue(["contractConditions", props.name, "corporateDocumentUrl"], response?.data?.DocumentUrl);
                    message.success(response.data.msg);
                    setFileLoader1(false)
                }).catch(err => {
                    message.error(err.response.data.message);
                    setFileLoader1(false)
                });
            }
    }, [corporateGuaranteeDocument]);
    useEffect(() => {
        if(bankDocumentUrl !== null) {
            setFileLoader2(true)
            let bodyFormData = new FormData();
            bodyFormData.append('file', bankDocumentUrl);
            bodyFormData.append('clientId', props.data.clientId);
            bodyFormData.append('clientName', props.data.entityName);
            bodyFormData.append('documentType', "Bank Guarantee Document");
            axios({
                method: "post",
                url: `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/file/upload`,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data", "Authorization": ` Bearer ${auth.accessToken}` },
              }).then(function (response) {
                  form.setFieldValue(["contractConditions", props.name, "bankDocumentUrl"], response?.data?.DocumentUrl);
                  message.success(response.data.msg);
                  setFileLoader2(false)
                }).catch(err => {
                    message.error(err.response.data.message);
                    setFileLoader2(false)
                });
            }
    }, [bankDocumentUrl]);
    useEffect(() => {
        if(letterOfComfortDocument !== null) {
            setFileLoader3(true)
            let bodyFormData = new FormData();
            bodyFormData.append('file', letterOfComfortDocument);
            bodyFormData.append('clientId', props.data.clientId);
            bodyFormData.append('clientName', props.data.entityName);
            bodyFormData.append('documentType', "Letter Of Comfort Document");
            axios({
                method: "post",
                url: `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/file/upload`,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data", "Authorization": ` Bearer ${auth.accessToken}` },
              }).then((response) => {
                  form.setFieldValue(["contractConditions", props.name, "letterOfComfortDocumentUrl"], response?.data?.DocumentUrl);
                  message.success(response.data.msg);
                  setFileLoader3(false)
                }).catch(err => {
                    console.log(err)
                    setFileLoader3(false)
                    message.error(err.response.data.message);
                });
            }

    }, [letterOfComfortDocument]);
    useEffect(() => {
        if(mlaDocument !== null) {
            setFileLoader4(true)
            let bodyFormData = new FormData();
            bodyFormData.append('file', mlaDocument);
            bodyFormData.append('clientId', props.data.clientId);
            bodyFormData.append('clientName', props.data.entityName);
            bodyFormData.append('documentType', "MLA Document");
            axios({
                method: "post",
                url: `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/file/upload`,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data", "Authorization": ` Bearer ${auth.accessToken}` },
              }).then(function (response) {
                    form.setFieldValue(["contractConditions", props.name, "mlaDocumentUrl"], response?.data?.DocumentUrl);
                    message.success(response.data.msg);
                    setFileLoader4(false)
                }).catch(err => {
                    message.error(err.response.data.message);
                    setFileLoader4(false)
                });
            }
    }, [mlaDocument]);

    useEffect(() => {
        form.setFieldValue(["contractConditions", props.name, "securityDepositType"], form.getFieldValue(["contractConditions", props.name, "securityDepositType"]) !== undefined ? form.getFieldValue(["contractConditions", props.name, "securityDepositType"]) : "Percentage");
        form.setFieldValue(["contractConditions", props.name, "securityDepositApplicable"], securityDepositApplicable !== null ? securityDepositApplicable : false);
        form.setFieldValue(["contractConditions", props.name, "downPaymentApplicable"], downPayment !== null ? downPayment : false);
        form.setFieldValue(["contractConditions", props.name, "bankGuaranteeApplicable"], bankGuarantee !== null ? bankGuarantee : false);
        form.setFieldValue(["contractConditions", props.name, "corporateGuaranteeApplicable"], corporateGuarantee !== null ? corporateGuarantee : false);
        form.setFieldValue(["contractConditions", props.name, "processingFeeApplicable"], processingFeeApplicable !== null ? processingFeeApplicable : false);
    }, [])

    const handleDocViewer = (url: any) => {
        if(url == undefined) return;
        setModalDocUrl(url);
        setModalOpen(true);
    }

    const handleSecurityDeposit = (e: any) => {
        setSecurityDepositApplicable(e.target.value); 
        if(e.target.value)
            form.setFieldValue(["contractConditions", props.name, "securityDepositType"], "Percentage")
        else
            form.setFieldValue(["contractConditions", props.name, "securityDepositType"], null)
    }
    
    console.log(form.getFieldsValue(["contractConditions", props.name, "mlaNumber"]).mlaNumber);
    return (
        <React.Fragment>
            <Row className="">
            <Col className="form-column">
                <Form.Item
                    label="MLA Number"
                    name={[props.name, "mlaNumber"]}
                    rules={disabled ? [] : [{ required: true, message: 'Please enter MLA number!' }]}
                >
                    <Input style={{width: "100%"}} defaultValue={form.getFieldsValue(["contractConditions", props.name, "mlaNumber"]).mlaNumber || null} onWheel={event => event.currentTarget.blur()} placeholder="MLA Number" />
                </Form.Item>
                <Form.Item
                    label="Security Deposit Applicable"
                    name={[props.name, "securityDepositApplicable"]}
                    rules={!disabled ? [{ required: true, message: 'Please enter security deposit applicable!' }] : []}
                >
                    <Radio.Group disabled={disabled} defaultValue={securityDepositApplicable} onChange={(e) => handleSecurityDeposit(e)}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="Down Payment Applicable"
                    name={[props.name, "downPaymentApplicable"]}
                    rules={!disabled ? [{ required: true, message: 'Please enter down payment applicable!' }] : []}
                >
                    <Radio.Group disabled={disabled} defaultValue={downPayment} onChange={(e) => setDownPayment(e.target.value)}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="Processing Fee Applicable"
                    name={[props.name, "processingFeeApplicable"]}
                    rules={!disabled ? [{ required: true, message: 'Please enter  processing fee applicable!' }] : []}
                >
                    <Radio.Group disabled={disabled} defaultValue={processingFeeApplicable} onChange={(e) => setProcessingFeeApplicable(e.target.value)}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="Bank Guarantee"
                    name={[props.name, "bankGuaranteeApplicable"]}
                    rules={!disabled ? [{ required: true, message: 'Please enter bank guarantee!' }] : []}
                >
                    <Radio.Group disabled={disabled} defaultValue={bankGuarantee} onChange={e => setBankGuarantee(e.target.value)}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="Corporate Guarantee"
                    name={[props.name, "corporateGuaranteeApplicable"]}
                    rules={!disabled ? [{ required: true, message: 'Please enter corporate guarantee!' }] : []}
                >
                    <Radio.Group disabled={disabled} defaultValue={corporateGuarantee} onChange={e => setCorporateGuarantee(e.target.value)}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>
            </Col>
            <Col className="form-column">
                <Form.Item
                        label="MLA Document"
                        name={[props.name, "mlaDocumentUrl"]}
                        //rules={[{ required: true, message: 'Please input your contact number!' }]}
                    >
                        <div style={{display: "flex", alignItems: "flex-start"}}>
                            <Upload 
                                multiple={false}
                                maxCount={1}
                                showUploadList={false}
                                beforeUpload={(file) => {
                                    setMLADocument(file)
                                    return false
                                }}
                            >
                                <Button style={{height: 40}} icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                            {
                            fileLoader4
                            ? <LoadingOutlined 
                                style={{
                                    color: "blue", 
                                    fontSize: 24, 
                                    marginLeft: 5, 
                                    marginTop: 2
                                }} /> 
                                : form.getFieldValue(["contractConditions", props.name, "mlaDocumentUrl"])
                                ? 
                                <Tooltip title={tooltip4?.split(".com/")[1]}>
                                    <LinkOutlined
                                        style={{
                                                color: "blue", 
                                                fontSize: 24, 
                                                marginLeft: 5, 
                                                marginTop: 8
                                            }} 
                                        onClick={() => handleDocViewer(form.getFieldValue(["contractConditions", props.name, "mlaDocumentUrl"]))} 
                                    /> 
                                </Tooltip>
                            : null
                            }
                        </div>
                </Form.Item>
                <Form.Item
                    label="Security Deposit"
                    name={[props.name, "securityDepositValue"]}
                    style={{width: "100%"}}
                    rules={disabled || !securityDepositApplicable ? [] : [{ required: true, message: 'Please enter security deposit!' }]}
                >
                    <Space direction="vertical" size="middle" style={{width: "100%"}}>
                        <Space.Compact style={{width: "100%"}}>
                            <Select 
                                showSearch={true}
                                disabled={disabled || !securityDepositApplicable} 
                                options={options} 
                                placeholder={"Select"}
                                defaultValue={form.getFieldValue(["contractConditions", props.name, "securityDepositType"]) || "Percentage"}
                                onChange={(value) => form.setFieldValue(["contractConditions", props.name, "securityDepositType"], value)}
                                style={{width: "50%", height: 40}}
                            />
                            <InputNumber 
                                type="number"
                                onWheel={event => event.currentTarget.blur()}
                                disabled={disabled || !securityDepositApplicable} 
                                defaultValue={form.getFieldValue(["contractConditions", props.name, "securityDepositValue"])} 
                                onChange={(value) => form.setFieldValue(["contractConditions", props.name, "securityDepositValue"], value)}
                                style={{width: "100%", height: 40}}
                            />
                        </Space.Compact>
                    </Space>
                </Form.Item>
                <Form.Item
                    label="Down Payment(%)"
                    name={[props.name, "downPaymentPercentage"]}
                    rules={disabled || !downPayment ? [] : [{ required: true, message: 'Please enter down payment!' }]}
                >
                    <Input disabled={disabled || !downPayment} placeholder="Down Payment(%)" defaultValue={form.getFieldValue(["contractConditions", props.name, "downPaymentPercentage"])} />
                </Form.Item>
                <Form.Item
                    label="Processing Fee Amount"
                    name={[props.name, "processingFeeAmount"]}
                    rules={disabled || !processingFeeApplicable ? [] : [{ required: true, message: 'Please enter processing fee amount!' }]}
                >
                    <InputNumber 
                        disabled={disabled || !processingFeeApplicable} 
                        style={{width: "100%"}} placeholder="Processing Fee Amount" 
                        type="number" 
                        onWheel={event => event.currentTarget.blur()} 
                        defaultValue={form.getFieldValue(["contractConditions", props.name, "processingFeeAmount"])}
                    />
                </Form.Item>
                <Form.Item
                    label="Amount"
                    name={[props.name, "bankGuaranteeAmount"]}
                    rules={disabled || !bankGuarantee ? [] : [{ required: true, message: 'Please enter amount!' }]}
                >
                    <InputNumber disabled={disabled || !bankGuarantee} style={{width: "100%"}} defaultValue={form.getFieldValue(["contractConditions", props.name, "bankGuaranteeAmount"])} type="number" onWheel={event => event.currentTarget.blur()} placeholder="Amount" />
                </Form.Item>
            </Col>

            <Divider />
            
            <Form.Item
                    label="Corporate Guarantee Document"
                    name={[props.name, "corporateDocumentUrl"]}
                    //rules={[{ required: true, message: 'Please input your contact number!' }]}
                >
                    <div style={{display: "flex", alignItems: "flex-start"}}>
                        <Upload 
                            multiple={false}
                            maxCount={1}
                            showUploadList={false}
                            beforeUpload={(file) => {
                                setCorporateGuaranteeDocument(file)
                                return false
                            }}
                        >
                            <Button disabled={!corporateGuarantee  || disabled} icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                        {
                        fileLoader1
                        ? <LoadingOutlined 
                            style={{
                                color: "blue", 
                                fontSize: 24, 
                                marginLeft: 5, 
                                marginTop: 2
                            }} /> 
                            : form.getFieldValue(["contractConditions", props.name, "corporateDocumentUrl"])
                            ? 
                            <Tooltip title={tooltip1.split(".com/")[1]}>
                                <LinkOutlined
                                    style={{
                                            color: "blue", 
                                            fontSize: 24, 
                                            marginLeft: 5, 
                                            marginTop: 2
                                        }} 
                                    onClick={() => handleDocViewer(form.getFieldValue(["contractConditions", props.name, "corporateDocumentUrl"]))} 
                                /> 
                            </Tooltip>
                        : null
                        }
                    </div>
            </Form.Item>
            <Form.Item
                label="Bank Guarantee Document"
                name={[props.name, "bankDocumentUrl"]}
                //rules={[{ required: true, message: 'Please input your contact number!' }]}
            >
                <div style={{display: "flex", alignItems: "flex-start"}}>
                    <Upload 
                        multiple={false}
                        maxCount={1}
                        showUploadList={false}
                        beforeUpload={(file) => {
                            setBankGuaranteeDocument(file)
                            return false
                        }}
                    >
                        <Button disabled={!bankGuarantee  || disabled} icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                    {
                    fileLoader2
                    ? <LoadingOutlined 
                        style={{
                            color: "blue", 
                            fontSize: 24, 
                            marginLeft: 5, 
                            marginTop: 2
                        }} /> 
                        : form.getFieldValue(["contractConditions", props.name, "bankDocumentUrl"]) 
                        ? 
                        <Tooltip title={tooltip2.split(".com/")[1]}>
                            <LinkOutlined
                                style={{
                                        color: "blue", 
                                        fontSize: 24, 
                                        marginLeft: 5, 
                                        marginTop: 2
                                    }} 
                                onClick={() => handleDocViewer(form.getFieldValue(["contractConditions", props.name, "bankDocumentUrl"]))} 
                            /> 
                        </Tooltip>
                    : null
                    }
                </div>
            </Form.Item>
            <Form.Item
                label="Letter of Comfort"
                name={[props.name, "letterOfComfortDocumentUrl"]}
                //rules={[{ required: true, message: 'Please input your contact number!' }]}
            >
                <div style={{display: "flex", alignItems: "flex-start"}}>
                    <Upload
                        multiple={false}
                        showUploadList={false}
                        maxCount={1}
                        beforeUpload={(file) => {
                            setLetterOfComfortDocument(file)
                            return false
                        }}
                    >
                        <Button disabled={disabled  || disabled} icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                    {
                    fileLoader3 
                    ? <LoadingOutlined 
                        style={{
                            color: "blue", 
                            fontSize: 24, 
                            marginLeft: 5, 
                            marginTop: 2
                        }} /> 
                    : form.getFieldValue(["contractConditions", props.name, "letterOfComfortDocumentUrl"])
                    ? 
                    <Tooltip title={tooltip3.split(".com/")[1]}>
                        <LinkOutlined
                            style={{
                                    color: "blue", 
                                    fontSize: 24, 
                                    marginLeft: 5, 
                                    marginTop: 2
                                }} 
                            onClick={() => handleDocViewer(form.getFieldValue(["contractConditions", props.name, "letterOfComfortDocumentUrl"]))}
                        /> 
                    </Tooltip>
                : null
                }
                </div>
            </Form.Item>
            </Row>
            <Modal
                centered
                open={modalOpen}
                width={1000}
                style={{}}
                onCancel={() => setModalOpen(false)}
                footer={[
                    <Button type="primary" key="cancel" onClick={() => setModalOpen(false)}>
                    Close
                    </Button>,
                ]}
            >
                <iframe
                    title="docx"
                    src={modalDocUrl}
                    style={{ width: "100%", height: "60vh", border: "none" }}
                />
            </Modal>
        </React.Fragment>
     );
}

export default CreditCondition;