import axios from "axios";

export const fetchProspectsData = async (auth: any) => {
    const prospectsResponse = await axios.get(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/prospect`,
                { headers: { Authorization: `Bearer ${auth.accessToken}` } }
              );
    const prospectHolder = prospectsResponse.data.map((prospect: any)=>{
      return {
        "name": prospect?.name,
        "id": prospect?.prospectId
      }
    })
    return prospectHolder;
};
export const fetchCitiesData = async (auth: any) => {
    const citiesResponse = await axios.get(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/cities`,
                { headers: { Authorization: `Bearer ${auth.accessToken}` } }
              );
    return citiesResponse.data;
};
export const fetchVehiclesData = async (auth: any) => {
    const vehiclesResponse = await axios.post(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/vehicles`,
                {},
                { headers: { Authorization: `Bearer ${auth.accessToken}` } }
              );
    return vehiclesResponse.data;
};
export const fetchMakersData = async (auth: any, selectedVehicleId: any) => {
    const makersResponse = await axios.post(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/makerByVehicle?vehicleId=${selectedVehicleId}`,
                {},
                { headers: { Authorization: `Bearer ${auth.accessToken}` } }
              );
    return makersResponse.data;
};

export const fetchModelsData = async (auth: any, selectedMakeId: any) => {
    const modelsResponse = await axios.post(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/modelByMaker?makerId=${selectedMakeId}`,
                {},
                { headers: { Authorization: `Bearer ${auth.accessToken}` } }
              );
    return modelsResponse.data;
};
export const fetchVariantsData = async (auth: any, selectedModelId: any) => {
    const variantsResponse = await axios.post(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/variantByModel?modelId=${selectedModelId}`,
                {},
                { headers: { Authorization: `Bearer ${auth.accessToken}` } }
              );
    return variantsResponse.data;
};
export const fetchTenuresData = async (auth: any) => {
    const tenuresResponse = await axios.post(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/tenures`,
                {},
                { headers: { Authorization: `Bearer ${auth.accessToken}` } }
              );
    return tenuresResponse.data;
};
export const fetchAccessoriesData = async (auth: any) => {
    const accessoriesResponse = await axios.post(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/accessories`,
                {},
                { headers: { Authorization: `Bearer ${auth.accessToken}` } }
              );
    return accessoriesResponse.data;
};
export const fetchServicesData = async (auth: any) => {
    const serviceResponse = await axios.post(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/services`,
                {},
                { headers: { Authorization: `Bearer ${auth.accessToken}` } }
              );
    return serviceResponse.data;
};
export const fetchGevlStateListData = async (auth: any) => {
  const serviceResponse = await axios.get(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/gevl-states`,
                { headers: { Authorization: `Bearer ${auth.accessToken}` } }
              );
  return serviceResponse.data;
};
