import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Form, Typography } from 'antd';
import { ReactComponent as DownMark } from '../../../../utils/SVGs/DownMark.svg';
import { ReactComponent as RightMark } from '../../../../utils/SVGs/RightMark.svg';
import { formatIndianRupee, formatRatioNumber } from '../formUtils';

interface DebtToEBIDTARatioProps {
    form: any; 
    updateTotalCnt: number;
    viewModeActive: boolean;
    setEbidta: (ebidta:number)=>void;
}

const DebtToEBIDTARatioComponent: React.FC<DebtToEBIDTARatioProps> = ({ form, updateTotalCnt, viewModeActive, setEbidta }) => {
    const [debtToEbidtaRatio, setDebtToEBIDTARatio] = useState<number>(0);
    const [collapseDebtToEBIDTARatioFields, setCollapseDebtToEBIDTARatioFields] = useState<boolean>(false);
    const INR_SYMBOL: string = '\u20B9';
    
    useEffect(() => {
        const values = form.getFieldsValue(true);
        if (values.debtToEbidtaRatio)
        {
            setDebtToEBIDTARatio(values.debtToEbidtaRatio);
            setEbidta(values.ebidta);
        }
        
    }, [updateTotalCnt]);

    const handleFieldChange = () => {
        const values = form.getFieldsValue();
        const EBITDA = Number(values.ebidta);
        
        if (!form.getFieldValue('totalDebt') || !EBITDA) {
            setDebtToEBIDTARatio(0);
            return;
        }

        const updatedDebtToEBIDTARatio = form.getFieldValue('totalDebt') / EBITDA;
        form.setFieldsValue({ debtToEbidtaRatio: updatedDebtToEBIDTARatio });
        setEbidta(EBITDA);
        setDebtToEBIDTARatio(updatedDebtToEBIDTARatio);
    };

    return (
        <>
            <Row
                gutter={16}
                style={{ marginTop: '10px', paddingTop: '10px', borderBottom: '1px #E8E8E8 solid', paddingBottom: '20px' }}
            >
                <Col span={12}>
                    <span onClick={() => setCollapseDebtToEBIDTARatioFields((oldState) => !oldState)}>
                        {collapseDebtToEBIDTARatioFields ? <RightMark /> : <DownMark />}
                    </span>
                    <span style={{ fontSize: '14px', fontWeight: '600', marginLeft:'10px' }}>Debt to EBITDA Ratio</span>
                </Col>
                <Col span={12} key={1}>
                    <Typography style={{ fontSize: 14, fontWeight: '600', paddingLeft: '10px' }}>
                        {formatRatioNumber(debtToEbidtaRatio)}
                    </Typography>
                </Col>
            </Row>
            {
                !collapseDebtToEBIDTARatioFields && (
                    <>
                        <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px', borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                            <Col span={12}><p style={{ marginLeft: '20px' }}>Total Debt</p></Col>
                            <Col span={12} key={1}>
                                <Typography style={{ paddingLeft: '10px' }}>
                                    <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue('totalDebt'))}`}</span>
                                </Typography>
                            </Col>
                        </Row>

                        <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px', borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                            <Col span={12}><p style={{ marginLeft: '20px' }}>EBITDA</p></Col>
                            <Col span={12} key={1}>
                                {viewModeActive ? (
                                    <Typography style={{ paddingLeft: '10px' }}>
                                        <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue('ebidta'))}`}</span>
                                    </Typography>
                                ) : (
                                    <Form.Item
                                        name={'ebidta'}
                                        rules={[{ required: true, message: 'Please enter EBIDTA value' }]}
                                    >
                                        <Input
                                            type="number"
                                            onChange={handleFieldChange}
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                        </Row>
                    </>
                )
            }
        </>
    );
};

export default DebtToEBIDTARatioComponent;
