type Field = {
  label: string;
  name: string;
  values?: string[];
  apiData?: boolean;
  multipleSelect?: boolean;
  required?: boolean;
  isNumber?: boolean;
  isApiResponse?: boolean;
  editable?: boolean;
};

type Step = {
  title: string;
  fields: Field[];
};

const steps: Step[] = [
  {
    "title": "Step 1",
    "fields": [
      {
        "label": "Prospect Name",
        "name": "prospectId",
        "required": true,
      },
      {
        "label": "Vehicle Type",
        "name": "vehicleType",
        "values": ["New", "Used"],
        "required": true
      },
      {
        "label": "Buying City",
        "name": "buyingCityId",
        "required": true
      },
      {
        "label": "GEVL State",
        "name": "gevlStateId",
        "required": true
      },
      {
        "label": "Registered To",
        "name": "registeredTo",
        "values": ["Lessor", "Lessee"],
        "required": true
      },
      {
        "label": "Registration Type",
        "name": "registrationType",
        "values": ["Commercial", "Private"],
        "required": true
      },
      {
        "label": "Payment Type",
        "name": "paymentType",
        "values": ["Arrear", "Advance"],
        "required": true
      },
      {
        "label": "Insurance Type",
        "name": "insuranceType",
        "values": ["AYF", "IYF", "AYR", "Not Managed"],
        "required": true
      },
      {
        "label": "Vehicle Category",
        "name": "vehicleId",
        "required": true
      },
      {
        "label": "Make",
        "name": "makeId",
        "required": true
      },
      {
        "label": "Model",
        "name": "modelId",
        "required": true
      },
      {
        "label": "Variant",
        "name": "variantId",
        "required": true
      }
    ]
  },
  {
    "title": "Step 2",
    "fields": [
      {
        "label": "Ex-showroom Price",
        "name": "exShowroom",
        "required": true,
        "isNumber": true,
      },
      {
        "label": "Base Price",
        "name": "basePrice",
        "required": false,
        "isNumber": true,
        "isApiResponse": true,
        "editable": false,
      },
      {
        "label": "GST @ 5%",
        "name": "gstOnVehicle",
        "required": true,
        "isNumber": true,
        "isApiResponse": true,
        "editable": true,
      },
      {
        "label": "Fame Subsidy",
        "name": "fameSubsidy",
        "required": false,
        "isNumber": true,
      },
      {
        "label": "State Subsidy",
        "name": "stateSubsidy",
        "required": false,
        "isNumber": true,
      },
      {
        "label": "Discount",
        "name": "discount",
        "required": false,
        "isNumber": true,
      },
      {
        "label": "Road Tax",
        "name": "roadTaxType",
        "required": true,
        "values": ["Financed", "Reimbursed", "Not Managed"],
      },
      {
        "label": "Road Tax Amount",
        "name": "roadTaxTotal",
        "required": true,
        "isNumber": true,
      },
      {
        "label": "Tenure In Month",
        "name": "tenureInMonthId",
        "required": true
      },
      {
        "label": "Insurance Amount",
        "name": "insuranceAmountTotal",
        "isNumber": true,
        "isApiResponse": true,
        "editable": false,
      },
      {
        "label": "First Year Insurance Premium",
        "name": "firstYearInsurancePremium",
        "isNumber": true,
      },
      {
        "label": "Mileage Per Month(KM)",
        "name": "mileagePerMonth",
        "required": true,
        "isNumber": true,
      },
      {
        "label": "Fabrication Amount",
        "name": "fabricationAmountTotal",
        "required": false,
        "isNumber": true,
      },
      {
        "label": "Accessories",
        "name": "accessories",
        "multipleSelect": true,
        "required": false
      },
      {
        "label": "Other Accessories Description",
        "name": "otherAccessoriesDescription",
        "required": false
      },
      
    ]
  },
  {
    "title": "Step 3",
    "fields": [
      {
        "label": "Service",
        "name": "services",
        "apiData": true,
        "required": false
      },
      {
        "label": "Management Fees",
        "name": "managementFees",
        "required": true,
        "isNumber": true,
        "isApiResponse": true,
      },
      {
        "label": "TCS",
        "name": "tcs",
        "required": false,
        "isApiResponse": true,
        "isNumber": true,
        "editable": true,
      },
      {
        "label": "RV %",
        "name": "rvPercentage",
        "isNumber": true,
        "required": true,
        "isApiResponse": true,
      },
      {
        "label": "Cost of Fund %",
        "name": "costOfFundPercentage",
        "required": true,
        "isNumber": true,
      },
      {
        "label": "Margin Rate %",
        "name": "offerRatePercentage",
        "required": true,
        "isNumber": true,
      },
      {
        "label": "Excess Mileage Charge(Per KM)",
        "name": "excessMileageChargePerKm",
        "required": true,
        "isNumber": true,
      },
      {
        "label": "Is Active",
        "name": "active",
      },
      
    ]
  },
  {
    "title": "Step ",
    "fields": [],
  }
];

export default steps;
