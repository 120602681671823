import React, { useEffect, useState } from "react";
import { Form, Input, Button, Divider, DatePicker, Row, Col, message, Dropdown, MenuProps, Select, SelectProps } from "antd";
import { authSelector } from "../../../features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { LockOutlined, MailOutlined, PlusOutlined, SmileOutlined, UserOutlined } from '@ant-design/icons';
import axios from "axios";
import dayjs from "dayjs";
import { setCities, utilsSelector } from "../../../features/utils/utilsSlice";
import moment from 'moment';

const { Option } = Select;
interface FormDetails {
    email: string,
    firstname: string,
    lastname: string,
    roles: string[],
    contact_no: string,
    department: string,
    designation: string,
    location: string,
    date_of_joining: string,
}

const initFormState = {
    email: "",
    firstname: "",
    lastname: "",
    roles: [],
    contact_no: "",
    department: "",
    designation: "",
    location: "",
    date_of_joining: ""
    };

const handleChange = (value: string) => {
    console.log(`selected ${value}`);
};

const CreateUser = () => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [fromData, setFormData] = useState<FormDetails>(initFormState);
    const [location, setLocation] = useState<any>([]);
    const [designation, setDesignation] = useState<any>([]);
    const [department, setDepartment] = useState<any>([]);
    const [role, setRole] = useState<any>([]);
    const auth = useAppSelector(authSelector);
    const dispatch = useAppDispatch();
    const utils = useAppSelector(utilsSelector);
    const cities = utils.cities;

    const onFinish = async (values: FormDetails) => {
        setFormData(values);
        const body = {
            firstName: values.firstname,
            lastName: values.lastname,
            emailId: values.email,
            role: [values.roles],
            contactNo: values.contact_no,
            department: values.department,
            designation: values.designation,
            location: values.location,
            dateOfJoining: (dayjs(values.date_of_joining).toISOString().split("T")[0]).split("-").join("/")
        }

        try {
            await axios.post(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/users`, body, {
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`,
                    "Content-Type": "application/json"
                }
            }).then(res => {
                if(res.status === 204 || res.status === 200) {
                    messageApi.success("User added!")
                    setTimeout(() => {
                        window.open(`${process.env.REACT_APP_REDIRECT_ENDPOINT}/dashboard/users`, "_self");
                    }, 1000);
                }
                else 
                    messageApi.error("Failed to add user!");
            });
            form.resetFields(["firstname", "lastname", "email", "roles"]);
        } catch (err: any) {
            messageApi.error(`${err.response.data.errors[0]}`);
        }      
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await axios.post(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/cities`, {}, {
                    headers: {
                        "Authorization": `Bearer ${auth.accessToken}`,
                        "Content-Type": "application/json"
                    }
                }).then(res => {
                    dispatch(setCities(res.data));
                })

                await axios.get(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/departments`, {
                    headers: {
                        "Authorization": `Bearer ${auth.accessToken}`,
                        "Content-Type": "application/json"
                    }
                }).then(res => {
                    let input: any = [];
                    res.data.map((e: any) => {
                        input.push({
                            label: e.departmentName,
                            value: e.departmentName
                        });
                    })
                    setDepartment(input);
                })

                await axios.get(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/designations`, {
                    headers: {
                        "Authorization": `Bearer ${auth.accessToken}`,
                        "Content-Type": "application/json"
                    }
                }).then(res => {
                    let input: any = [];
                    res.data.map((e: any) => {
                        input.push({
                            label: e.name,
                            value: e.name
                        });
                    })
                    setDesignation(input);
                })

                await axios.get(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/roles`, {
                    headers: {
                        "Authorization": `Bearer ${auth.accessToken}`,
                        "Content-Type": "application/json"
                    }
                }).then(res => {
                    let input: any = [];
                    res.data.map((e: any) => {
                        input.push({
                            label: e.name,
                            value: e.name
                        });
                    })
                    setRole(input);
                })

            } catch (err) {
                console.log("Error: ", err);
            }
        }
        fetchData();
    }, []);

    const optionsToRender = (options: any[]) => {
        return options.map((value: any) => (
          <Option key={value.id} value={value.name}>
            {value.name}
          </Option>
        ));
      };

    function disabledDate(current: any) {
    // Can not select days future dates
    return current && current > moment().endOf('day');
    }

    return (
        <React.Fragment>
        {contextHolder}
        <Form 
            form={form}
            onFinish={onFinish}
            layout="vertical"
            className="form-container"
        >
        <h4>Create User</h4>
            <Row className="">
                <Col className="form-column">
                    <Form.Item
                        label="First Name"
                        name="firstname"
                        rules={[{ required: true, message: 'Please enter first name!' }]}
                    >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />}  placeholder="First name" />
                    </Form.Item>
                    <Form.Item
                        label="Last Name"
                        name="lastname"
                        rules={[{ required: true, message: 'Please enter last name!' }]}
                    >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Last name" />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        label="Designation"
                        name="designation"
                        rules={[{ required: true, message: 'Please enter designation!' }]}
                    >
                        <Select
                            showSearch={true}
                            style={{ width: '100%' }}
                            placeholder="Designations"
                            onChange={handleChange}
                            options={designation}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Location"
                        name="location"
                        rules={[{ required: true, message: 'Please enter location!' }]}
                    >
                        <Select
                            showSearch={true}
                            style={{ width: '100%' }}
                            placeholder="Locations"
                            onChange={handleChange}
                        >
                            {optionsToRender(cities)}
                        </Select>
                    </Form.Item>
                </Col>
                <Col className="form-column">
                    <Form.Item
                        label="Date of joining"
                        name="date_of_joining"
                        rules={[{ required: true, message: 'Please enter date of joining!' }]}
                    >
                        <DatePicker
                            format="YYYY-MM-DD"
                            style={{ width: "100%"}}
                            disabledDate={disabledDate}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Role"
                        name="roles"
                        rules={[{ required: true, message: 'Please input user roles!' }]}
                    >
                        <Select
                            showSearch={true}
                            style={{ width: '100%' }}
                            placeholder="Roles"
                            onChange={handleChange}
                            options={role}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Department"
                        name="department"
                        rules={[{ required: true, message: 'Please enter department!' }]}
                    >
                        <Select
                            showSearch={true}
                            style={{ width: '100%' }}
                            placeholder="Department"
                            onChange={handleChange}
                            options={department}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Contact Number"
                        name="contact_no"
                        rules={[{ required: true, message: 'Please input your contact number!' }]}
                    >
                        <Input maxLength={10} placeholder="Contact number" />
                    </Form.Item>
                </Col>
            </Row>

        <Form.Item>
        <Button type="primary" htmlType="submit">
            Submit
        </Button>
        </Form.Item>
        </Form>
        </React.Fragment>
    );
};

export default CreateUser;