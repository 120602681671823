import React, { useState } from 'react';
import Modal from 'antd/lib/modal/Modal'; // Assuming you are using Ant Design modal
import Button from 'antd/lib/button/button'; // Assuming you are using Ant Design button
import { ReactComponent as CrossIcon } from '../pages/Dashboard/Prospect/utils/SVGs/cross_icon.svg';
import { ReactComponent as TickIcon } from '../pages/Dashboard/Prospect/utils/SVGs/tick_icon_stroke.svg';

interface ConfirmPopupProps {
  visible: boolean;
  title: string;
  message: string;
  dontSaveButtonText: string;
  saveButtonText:string;
  onCancel: () => void;
  onDontSave: () => void;
  onConfirm: () => void;
}

const ConfirmPopupCustom: React.FC<ConfirmPopupProps> = ({
  visible,
  title,
  message,
  dontSaveButtonText,
  saveButtonText,
  onCancel,
  onDontSave,
  onConfirm,
}) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      centered={true}
      footer={[
        <Button style={{color:'#1777FF',backgroundColor:'#fff',borderColor:'#FFF', boxShadow:'none', fontWeight: '500'}} key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button icon={<CrossIcon />} style={{color:'#FF4D4F',backgroundColor:'rgba(255, 77, 79, 0.10)',borderColor:'#FF4D4F', fontWeight: '500'}} key="cancel" onClick={onDontSave}>
          {dontSaveButtonText}
        </Button>,
        <Button icon={<TickIcon color='white'/>} style={{color:'#FFF',backgroundColor:'1777FF', fontWeight: '500'}} key="confirm" type="primary" onClick={onConfirm}>
          {saveButtonText}
        </Button>,
      ]}
    >
      {message}
    </Modal>
  );
};

export default ConfirmPopupCustom;
