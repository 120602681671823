import { Button, Input, Modal, Spin, Typography } from "antd";
import { ReactComponent as DisabledIcon } from '../../SVGs/disabled_icon.svg';
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;
const antIconOpsReview = <LoadingOutlined style={{ fontSize: 16, color: "#FF4D4F", marginLeft: 6 }} spin />;

interface ModalProps {
    type: string;
    title: string;
    subTitle: string;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    remark: string;
    setRemark: (remark: string) => void;
    cancel: () => void;
    cancelLoader: boolean;
    backToOpsReviewLoader:boolean;
    isBackToOpsReview : boolean;
    setIsBackToOpsReview: (isBackToOpsReview: boolean) => void;
    quoteStatusId : number;
}

const CancelRequestModal: React.FC<ModalProps> = ({type, showModal, title, subTitle, setShowModal, remark, setRemark, cancel, cancelLoader,backToOpsReviewLoader, isBackToOpsReview, setIsBackToOpsReview, quoteStatusId}) => {
    const [rejectButtonVisibility, setRejectButtonVisibility] = useState<boolean>(true);
    const [opsReviewButtonVisibility, setOpsReviewButtonVisibility] = useState<boolean>(true);
    const [doNotRejectButtonVisibility, setDoNotRejectButtonVisibility] = useState<boolean>(true);
    const firstRenderRef = useRef(true);
    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
            return;
        }
        type === "Reject" && cancel();
    }, [isBackToOpsReview]);

    return (
        <Modal
            title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 500, color: "#404040"}}>{title}</Typography>}
            centered
            open={showModal}
            onOk={() => setShowModal(false)}
            onCancel={() => setShowModal(false)}
            closable={true}
            afterClose={() => setRemark("")}
            className="preview-modal"
            footer={<>
                <div className="edit-section">
                    <Button
                        ghost
                        disabled={!doNotRejectButtonVisibility}
                        type="primary"
                        onClick={_ => {setShowModal(false); setRemark("")}}
                        className="button-override primary"
                        >
                        Don't {type}
                    </Button>
                    {
                    type.localeCompare("Reject")===0 && quoteStatusId == 3 &&
                        <Button
                            ghost
                            danger
                            type="primary"
                            disabled={remark === "" || !opsReviewButtonVisibility}
                            icon={backToOpsReviewLoader? <Spin indicator={antIconOpsReview} /> : <DisabledIcon />}
                            onClick={() => {setIsBackToOpsReview(true); 
                                setRejectButtonVisibility(false); setDoNotRejectButtonVisibility(false);
                                }}
                            className="button-override danger"
                            >
                            Back to Ops Review
                        </Button>
                    }
                    <Button
                        danger
                        disabled={remark === "" || !rejectButtonVisibility}
                        type="primary"
                        icon={cancelLoader ? <Spin indicator={antIcon} /> : <DisabledIcon />}
                        onClick={() => {
                            !cancelLoader && cancel(); 
                            setOpsReviewButtonVisibility(false); setDoNotRejectButtonVisibility(false);
                        }}
                        className="button-override"
                        >
                        {type} Request
                    </Button>
                </div>
            </>}
        >
            <p>{subTitle}</p>
            <Input className="input-number-override" style={{height: 40, padding: 4, backgroundColor: "#F6FAFF"}} value={remark} onChange={e => setRemark(e.target.value)} placeholder="Enter Remark" />
        </Modal>
    );
}

export default CancelRequestModal;