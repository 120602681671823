import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Form, Typography } from 'antd';
import { ReactComponent as DownMark } from '../../../../utils/SVGs/DownMark.svg';
import { ReactComponent as RightMark } from '../../../../utils/SVGs/RightMark.svg';
import { formatIndianRupee, formatRatioNumber } from '../formUtils';

interface DebtToServiceCoverageRatioProps {
    form: any; 
    updateTotalCnt: number;
    viewModeActive: boolean;
    netRevenue: number;
}

const DebtToServiceCoverageRatioComponent: React.FC<DebtToServiceCoverageRatioProps> = ({ form, updateTotalCnt, viewModeActive, netRevenue }) => {
    const [debtToServiceCoverageRatio, setDebtToServiceCoverageRatio] = useState<number>(0);
    const [revenueCoe, setRevenueCoe] = useState<number>(0);
    const [collapseDebtToServiceCoverageRatioFields, setCollapseDebtToServiceCoverageRatioFields] = useState<boolean>(false);
    const INR_SYMBOL: string = '\u20B9';
    
    useEffect(() => {
        const values = form.getFieldsValue(true);
        const operatingExp = Number(values.operatingExp);
        const newRevenueCoe = netRevenue - operatingExp;
        setRevenueCoe(newRevenueCoe)
        const revenueCoe = Number(values.revenueCoe);
        form.setFieldsValue({ revenueCoe : newRevenueCoe})
        const currentDebtObligations = Number(values.currentDebtObligations);
        form.setFieldsValue({ debtToServiceCoverageRatio : newRevenueCoe / currentDebtObligations})
        
        setDebtToServiceCoverageRatio(newRevenueCoe/currentDebtObligations);
    }, [updateTotalCnt]);

    const handleFieldChange = () => {
        const values = form.getFieldsValue(true);
        const revenueCoe = Number(values.revenueCoe);
        const operatingExp = Number(values.operatingExp);
        const currentDebtObligations = Number(values.currentDebtObligations);
        const newRevenueCoe = netRevenue - operatingExp
        setRevenueCoe(newRevenueCoe);
        form.setFieldsValue({ revenueCoe : newRevenueCoe})
        
        if (!newRevenueCoe || !currentDebtObligations) {
            setDebtToServiceCoverageRatio(0);
            return;
        }

        form.setFieldsValue({ debtToServiceCoverageRatio : newRevenueCoe / currentDebtObligations})
        
        setDebtToServiceCoverageRatio(newRevenueCoe/currentDebtObligations);
    };

    return (
        <>
            <Row
                gutter={16}
                style={{ marginTop: '10px', paddingTop: '10px', borderBottom: '1px #E8E8E8 solid', paddingBottom: '20px' }}
            >
                <Col span={12}>
                    <span onClick={() => setCollapseDebtToServiceCoverageRatioFields((oldState) => !oldState)}>
                        {collapseDebtToServiceCoverageRatioFields ? <RightMark /> : <DownMark />}
                    </span>
                    <span style={{ fontSize: '14px', fontWeight: '600', marginLeft:'10px' }}>Debt to Service Coverage Ratio</span>
                </Col>
                <Col span={12} key={1}>
                    <Typography style={{ fontSize: 14, fontWeight: '600', paddingLeft: '10px' }}>
                        { formatRatioNumber(debtToServiceCoverageRatio) }
                    </Typography>
                </Col>
            </Row>
            {
                !collapseDebtToServiceCoverageRatioFields && (
                <>
                    <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: '20px', borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                        <Col span={12}><p style={{ marginLeft: '20px' }}>Revenue - COE</p></Col>
                        <Col span={12} key={1}>
                                <Typography style={{ paddingLeft: '10px' }}>
                                    <span>{`${INR_SYMBOL} ${formatIndianRupee(revenueCoe)}`}</span>
                                </Typography>
                                
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px', borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                        <Col span={12}><p style={{ marginLeft: '20px' }}>Operating Exp</p></Col>
                        <Col span={12} key={1}>
                            {viewModeActive ? (
                                <Typography style={{ paddingLeft: '10px' }}>
                                    <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue('operatingExp'))}`}</span>
                                </Typography>
                            ) : (
                                <Form.Item
                                    name={"operatingExp"}
                                    rules={[{ required: true, message: 'Please enter Operating Exp value' }]}
                                >
                                    <Input
                                        type="number"
                                        onChange={handleFieldChange}
                                    />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: '20px', borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                        <Col span={12}><p style={{ marginLeft: '20px' }}>Current Debt Obligations</p></Col>
                        <Col span={12} key={1}>
                            <Typography style={{ paddingLeft: '10px' }}>
                                <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue('currentDebtObligations'))}`}</span>
                            </Typography>
                        </Col>
                    </Row>
                </>
                )
            }
        </>
    );
};

export default DebtToServiceCoverageRatioComponent;
