// INPUT SHOULD BE IN ISO STRING FORMAT

export const getFormattedDate =  (inputInstant : string) => {
    const instant = new Date(inputInstant);
    const istInstant = new Date(instant.getTime() + (5.5 * 60 * 60 * 1000));
    
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = istInstant.getUTCDate();
    const month = months[istInstant.getUTCMonth()];
    const year = istInstant.getUTCFullYear();
    
    const hours = istInstant.getUTCHours();
    const minutes = istInstant.getUTCMinutes();

    const period = hours >= 12 ? ' PM' : ' AM';
    const formattedHours = hours % 12 || 12;

    const formattedTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes}${period}`;

    const formattedDate = `${formattedTime}, ${day} ${month} ${year}`;
    return formattedDate;
}

export const getFormattedDateWithoutChangingTimezone = (inputInstant: string) => {
    const instant = new Date(inputInstant);

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = instant.getUTCDate();
    const month = months[instant.getUTCMonth()];
    const year = instant.getUTCFullYear();

    const hours = instant.getUTCHours();
    const minutes = instant.getUTCMinutes();

    const period = hours >= 12 ? ' PM' : ' AM';
    const formattedHours = hours % 12 || 12;

    const formattedTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes}${period}`;

    const formattedDate = `${formattedTime}, ${day} ${month} ${year}`;
    return formattedDate;
}
