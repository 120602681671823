import { Tooltip, Select } from 'antd';
import { EditOutlined, EyeOutlined, BarsOutlined, FilterOutlined, MenuOutlined, ArrowUpOutlined, ArrowDownOutlined, CaretUpOutlined, CaretDownOutlined, SearchOutlined, EyeFilled } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import CustomSelectFilter from '../../Prospect/utils/CustomSelectFilter';
import '../../Prospect/utils/CustomSelectFilter.css';
import { ReactComponent as DownloadIcon } from '../../SVGs/download_icon.svg';
import { ReactComponent as SortFilterIcon } from './../../../../utils/icons/Vector.svg';
import { ReactComponent as EyeIcon } from './../../../../utils/icons/view_icon.svg';
import UnionFilterIcon from "../../Prospect/utils/SVGs/UnionFilterIcon";
import SortUpperFadedIcon from '../../Prospect/utils/SVGs/SortUpperFadedIcon';
import SortLowerFadedIcon from '../../Prospect/utils/SVGs/SortLowerFadedIcon';
import { getFormattedDate, getFormattedDateWithoutChangingTimezone } from '../../../../utils/helpers/utils';
import { handleFileDownload } from '../../../../utils/handler/apiHandler';

const { Option } = Select;

interface AxisState {
  XAxis: number;
  YAxis: number;
}

export const getApprovedCreditAssessmentRequestColumns = (
  statusList: any[],
  businessSegmentsList: any[],
  selectedStatus: string[],
  selectedBusinessSegments: string[],
  setSelectedStatus: (selectedStatus: string[]) => void,
  setSelectedBusinessSegments: (selectedBusinessSegments: string[]) => void,
  displayLegalBusinessSearchFilterScreen: boolean,
  setDisplayLegalBusinessSearchFilterScreen: (displayProspectSearchFilterScreen: boolean) => void,
  setDisplayOwnerSearchFilterScreen: (displayOwnerSearchFilterScreen: boolean) => void,
  setDisplayUpdatedByFilterScreen: (displayUpdatedBySearchFilterScreen: boolean) => void,
  editProspect: (payload: any) => void,
  agingSortingOrder: 'ascending' | 'descending' | null,
  handleUpdatedAtOrder: () => void,
  requestedAtOrder: 'ascending' | 'descending' | null,
  validTillOrder: 'ascending' | 'descending' | null,
  amountSortOrder: 'ascending' | 'descending' | null,
  handleAmountOrder: () => void,
  handleRequestedAtOrder: () => void,
  handleValidTillOrder: () => void,
  setMainCurrentPage: (currentPage: number) => void,
  selectedProspectsList: any[],
  selectedOwnersList: any[],
  selectedUpdatedByList: any[],
  setProspectOwnerAxisState: (axis: AxisState) =>void,
  setLegalBusinessAxisState: (axis: AxisState) =>void,
  setUpdatedByAxisState: (axis: AxisState) =>void,
  setSanctionModal: (sanctionModal: boolean) => void,
  setSanctionData: (sanctionData: boolean) => void
): ColumnsType<any> => {
  
  const AgingSortIcon = agingSortingOrder === 'ascending' ? <CaretUpOutlined style={{ fontSize: '1.3rem'}} /> : <CaretDownOutlined style={{ fontSize: '1.3rem'}}/>;
  const FILTER_ICON_COLOR = '#1777FF';
  const UNICODE_FOR_INR_SYMBOL = '\u20B9';
  const filterIcon = () => (
    <UnionFilterIcon fill={selectedStatus.length===0 ? '#2C2C2C' : FILTER_ICON_COLOR} style={{ marginLeft: "12px" }} />
  );
  const segmentFilterIcon = (filtered:any) => (
    <UnionFilterIcon fill={selectedBusinessSegments.length===0 ? '#2C2C2C' : FILTER_ICON_COLOR} style={{ marginLeft: "12px" }} />
  );

  const sortValidTillFilterIcon = () => (
      validTillOrder === 'descending' ? <SortUpperFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : validTillOrder === 'ascending' ?  <SortLowerFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : <SortFilterIcon  style={{ marginLeft: "12px", marginTop:'5px' }} />
  );

  const sortRequestedAtFilterIcon = () => (
    requestedAtOrder === 'descending' ? <SortUpperFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : requestedAtOrder === 'ascending' ?  <SortLowerFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : <SortFilterIcon  style={{ marginLeft: "12px", marginTop:'5px' }} />
  );

  const sortUpdatedAtFilterIcon = () => (
    agingSortingOrder === 'descending' ? <SortUpperFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : agingSortingOrder === 'ascending' ?  <SortLowerFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : <SortFilterIcon  style={{ marginLeft: "12px", marginTop:'5px' }} />
  );

  const sortTotalInvestmentFilterIcon = () => (
    amountSortOrder === 'descending' ? <SortUpperFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : amountSortOrder === 'ascending' ?  <SortLowerFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : <SortFilterIcon  style={{ marginLeft: "12px", marginTop:'5px' }} />
  );

  const handleLegalBusinessIconClick = (e:any) => {
    console.log(`e=%o. e.clientX=%o`,e,e.clientX);
    setLegalBusinessAxisState({'XAxis':e.clientX,'YAxis':e.clientY});
    setDisplayLegalBusinessSearchFilterScreen(true);
  };

  const handleOwnerSearchIconClick = (e:any) => {
    console.log(`e=%o. e.clientX=%o`,e,e.clientX);
    setProspectOwnerAxisState({'XAxis':e.clientX,'YAxis':e.clientY});
    setDisplayOwnerSearchFilterScreen(true);
  };

  const handleUpdatedByIconClick = (e:any) => {
    setDisplayUpdatedByFilterScreen(true);
    setUpdatedByAxisState({'XAxis':e.clientX,'YAxis':e.clientY});
  };
  
  const handleDownloadSanction = (record: any) => {
    handleFileDownload(record?.sanctionDocId, `Sanction_${record?.creditRequestId}`, "pdf")
  }

  return [
    {
      title: 'Request no.',
      dataIndex: 'creditRequestId',
      key: 'creditRequestId',
      width: 100,
    },

    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{}}>Legal Business</span>
          <SearchOutlined onClick={(e)=>handleLegalBusinessIconClick(e)} style={{ marginLeft: '12px', cursor: 'pointer', color: selectedOwnersList.length!==0 ? FILTER_ICON_COLOR : 'black'}} />
        </div>
      ),
      dataIndex: 'legalBussinessName',
      key: 'legalBussinessName',
      width: 140,
      render: (text: string) => {
          return (
              <Tooltip title={text}>
                  <div style={{ width: 130, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  {text}
                  </div>
              </Tooltip>
          )
      }
    },

    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{}}>Prospect Owner</span>
          <SearchOutlined onClick={(e)=>handleOwnerSearchIconClick(e)} style={{ marginLeft: '12px', cursor: 'pointer', color: selectedOwnersList.length!==0 ? FILTER_ICON_COLOR : 'black'}} />
        </div>
      ),
      dataIndex: 'prospectOwner',
      key: 'prospectOwner',
      width: 140,
      render: (text: string) => {
          const owner = JSON.parse(text).name;
          return (
              <Tooltip title={owner}>
                  <div style={{ width: 130, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  {owner}
                  </div>
              </Tooltip>
          )
      }
    },


    {
      dataIndex: 'approvedAmount',
      key: 'approvedAmount',
      width: 190,
      render: (text: string) => text != null ? <p>{UNICODE_FOR_INR_SYMBOL} {text}</p> : <span style={{ fontSize: 12 }}>NA</span>,
      sortDirections: ['ascend', 'descend'],
      title: (
          <div onClick={handleAmountOrder} style={{ cursor: 'pointer', display: 'flex' }}>
              <span style={{ marginRight: '0.5rem' }}>Approved Amount</span>
              {sortTotalInvestmentFilterIcon()}
          </div>
      ),
    },
  
    {
      dataIndex: 'validTill',
      key: 'validTill',
      width: 170,
      render: (text: string) => text != null ? <span style={{fontSize: 13}}>{`${getFormattedDate(text)}`}</span> : <></>,
      sortDirections: ['ascend', 'descend'],
      title: (
        <div onClick={handleRequestedAtOrder} style={{ cursor: 'pointer', display:'flex' }}>
          <span style={{marginRight:'0.5rem'}}>Valid Till</span> 
          {sortValidTillFilterIcon()}
        </div>
      ),
    },

    {
      dataIndex: 'sanctionedAt',
      key: 'sanctionedAt',
      width: 170,
      render: (text: string) => text != null ? <span style={{fontSize: 13}}>{`${getFormattedDate(text)}`}</span> : <></>,
      sortDirections: ['ascend', 'descend'],
      title: (
        <div onClick={handleValidTillOrder} style={{ cursor: 'pointer', display:'flex' }}>
          <span style={{marginRight:'0.5rem'}}>Sanctioned At</span> 
          {sortRequestedAtFilterIcon()}
        </div>
      ),
    },

    {
      title: 'Action',
      key: 'action',
      width: 120,
      align: "left",
      render: (text, record) => (
        <div style={{display: "flex", justifyContent: "flex-start"}}>
          <Tooltip placement="bottom" title={"View Credit Assessment Request"}>
            <a onClick={() => {setSanctionModal(true); setSanctionData(record);}}>
              <EyeIcon style={{marginRight:'0.6rem'}}/>
            </a>
          </Tooltip>
          <Tooltip placement="bottom" title={"Download Sanction Letter"}>
            <a onClick={() => handleDownloadSanction(record)}>
              <DownloadIcon style={{marginRight:'0.6rem'}}/>
            </a>
          </Tooltip>
        </div>

      ),
    },
    
  ];
};
