import { Button, Input, Modal, Spin, Typography } from "antd";
import { ReactComponent as RightArrowIcon } from '../../SVGs/right_arrow_icon.svg';
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;

interface ModalProps {
    isAddModal : boolean
    showParentModal: boolean;
    setShowParentModal: (showModal: boolean) => void;
    parentEntityName: string;
    setParentEntityName: (showModal: string) => void;
    handler: () => void;
    loader: boolean;
}

const ParentEntityModal: React.FC<ModalProps> = ({isAddModal, showParentModal, setShowParentModal, parentEntityName, setParentEntityName, handler, loader }) => {

    return (
        <Modal
            title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 700, color: "#404040", marginBottom: 10}}>{isAddModal ? "Add Parent Company" : "Edit Parent Company"}</Typography>}
            centered
            open={showParentModal}
            onOk={() => { setShowParentModal(false);}}
            onCancel={() => { setShowParentModal(false); }}
            closable={true}
            afterClose={() => {}}
            className="preview-modal"
            footer={<>
                <div className="edit-section">
                    <Button
                        type="primary"
                        disabled={parentEntityName === ""}
                        onClick={_ => !loader && handler()}
                        className="button-override"
                        >
                        {isAddModal === true ? "Add Parent Company" : "Proceed"}
                        { loader ? <Spin indicator={antIcon} /> : <RightArrowIcon style={{marginLeft: 10}} /> }
                    </Button>
                </div>
            </>}
        >
            <Typography>{isAddModal === true ? "Enter parent Legal Business Name" : "Parent Legal Business Name"}</Typography>
            <Input style={{marginTop :10}} value={parentEntityName} onChange={(event) => {setParentEntityName(event.target.value)}} onWheel={event => event.currentTarget.blur()} placeholder="Enter legal business name here" />
        </Modal>
    );
}

export default ParentEntityModal;