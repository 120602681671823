import React, { useState, useEffect } from 'react'
import { useAppSelector } from '../../../app/hooks';
import { authSelector } from '../../../features/auth/authSlice';
import { useLocation, useNavigate } from 'react-router';
import {
  Table,
  Column,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  sortingFns,
  getSortedRowModel,
  FilterFn,
  SortingFn,
  ColumnDef,
  flexRender,
  FilterFns,
  createColumnHelper,
} from '@tanstack/react-table'
import {
  RankingInfo,
  rankItem,
  compareItems,
} from '@tanstack/match-sorter-utils';
import { Button, Input, Tooltip, Select, Tag, Modal, Descriptions } from 'antd';
import { DownOutlined, EditOutlined, EyeOutlined, PlusOutlined, ReconciliationOutlined, SendOutlined, UpOutlined } from '@ant-design/icons';
import MUITable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DebouncedInput from '../../../utils/helpers/DebounceInput';
import { getRequest } from '../../../utils/handler/apiHandler';
import dayjs from 'dayjs';
import getDynamicAction from '../../../utils/helpers/dynamicAction';
import { menuSelector } from '../../../features/menu/menuSlice';
import { userSelector } from '../../../features/auth/userSlice';

type ClientRequest = {
    clientOnBoardingId: number
    clientId: string;
    legalBusinessName: string;
    mlaNumbers: string;
    agreementType: string;
    entityName: string;
    state: string;
    registrationAddress: string;
    invoiceAddresses: string;
    communicationAddresses: string;
    businessSegment: string;
    parentGstNumber: string;
    parentCinNumber: string;
    parentPanNumber: string;
    clientOnBoardingDate: string;
    entityGstNumber: string;
    entityCinNumber: string;
    entityPanNumber: string;
    contactNumbers: string;
    emailIds: string;
    registeredTo: string;
    primaryUseCase: string;
    otherUseCase: string;
    downPaymentApplicable: boolean;
    insuranceType: string;
    securityDepositApplicable: boolean;
    authorizeSignatories: string;
    accountManagers: string;
    bdManagers: string;
    serviceInclusions: string;
    paymentType: string;
    paymentMode: string;
    billingType: string;
    lockInPeriod: string;
    leaseRentalPayable: number;
    foreclosurePenaltyPercentage: number;
    delayedPaymentChargePercentage: number;
    otherClause: string;
    createdBy: string;
}

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

function Filter({
  column,
  table,
}: {
  column: Column<any, unknown>
  table: Table<any>
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id)

  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )
  return typeof firstValue === 'number' ? (
    <div>
      <div className="flex space-x-2" style={{display: "flex"}}>
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          // placeholder="Min"
          placeholder="Min"
        />
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder="Max"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? '') as string}
        onChange={value => column.setFilterValue(value)}
        placeholder={`Filter`}
        className="w-36 border shadow rounded"
        list={column.id + 'list'}
      />
      <div className="h-1" />
    </>
  )
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)
  // Store the itemRank info
  addMeta({
    itemRank,
  })
  // Return if the item should be filtered in/out
  return itemRank.passed
}

function ClientRequestList() {
  const navigate = useNavigate();
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = React.useState('');
  const auth = useAppSelector(authSelector);
  const columnHelper = createColumnHelper<ClientRequest>();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState<any>();
  const [remark, setRemark] = useState("");
  
  const pageUrl = useLocation().pathname.split("/").pop();
  const menus = useAppSelector(menuSelector).menu;
  const actionItem = getDynamicAction(menus, pageUrl, "credit");
  const user = useAppSelector(userSelector);

  const columns = [
    columnHelper.accessor('clientId', {
        cell: info => info.getValue(),
        header: () => <span>Client ID</span>,
    }),
    columnHelper.accessor('entityName', {
      cell: info => info.getValue(),
      header: () => <span>Entity Name</span>,
    }),
    columnHelper.accessor('legalBusinessName', {
      cell: info => info.getValue(),
      header: () => <span>Child Entity Name</span>,
    }),
    columnHelper.accessor('mlaNumbers', {
      cell: info => info.getValue()?.split(",").map((e: any) => {
        return <Tag color="#1677ff" style={{margin: 1}}>{e}</Tag>
    }),
      header: () => <span>MLA Number</span>,
    }),
    columnHelper.accessor('primaryUseCase', {
      cell: info => info.getValue(),
      header: () => <span>Use Case</span>,
    }),
  ]
  const defaultData: ClientRequest[] = [];

  const [data, setData] = useState<ClientRequest[]>(() => [...defaultData])

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })

  useEffect(() => {
    getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/client-on-boarding`).then(res => {
        if(res.data)
          setData(res.data);
    })
  }, []);

const handleEdit = (data: any) => navigate(`/dashboard/client/${data.clientOnBoardingId}`);

const handleView = (data: any) => {
    setModalData(data);
    setModalOpen(true)
}

const handlePageLengthDropdown = (value: string) => {
  console.log(`selected ${value}`);
  table.setPageSize(Number(value))
};

return (
  <div className="p-2 form-container">
    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end"}}>
      <DebouncedInput
        value={globalFilter ?? ''}
        onChange={value => setGlobalFilter(String(value))}
        style={{width: 300}}
        placeholder="Search all columns..."
      />
      <Button 
        type="primary" 
        icon={<PlusOutlined />} 
        onClick={_=> navigate("/dashboard/client/client-request")}
        style={{marginLeft: 10, height: 40, display: actionItem?.["Client Onboarding"] === "true" ? "block" : "none"}}
        >
        Client Onboarding
      </Button>
    </div>
    <div className="h-2" />
    <div style={{width: "100%", overflow: "scroll", borderRadius: 4}}>
      <TableContainer>
      <MUITable>
        <TableHead className='table-header'>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <TableCell style={{fontWeight: 600}} key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <UpOutlined />,
                            desc: <DownOutlined />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null}

                      </>
                    )}
                  </TableCell>
                )
              })}
              <TableCell style={{fontWeight: 600, width: 20}}>Action</TableCell>
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map(row => {
            return (
              <TableRow className="table-row" key={row.id}>
                {row.getVisibleCells().map(cell => {
                    return (
                        <TableCell key={cell.id}>
                        {
                            flexRender(cell.column.columnDef.cell, cell.getContext())
                        }
                        </TableCell>
                    )
                })}
                <TableCell style={{display: "flex", border: "none"}}>
                <Tooltip placement="bottom" title={actionItem?.["isAdmin"] === "true" || actionItem?.["isHead"] === "true" || row.original.createdBy === user.ssoId ? "Edit Client Info" : "Can't Edit Client Info"}>
                    <a onClick={(e) => actionItem?.["isAdmin"] === "true" || actionItem?.["isHead"] === "true" || row.original.createdBy === user.ssoId ? handleEdit(row?.original) : null} style={{display: actionItem?.["Action"]["Edit"] === "true" ? "block" : "none", color: actionItem?.["isAdmin"] === "true" || actionItem?.["isHead"] === "true" || row.original.createdBy === user.ssoId ? "#1677ff" : "grey"}}><EditOutlined /></a>
                </Tooltip>
                <Tooltip placement="bottom" title="View Client Info">
                    <a onClick={(e) => handleView(row?.original)} style={{display: actionItem?.["Action"]["View"] === "true" ? "block" : "none"}}><EyeOutlined /></a>
                </Tooltip>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </MUITable>
      </TableContainer>
    </div>
    <div className="h-2" />
    <div className="pagination-section">
      <span>
      <Button
        className="border rounded p-1"
        onClick={() => table.setPageIndex(0)}
        disabled={!table.getCanPreviousPage()}
        style={{margin: 2}}
      >
        {'<<'}
      </Button>
      <Button
        className="border rounded p-1"
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
        style={{margin: 2}}
      >
        {'<'}
      </Button>
      <Button
        className="border rounded p-1"
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
        style={{margin: 2}}
      >
        {'>'}
      </Button>
      <Button
        className="border rounded p-1"
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        disabled={!table.getCanNextPage()}
        style={{margin: 2}}
      >
        {'>>'}
      </Button>
      </span>

      <span>
      <span className="flex items-center gap-1">
        <strong style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          {`Page ${table.getState().pagination.pageIndex + 1} of ${table.getPageCount()}`}
          <Input
          placeholder="Go to page"
          type="number"
          defaultValue={table.getState().pagination.pageIndex + 1}
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0
            table.setPageIndex(page)
          }}
          style={{width: 50, marginLeft: 10}}
        />
        </strong>
      </span>
      </span>
      <Select
          defaultValue="10"
          style={{ width: 200 }}
          onChange={handlePageLengthDropdown}
          options={[
            { label: '5', value: '5' },
            { label: '10', value: '10' },
            { label: '15', value: '15' },
            { label: '20', value: '20' },
          ]}
        />
    </div>
    <div>{table.getPrePaginationRowModel().rows.length} Rows</div>

    <Modal
        centered
        open={modalOpen}
        width={1200}
        style={{margin: "10px 0"}}
        onCancel={() => setModalOpen(false)}
        footer={[
            <Button type="primary" key="cancel" onClick={() => setModalOpen(false)}>
              Close
            </Button>,
          ]}
    >
        <Descriptions bordered title={`${modalData?.entityName} - ${modalData?.clientId}`}>
            <Descriptions.Item label="Client ID">{modalData?.clientId}</Descriptions.Item>
            <Descriptions.Item label="Entity Name">{modalData?.entityName}</Descriptions.Item>
            <Descriptions.Item label="Legal Business Name">{modalData?.legalBusinessName}</Descriptions.Item>
            <Descriptions.Item label="MLA Number">{modalData?.mlaNumbers}</Descriptions.Item>
            <Descriptions.Item label="Agreement Type">{modalData?.agreementType}</Descriptions.Item>
            <Descriptions.Item label="State">{modalData?.state}</Descriptions.Item>
            <Descriptions.Item label="Registration Addresses">{
              modalData?.registrationAddress 
            }</Descriptions.Item>
            <Descriptions.Item label="Invoice Addresses"><div style={{display: "flex", flexWrap: "wrap"}}>{
              modalData?.invoiceAddresses ? modalData?.invoiceAddresses.split(" |$#$| ").map((e: any, i: any) => {
                return <><div style={{backgroundColor: "#1677ff", borderRadius: 6, padding: "2px 8px", margin: 1, color: "white", fontSize: 12, maxWidth: "auto"}}>{e}</div></>
            }) : null
            }</div></Descriptions.Item>
            <Descriptions.Item label="Communication Addresses"><div style={{display: "flex", flexWrap: "wrap"}}>{
                modalData?.communicationAddresses ? modalData?.communicationAddresses.split(" |$#$| ").map((e: any) => {
                  return <><div style={{backgroundColor: "#1677ff", borderRadius: 6, padding: "2px 8px", margin: 1, color: "white", fontSize: 12, maxWidth: "auto"}}>{e}</div></>
              }) : null
              }</div></Descriptions.Item>
            <Descriptions.Item label="Business Segment">{modalData?.businessSegment}</Descriptions.Item>
            { modalData?.parentGstNumber !== null ? <Descriptions.Item label="Parent GST Number">{modalData?.parentGstNumber}</Descriptions.Item> : null}
            { modalData?.parentCinNumber !== null ? <Descriptions.Item label="Parent CIN Number">{modalData?.parentCinNumber}</Descriptions.Item> : null}
            { modalData?.parentPanNumber !== null ? <Descriptions.Item label="Parent PAN Number">{modalData?.parentPanNumber}</Descriptions.Item> : null}
            <Descriptions.Item label="Client On Boarding Date">{modalData?.clientOnBoardingDate}</Descriptions.Item>
            <Descriptions.Item label="Entity GST Number">{modalData?.entityGstNumber}</Descriptions.Item>
            <Descriptions.Item label="Entity CIN Number">{modalData?.entityCinNumber}</Descriptions.Item>
            <Descriptions.Item label="Entity PAN Number">{modalData?.entityPanNumber}</Descriptions.Item>
            <Descriptions.Item label="Contact Numbers">{
              modalData?.contactNumbers ? JSON.parse(modalData?.contactNumbers).map((e: any) => {
                return <Tag color="#1677ff" style={{margin: 1}}>{e}</Tag>
            }) : null
              }</Descriptions.Item>
            <Descriptions.Item label="Email IDs">{
              modalData?.emailIds ? JSON.parse(modalData?.emailIds).map((e: any) => {
                return <Tag color="#1677ff" style={{margin: 1}}>{e}</Tag>
            }) : null
              }</Descriptions.Item>
            <Descriptions.Item label="Registered To">{modalData?.registeredTo}</Descriptions.Item>
            <Descriptions.Item label="Primary Use Case">{modalData?.primaryUseCase}</Descriptions.Item>
            <Descriptions.Item label="Other Use Case">{modalData?.otherUseCase || "NA"}</Descriptions.Item>
            <Descriptions.Item label="MLA Numbers">{
              modalData?.mlaNumbers ? modalData?.mlaNumbers.split(",").map((e: any) => {
                return <Tag color="#1677ff" style={{margin: 1}}>{e}</Tag>
            }) : null
              }</Descriptions.Item>
            <Descriptions.Item label="Insurance Type">{modalData?.insuranceType}</Descriptions.Item>
            <Descriptions.Item label="Insurance Managed">{modalData?.insuranceManaged ? "Yes" : "No"}</Descriptions.Item>
            <Descriptions.Item label="Insurance Zero Dep">{modalData?.insuranceZeroDep ? "Yes" : "No"}</Descriptions.Item>
            <Descriptions.Item label="Road Tax Type">{modalData?.roadTaxType}</Descriptions.Item>
            
            <Descriptions.Item label="Authorize Signatories">{
              modalData?.authorizeSignatories ? JSON.parse(modalData?.authorizeSignatories).map((e: any) => {
                return <Tag color="#1677ff" style={{margin: 1}}>{e}</Tag>
            }) : null
              }</Descriptions.Item>
            <Descriptions.Item label="Account Managers">{
              modalData?.accountManagers ? JSON.parse(modalData?.accountManagers).map((e: any) => {
                return <Tag color="#1677ff" style={{margin: 1}}>{e}</Tag>
            }) : null
            }</Descriptions.Item>
            <Descriptions.Item label="BD Managers">{
              modalData?.bdManagers ? JSON.parse(modalData?.bdManagers).map((e: any) => {
                return <Tag color="#1677ff" style={{margin: 1}}>{e}</Tag>
            }) : null
            }</Descriptions.Item>
            <Descriptions.Item label="Service Inclusions">{
              modalData?.serviceInclusions ? JSON.parse(modalData?.serviceInclusions).map((e: any) => {
                return <Tag color="#1677ff" style={{margin: 1}}>{e}</Tag>
            }) : null
            }</Descriptions.Item>
            <Descriptions.Item label="Payment Type">{modalData?.paymentType}</Descriptions.Item>
            <Descriptions.Item label="Payment Mode">{modalData?.paymentMode}</Descriptions.Item>
            <Descriptions.Item label="Billing Type">{modalData?.billingType}</Descriptions.Item>
            <Descriptions.Item label="Lock In Period">{modalData?.lockInPeriod}</Descriptions.Item>
            <Descriptions.Item label="Lease Rental Payable">{modalData?.leaseRentalPayable}</Descriptions.Item>
            <Descriptions.Item label="Foreclosure Penalty Percentage">{modalData?.foreclosurePenaltyPercentage}</Descriptions.Item>
            <Descriptions.Item label="Delayed Payment Charge Percentage">{modalData?.delayedPaymentChargePercentage}</Descriptions.Item>
            <Descriptions.Item label="Other Clause">{modalData?.otherClause}</Descriptions.Item>
            <Descriptions.Item label="Created By">{modalData?.createdByName}</Descriptions.Item>
            <Descriptions.Item label="Updated By">{modalData?.updatedByName}</Descriptions.Item>
        </Descriptions>
    </Modal>
  </div>
)
}

export default ClientRequestList;
