import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { User } from "../../interfaces/Interface"

interface Menu {
  menu: any[];
}

const initialState: Menu = {
  menu: [],
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setMenu: (state, action: PayloadAction<any>) => {
      state.menu = action.payload.menu;
    }
  },
});
export const { setMenu } = menuSlice.actions;
export const menuSelector = (state: RootState) => state.menu;
export default menuSlice.reducer;