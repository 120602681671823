import { Button, Card, Empty, Row } from "antd";
import "../Requirements.css";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import AddRequirement from "./AddRequirement";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest } from "../../../../utils/handler/apiHandler";
import RequirementItem from "./RequirementItem";
import _ from "lodash";
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReactComponent as DownloadIcon } from '../utils/SVGs/download_icon.svg';
import axios from "axios";
import { authSelector } from "../../../../features/auth/authSlice";
import { useAppSelector } from "../../../../app/hooks";

const ProspectRequirement = (props: any) => {

  const auth = useAppSelector(authSelector);
  const disable = props.disableEditOrAddSection;
  const setDisable = props.setDisableEditOrAddSection;
  const [showAddRequirement, setShowAddRequirement] = useState<boolean>(false);
  const [requirementsData, setRequirementsData] = useState<any>([]);
  const [requirementsLoader, setRequirementsLoader] = useState(false);
  const roleAccess = props.roleAccess;
  const url = useLocation();
  const prospectId = parseInt(url.pathname.split("/").pop() || '', 10)
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0); // Initialize currentPage to 0
  const [totalPages, setTotalPages] = useState(0); // Initialize totalPages to 0
  const REQUIREMENTSPAGESIZE = 10;
  const [filteredQuoteIds, setFilteredQuoteIds] = useState<number[]>([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setRequirementsLoader(true);
        const response = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/requirements?prospectId=${prospectId}&page=${currentPage}&size=${REQUIREMENTSPAGESIZE}`);
        if (response.status === 404 || response.status === 400) {
          navigate("/dashboard/prospect");
          return;
        }
        const { data, pageCount: newTotalPages } = response?.data; // Assuming totalPages is returned from API
        setRequirementsData((prevData: any) => [...prevData, ...data]);
        setTotalPages(newTotalPages);
        setRequirementsLoader(false);
        const numEntry=response?.data.totalRecord;
        if(numEntry!==0)
        {
          const res = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/requirements?prospectId=${prospectId}&page=${0}&size=${numEntry}`);
          if (res.status === 404 || res.status === 400) {
            navigate("/dashboard/prospect");
            return;
          }
          const filteredQuoteIds = res.data.data
          .filter((item: { quoteStatusId: number; }) => item.quoteStatusId === 5)
          .map((item: { quoteId: any; }) => item.quoteId);
          setFilteredQuoteIds(filteredQuoteIds);
        }
      } catch (error) {
        // Handle error if any
        console.error("Error fetching Requirements Data:", error);
      }
    };

    fetchData();
  }, [currentPage]); // Fetch data when currentPage changes

  const fetchMoreData = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const downloadQuotePreviewExcel = async (filteredQuoteIds: number[]) => {
  try {
    const details = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/prospect/${prospectId}`);
    if (details.status === 404 || details.status === 400) {
      navigate("/dashboard/prospect");
      return;
    }
    const res = await axios.post(
      `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/report/excel/${filteredQuoteIds.toString()}`,
      {},
      {
        headers: {
          "Authorization": `Bearer ${auth.accessToken}`
        },
        responseType: 'arraybuffer'
      }
    );
    const url =  window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${JSON.parse(details.data.createdBy)?.name}_${details.data?.prospectId}.xlsx`);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
    // setTimeout(() => {
    // }, 100);
  } catch (error) {
      console.error('Error fetching dbData:', error);
  }
}

  return (
    <div style={{ marginTop: '20px', marginBottom: '20px', backgroundColor: '#F6FAFF' }} >
      <div className="header">
        <span className="requirements-heading-text">Requirements</span>
        <div>
        {/* {(roleAccess && filteredQuoteIds.length > 0) && <Button
          type="primary"
          ghost
          onClick={(_) => {downloadQuotePreviewExcel(filteredQuoteIds)}}
          className={"button-override primary"}
        >
          <span style={{ marginRight:'0.4rem' }}>
          <DownloadIcon />
          </span>
        Download Completed Quotes
        </Button>} */}
        {roleAccess && <Button
          disabled={disable}
          type="primary"
          ghost
          icon={<PlusOutlined />}
          onClick={(_) => {
            setShowAddRequirement(true);
            setDisable(true)
          }}
          className={disable ? "button-override disable" : "button-override primary"}
          style={{marginLeft: 10}}
        >
          Add Requirement
        </Button>}
        </div>
      </div>
      {showAddRequirement && <AddRequirement prospectId={prospectId}
        requirementNumber={requirementsData.length + 1}
        setShowAddRequirement={setShowAddRequirement}
        formType={'ADD'}
        data={null}
        roleAccess={roleAccess}
        disableAddOrEditRequirement={disable}
        setDisableAddOrEditRequirement={setDisable}
        stageId={props.stageId}
        prospectName={props.prospectName}
      />}
      <InfiniteScroll
        dataLength={requirementsData.length}
        next={fetchMoreData}
        hasMore={currentPage < totalPages-1 && !requirementsLoader}
        loader={<Row justify="space-between" className="shimmer-ui" style={{ height: 40, borderRadius: 12, marginBottom: 8 }} />}
        height={800}
        style={{ overflowY: 'scroll', scrollbarWidth: 'none' }}
      >
        {
          requirementsData.map((requirement: any, key: number) => (
            <AddRequirement
              key={key}
              prospectId={prospectId}
              requirementNumber={requirement?.requirementNumber}
              setShowAddRequirement={setShowAddRequirement}
              formType={'DISPLAY'}
              data={requirement}
              roleAccess={roleAccess}
              disableAddOrEditRequirement={disable}
              setDisableAddOrEditRequirement={setDisable}
              stageId={props.stageId}
              prospectName={props.prospectName}
            />
          ))
        }
      </InfiniteScroll>
    </div>
  );
}

export default ProspectRequirement;
