import { Button, Checkbox, Form, Modal, Spin, Tooltip } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { ReactComponent as CrossIcon } from '../.././utils/SVGs/cross_icon.svg';
import { ReactComponent as TickIcon } from '../.././utils/SVGs/tick_icon_stroke.svg';
import { ReactComponent as AddIcon } from '../.././utils/SVGs/add_icon.svg';
import { useNavigate, useLocation } from "react-router-dom";
import { getRequest, postRequest } from '../../../../../utils/handler/apiHandler';
import { useAppSelector } from '../../../../../app/hooks';
import { userSelector } from '../../../../../features/auth/userSlice';
import SelectNewDocument from './SelectNewDocument';

const OTHER = "Other";

interface AddDocumentSelectModalProps {
  visible: boolean;
  documents: any[];
  selectedItems: any[];
  placeholder: string;
  setSelectedItems: (selectedItems: string[]) => void;
  onSave: () => void;
  onEditorClose: () => void;
}

const AddDocumentSelectModal: React.FC<AddDocumentSelectModalProps> = ({
  visible,
  documents,
  selectedItems,
  placeholder,
  setSelectedItems,
  onSave,
  onEditorClose,
}) => {
  const [documentsType, setDocumentsType] = useState<any[]>([]);
  const [documentsInternal, setDocumentsInternal] = useState<any[]>([]);
  const [selectedItemsInternal, setSelectedItemsInternal] = useState<any[]>(selectedItems);
  const [loading, setLoading] = useState<boolean>(false);
  const [showSelectNewDocument, setShowSelectNewDocument] = useState<boolean>(false);
  const [disableSaveAddDocsButton, setDisableSaveAddDocsButton] = useState<boolean>(false);
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(true);
  const OTHER_DOC_ID: number = 6;

  const [form] = Form.useForm();
  const user = useAppSelector(userSelector);

  const url = useLocation();
  const prospectId = url.pathname.split("/").pop()
  const navigate = useNavigate();

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const fetchDocumentTypes = async () => {
      setLoading(true);
      try {
        const res = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/doc-types`);
        if (res.status === 404 || res.status === 400) {
          navigate("/dashboard/prospect");
          return;
        }
        setDocumentsType(res.data);
      } catch (error) {
        console.error('Error fetching DocumentTypes:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDocumentTypes();
  }, []);

  useEffect(() => {
    let indexArr: any[] = [];
    for (let ind = 0; ind < documents.length; ind += 1) {
      indexArr.push(documents[ind]?.prospectDocId);
    }
    setSelectedItemsInternal(indexArr);
    const tempDocumentsInternal = documents.map((doc: any) => { return { ...doc, prospectDocIdInternal: doc.prospectDocId } });
    setDocumentsInternal(tempDocumentsInternal);
  }, [documents]);

  useEffect(()=>{
    let indexArr: any[] = [];
    for (let ind = 0; ind < documents.length; ind += 1) {
      indexArr.push(documents[ind]?.prospectDocId);
    }
    const setOfDocumentsIndex = new Set([...indexArr]);
    let ind:number = 0;
    if(selectedItemsInternal.length !== indexArr.length){
      setDisableSaveButton(false);
        return;
    }
    for(ind=0;ind<selectedItemsInternal.length; ind+=1){
        if(!setOfDocumentsIndex.has(selectedItemsInternal[ind])){
        setDisableSaveButton(false);
        return;
      }
    }
    setDisableSaveButton(true);

  } ,[selectedItemsInternal]);

  const generateDocumentTypePayloadItem = (item: number, name: string, isOtherDocType: boolean) => {
    return {
      "prospectDocId": null,
      "id": item,
      "name": name,
      "isOtherDocType": isOtherDocType,
      "isCheck": true,
      "prospectDocIdInternal": documentsInternal.reduce((max, obj) => obj.prospectDocIdInternal > max ? obj.prospectDocIdInternal : max, 0) + 1
    };
  };

  const handleSave = async () => {
    setDisableSaveAddDocsButton(true);
    const URL = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/prospectDoc/addUpdateProspectDocNew?prospectId=${prospectId}`
    try {
      const response = await postRequest(URL,
        documentsInternal.filter((doc: any) => doc.isCheck == true)
          .map((doc: any) => {
            return {
              "prospectDocId": doc.prospectDocId ? doc.prospectDocId : null,
              "id": doc.id,
              "name": doc.name,
              "isOtherDocType": doc.id == OTHER_DOC_ID ? true : false,
              "isCheck": selectedItemsInternal.includes(doc.prospectDocIdInternal),
            }
          })
      );

      if (response.status === 404 || response.status === 400) {
        navigate("/dashboard/prospect");
        return;
      }

      onSave();
    } catch (errorInfo) {
      console.log('Validation failed:', errorInfo);
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
      }
    }, 100);// to delay the scroll until DOM updates
  };

  const handleAddNewDoc = () => {
    setShowSelectNewDocument(true);
    scrollToBottom();
  };

  const handleModalClose = () => {
    form.resetFields();
    onEditorClose();
    setShowSelectNewDocument(false);
  };

  const handleItemSelection = (itemId: string) => {
    const index = selectedItemsInternal.indexOf(itemId);
    const tempDocInternal = documentsInternal.map((docInt: any) => docInt.prospectDocIdInternal == itemId ? {
      ...docInt,
      'isCheck': !docInt.isCheck,
    } : { ...docInt })
    setDocumentsInternal(tempDocInternal);
    if (index === -1) {
      setSelectedItemsInternal([...selectedItemsInternal, itemId]);

    } else {
      const updatedItems = [...selectedItemsInternal];
      updatedItems.splice(index, 1);

      setSelectedItemsInternal(updatedItems);
    }
  };

  const handleSelectNewDocumentClose = () => {
    setShowSelectNewDocument(false);
  };

  const handleSelectNewDocumentSave = (id: number, name: string) => {
    const selectedDocumentType = documentsType.find((docType: any) => docType.id == id);
    const newDocumentObj = generateDocumentTypePayloadItem(id, id === OTHER_DOC_ID ? `Other : ${name}` : selectedDocumentType?.name, id === OTHER_DOC_ID);
    setDocumentsInternal([...documentsInternal, newDocumentObj]);
    setShowSelectNewDocument(false);
    setSelectedItemsInternal([...selectedItemsInternal, newDocumentObj.prospectDocIdInternal]);
  };
  
  return (
    <Modal
      title={<span style={{ fontSize: 16, fontWeight: 700 }}>{placeholder}</span>}
      visible={visible}
      width={'484px'}
      onCancel={handleModalClose}
      centered={true}
      className='document-select-modal'
      styles={{
        header: { backgroundColor: '#F6FAFF' },
      }}
      footer={[
        <Button icon={<CrossIcon />} style={{ height:'2.5rem', color: '#FF4D4F', backgroundColor: 'rgba(255, 77, 79, 0.10)', borderColor: '#FF4D4F', fontWeight: '500' }} key="cancel" onClick={handleModalClose}>
          Cancel
        </Button>,
        <Button disabled={disableSaveButton || disableSaveAddDocsButton} icon={<TickIcon color='white' />} style={{marginRight:'0.3rem', height:'2.5rem', color: '#FFF', backgroundColor: '1777FF', fontWeight: '500' }} key="confirm" type="primary" onClick={handleSave}>
          Save
        </Button>,
      ]}
    >
      <div>
        <span>
          These are the recommended documents for the business segment, You can change type or add more
        </span>
        <div ref={scrollContainerRef} className="item-list" style={{ maxHeight: '250px', backgroundColor: 'white', marginTop: '1rem', padding: '0rem 1rem', borderRadius: '0.5rem', overflowY: 'auto' }}>
          <div style={{ margin: '1rem 0rem', fontWeight: '700' }}>
            Document
          </div>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontSize: 14, padding: 1 }}>
              <Spin />
            </div>
          ) : (
            <div>
              <div>
                {
                 documentsInternal.map((item: any) => {
                  let disableCheckbox = item.requestedBy ? true : false;
                  if(disableCheckbox){
                    disableCheckbox = item.isFileUploaded==='true' || user?.ssoId !== JSON.parse(item?.requestedBy)?.ssoId ;
                  }
                  return <div key={item.id} style={{ marginBottom: '0.4rem', padding: '0.3rem 0.7rem', display:'flex', justifyContent:'space-between', alignItems:'center' }} className='list-item'>
                    <div style={{display:'flex'}}>
                      <Checkbox
                        checked={selectedItemsInternal.includes(item.prospectDocIdInternal)}
                        onChange={() => handleItemSelection(item.prospectDocIdInternal)}
                        disabled={disableCheckbox}
                        className={ disableCheckbox ? 'disabled-custom-checkbox': 'custom-checkbox'}
                      />
                      <Tooltip title={item?.name}>
                        <div style={{ margin: '8px', maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                        color: disableCheckbox ? '#AFAFAF' : '#404040'}}>
                          {item.name === 'Other' ? item?.otherDescription : item?.name}
                        </div>
                      </Tooltip>
                    </div>
                    {
                      item.isFileUploaded==='true' && <p style={{color:'#E39A2C', margin:'8px', fontSize:'12px', fontWeight:'500'}}>File Uploaded</p>
                    }
                    
                  </div>
                }
                )}
                {
                  showSelectNewDocument && <SelectNewDocument documentsType={documentsType} setDocumentsType={setDocumentsType}
                    documentsInternal={documentsInternal} handleClose={handleSelectNewDocumentClose} handleSave={handleSelectNewDocumentSave} />
                }
              </div>
              <div>
                <Button icon={<AddIcon color='#2C66E3' />} style={{ color: '#2C66E3', backgroundColor: '#E0EDFF', fontWeight: '500', borderColor: '#1777FF', width: '100%', marginTop: '0.6rem', marginBottom: '1.2rem' }} key="confirm" type="primary" onClick={handleAddNewDoc}>
                  Add New Doc
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddDocumentSelectModal;
