import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col, InputNumber, Radio, Collapse, Space, Select, Upload, Button, message, Divider, Modal, Tooltip } from "antd";
import { FilePdfOutlined, LinkOutlined, LoadingOutlined, PhoneOutlined, PlusOutlined, UploadOutlined, UserOutlined } from "@ant-design/icons";
import type { UploadProps } from 'antd';
import { authSelector } from "../../../../features/auth/authSlice";
import { useAppSelector } from "../../../../app/hooks";
import { postRequest } from "../../../../utils/handler/apiHandler";
import axios from "axios";
import { element } from "prop-types";
import ContractCondition from "./ContractCondition";
const Panel = Collapse.Panel;

function Step2(props: any) {
    const [activeKey, setActiveKey] = useState(0);
    function handleClick(key: any) {
        console.log(key)
        setActiveKey(key);
    }

    const initialValue = [{
        mlaNumber: null,
        mlaDocumentUrl: null,
        securityDepositApplicable: false,
        securityDepositType: null,
        securityDepositValue: null,
        bankGuaranteeApplicable: false,
        bankGuaranteeAmount: null,
        bankDocumentUrl: null,
        downPaymentApplicable: false,
        downPaymentPercentage: null,
        processingFeeApplicable: false,
        bankGuarantee: false,
        corporateGuarantee: false,
        processingFeeAmount: null,
        corporateGuaranteeApplicable: false,
        corporateDocumentUrl: null,
        letterOfComfortDocumentUrl: null
    }];

    const form = props.form;
    const auth = useAppSelector(authSelector);

    return (
        <React.Fragment>
        <Form.List name="contractConditions" initialValue={props.form.getFieldValue("contractConditions") || initialValue}>
            {(fields, { add, remove }) => (
                <>
                {fields.map(({ key, name, ...restField }, i) => (
                    <Collapse style={{margin: "10px 0"}} onChange={() => handleClick(key)} activeKey={activeKey}>
                        <Panel header={<strong>{`Contract Condition ${i+1}`}</strong>} key={key} >
                            <ContractCondition form={props.form} key={key} name={name} data={props.data} />
                            <Button 
                                type="primary" 
                                danger 
                                style={{maxWidth: "5rem"}} 
                                onClick={() => remove(name)}>
                                    Delete
                            </Button>
                        </Panel>  
                    </Collapse>
                    ))}

                    <Form.Item>
                        <Button
                        type="dashed"
                        onClick={() => {
                            add();
                        }}
                        block
                        icon={<PlusOutlined />}
                        >
                        Add Contract Condition
                        </Button>
                    </Form.Item>
                    </>
                )}
            </Form.List>
        </React.Fragment>
     );
}

export default Step2;