import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface Master {
    prospect: any[];
}

const initialState: Master = {
    prospect: [],
};

export const masterSlice = createSlice({
  name: "master",
  initialState,
  reducers: {
    setProspect: (state, action: PayloadAction<any>) => {
      state.prospect = action.payload.prospect;
    }
  },
});
export const { setProspect } = masterSlice.actions;
export const masterSelector = (state: RootState) => state.master;
export default masterSlice.reducer;