import React from 'react';
import ProspectBasicDetails from './ProspectBasicDetails';
import ProspectRequirement from './ProspectRequirement';

function ProspectDetail(props: any) {
    return ( 
        <React.Fragment>
            <div className="container-inner">
                <div className="container-inner-section">
                    <ProspectBasicDetails roleAccess={props.roleAccess} disableEditOrAddSection={props.disableEditOrAddSection} setDisableEditOrAddSection={props.setDisableEditOrAddSection} />
                </div>
                <div className="container-inner-section">
                    <ProspectRequirement roleAccess={props.roleAccess} disableEditOrAddSection={props.disableEditOrAddSection} setDisableEditOrAddSection={props.setDisableEditOrAddSection} stageId={props.stageId} prospectName={props.prospectName} />
                </div>
            </div>
        </React.Fragment>
    );
}

export default ProspectDetail;