import { Button, Input, Modal, Spin, Typography } from "antd";
import { ReactComponent as RightArrowIcon } from '../../SVGs/right_arrow_icon.svg';
import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as CrossIcon } from '../../SVGs/cross_icon.svg';
import { ReactComponent as TickIcon } from '../../SVGs/tick_icon.svg';
import ReactQuill from "react-quill";
import { useState } from "react";

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;

interface ModalProps {
    title: string;
    buttonText: string;
    showModal : boolean;
    setShowModal: (showModal: boolean) => void;
    handler: () => void;
    loader: boolean;
    type: string;
    noteText: string;
    setNoteText: (noteText: string) => void;
}

const ReasonModal: React.FC<ModalProps> = ({ title, buttonText, showModal, setShowModal, handler, loader, type, noteText, setNoteText }) => {
    
    console.log(noteText);

    return (
        <Modal
            title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 700, color: "#404040", marginBottom: 10}}>{title}</Typography>}
            centered
            open={showModal}
            closable={true}
            onCancel={() => setShowModal(false)}
            afterClose={() => setNoteText("")}
            className="preview-modal"
            width={800}
            footer={<>
                <div className="edit-section">
                    <Button
                        ghost
                        danger={type !== "noGo"}
                        type={type !== "noGo" ? "default" : "primary"}
                        onClick={_ => !loader && setShowModal(false)}
                        className={type !== "noGo" ? "button-override danger" : "button-override primary"}
                        icon={ loader ? <Spin indicator={antIcon} /> : type === "noGo" ? null : <CrossIcon /> }
                        >
                        Cancel
                    </Button>
                    <Button
                        disabled={noteText === "" || noteText === "<p><br></p>"}
                        danger={type === "noGo"}
                        type="primary"
                        onClick={_ => !loader && handler()}
                        className="button-override"
                        icon={ loader ? <Spin indicator={antIcon} /> : type === "noGo" ? <CrossIcon /> : <TickIcon /> }
                        >
                        { buttonText }
                    </Button>
                </div>
            </>}
        >
            <ReactQuill
                theme="snow"
                value={noteText}
                onChange={(value) => setNoteText(value)}
                placeholder='Type note here'
                style={{display : "flex", flexDirection : "column-reverse", height : "200px"}}
                modules={{
                  toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    ['code-block'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    [{ 'align': [] }],
                    ['link', true],
                    ['clean'],
                    [{ 'header': [1, 2, false] }],
                  ],
                }}
              />
        </Modal>
    );
}

export default ReasonModal;