import { Button, DatePicker, Input, Modal, Spin, Typography } from "antd";
import { ReactComponent as CrossIconIcon } from '../../SVGs/cross_icon.svg';
import { ReactComponent as RightArrowIcon } from '../../SVGs/right_arrow_icon.svg';
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;

interface ModalProps {
    title: string;
    subTitle: string;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    eta: string;
    setEta: (eta: string) => void;
    handler: () => void;
    etaLoader: boolean;
    buttonText: string;
}

const ETAModal: React.FC<ModalProps> = ({ showModal, title, subTitle, setShowModal, eta, setEta, handler, etaLoader, buttonText }) => {
    function disabledDate(current: any) {
        // Can not select days future dates
        return current && current < new Date().setHours(0, 0, 0, 0);
        }

    return (
        <Modal
            title={<Typography style={{background: "#F6FAFF", fontSize: 14, fontWeight: 500, color: "#404040"}}>{title}</Typography>}
            centered
            open={showModal}
            onOk={() => { setShowModal(false), setEta("") }}
            onCancel={() => { setShowModal(false), setEta("") }}
            closable={true}
            afterClose={() => setEta("")}
            className="preview-modal"
            footer={<>
                <div className="edit-section">
                    
                    <Button
                        ghost
                        danger
                        type="primary"
                        icon={<CrossIconIcon />}
                        onClick={_ => {setShowModal(false); setEta("")}}
                        className="button-override danger"
                        >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        disabled={eta === ""}
                        onClick={_ => !etaLoader && handler()}
                        className="button-override"
                        >
                        {buttonText}
                        { etaLoader ? <Spin indicator={antIcon} /> : <RightArrowIcon style={{marginLeft: 10}} /> }
                    </Button>
                </div>
            </>}
        >
            <Typography>{subTitle}</Typography>
            <DatePicker placeholder="dd/mm/yyyy" disabledDate={disabledDate} format="DD/MM/YYYY" className="input-number-override" value={eta !=="" && dayjs(eta)} style={{height: 40, padding: 4, width: "100%", marginTop: 20}} onChange={(e: any) => setEta(dayjs(e).format("YYYY-MM-DD"))} />
        </Modal>
    );
}

export default ETAModal;