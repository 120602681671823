import React from 'react';

const UnionFilterIcon = (props) => (
  <svg
    width="15"
    height="10"
    viewBox="0 0 15 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 0.824219C0 0.410005 0.335786 0.0742188 0.75 0.0742188H13.2735C13.6877 0.0742188 14.0235 0.410005 14.0235 0.824219C14.0235 1.23843 13.6877 1.57422 13.2735 1.57422H0.75C0.335786 1.57422 0 1.23843 0 0.824219ZM1.82626 4.99808C1.82626 4.58386 2.16205 4.24808 2.57626 4.24808L11.4471 4.24808C11.8613 4.24808 12.1971 4.58386 12.1971 4.99808C12.1971 5.41229 11.8613 5.74808 11.4471 5.74808L2.57626 5.74808C2.16205 5.74808 1.82626 5.41229 1.82626 4.99808ZM4.66359 8.42435C4.24938 8.42435 3.91359 8.76014 3.91359 9.17435C3.91359 9.58856 4.24938 9.92435 4.66359 9.92435H9.35992C9.77413 9.92435 10.1099 9.58856 10.1099 9.17435C10.1099 8.76014 9.77413 8.42435 9.35992 8.42435H4.66359Z"
      fill={props.fill || "#000"} // Default fill color is black
    />
  </svg>
);

export default UnionFilterIcon;
