import React, { useState, useEffect, useRef } from 'react';
import { Table, Spin, Select, Tooltip } from 'antd';
import { LeftOutlined, MinusOutlined, RightOutlined } from '@ant-design/icons';
import { useLocation} from 'react-router';
import { ProspectStageHistory } from '../utils/ProspectTypes';
import { getRequest } from '../../../../utils/handler/apiHandler';
import SortUpperFadedIcon from '../utils/SVGs/SortUpperFadedIcon';
import SortLowerFadedIcon from '../utils/SVGs/SortLowerFadedIcon';
import { ReactComponent as SortFilterIcon } from './../../../../utils/icons/Vector.svg'; 
import { ColumnsType } from 'antd/es/table';
import { Option } from "antd/es/mentions";
import { handlePageChange, handlePageSizeChange } from '../utils/paginationFunctions';
import { getFormattedDate } from '../../../../utils/helpers/utils';
import { getStageStyles } from '../utils/stageUtils';

const OTHER = "Other";

const LeadHistory: React.FC = () =>  {
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageCount, setPageCount] = useState<number>(0);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [agingSortingOrder, setAgingSortingOrder] = useState<'ascending' | 'descending' | null>(null);
    const defaultData: ProspectStageHistory[] = [];
    const [data, setData] = useState<ProspectStageHistory[]>(defaultData);
    const url = useLocation();
    const tableRef = useRef<HTMLDivElement>(null);
    const [tableHeight, setTableHeight] = useState<number>(400);
    const prospectId = url.pathname.split("/").pop()
    const FILTER_ICON_COLOR = '#1777FF';

    const handleSortAging = () => {
        setAgingSortingOrder(agingSortingOrder === null ? 'ascending' : agingSortingOrder ==='ascending' ? 'descending' : null );
    };

    const sortFilterIcon = () => (
        agingSortingOrder === 'descending' ? <SortUpperFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", width: '15px', height: '15px', marginTop:'5px' }} />
          : agingSortingOrder === 'ascending' ?  <SortLowerFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", width: '15px', height: '15px', marginTop:'5px' }} />
          : <SortFilterIcon style={{ marginLeft: "12px", width: '15px', height: '15px', marginTop:'5px' }}/> 
    );

    const getAgingSortOrder = () => `stageAge:${agingSortingOrder === 'ascending' ? 'asc' : 'desc'}`;

    useEffect(() => {
        fetchProspectStageTableData();
    }, [currentPage, pageSize, agingSortingOrder]);

    const fetchProspectStageTableData = async (): Promise<void> => {
        try {
          setLoading(true);
          let getApi = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/stageLog?prospectId=${prospectId}&page=${currentPage-1}&size=${pageSize}`;
          if(agingSortingOrder != null ){
            getApi = getApi + `&sort=${getAgingSortOrder()}`;
          }

          const res = await getRequest(getApi);
          if (res.status === 200) {
            setData(res.data.data);
            if ('pageCount' in res.data) {
              setPageCount(res.data.pageCount);
            }
            if ('totalRecord' in res.data) {
              setTotalRecord(res.data.totalRecord);
            }
          } else {
            console.error('Error fetching Prospects Table List:', res.msg);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };

    const handlePageSizeChangeLocal = (current: number, size: number) => {
        handlePageSizeChange(current, size, setPageSize, setCurrentPage);
    };

    const handlePageChangeLocal = (page: number) => {
        handlePageChange(page, setCurrentPage);
    };

    const getColumns = () : ColumnsType<any> => [
        {
          title: 'S. No.',
          dataIndex: 'serialNumber',
          key: 'serialNumber',
          width: '6%',
        },
        {
            title: 'Initial Stage',
            dataIndex: 'oldStageName',
            render: (text: string, record: any) => {
              const { color, backgroundColor } = getStageStyles(record?.oldStageId);
                return(
                  <Tooltip title={text}>
                    <div style={{color : color, backgroundColor : backgroundColor, padding: '0 8px', borderRadius: '4px', display: 'inline-block', maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {text}
                    </div>
                  </Tooltip>
                );
            },
            key: 'oldStageName',
            width: '17%',
        },
        {
            title: 'Changed Stage',
            dataIndex: 'currentStageName',
            render: (text: string, record: any) => {
              const { color, backgroundColor } = getStageStyles(record?.currentStageId);
                return(
                  <Tooltip title={text}>
                    <div style={{color : color, backgroundColor : backgroundColor, padding: '0 8px', borderRadius: '4px', display: 'inline-block', maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {text}
                    </div>
                 </Tooltip>
                );
            },
            key: 'currentStageName',
            width: '17%',
        },
        {
          title: 'Remark',
          dataIndex: 'stageRemark',
          render: (text: string, record: any) => {
              let textToRender = "";
              if(record?.stageRemark != null && record?.prospectLostReason != null){
                if(JSON.parse(record?.prospectLostReason).name == OTHER){
                  textToRender = record?.stageRemark;
                }else{
                  textToRender = JSON.parse(record?.prospectLostReason).name + " | " + record?.stageRemark;
                }  
              }else if(record?.prospectLostReason != null){
                textToRender = JSON.parse(record?.prospectLostReason).name;
              }else if(record?.stageRemark != null){
                textToRender = record?.stageRemark;
              }
              return(
                <Tooltip title={textToRender}>
                  <div style={{display: 'inline-block', maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {textToRender}
                  </div>
               </Tooltip>
              );
          },
          key: 'stageRemark',
          width: '17%',
      },
        {
            title: 'Changed at',
            dataIndex: 'createdAt',
            render: (obj : string) => {
              const formattedDate = getFormattedDate(obj);
              return(
                formattedDate
              ); 
            },
            key: 'createdAt',
            width: '17%',
        },
        {
            title: 'Changed by',
            dataIndex: 'createdBy',
            render: (obj : string) => JSON.parse(obj).name,
            key: 'createdBy',
            width: '18%',
        },
        {
            dataIndex: 'stageAge',
            key: 'stageAge',
            width: '10%',
            render: (text: string) => text != null ? `${text} Days` : '',
            sortDirections: ['ascend', 'descend'],
            title: (
              <div onClick={() => handleSortAging()} style={{ cursor: 'pointer', display:'flex' }}>
                <span style={{marginRight:'0.5rem'}}>Aging</span> 
                {sortFilterIcon()}
              </div>
            ),
          },
    ];

    const rotatedMinusIconStyle = {
        transform: 'rotate(90deg)',
    };

    return ( 
        <div style={{ backgroundColor: '#F6FAFF', height: '100%'}}>
            <div ref={tableRef} style={{ height: 0.80 * tableHeight }}>
                <Spin spinning={loading} size="large">
                    <Table
                        columns={getColumns()}
                        dataSource={data || []}
                        pagination={{
                        pageSize: pageSize,
                        position: ["bottomRight"],
                        showSizeChanger: false,
                        showTotal: (total, range) => (
                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', marginTop:'-4px' }}>
                            <div style={{fontSize:'1rem'}}>
                                <span>Showing</span>
                                <Select defaultValue={10} style={{ width: 60, margin: '0 5px' }} onChange={(value) => handlePageSizeChangeLocal(1, value)}>
                                    <Option value="10">10</Option>
                                    <Option value="20">20</Option>  
                                    <Option value="50">50</Option>
                                    <Option value="100">100</Option>
                                </Select>
                                <span>Per Page</span>
                                <span style={{ marginRight:'10px', marginLeft:'10px', color:'grey'}}><MinusOutlined style={rotatedMinusIconStyle} /></span>
                                <span>{`Results: ${range[0]}-${range[1]} of ${total}`}</span>
                            </div>
                            </div>
                        ),
                        onChange: handlePageChangeLocal,
                        current: currentPage,
                        total: totalRecord,
                        itemRender: (current, type, originalElement) => {
                            if (type === 'prev') {
                            return <LeftOutlined style={{marginLeft:'4rem'}}/>;
                            }
                            if (type === 'next') {
                            return  <RightOutlined />;
                            }
                            if (type === 'page') {
                            return (
                                <span style={{cursor: 'pointer' }} onClick={() => handlePageChangeLocal(current)}>
                                {current}
                                </span>
                            );
                            }
                            if (type === 'jump-next' || type === 'jump-prev') {
                            return <a style={{color: 'black'}}><strong> ... </strong></a>;
                            }
                            return originalElement;
                        },
                        }}            
                        scroll={{ x: 'max-content', y: 0.80 * tableHeight }}
                        size="middle"
                        components={{
                        header: {
                            wrapper: (props: any) => <thead style={{ backgroundColor: '#CEE6FA', height: '3.5rem' }}>{props.children}</thead>,
                        },
                        }}
                    />
                </Spin>
            </div>
        </div>
    );
};

export default LeadHistory;