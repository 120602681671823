import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface Utils {
  cities: any[];
  stages: any[];
  segments: any[];
  designations: any[];
}

const initialState: Utils = {
  cities: [],
  stages: [],
  segments: [],
  designations: []
};

export const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    setCities: (state, action: PayloadAction<any[]>) => {
      state.cities = action.payload;
    },
    setStages: (state, action: PayloadAction<any[]>) => {
      state.stages = action.payload;
    },
    setSegments: (state, action: PayloadAction<any[]>) => {
      state.segments = action.payload;
    },
    setDesignations: (state, action: PayloadAction<any[]>) => {
      state.designations = action.payload;
    }
  },
});
export const { setCities, setStages, setSegments, setDesignations } = utilsSlice.actions;
export const utilsSelector = (state: RootState) => state.utils;
export default utilsSlice.reducer;