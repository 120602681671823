import { Button, Card, Divider, Form, Row, Select, Typography } from "antd";
import { ReactComponent as TickIcon } from '../../SVGs/tick_icon.svg';
import React, { useEffect, useState } from "react";
import { ADMIN, AS_HEAD, AS_MANAGER } from "../../constants";
import { ReactComponent as EditIcon } from "../../SVGs/edit_icon.svg";
import { ReactComponent as CrossIcon } from '../../SVGs/cross_icon.svg';
import { putRequest } from "../../../../utils/handler/apiHandler";
import QuotePriceDetails from "./QuoteDetailsForm";

interface CompleteQuoteDetailsProps {
    loader: boolean;
    quoteData : any;
    requestDetails: any;
    user: any;
}

const { Title } = Typography;
const RUPEE_SYMBOL = "₹ ";

const CompleteQuoteDetails: React.FC<CompleteQuoteDetailsProps> = ({ loader, quoteData, requestDetails, user }) => {
    const [form] = Form.useForm();
    const [editable, setEditable] = useState(false);
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        form.setFieldsValue({exShowroom : quoteData.exShowroom, 
            baseAmt : quoteData.basePrice,
            gstAmt : quoteData.gstOnVehicle,
            fameSubsidy : quoteData.fameSubsidy,
            stateSubsidy : quoteData.stateSubsidy,
            discount: quoteData.discount,
            roadTax : quoteData.roadTaxTotal,
            insuranceAmt : quoteData.insuranceAmountTotal,
            firstYrInsuranceAmt : JSON.parse(quoteData.projectedPremium)['1'],
            managementFee : quoteData.managementFees,
            tcs : quoteData.tcs,
            rvPercentage : quoteData.rvPercentage,
            costOfFund : quoteData.costOfFundPercentage,
            marginRate : quoteData.offerRatePercentage,
            quoteRemarks : quoteData.quoteRemarks,
            excessMileageCharge : quoteData.excessMileageChargePerKm,
            fabricationAmountTotal : quoteData.fabricationAmountTotal,
            fabricationGst: quoteData.fabricationGst,
            fabricationBaseAmountTotal: quoteData.fabricationBaseAmountTotal,
            otherAccessoriesDescription:quoteData.otherAccessoriesDescription,
        });
    }, [editable == true]);


    const handleFinaliseQuote = async(statusId:any) => {
        try{
            const payload : any = {
                statusChangeTypeId : statusId
            }
            const res = await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/quoteRequest/${quoteData?.requestId}`, payload);
            setTimeout(()=>window.location.reload(), 100);
            } 
        catch(err) {
            console.log("Error: ", err);
        }
    }
    
    return ( 
        <React.Fragment>
        <Card bordered={false} style={{marginTop: 20}}>
          <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
            <div className="requirement-card-info">
            <Typography className="description-label">Vehicle Type</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : quoteData?.vehicleType
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Vehicle Category</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : quoteData?.vehicle && JSON.parse(quoteData?.vehicle).name
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Make</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : quoteData?.vehicle && JSON.parse(quoteData?.make).name
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Model</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : quoteData?.vehicle && JSON.parse(quoteData?.model).name
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Variant</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : quoteData?.vehicle && JSON.parse(quoteData?.variant).name
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Buying City</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : quoteData?.buyingCityDetail && JSON.parse(quoteData?.buyingCityDetail).name
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">GEVL State</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : quoteData?.gevlStateDetail && JSON.parse(quoteData?.gevlStateDetail).name
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Registered To</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : quoteData?.registeredTo
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Registration Type</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : quoteData?.registrationType
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Payment Type</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : quoteData?.paymentType
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Insurance Type</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : quoteData?.insuranceType
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Road Tax Type</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : quoteData?.roadTaxType
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">No of Vehicles</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : quoteData?.vehicleCount || "NA"
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Tenure in Month</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : quoteData?.tenureInMonthDetail && JSON.parse(quoteData.tenureInMonthDetail)?.name
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Mileage per month(KM)</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : quoteData?.mileagePerMonth
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Lease Type</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : quoteData?.leaseType || "NA"
                }</span>
            </Typography>
            </div>
            {
                quoteData?.prospectRequirementRemark && <div className="requirement-card-info" style={{width: "100%"}}>
                <Typography className="description-label">Prospect Requirement Remark</Typography>
                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                    <span style={{display: loader?"none":""}}>{
                        loader ? "" : quoteData?.prospectRequirementRemark
                    }</span>
                </Typography>
                </div>
            }
          </div>
        </Card>

            
            {
                !editable && user.role === ADMIN &&
                <>
                <div style={{display: 'flex'}}>
                <Title style={{marginTop: 30, fontWeight: 700}} level={5}>Price Details</Title>
                <div style={{marginLeft : 'auto', alignContent :'flex-end'}}>
                    <Button
                        type="primary"
                        ghost
                        icon={<EditIcon />}
                        disabled={disable}
                        onClick={(_) => {
                        setEditable(true);
                        }}
                        className={
                        disable
                            ? "button-override disable"
                            : "button-override primary"
                        }
                        style={{display: editable ? "none" : "" }}
                    >
                        Edit
                    </Button>
                </div>

            </div>

                <Card bordered={false} style={{marginTop: 20}}>
                <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                    <div className="requirement-card-info" style={{width: '25%'}}>
                    <Typography className="description-label">Ex-showroom Price</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : RUPEE_SYMBOL + quoteData?.exShowroom 
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width: '25%'}}>
                    <Typography className="description-label">Base Price</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : RUPEE_SYMBOL + quoteData?.basePrice
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width: '25%'}}>
                    <Typography className="description-label">GST @ {quoteData?.gstPercentOnVehicle}%</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : RUPEE_SYMBOL + quoteData?.gstOnVehicle
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width: '25%'}}>
                    <Typography className="description-label">Fame Subsidy</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : RUPEE_SYMBOL + quoteData?.fameSubsidy
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width: '25%'}}>
                    <Typography className="description-label">Discount</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : RUPEE_SYMBOL + quoteData?.discount
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width: '25%'}}>
                    <Typography className="description-label">Road Tax</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : RUPEE_SYMBOL + quoteData?.roadTaxTotal
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width: '25%'}}>
                    <Typography className="description-label">Insurance Amount</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : RUPEE_SYMBOL + quoteData?.insuranceAmountTotal
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width: '25%'}}>
                    <Typography className="description-label">First year insurance premium</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : quoteData?.projectedPremium && JSON.parse(quoteData?.projectedPremium)["1"] ? RUPEE_SYMBOL + JSON.parse(quoteData?.projectedPremium)["1"] : RUPEE_SYMBOL + 0
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width: '25%'}}>
                    <Typography className="description-label">State Subsidy</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : RUPEE_SYMBOL + quoteData?.stateSubsidy
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width : '75%'}}>
                    <Typography className="description-label">Fabrication Amount</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : <div style={{ width: '50%', display : 'flex', justifyContent : 'space-between'}}><span>{RUPEE_SYMBOL + (quoteData?.fabricationAmountTotal == null ? 0 : quoteData?.fabricationAmountTotal)}</span>{"GST : " + (quoteData?.fabricationGst == null ? 0 : quoteData?.fabricationGst) + '%'}<span></span><span>{"Base Amount : " + RUPEE_SYMBOL + (quoteData?.fabricationBaseAmountTotal == null ? 0 : quoteData?.fabricationBaseAmountTotal)}</span></div>
                        }</span>
                    </Typography>
                    </div>


                    {
                        quoteData?.accessories && JSON.parse(quoteData?.accessories).length !==0 &&
                            <div style={{width: "100%"}}>
                                <Divider orientation="left" orientationMargin={0} style={{margin: "0 0 18px 0"}} ><span style={{fontSize : 14, fontWeight : 'bold'}}>Accessories</span></Divider>
                                <Row style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <Typography className="description-label" style={{width :'25%'}}>Accessories</Typography>
                                    <Typography className="description-label" style={{width :'25%'}}>Price</Typography>
                                    <Typography className="description-label" style={{width :'25%'}}>GST</Typography>
                                    <Typography className="description-label" style={{width :'25%'}}>Base Amount</Typography>
                                </Row>
                                {JSON.parse(quoteData?.accessories).map((data:any) => (
                                    <Row key={data.id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15, width :'25%'}}>{data.name }</Typography>
                                            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15, width :'25%'}}>{RUPEE_SYMBOL + (data.totalAmount == null ? 0 : data.totalAmount)}</Typography>
                                            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15, width :'25%'}}>{data.gst == null ? 0 : data.gst}%</Typography>
                                            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15, width :'25%'}}>{RUPEE_SYMBOL + (data.baseAmount == null ? 0 : data.baseAmount)}</Typography>
                                    </Row>
                                ))}
                            </div>
                    }
                </div>
            </Card>

            <Title style={{marginTop: 30, fontWeight: 700}} level={5}>Service Details</Title>

                            <Card bordered={false} style={{marginTop: 20}}>
                            <div style={{display: "flex", flexWrap: "wrap"}}>
                                <div className="requirement-card-info" style={{width : '25%'}}>
                                <Typography className="description-label">Management Fees</Typography>
                                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                                    <span style={{display: loader?"none":""}}>{
                                        loader ? "" : RUPEE_SYMBOL + quoteData?.managementFees 
                                    }</span>
                                </Typography>
                                </div>
                                <div className="requirement-card-info" style={{width : '25%'}}>
                                <Typography className="description-label">TCS</Typography>
                                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                                    <span style={{display: loader?"none":""}}>{
                                        loader ? "" : RUPEE_SYMBOL + quoteData?.tcs
                                    }</span>
                                </Typography>
                                </div>
                                <div className="requirement-card-info" style={{width : '25%'}}>
                                <Typography className="description-label">RV%</Typography>
                                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                                    <span style={{display: loader?"none":""}}>{
                                        loader ? "" : quoteData?.rvPercentage+ "%"
                                    }</span>
                                </Typography>
                                </div>
                                <div className="requirement-card-info" style={{width : '25%'}}>
                                <Typography className="description-label">Cost of Fund%</Typography>
                                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                                    <span style={{display: loader?"none":""}}>{
                                        loader ? "" : quoteData?.costOfFundPercentage+"%"
                                    }</span>
                                </Typography>
                                </div>
                                <div className="requirement-card-info" style={{width : '25%'}}>
                                <Typography className="description-label">Margin Rate%</Typography>
                                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                                    <span style={{display: loader?"none":""}}>{
                                        loader ? "" : quoteData?.offerRatePercentage+"%"
                                    }</span>
                                </Typography>
                                </div>
                                <div className="requirement-card-info" style={{width : '25%'}}>
                                <Typography className="description-label">Excess Mileage Charge (Per KM)</Typography>
                                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                                    <span style={{display: loader?"none":""}}>{
                                        loader ? "" : RUPEE_SYMBOL + quoteData?.excessMileageChargePerKm
                                    }</span>
                                </Typography>
                                </div>
            
                                {
                                    quoteData?.services && JSON.parse(quoteData?.services).length !==0 &&
                                        <div style={{width: "100%"}}>
                                            <Divider orientation="left" orientationMargin={0} style={{margin: "0 0 18px 0"}} ><span style={{fontSize : 14, fontWeight : 'bold'}}>Service</span></Divider>
                                            <div style={{width: "50%"}}>
                                                <Row style={{ display: 'flex', justifyContent: 'space-between'}}>
                                                    <Typography className="description-label" style={{width :'50%'}}>Service</Typography>
                                                    <Typography className="description-label" style={{width :'50%'}}>Price</Typography>
                                                </Row>
                                                    {JSON.parse(quoteData?.services).map((data:any) => (
                                                        <Row key={data.id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15, width :'50%'}}>{data.name}</Typography>
                                                                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15, width :'50%'}}>{ data.name === "Replacement Vehicle" || data.name === "RMTY" ? RUPEE_SYMBOL + (data.value == null ? 0 : data.value) : RUPEE_SYMBOL+ (data.inMonth == null ? 0 : data.inMonth)}</Typography>
                                                        </Row>
                                                    ))}
                                            </div>
                                        </div>
                                }
                            </div>
                        </Card>
            {
                quoteData?.quoteRemarks &&
                <div>
                    <Title style={{marginTop: 30, fontWeight: 700}} level={5}>Quote Remark</Title>
                        <Card bordered={false} style={{marginTop: 20}}>
                            <div style={{display: "flex", flexWrap: "wrap"}}>
                                <div className="requirement-card-info" style={{width : '100%'}}>
                                <Typography style={{fontSize: 14, minHeight: 15, marginTop : -15}}>
                                            {quoteData?.quoteRemarks}
                                </Typography>
                                </div>
                            </div>
                        </Card>
                </div>
            }
            </>
            }
            {
                editable && 
                <QuotePriceDetails details={quoteData} loader={loader} form={form} data={quoteData} isEdit={true} setEditable = {setEditable}/>
            }
            {/* <div style={{marginTop: 20, display: "flex", justifyContent: "flex-end"}}>
            {
                ((user.role === AS_MANAGER || user.role === AS_HEAD || user.role === ADMIN))
            && <div>
                <Button
                onClick={() => handleFinaliseQuote(9)}
                icon={<TickIcon style={{}}/>}
                style={{marginLeft: 10, color:'#02B51F', background:"#E6F8E9",border:"solid #02B51F", borderWidth:"thin"}}
                className="button-override"
                >Finalise Quote</Button>
            </div>
            }
            </div> */}
        </React.Fragment>
     );
}

export default CompleteQuoteDetails;