import { Button, Input, Modal, Spin, Typography } from "antd";
import { ReactComponent as CrossIcon } from '../../SVGs/cross_icon.svg';
import { ReactComponent as RightArrowIcon } from '../../SVGs/right_arrow_icon.svg';
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;

interface ModalProps {
    type: boolean;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    remark: string;
    setRemark: (remark: string) => void;
    handler: (statusId: number) => void;
    loader: boolean;
}

const ApproveRejectModal: React.FC<ModalProps> = ({ type, showModal, setShowModal, remark, setRemark, handler, loader }) => {
    const statusId = type ? 1 : 2;
    return (
        <Modal
            title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 500, color: "#404040"}}>{`Are you sure you want to ${type ? "approve" : "reject"} this?`}</Typography>}
            centered
            open={showModal}
            onOk={() => setShowModal(false)}
            onCancel={() => setShowModal(false)}
            closable={true}
            afterClose={() => setRemark("")}
            className="preview-modal"
            footer={<>
                <div className="edit-section">
                    <Button
                        ghost
                        danger
                        type="primary"
                        onClick={_ => {setShowModal(false); setRemark("")}}
                        className="button-override danger"
                        icon={<CrossIcon/>}
                        >
                        No
                    </Button>
                    <Button
                        disabled={(!type && remark==="")}
                        type="primary"
                        onClick={() => {
                            !loader && handler(statusId);
                        }}
                        className="button-override"
                        >
                        Yes
                        {loader ? <Spin indicator={antIcon} /> : <RightArrowIcon style={{marginLeft: 10}} />}
                    </Button>
                </div>
            </>}
        >   
            <Typography className="description-label">Enter Remark {type && <span className="optional-label">(Optional)</span>}</Typography>
            <Input className="input-number-override" style={{height: 40, padding: 4, backgroundColor: "#F6FAFF"}} value={remark} onChange={e => setRemark(e.target.value) } placeholder="Enter Remark" />
        </Modal>
    );
}

export default ApproveRejectModal;