import { getRequest } from "../../../../utils/handler/apiHandler";

export const fetchCreditAssessmentRequestTableData = async (queryString: string, setData: any, setPageCount: any, setTotalRecord: any, setLoading: any): Promise<void> => {
    try {
      setLoading(true); // Set loading to true before fetching data
      const res = await getRequest(
        `${queryString}`
      );
      if (res.status === 200) {
        setData(res.data.data);
        if ('pageCount' in res.data) {
          setPageCount(res.data.pageCount);
        }
        if ('totalRecord' in res.data) {
          setTotalRecord(res.data.totalRecord);
        }
      } else {
        console.error('Error fetching Prospects Table List:', res.msg);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched or in case of an error
    }
  };
