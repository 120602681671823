import { useNavigate } from 'react-router-dom';

export const useNavigationFunctions = () => {
  const navigate = useNavigate();

  const navigateToEdit = (payload:any) => {
    navigate(`/dashboard/prospect/${payload?.prospectId}`);
  };

  const navigateToDisplay = (payload:any) => {
    navigate(`/dashboard/prospect/display/${payload?.prospectId}`);
  };

  return { navigateToEdit, navigateToDisplay };
};
