import React from 'react';
import { Table } from 'antd';
import '../../../../styles/tableStyles.css'; // Import the CSS file

interface TwoColumnThreeRowTableProps {
  prospectName: string;
  vehicleDetails: string;
  tenureAndMileage: string;
}

const TwoColumnThreeRowTable: React.FC<TwoColumnThreeRowTableProps> = ({
  prospectName,
  vehicleDetails,
  tenureAndMileage,
}) => {
  const dataSource = [
    {
      key: '1',
      column1: 'Prospect Name',
      column2: prospectName,
    },
    {
      key: '2',
      column1: 'Vehicle Details',
      column2: vehicleDetails?.replace(/\s+/g, ''),
    },
    {
      key: '3',
      column1: 'Tenure / Contracted Mileage',
      column2: tenureAndMileage,
    },
  ];

  const columns = [
    {
      title: <span className="bolder-text">Details</span>, // Apply bold-text class to make header bold
      dataIndex: 'column1',
      key: 'column1',
      colSpan: 2,
      render: (text: string) => <span className="bold-text">{text}</span>, // Apply bold-text class to make cells in the first column bold
    },
    {
      title: '',
      dataIndex: 'column2',
      key: 'column2',
      width: '60%',
    },
  ];

  return (
    <Table dataSource={dataSource} columns={columns} pagination={false} bordered={false}>
      <colgroup>
        <col style={{ width: '50%' }} />
        <col style={{ width: '50%' }} />
      </colgroup>
    </Table>
  );
};

export default TwoColumnThreeRowTable;
