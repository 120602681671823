import { DownloadOutlined, LoadingOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Button, Input, Table, Tooltip, Spin } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAppSelector } from '../../../app/hooks';
import { authSelector } from '../../../features/auth/authSlice';
import { useGlobalSearchFilter } from '../../../utils/custom_hooks/useGlobalSearchFilter';
import { menuSelector } from '../../../features/menu/menuSlice';
import getDynamicAction from '../../../utils/helpers/dynamicAction';
import { getRequest } from '../../../utils/handler/apiHandler';
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "#1677ff", marginLeft: 6 }} spin />;

interface AssetInput {
    assetNo: number,
    regNo: number,
    deviceId: number,
    chassisNo: number,
    vtsTypeId: number,
    vehicleId: number,
    makerId: number,
    modelId: number,
    variantId: number,
    clientId: number,
    locationId: string,
    subscriptionStart:string,
    subscriptionEnd:string,
    deliveryDate:string,
    contractedMonth:number,
    contractedMileage:number,
}

type DataIndex = keyof AssetInput;

function FleetList() {
    const navigate = useNavigate();
    const [data, setData] = useState<AssetInput[]>([]);
    const [pageSize, setPageSize] = useState<number>(8);

    const auth = useAppSelector(authSelector);
    const [loader, setLoader] = useState(false);
    const [showVtsBtn, setShowVtsBtn] = useState(true);
    const [vtsBtnMessage, setVtsBtnMessage] = useState('Click here to initiate the generation and sending of the VTS report to your email address');
    const pageUrl = useLocation().pathname.split("/").pop();
    const menus = useAppSelector(menuSelector).menu;
    const actionItem = getDynamicAction(menus, pageUrl, "asset");

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.post<AssetInput[]>(
              `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/reportservice/fleet`,
              {}
              ,
            {
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`
                }
            }
            );
            setData(response.data);
          } catch (error) {
            console.error('Error fetching dbdata:', error);
          }
        };
        const setShowVtsBtnStatus = async () => {
          try {
            const response = await getRequest(
              `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/reportservice/report/checkVtsReportSent`,
              );
            setShowVtsBtn(response?.data?.showVtsBtn);
            if(response?.data?.status!=='Not Sent')
              setVtsBtnMessage(response?.data?.status);
          } catch (error) {
            console.error('Error fetching dbdata:', error);
          }
        };
    
        fetchData();
        setShowVtsBtnStatus();
      }, []);

    const { filteredData, handleSearch } = useGlobalSearchFilter<AssetInput>(data, [], 500);

    const columns: ColumnsType<AssetInput> = [
        {
            title: 'Asset No.',
            dataIndex: 'assetNo',
            key: 'assetNo',
        },
        {
            title: 'Registration No.',
            dataIndex: 'regNo',
            key: 'regNo',
        },
        {
            title: 'Device ID',
            dataIndex: 'deviceId',
            key: 'deviceId',
        },
        {
          title: 'Chassis No.',
          dataIndex: 'chassisNo',
          key: 'chassisNo',
        },{
          title: 'Username',
          dataIndex: 'username',
          key: 'username',
        },
        {
            title: 'VTS Type',
            dataIndex: 'vtsTypeDetails',
            key: 'vtsTypeDetails',
            render: (record, rowIndex) => {
                const vtsTypeDetail = JSON.parse(record);
                return (
                  <p >{vtsTypeDetail?.name}</p>
                );
            },
        },
        {
          title: 'Client',
          dataIndex: 'clientDetails',
          key: 'clientDetails',
          render: (record, rowIndex) => {
              const makerDetail = JSON.parse(record);
              return (
                <p >{makerDetail?.name}</p>
              );
          },
        },
        {
          title: 'Contracted Month',
          dataIndex: 'contractedMonth',
          key: 'contractedMonth',
        },
        {
          title: 'Average Mileage',
          dataIndex: 'avgMileage',
          key: 'avgMileage',
        },
        {
          title: 'Contracted Mileage',
          dataIndex: 'contractedMileage',
          key: 'contractedMileage',
        },
        {
          title: 'Vehicle Category',
          dataIndex: 'vehicleDetails',
          key: 'vehicleDetails',
          render: (record, rowIndex) => {
              const vehicleDetail = JSON.parse(record);
              return (
                <p >{vehicleDetail?.name}</p>
              );
          },
        },
        {
            title: 'Make',
            dataIndex: 'makerDetails',
            key: 'makerDetails',
            render: (record, rowIndex) => {
                const makerDetail = JSON.parse(record);
                return (
                  <p >{makerDetail?.name}</p>
                );
            },
        },
        {
            title: 'Model',
            dataIndex: 'modelDetails',
            key: 'modelDetails',
            render: (record, rowIndex) => {
                const makerDetail = JSON.parse(record);
                return (
                  <p >{makerDetail?.name}</p>
                );
            },
        },        
        {
            title: 'Location',
            dataIndex: 'locationDetails',
            key: 'locationDetails',
            render: (record, rowIndex) => {
                const makerDetail = JSON.parse(record);
                return (
                  <p >{makerDetail?.name}</p>
                );
            },
        },
        {
          title: 'Delivery Date',
          dataIndex: 'deliveryDate',
          key: 'deliveryDate',
        }, 
        {
            title: 'Subscription Start',
            dataIndex: 'subscriptionStart',
            key: 'subscriptionStart',
        },
        {
            title: 'Subscription End',
            dataIndex: 'subscriptionEnd',
            key: 'subscriptionEnd',
        },
    ];

    const handlePageSizeChange = (current: number, size: number) => {
      // Update the page size state
      setPageSize(size);
    };

    const handleReportDownload = async () => {
      const date = new Date();
      const doc_name = "VTS_Report_" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getFullYear();
      console.log(doc_name);
      setShowVtsBtn(false);
      setVtsBtnMessage('In Progress');
      setLoader(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/reportservice/report/vtsReport`,
          {
              headers: {
                  "Authorization": `Bearer ${auth.accessToken}`
              },
              responseType: 'arraybuffer'
          }
          );
          setVtsBtnMessage('Already Sent');
          setLoader(false);
      } catch (error) {
          setLoader(false);
          console.error('Error fetching dbdata:', error);
      }
    }

  
    return (
      <React.Fragment>
        <div className="form-container">
          <div className="page-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => navigate('/dashboard/asset/create-fleet')} style={{display: actionItem?.["Add New Fleet"] === "true" ? "block" : "none"}}>
              Add New Fleet
            </Button>
            <Tooltip placement="bottom" title={vtsBtnMessage}>
              <Button disabled={!showVtsBtn} icon={<DownloadOutlined />} 
                onClick={() => handleReportDownload()} 
                style={{display: actionItem?.["Add New Fleet"] === "true" ? "block" : "none",
                  backgroundColor: vtsBtnMessage=='Already Sent' ? 'rgba(208, 14, 14, 0.10)' : 
                    vtsBtnMessage== 'In Progress' ? 'rgba(227, 154, 44, 0.20)' : 'rgba(44, 227, 95, 0.20)'
                  }}>
                Send VTS Report
                {loader ? <Spin indicator={antIcon} /> : ""}
              </Button>
            </Tooltip>
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search"
              style={{ width: 200 , height: 50}}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={{
              pageSize: pageSize,
              pageSizeOptions: ['5', '10', '20', '50'], // Define the available page sizes
              showSizeChanger: true, // Display the page size changer
              onShowSizeChange: handlePageSizeChange, // Handle page size change event
            }}
            scroll={{ x: 2000, y: 500 }} // Adjust the value according to your total column widths and desired height of the fixed header
            size="middle"
          />
        </div>
      </React.Fragment>
    );
};

export default FleetList;
