// useResizeEffect.ts
import { useEffect } from 'react';

export const useResizeEffect = (tableRef: React.RefObject<HTMLDivElement>, setTableHeight: React.Dispatch<React.SetStateAction<number>>) => {
  useEffect(() => {
    const updateTableHeight = () => {
      if (tableRef.current) {
        const height = window.innerHeight - tableRef.current.getBoundingClientRect().top;
        setTableHeight(height);
      }
    };
    window.addEventListener('resize', updateTableHeight);
    updateTableHeight();
    return () => {
      window.removeEventListener('resize', updateTableHeight);
    };
  }, [tableRef, setTableHeight]);
};
