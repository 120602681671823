// apiCalls.ts

import { putRequest } from '../../../../../../utils/handler/apiHandler';
import { ApiData, RatioAnalysisApiData, RiskMatrixApiData, UpdateApiData, UpdateRatioAnalysisApiData, UpdateRiskMatrixApiData } from './creditRequestInterfaces';

export const sendFormData = async (apiData: ApiData, creditRequestId: number, parentAssessmentFrom: boolean) => {
    const payload = {
        isParentEntityAssessment: parentAssessmentFrom,
        ...apiData
    }
    try {
        const response = await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/${creditRequestId}`,payload);
    } catch (error) {
        console.error('API Error:', error);
        throw error; // Optionally handle or rethrow the error as needed
    }
};
export const sendUpdateFormData = async (apiData: UpdateApiData, id:number, creditRequestId: number, parentAssessmentFrom: boolean) => {
    const payload = {
        isParentEntityAssessment: parentAssessmentFrom,
        ...apiData
    }
    try {
        const response = await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/financialSpreading/${id}?creditRequestId=${creditRequestId}`,payload);
    } catch (error) {
        console.error('API Error:', error);
        throw error; // Optionally handle or rethrow the error as needed
    }
};

export const sendRatioAnalysisFormData = async (apiData: RatioAnalysisApiData, creditRequestId: number, parentAssessmentFrom: boolean) => {
    const payload = {
        isParentEntityAssessment: parentAssessmentFrom,
        ...apiData
    }
    try {
        const response = await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/${creditRequestId}`,payload);
    } catch (error) {
        console.error('API Error:', error);
        throw error; // Optionally handle or rethrow the error as needed
    }
};

export const sendUpdateRatioAnalysisFormData = async (apiData: UpdateRatioAnalysisApiData,id: number, creditRequestId: number, parentAssessmentFrom: boolean) => {
    const payload = {
        isParentEntityAssessment: parentAssessmentFrom,
        ...apiData
    }
    try {
        const response = await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/ratioAnalysis/${id}?creditRequestId=${creditRequestId}`,payload);
    } catch (error) {
        console.error('API Error:', error);
        throw error; // Optionally handle or rethrow the error as needed
    }
};

export const sendRiskMatrixFormData = async (apiData: RiskMatrixApiData, creditRequestId: number, parentAssessmentFrom: boolean) => {
    const payload = {
        isParentEntityAssessment: parentAssessmentFrom,
        ...apiData
    }
    try {
        const response = await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/${creditRequestId}`,payload);
    } catch (error) {
        console.error('API Error:', error);
        throw error; // Optionally handle or rethrow the error as needed
    }
};

export const sendUpdateRiskMatrixFormData = async (apiData: UpdateRiskMatrixApiData, id: number, creditRequestId: number, parentAssessmentFrom: boolean) => {
    const payload = {
        isParentEntityAssessment: parentAssessmentFrom,
        ...apiData
    }
    try {
        const response = await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/riskMatrix/${id}?creditRequestId=${creditRequestId}`,payload);
    } catch (error) {
        console.error('API Error:', error);
        throw error; // Optionally handle or rethrow the error as needed
    }
};