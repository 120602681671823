import React, { useState } from 'react';
import Modal from 'antd/lib/modal/Modal'; // Assuming you are using Ant Design modal
import Button from 'antd/lib/button/button'; // Assuming you are using Ant Design button
import { ReactComponent as CrossIcon } from '../pages/Dashboard/Prospect/utils/SVGs/cross_icon.svg';
import { ReactComponent as TickIcon } from '../pages/Dashboard/Prospect/utils/SVGs/tick_icon_stroke.svg';

interface CustomConfirmationModalButtomColorReversedProps {
  visible: boolean;
  title: string;
  message: string;
  confirmButtonLabel: string;
  cancelButtonLabel: string;
  onClose: () => void;
  onConfirm: () => void;  
}

const CustomConfirmationModalButtomColorReversed: React.FC<CustomConfirmationModalButtomColorReversedProps> = ({
  visible,
  title,
  message,
  confirmButtonLabel,
  cancelButtonLabel,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      centered={true}
      footer={[
        <Button style={{height:'2.5rem',color:'#1777FF',backgroundColor:'#E0EDFF',borderColor:'#1777FF', fontWeight: '500'}} key="cancel" onClick={onClose}>
          {cancelButtonLabel}
        </Button>,
        <Button icon={<CrossIcon color='#FFF'/>} style={{height:'2.5rem',color:'#FFF',backgroundColor:'#FF4D4F', fontWeight: '500'}} key="confirm" type="primary" onClick={onConfirm}>
          {confirmButtonLabel}
        </Button>       
      ]}
    >
      <span style={{fontSize:'16px', fontWeight:'700'}}>{message}</span>
    </Modal>
  );
};

export default CustomConfirmationModalButtomColorReversed;
