import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface User {
  name: string;
  email: string;
  role: string;
  ssoId: String
}

const initialState: User = {
    name: "",
    email: "",
    role: "",
    ssoId: "",
};

export const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.role = action.payload.role;
        state.ssoId = action.payload.ssoId;
    }
  },
});
export const { setUser } = authSlice.actions;
export const userSelector = (state: RootState) => state.user;
export default authSlice.reducer;