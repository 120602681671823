import React from 'react';

interface FileViewerProps {
  previewFile: any;
}
    
const FileViewer: React.FC<FileViewerProps> = ({ previewFile }) => {
    
    return previewFile ? (
        <div style={{ width: '100%', minHeight: '150px', backgroundColor: 'white', margin: '1rem 1rem' }}>
            <div style={{color:'#464646', backgroundColor:"#F6FAFF", fontWeight:'700', fontSize:'12px'}}>{previewFile.fileName}</div>
            <iframe
                title="previewFile.fileName"
                src={previewFile.url}
                style={{ width: "100%", height: "100%", border: "none" }}
            />
        </div>
    ) : null;
}

export default FileViewer;
