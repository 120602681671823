import { LeftOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Spin, Tooltip, Typography, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as PipeIcon } from '../../../../utils/icons/Pipe_Icon.svg';
import { ReactComponent as DisabledIcon } from '../../SVGs/disabled_icon.svg';
import { ReactComponent as RightArrowIcon } from '../../SVGs/right_arrow_icon.svg';
import { ReactComponent as CloudUploadIcon } from '../../SVGs/cloud_upload_icon.svg';
import { ReactComponent as PreviewDocIcon } from '../../SVGs/preview_doc_icon.svg';
import { ReactComponent as DownloadSheetIcon } from '../../Prospect/utils/SVGs/DownloadSheet.svg'; 
import { ReactComponent as AddIcon } from '../../Prospect/utils/SVGs/add_icon.svg';
import { useAppSelector } from "../../../../app/hooks";
import { menuSelector } from "../../../../features/menu/menuSlice";
import { userSelector } from "../../../../features/auth/userSlice";
import CancelRequestModal from "./CancelRequestModal";
import { getRequest, putRequest } from "../../../../utils/handler/apiHandler";
import RequestedQuoteDetails from "./RequestedQuoteDetails";
import UploadModal from "./UploadModal";
import ETAModal from "./ETAModal";
import QuotePriceDetails from "./QuoteDetailsForm";
import CompleteQuoteDetails from "./CompleteQuoteDetails";
import axios from "axios";
import { authSelector } from "../../../../features/auth/authSlice";
import { ADMIN, SALES_MANAGER, SALES_HEAD, AS_MANAGER, AS_HEAD} from "../../constants";
import UploadModalDemo from "./UploadModalDemo";
import DocumentTableModal from "./DocumentTableModal";

const { Title } = Typography;

function RequestedQuote() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const auth = useAppSelector(authSelector);
  const url: any = useLocation();
  const requestNumber = url.pathname.split("/").pop();
  const [loader, setLoader] = useState(false);
  const [quoteRequestDetails, setQuoteRequestDetails] = useState<any>();
  const [quoteData, setQuoteData] = useState<any>();
  const menus = useAppSelector(menuSelector).menu;
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showETAModal, setShowETAModal] = useState(false);
  const [docViewer, setDocViewer] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadModalDemo, setUploadModalDemo] = useState(false);
  const [remark, setRemark] = useState("");
  const [eta, setEta] = useState("");
  const [cancelLoader, setCancelLoader] = useState<boolean>(false);
  const [backToOpsReviewLoader, setBackToOpsReviewLoader] = useState<boolean>(false);
  const [acceptLoader, setAcceptLoader] = useState<boolean>(false);
  const [proceedLoader,setProceedLoader] = useState<boolean>(false);
  const [etaLoader, setEtaLoader] = useState<boolean>(false);
  const [statusId, setStatusId] = useState<any>(null);
  const [isBackToOpsReview, setIsBackToOpsReview] = useState<boolean>(false);
  const [title, setTitle] = useState<any>("");
  const [subTitle, setSubTitle] = useState<any>("");
  const [piFiles, setPiFiles] = useState<any[]>([]);
  const [fileSelectionFlag, setFileSelectionFlag ] = useState<boolean>(false);
  const user: any = useAppSelector(userSelector);
  const [editable, setEditable] = useState<boolean>(false);
  const [quoteStatusId, setQuoteStatusId] = useState<number>(-1);
  let [searchParams, setSearchParams] = useSearchParams();
  const quoteId = parseInt(searchParams.get("quoteId") || "");
  const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;


  useEffect(() => {
    setLoader(true);
    (async () => {
      if(quoteId){
        fetchQuoteDataById(quoteId);
      }
      else {
        const res = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/quoteDetail/${requestNumber}`);
        setQuoteRequestDetails(res.data);
        setQuoteStatusId(res.data?.quoteStatusId);
      }
      setLoader(false);
    }
    )()
  }, []);


  const fetchQuoteDataById = async (quoteId :number) =>{
    try{
      const res = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/${quoteId}`);
      if(res.status === 404) navigate("/dashboard/quote")
        console.log(res)
      setQuoteData(res.data);
    } catch(err) {
      console.log("Error in fetching Quote by Id : " + quoteId, err);
      navigate("/dashboard/quote")
    }
  }

  const handleCancelRequest = async (statusId: number) => {
    isBackToOpsReview ? setBackToOpsReviewLoader(true) : setCancelLoader(true);
    try {
      let payload = {};
       if(!isBackToOpsReview){
        payload = { statusChangeTypeId: statusId, rejectRemark: remark }
       }else{
        payload = { statusChangeTypeId: 1, backToOpsReviewRemark: remark}
       }

       console.log("Payload :: ", payload);
       
        await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/quoteRequest/${requestNumber}`, payload);
        setCancelLoader(false);
        setBackToOpsReviewLoader(false);
        setShowCancelModal(false);
        setTimeout(()=>window.location.reload(), 100);


    } catch(err) {
      console.log("Error: ", err);
    }
  }

  const handleProceed = async () => {
    setEtaLoader(true); 
    try {
      const payload: any = {
        statusChangeTypeId: statusId,
      }
      statusId === 1 ? payload["piEta"] = eta : statusId === 3 ? payload["eta"] = eta : null
      const res = await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/quoteRequest/${requestNumber}`, payload);
      if(res) setEtaLoader(false);
      setShowCancelModal(false);
      setShowETAModal(false)
      setTimeout(()=>window.location.reload(), 100);
    } catch(err) {
      console.log("Error: ", err);
    }
  }

const downloadQuotePreviewExcel = async (record : any) => {
  try {
    const res = await axios.post(
    `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/report/excel/${record?.quoteId}`,
    {},
    {
        headers: {
            "Authorization": `Bearer ${auth.accessToken}`
        },
        responseType: 'arraybuffer'
    }
    );
    const url =  window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${record?.prospectName}_${record?.quoteNo}.xlsx`);
    document.body.appendChild(link);
    link.addEventListener('click', () => {
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
        }, 100);
    });
    link.click();
    document.body.parentNode?.removeChild(link);
} catch (error) {
    console.error('Error fetching dbData:', error);
}
}

const markQuotePending = async (statusId:any) => {
  try {
    setProceedLoader(true)
    setStatusId(statusId)
    console.log(statusId)
    const payload: any = {
      statusChangeTypeId: statusId,
    }
    const res = await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/quoteRequest/${requestNumber}`, payload);
    setProceedLoader(false)
    setTimeout(()=>window.location.reload(), 100);
  } catch(err) {
    console.log("Error: ", err);
  }
}

const handleUpload = (fileList: any) => {
  setPiFiles(fileList);
};

  return ( 
  <React.Fragment>
    <div className="parent-container">
      <div className="container-layout">

        {/* HEADER SECTION START*/}
        <div className="container-header">
            <div className="container-header-title">
              <div className="container-header-title-section-one" style={{height: 30}}>
                <LeftOutlined style={{margin: 0, padding: 0}} onClick={_ => navigate("/dashboard/quote")} />
                <Tooltip title={""} placement="bottomLeft">
                  <Title level={4} style={{maxWidth: 300, overflow: "hidden", width: loader ? 500 : "auto"}} className={loader ? "shimmer-ui" : ""}>
                      {quoteId ? <>Quote No. {quoteData?.quoteNo}</> : <>Request No. {requestNumber}</>}
                  </Title>
                </Tooltip>
                <div style={{backgroundColor: quoteRequestDetails?.statusBackgroundColor, color: quoteRequestDetails?.statusTextColor, padding: 8, borderRadius: 4, fontWeight: 500, fontSize: 12, marginLeft: 10}}>{quoteRequestDetails?.quoteStatus}</div>
              </div>
              <div className="container-header-title-section-two">
                  <Typography style={{width: "auto"}}>
                      Prospect Name: <a className="link" href={`/dashboard/prospect/${quoteData?.quoteId ? quoteData?.prospectId : quoteRequestDetails?.prospectId}`}>{quoteRequestDetails?.prospectName || quoteData?.prospectName}</a>
                  </Typography>
                  {
                    (quoteData?.requirementNumber != null || quoteRequestDetails?.requirementNumber) && <div style={{ marginRight: '5px', marginLeft: '5px' }}><PipeIcon /> </div>
                  }
                  {
                    quoteData?.requirementNumber != null && <Typography style={{width: "auto"}}>Requirement: Requirement {quoteData?.requirementNumber}</Typography>
                  }
                  {
                    (quoteData?.requirementNumber == null && quoteRequestDetails?.requirementNumber != null) && <Typography style={{width: "auto"}}>Requirement: Requirement {quoteRequestDetails?.requirementNumber}</Typography>
                  }


                  {
                    ((quoteData?.piEta != null || quoteRequestDetails?.piEta) && quoteRequestDetails?.quoteStatusId === 2) && <div style={{ marginRight: '5px', marginLeft: '5px' }}><PipeIcon /> </div>
                  }
                  {
                    ((quoteData?.piEta == null && quoteRequestDetails?.piEta != null) && quoteRequestDetails?.quoteStatusId === 2) && <Typography style={{width: "auto"}}>ETA for PI: {quoteRequestDetails?.piEta}</Typography>
                  }

                  {
                    ((quoteData?.eta != null || quoteRequestDetails?.eta) && quoteRequestDetails?.quoteStatusId === 4) && <div style={{ marginRight: '5px', marginLeft: '5px' }}><PipeIcon /> </div>
                  }
                  {
                    ((quoteData?.eta == null && quoteRequestDetails?.eta != null) && quoteRequestDetails?.quoteStatusId === 4) && <Typography style={{width: "auto"}}>ETA for Sample Quote: {quoteRequestDetails?.eta}</Typography>
                  }

                  <div className="container-header-action">
              </div>
              </div>
            </div>
            <div className="container-header-action">
              {
                ((quoteRequestDetails?.quoteStatusId === 1 || quoteRequestDetails?.quoteStatusId === 8) && (user.role === SALES_MANAGER || user.role === SALES_HEAD)) && <Button
                  ghost
                  danger
                  type="primary"
                  icon={<DisabledIcon />}
                  style={{marginLeft: 10}}
                  disabled={quoteRequestDetails?.quoteStatusId === 6}
                  onClick={_ => setShowCancelModal(true)}
                  className={quoteRequestDetails?.quoteStatusId === 6 ? "button-override disable" : "button-override danger"}
                >
                    Cancel Request
                </Button>
              }
              {
                (quoteRequestDetails?.proformaDocId === null && (quoteRequestDetails?.quoteStatusId === 2 && (user.role === AS_MANAGER || user.role === AS_HEAD || user.role === ADMIN))) && 
                <Upload
                accept=".docx,.doc,.pptx,.ppt,.xlsx,.xls,.pdf,.jpg,.jpeg,.png"
                multiple={true}
                onChange={(info) => {setUploadModalDemo(true) ; setFileSelectionFlag(!fileSelectionFlag); handleUpload(info.fileList); }}
                showUploadList={false}
                fileList={[]}
                beforeUpload={() => false}
                >
                  <Button
                    type="primary"
                    icon={<CloudUploadIcon />}
                    style={{marginLeft: 10}}
                    disabled={quoteRequestDetails?.quoteStatusId === 6}
                    onClick={() => {}}
                    className="button-override"
                  >
                      Upload Proforma Invoice
                  </Button>
                </Upload>
              }
              {
                (((quoteRequestDetails?.quoteStatusId === 2 && quoteRequestDetails?.proformaDocId !== null) || quoteRequestDetails?.quoteStatusId === 3) && (user.role === AS_MANAGER || user.role === AS_HEAD || user.role === ADMIN)) && <>
                <Upload
                accept=".docx,.doc,.pptx,.ppt,.xlsx,.xls,.pdf,.jpg,.jpeg,.png"
                multiple={true}
                onChange={(info) => {setUploadModalDemo(true); setUploadModalDemo(true) ; setFileSelectionFlag(!fileSelectionFlag); handleUpload(info.fileList); }}
                showUploadList={false}
                fileList={[]}
                beforeUpload={() => false}
                >
                  <Button
                    ghost
                    type="primary"
                    icon={<AddIcon />}
                    style={{marginLeft: 10}}
                    onClick={() => {}}
                    className="button-override primary"
                  >
                      Add more Proforma Invoice
                  </Button>
                </Upload>
                <Button
                  ghost
                  type="primary"
                  icon={<PreviewDocIcon />}
                  style={{marginLeft: 10}}
                  onClick={_ => setDocViewer(true)}
                  className="button-override primary"
                >
                    View Proforma Invoice
                </Button>
                </>
              }
              {
                (quoteRequestDetails?.quoteStatusId === 4 && (user.role === AS_MANAGER || user.role === AS_HEAD || user.role === ADMIN)) && <>
                <Button
                  ghost
                  danger
                  type="primary"
                  icon={<DisabledIcon />}
                  style={{marginLeft: 10}}
                  disabled={quoteRequestDetails?.quoteStatusId === 6}
                  onClick={_ => setShowCancelModal(true)}
                  className={quoteRequestDetails?.quoteStatusId === 6 ? "button-override disable" : "button-override danger"}
                >
                    Cancel Request
                </Button>
                <Button
                  ghost
                  type="primary"
                  icon={<PreviewDocIcon />}
                  style={{marginLeft: 10}}
                  onClick={_ => setDocViewer(true)}
                  className="button-override primary"
                >
                    View Proforma Invoice
                </Button>
                </>
              }
              {
                (quoteData?.proformaId) && <>
                <Button
                  ghost
                  type="primary"
                  icon={<PreviewDocIcon />}
                  style={{marginLeft: 10}}
                  onClick={_ => setDocViewer(true)}
                  className="button-override primary"
                >
                    View Proforma Invoice
                </Button>
                </>
              }
              {
                (quoteData?.quoteId) && <>
                <Button
                  ghost
                  type="primary"
                  icon={<DownloadSheetIcon />}
                  style={{marginLeft: 10}}
                  onClick={_ => downloadQuotePreviewExcel(quoteData)}
                  className="button-override primary"
                >
                    Download Sheet
                </Button>
                </>
              }
            </div>
        </div>
        {/* HEADER SECTION END */}


        {/* QUOTE REQUEST SECTION ONE START */}
        {!quoteId && 
        <RequestedQuoteDetails 
          details={quoteRequestDetails} 
          loader={loader} 
          setShowRejectModal={setShowRejectModal} 
          setShowETAModal={setShowETAModal} 
          setStatusId={setStatusId}
          acceptLoader={acceptLoader}
          user={user}
          setTitle={setTitle}
          setSubTitle={setSubTitle}
        />}
        {/* QUOTE REQUEST SECTION ONE END */}
        { quoteRequestDetails?.proformaDocId !== null && 
        <div style={{display:"flex", justifyContent:"flex-end"}}>
        {
          (quoteRequestDetails?.quoteStatusId === 2 && (user.role === AS_MANAGER || user.role === AS_HEAD || user.role === ADMIN))
          && <Button
          type="primary"
          style={{marginLeft: 10}}
          onClick={_ => markQuotePending(2)}
          icon={proceedLoader && <Spin indicator={antIcon}/>}
          className="button-override"
          >
            Move to Quote Pending
            <span> {<RightArrowIcon style={{marginLeft:"0.5rem"}}/>}</span>
          </Button>
        }
        </div>}

        {/* ADD NEW COMPONENTS HERE... */}
        {/* {
          quoteRequestDetails?.quoteStatusId === 7 && <RejectionReason rejectionReason= {quoteRequestDetails?.rejectRemark}/>
        } */}

        {
          (quoteRequestDetails?.quoteStatusId === 4 && (user.role === AS_MANAGER || user.role === AS_HEAD || user.role === ADMIN)) && <>
            <QuotePriceDetails details={quoteRequestDetails} loader={loader} form={form} data={quoteRequestDetails} isEdit={false} setEditable={setEditable}/>
          </>
        }
        {
          (quoteRequestDetails?.quoteStatusId === 5 || quoteData) && <CompleteQuoteDetails quoteData={quoteData} loader={loader} requestDetails={quoteRequestDetails} user={user}/>
        }

        {/* MODAL COMPONENTS START */}
        <CancelRequestModal title={"Are you sure you want to cancel the Sample Quote Request"} subTitle="" type="Cancel" showModal={showCancelModal} setShowModal={setShowCancelModal} remark={remark} setRemark={setRemark} cancel={() => handleCancelRequest(5)} cancelLoader={cancelLoader} backToOpsReviewLoader={backToOpsReviewLoader} isBackToOpsReview ={isBackToOpsReview} setIsBackToOpsReview = {setIsBackToOpsReview} quoteStatusId={quoteStatusId}/>
        <CancelRequestModal title={"Are you sure you want to reject the Sample Quote Request"} subTitle="" type="Reject" showModal={showRejectModal} setShowModal={setShowRejectModal} remark={remark} setRemark={setRemark} cancel={() => handleCancelRequest(6)} cancelLoader={cancelLoader} backToOpsReviewLoader={backToOpsReviewLoader} isBackToOpsReview ={isBackToOpsReview} setIsBackToOpsReview = {setIsBackToOpsReview} quoteStatusId={quoteStatusId}/>
        <UploadModal title={"Upload Proforma Invoice"} statusChangeTypeId={2} requestId={requestNumber} showModal={uploadModal} setShowModal={setUploadModal} />
        <UploadModalDemo title={"Upload Proforma Invoice"} statusChangeTypeId={quoteRequestDetails?.quoteStatusId === 2 ? 1 : 2} requestId={requestNumber} showModal={uploadModalDemo} setShowModal={setUploadModalDemo} files={piFiles} setFiles={setPiFiles} fileSelectionFlag={fileSelectionFlag}/>
        <ETAModal title={title} subTitle={subTitle} showModal={showETAModal} setShowModal={setShowETAModal} eta={eta} setEta={setEta} handler={() => handleProceed()} etaLoader={etaLoader} buttonText="Proceed" />
        {/* <DocumentViewerModal title={""} docURI={quoteRequestDetails?.proformaDocId || quoteData?.proformaId} showModal={docViewer} setShowModal={setDocViewer} /> */}
        <DocumentTableModal isModalVisible={docViewer} setIsModalVisible={setDocViewer} requestId={requestNumber}/>
        {/* MODAL COMPONENTS END */}
      </div>
    </div>
  </React.Fragment> 
  )
}

export default RequestedQuote;