import { Menu, MenuProps, Divider } from 'antd';
import { useNavigate, useLocation } from "react-router";
import { useAppSelector } from '../app/hooks';
import { menuSelector } from '../features/menu/menuSlice';
import { titleCase } from '../utils/helpers/titleCase';
import "../styles/Nav.sass"

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

function Sidenav() {
  const navigate = useNavigate();
  const pageName = useLocation().pathname.split("/")[2];
  const menu = useAppSelector(menuSelector).menu;

  const items: any = [];
  
  for (const property in menu) {
    let subMenu: any = []
    menu[property]["submenu"].map((e: any) => {
      subMenu.push(getItem(e.menuName.split("_").join(" "), e.url.split("/").join(""), <></>));
    })
    items.push(
      getItem(titleCase(menu[property].menu.split("_").join(" ")), titleCase(menu[property].menu.split("_").join(" ")), <img style={{width: 20, color: "white"}} src={`data:image/svg+xml;utf8,${encodeURIComponent(menu[property].icon)}`} />, subMenu),
    )
  }

  let defaultOpenKeys = "";
  let selectedKeys = "";
  items?.map((menu: any) => {
    menu?.children?.map((subMenu: any) => {
      if(subMenu.key === pageName) {
        defaultOpenKeys = menu.key;
        selectedKeys = subMenu.key;
      }
    })
  });
  
  return (
      <div className="side-nav-container">
        <img style={{width: "60%", padding: "10px 20px 20px 20px"}} src={`${__dirname}assets/letsEV_Logo.svg`} alt="" />
        <Menu 
          onClick={e=> navigate(e.key)} 
          theme='dark'
          style={{background: "none"}}
          defaultOpenKeys={[defaultOpenKeys]}
          selectedKeys={[selectedKeys]}
          mode="inline" 
          items={items} 
        />
      </div>
  );
}

export default Sidenav;
