import { Button, Input, Modal, Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as CrossIcon } from '../../SVGs/cross_icon.svg';
import { ReactComponent as TickIcon } from '../../SVGs/tick_icon.svg';
import { ReactComponent as DisableIcon } from '../../SVGs/disabled_icon.svg';
import _ from "lodash";
const { TextArea } = Input;

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;

interface ModalProps {
    title: string;
    subTitle: string;
    btnText: string;
    loader: boolean;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    remark: string;
    setRemark: (remark: string) => void;
    handler: () => void;
    type: string;
}

const RemarkModal: React.FC<ModalProps> = ({ title, subTitle, btnText, loader, showModal, setShowModal, remark, setRemark, handler, type }) => {
    
    return (
        <Modal
            title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 700, color: "#404040", marginBottom: 10}}>{title}</Typography>}
            centered
            open={showModal}
            closable={true}
            onCancel={() => setShowModal(false)}
            afterClose={() => setRemark("")}
            className="preview-modal"
            width={500}
            footer={<>
                <div className="edit-section">
                    <Button
                        ghost
                        danger={type !== "bypass"}
                        type="primary"
                        onClick={_ => !loader && setShowModal(false)}
                        className={type === "bypass" ? "button-override primary" : "button-override danger"}
                        icon={ loader ? <Spin indicator={antIcon} /> : type === "bypass" ?<></> : <CrossIcon />}
                        >
                        {type === "bypass" ? "Don’t Bypass" : "Cancel"}
                    </Button>
                    <Button
                        disabled={_.isEmpty(remark)}
                        ghost
                        type="primary"
                        onClick={_ => !loader && handler()}
                        className={_.isEmpty(remark) ? "button-override disable" : type === "bypass" ? "button-override secondary" : "button-override primary"}
                        icon={ loader ? <Spin indicator={antIcon} /> : type === "bypass" ? <DisableIcon />  : <TickIcon /> }
                        >
                        {btnText}
                    </Button>
                </div>
            </>}
        >
            <Typography>{subTitle}</Typography>
            <TextArea rows={3} value={remark} onChange={(e) => setRemark(e.target.value)} placeholder="Remark" />
        </Modal>
    );
}

export default RemarkModal;